import api from './api';

export const saveProperty = async (communitiesIds, propertyId) => {
  try {
    const payload = { community_ids: communitiesIds };
    const options = {
      method: propertyId ? 'put' : 'post',
      url: propertyId ? `/v1/admin/properties/${propertyId}` : `/v1/admin/properties`,
      data: payload,
    };
    const response = await api(options);
    return response;
  } catch (err) {
    throw err;
  }
};

/**
 * Assign a property to an organization(admin only)
 * @param propertyId
 * @param organizationId
 * @returns {Promise<any>}
 */
export const assignProperty = async (propertyId, organizationId) => {
  try {
    const { data } = await api.patch(
      `/v1/admin/properties/${propertyId}/assign_to_organization`,
      {
        organization_id: organizationId,
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};
