import Loader from '../Loader/Loader';
import styles from './BookedTours.module.scss';
import { useBookedTours } from '../../hooks/useBookedTours';
import { Pagination } from '../Pagination/Pagination';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { BookedToursTable } from './BookedToursTable';

export const BookedToursList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const urlPage = useQueryParams().get('page') || '1';
  const { loading, data, meta } = useBookedTours(urlPage);

  useEffect(() => {
    if (!loading && !data) {
      enqueueSnackbar(
        `Sorry, an internal server error occurred. Please try again later.`,
        {
          variant: 'error',
        }
      );
    }
  }, [loading]);

  return (
    <div className={styles.list}>
      <Loader isLoading={loading}>
        {data && <BookedToursTable bookedTours={data} />}
      </Loader>

      {!loading && <Pagination meta={meta} columns={[]} data={data} />}
    </div>
  );
};
