import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Constants } from 'web-core';
import { changeUnitForm } from '../../../../redux/reducers';
import Button from '../../../Button/Button';
import DynamicForm from '../../../DynamicForm/DynamicForm';
import { BuildingFloorSelect } from './BuildingFloorSelect';
import { ModelSelectPopulateUnit } from './ModelSelectPopulateUnit';
import Icons from '../../../../constants/Icons';
import KthPopup from '../../../KthPopup/KthPopup';
import styles from './UnitsForm.module.scss';
import { selectUnitForm } from '../../../../redux/selectors/communityForm';

const UnitsForm = ({ onSave, onCancel, onDelete }) => {
  const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const origForm = useSelector(selectUnitForm);
  const [form, setForm] = useState(origForm);
  const dispatch = useDispatch();

  const handleFormDataChange = ({ formData }) => {
    dispatch(changeUnitForm(formData));
    setForm(formData);
  };

  const handleSaveForm = () => {
    dispatch(changeUnitForm(form));
    onSave(form);
  };

  const handleCancelForm = () => {
    onCancel();
  };

  const handleDeleteForm = () => {
    onDelete(form);
  };

  return (
    <div className={styles.popupForm}>
      <div className={styles.header}>
        <div>
          <h3>
            {form[Constants.DynamicSchemaFields.UnitName]
              ? form[Constants.DynamicSchemaFields.UnitName]
              : 'Unnamed Unit'}
          </h3>
          <p className={styles.tabDescription}>
            Add all the details for this Unit, paying attention to any differences it has
            from its Unit Type.
          </p>
        </div>
      </div>
      <div className={styles.popupFormSection}>
        <DynamicForm
          schema={jsonSchema}
          uiSchema={uiSchema}
          formData={form}
          onChange={handleFormDataChange}
          onSubmit={(ev) => ev.preventDefault()}
          noValidate
          widgets={{
            modelSelect: ModelSelectPopulateUnit,
            buildingFloorSelect: BuildingFloorSelect,
          }}
          formContext={{ currentTab: 'Units' }}
        ></DynamicForm>
      </div>
      <div className={`flex-kebab-row p-50 ${styles.bottomButtons}`}>
        <Button.LIGHT type='button' onClick={handleCancelForm}>
          Cancel
        </Button.LIGHT>
        <Button.DARK
          icon
          className='trashButton'
          type='button'
          onClick={() => setIsDeleteOpen(true)}
        >
          {Icons.Trash}
        </Button.DARK>
        <Button.DARK className='saveButton' onClick={handleSaveForm}>
          Save Unit
        </Button.DARK>
      </div>
      <KthPopup
        isOpen={isDeleteOpen}
        isLoading={null}
        title={`Delete Unit`}
        confirmButtonLabel={'Delete Unit'}
        onConfirmButtonClick={() => handleDeleteForm()}
        onClose={() => setIsDeleteOpen(false)}
      >
        <label>
          Are you sure you want to delete{' '}
          <strong>
            {form[Constants.DynamicSchemaFields.UnitName]
              ? `Unit ${form[Constants.DynamicSchemaFields.UnitName]}`
              : 'this Unit'}
          </strong>
          ?
        </label>
      </KthPopup>
    </div>
  );
};

export default UnitsForm;
