import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toFormData } from '../../../services/transform';
import { useSnackbar } from 'notistack';
import api from '../../../services/api';
import UnitImportTable from '../../../components/UnitImportTable/UnitImportTable';
import Loader from '../../../components/Loader/Loader';
import Button from '../../../components/Button/Button';
import styles from './UnitImportPage.module.scss';
import HeaderTitle from '../../../components/HeaderTitle/HeaderTitle';
import { useSelector } from 'react-redux';
import { APITools, Constants } from 'web-core';
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import useSWR from 'swr';
import { AccountRoles } from 'web-core/constants';
import { useOrganizationsInfinite } from '../../../hooks/useOrganizationsInfinite';

function UnitImportPage() {
  const account = useSelector((state) => state.auth.user);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [error, setError] = useState(false);
  const [showCredentials, setShowCredentials] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('');

  const shouldFetch =
    (account.role === AccountRoles.CorporateExecutive ||
      account.role === AccountRoles.Admin) &&
    !!selectedOrganizationId;

  const {
    data,
    isValidating,
    error: sftpCredentialsError,
  } = useSWR(
    shouldFetch
      ? `/v1/admin/sftp_credentials?organization_id=${selectedOrganizationId}`
      : null,
    api,
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );
  const credentials = data?.data?.credentials;

  const { organizations, loading: loadingOrganizations } = useOrganizationsInfinite(
    account.role === AccountRoles.Admin
  );

  useEffect(() => {
    if (account && account.organization) {
      setSelectedOrganizationId(account.organization.id);
    }
  }, [account]);

  const handleCSVUpload = async (event) => {
    setIsUploading(true);
    if (event.target.files?.length) {
      const data = toFormData({ file: event.target.files[0] });
      try {
        const response = await api.post(`/v1/admin/units/data_import`, data);
        setFileUploaded(response.data);
      } catch (err) {
        setError(err && err[0] ? err[0] : 'An internal server error occurred.');
        console.warn(err);
      }
      setIsUploading(false);
    }
  };

  const handleSubmitUnitData = async () => {
    setIsUploading(true);
    try {
      const response = await api.post(`/v1/admin/units/data_import/commit`, fileUploaded);
      setTimeout(() => {
        history.push('/properties');
      }, 2000);
      enqueueSnackbar(
        `Import successful! Communities for which units were updated have been submitted for approval.`,
        {
          variant: 'success',
        }
      );
    } catch (err) {
      setError(err && err[0] ? err[0] : 'An internal server error occurred');
      setFileUploaded(null);
    }
  };

  const handleCancelClick = () => {
    setIsUploading(false);
    setFileUploaded(null);
    setError(false);
  };

  const handleToggleShowCredentials = () => {
    setShowCredentials(!showCredentials);
  };

  const handleSelectOrganization = (e) => {
    setSelectedOrganizationId(e.target.value);
    if (!showCredentials) setShowCredentials(true);
  };

  const initialStatus = !isUploading && !error && !fileUploaded;
  const uploadingStatus = isUploading && !fileUploaded && !error;
  const verifyStatus = !error && fileUploaded;
  const errorStatus = !isUploading && error && !fileUploaded;

  return (
    <>
      <HeaderTitle title={'Unit Data Import'} />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>{verifyStatus ? 'Verify Data To Import' : 'Upload Unit File'}</h1>
          </div>
        </div>
        {initialStatus && (
          <>
            <p className={styles.tabDescription}>
              Individual unit data can be imported with a CSV or XLSX file, allowing for
              the import of a large number of unit data at one time. Before using the
              import tool, please download and review our sample{' '}
              <a
                href={'https://kw-example-files.s3.amazonaws.com/example-units-data.csv'}
                target='_blank'
                rel='noreferrer'
              >
                CSV
              </a>{' '}
              and{' '}
              <a
                href={'https://kw-example-files.s3.amazonaws.com/example-units-data.xlsx'}
                target='_blank'
                rel='noreferrer'
              >
                XSLX
              </a>{' '}
              files. The file you choose to import unit data should closely align with the
              template of the provided example. Additionally, make sure to add your PMS
              Unit Type ID to each matching Unit Type of each Community in our system.
              When you're ready to import data, drag and drop or select your file below to
              proceed.
            </p>
            <div className={styles.upload}>
              <h3>Drag & Drop Your Units CSV or XLSX File Here</h3>
              <hr />
              <Button.LIGHT>Browse your computer</Button.LIGHT>
              <input
                type='file'
                name='unitFileImport'
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv'
                onChange={handleCSVUpload}
              />
            </div>
            <div className={styles.yardiUpload}>
              <h3>Automated Upload via Yardi Export</h3>
              <p className={styles.tabDescription}>
                Work with your Yardi consultant to set up an automated nightly or weekly
                upload of unit data exported from Yardi. They will be able to create a
                repeating task that connects to our FTP server to upload the exported data
                in CSV or XLSX file format. Before setting this up, please make sure to
                add your PMS Unit Type ID to each matching Unit Type of each Community in
                our system.
              </p>
              <div className={styles.credentialsContainer}>
                {account.role === Constants.AccountRoles.CommunityManager && (
                  <p>
                    <b>
                      Ask your Corporate Executive or Kithward Support for help setting
                      this up.
                    </b>
                  </p>
                )}
                {account.role === Constants.AccountRoles.CorporateExecutive && (
                  <span className={styles.showCredentialsButton}>
                    <Button.BROWN onClick={handleToggleShowCredentials}>
                      View FTP Credentials
                    </Button.BROWN>
                  </span>
                )}
                {account.role === Constants.AccountRoles.Admin && [
                  <p style={{ display: 'block' }}>
                    View FTP Credentials for an Organization
                  </p>,
                  <FormControl
                    sx={{
                      width: '350px',
                      marginBottom: '24px',
                    }}
                  >
                    <InputLabel>Organization</InputLabel>
                    <Select
                      label='Organization'
                      onChange={handleSelectOrganization}
                      value={selectedOrganizationId}
                    >
                      <MenuItem disabled value=''>
                        <ListItemText secondary='Select an organization' />
                      </MenuItem>
                      {organizations
                        ? organizations.map((organization, index) => (
                            <MenuItem key={index} value={organization.id}>
                              {organization.name}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>,
                ]}
                <div className={styles.credentials}>
                  <Loader isLoading={isValidating}>
                    {credentials && showCredentials ? (
                      <>
                        <span>FTP Server: </span>
                        <p>
                          <b>sftp.kithward.com</b>
                        </p>
                        <span>FTP Username: </span>
                        <p>
                          <b>{`${credentials.sftp_upload_username}`}</b>
                        </p>
                        <span>FTP Password: </span>
                        <p>
                          <b>{`${credentials.sftp_upload_password}`}</b>
                        </p>
                      </>
                    ) : null}
                  </Loader>
                </div>
              </div>
            </div>
          </>
        )}
        {uploadingStatus && (
          <div className={styles.loadingDialogue}>
            <h3 className={styles.loading}>Processing Uploaded Data</h3>
            <Loader isLoading={true} />
          </div>
        )}
        {verifyStatus && (
          <>
            <p className={styles.tabDescription}>
              Each line item below represents a unit translated from the imported file.
              Please review the import information below, playing close attention to units
              marked in red. Lines with a green checkmark indicate that unit data is
              already available at a community. Units with a green plus sign indicate
              units that will be added to one of your community's profiles upon
              confirmation of the data. Units with a red X indicate unit data that is
              unable to be imported, no unit data will be translated from these items and
              fields unable to be validated will be marked in red text.
              <br />
              <br />
              After verifying the data below, you may confirm the import with the black
              button. If changes are needed or if you'd like to correct individual errors
              before proceeding, use the "Cancel Import" button and upload a different
              file or a file that has been modified with corrections.
            </p>
            <div className={styles.sectionHeader}>
              <span className={styles.sectionTitle}>Unit Information</span>
            </div>
            <UnitImportTable units={fileUploaded.uirs} />
            <div className={styles.actionButtons}>
              <Button.LIGHT onClick={() => handleCancelClick()}>
                Cancel Import
              </Button.LIGHT>
              <Button.DARK
                onClick={() => handleSubmitUnitData()}
                loading={isUploading}
                disabled={fileUploaded.meta.successful_units < 1}
                icon
              >
                Import {fileUploaded.meta.successful_units} of{' '}
                {fileUploaded.meta.total_units} Units
              </Button.DARK>
            </div>
          </>
        )}
        {errorStatus && (
          <>
            <div className={styles.error}>
              <b>Error - {error}</b>
              <p>
                No unit data was imported. Please double check that your CSV or XSLX file
                very closely resembles the example file found <a>here</a>. If you believe
                you're seeing this message in error, please reach out to Kithward support
                with an email including the file uploaded here as an attachment.
              </p>
              <Button.DARK onClick={() => handleCancelClick()}>Retry Import</Button.DARK>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default UnitImportPage;
