import styles from '../../pages/properties/AssessmentReportPage/AssessmentReportPage.module.scss';
import {Tooltip} from "@mui/material";
import React from "react";

interface Props {
  label: string;
  count: number;
  percent: number;
}

const Bar: React.FC<Props> = ({ label, count, percent }) => {
  return (
    <div key={label} className={styles.answer}>
      <p className={styles.label}>{label}</p>
      <div className={styles.barContainer}>
        <Tooltip
          title={`${label} - ${count} (${percent}%)`}
          arrow
        >
          <div
            className={styles.bar}
            style={{ width: `${percent || 1}%` }}
          />
        </Tooltip>
      </div>
      <div className={styles.count}>
        <p>{count}</p>
        <p>({percent}%)</p>
      </div>
    </div>
  );
};

export default Bar;