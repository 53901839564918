import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from '@mui/material';
import styles from './CustomRadio.module.scss';

export const CustomRadio = (props) => {
  const placeholder = props.placeholder || props.label || 'Select...';
  const valueType = props.schema.type;

  const handleRadioChange = (event) => {
    props.onChange(
      valueType === 'integer' ? parseInt(event.target.value) : event.target.value
    );
  };

  return (
    <FormControl component='fieldset' className={styles.radioWrapper}>
      <FormLabel component='legend' className={styles.label}>
        {placeholder}
      </FormLabel>
      <RadioGroup
        aria-label={placeholder}
        value={props.value || false}
        name='radio-buttons-group'
        className={styles.radioGroup}
        onChange={handleRadioChange}
      >
        {props.options.enumOptions.map(({ value, label }) => (
          <FormControlLabel value={value} control={<Radio />} label={label} key={value} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
