import { KWImage } from '../KWImage/KWImage';
import Loader from '../Loader/Loader';
import styles from './OrganizationList.module.scss';
import { Link } from 'react-router-dom';

export const OrganizationList = ({ organizations, isLoadingMore, onItemClick }) => {
  return (
    <>
      <div className={styles.organizationListContainer}>
        {organizations && organizations.length
          ? organizations.map((org, index) => (
              <div className={styles.organizationItem} key={index}>
                <KWImage
                  src={org.file_url}
                  alt={`${org.name} Logo`}
                  className={styles.logoImage}
                />
                <div className={styles.organizationItemText}>
                  <h4>{org.name || ''}</h4>
                  <div className={styles.orgLinks}>
                    <Link to={`/properties?organization_id=${org.id || ''}`}>
                      <p className='p3'>Properties</p>
                    </Link>
                    <p className='p3'>
                      <Link to={`/services?organization_id=${org.id || ''}`}>
                        Services
                      </Link>
                    </p>
                    <p className='p3'>
                      <Link to={`/users?organization_id=${org.id || ''}`}>
                        View Users
                      </Link>
                    </p>
                    <p className='p3' onClick={() => onItemClick(org)}>
                      Edit Organization
                    </p>
                    <p className='p3'>
                      <Link to={`/premium?organization_id=${org.id || ''}`}>Premium</Link>
                    </p>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
      <Loader isLoading={isLoadingMore} />
    </>
  );
};
