import styles from './UpsellPage.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarOfLife, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const UpsellPage = () => {
  return (
    <div className={styles.upsellPage}>
      <div>
        <h1>Kithward Growth Plans</h1>
        <h4>Select a plan for each of your properties</h4>
      </div>
      <div>
        <table>
          <tbody>
            <tr>
              <td></td>
              <td style={{ paddingTop: '2em' }}>
                <h5>Free</h5>
              </td>
              <td style={{ paddingTop: '2em' }}>
                <h5>Premium</h5>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Community Profile</h6>
                <p>
                  Visitors to kithward.com can discover your community via our advanced
                  filters.
                </p>
              </td>
              <td>
                <FontAwesomeIcon icon={faCircleCheck} className={styles.faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCircleCheck} className={styles.faCheck} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>Educational Assessment</h6>
                <p>
                  Visitors to your website, blog or other marketing contact points can
                  fill out our educational assessment to help qualify them and reduce
                  touch points to move in to your community.
                </p>
              </td>
              <td>—</td>
              <td>
                <FontAwesomeIcon icon={faCircleCheck} className={styles.faCheck} />
              </td>
            </tr>
            <tr>
              <td>
                <h6>Financial Assessment</h6>
                <p>
                  Visitors to your website, blog or other marketing contact points can
                  fill out our financial assessment to help determine if they can afford
                  to live at your community.
                </p>
              </td>
              <td>—</td>
              <td>
                <FontAwesomeIcon icon={faCircleCheck} className={styles.faCheck} />
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <td>*/}
            {/*    <h6>Assessment Reporting</h6>*/}
            {/*    <p>You will have access to advanced reporting features of results from your educational and financial assessments.</p>*/}
            {/*  </td>*/}
            {/*  <td>—</td>*/}
            {/*  <td><FontAwesomeIcon icon={faCircleCheck} className={styles.faCheck}/></td>*/}
            {/*</tr>*/}
            <tr style={{ borderTop: '1px solid black' }}>
              <td>
                <h6>New Resident Placement Service</h6>
                <p>
                  We will refer potential residents to you based on their interest in your
                  community.
                </p>
              </td>
              <td className={styles.newResident}>
                <FontAwesomeIcon icon={faStarOfLife} className={styles.faStar} />
                Paid per placement
              </td>
              <td className={styles.newResident}>
                <FontAwesomeIcon icon={faStarOfLife} className={styles.faStar} />
                Paid per placement
              </td>
            </tr>
            <tr style={{ borderTop: '1px solid black' }}>
              <td>
                <h6>Price per Property</h6>
                <p>
                  Please contact us if you would like to discuss bulk pricing for multiple
                  properties.
                </p>
              </td>
              <td>
                <div className={styles.price}>$0</div>
                <div className={styles.priceText}>per month</div>
              </td>
              <td className={styles.priceText}>Contact us</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UpsellPage;
