import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Constants } from 'web-core';
import { KWImage } from '../../../../KWImage/KWImage';
import styles from '../../../TabContent/TabContent.module.scss';
import { MenuItem, Grid, TextField } from '@mui/material';

export const ModelRow = ({ model, onFieldChange }) => {
  const { jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const {
    ModelName: modelName,
    ModelUnitType: unitType,
    ModelBedrooms: bedrooms,
    ModelBathrooms: bathrooms,
  } = Constants.DynamicSchemaFields;

  const modelImage = useSelector((state) =>
    state.communityForm.model_type_images.find(
      (image) => image.id === model[Constants.DynamicSchemaFields.ModelImage]
    )
  );
  const unitTypes = jsonSchema.properties[Constants.DynamicSchemaFields.ModelUnitType];

  const [unitTypeOptions] = useState(unitTypes.enum);
  // NOTE: Working, but should we also move bedroomOptions and bathroomOptions to dynamic schema like unitTypeOptions later?
  const [bedroomOptions] = useState(['Studio', '0', '1', '2', '3', '4', '5']);
  const [bathroomOptions] = useState([
    '0',
    '1',
    '1.5',
    '2',
    '2.5',
    '3',
    '3.5',
    '4',
    '4.5',
    '5',
  ]);

  const getBathroomCount = () => {
    let bathroomArray = model[bathrooms];
    if (bathroomArray && bathroomArray.length > 0) {
      let bathroomCount = 0;
      bathroomArray.forEach((bathroom) => {
        bathroomCount +=
          bathroom[Constants.DynamicSchemaFields.ModelBathroomType] ===
          Constants.BathroomTypes.FullBathroom
            ? 1
            : 0.5;
      });
      return String(bathroomCount);
    } else if (bathroomArray && bathroomArray.length === 0) {
      return '0';
    } else {
      return '';
    }
  };
  const getBedroomCount = () => {
    let bedroomArray = model[bedrooms];
    if (bedroomArray && bedroomArray.length > 0) {
      return String(bedroomArray.length);
    } else if (bedroomArray && bedroomArray.length === 0) {
      return model[Constants.DynamicSchemaFields.ModelHasStudioLayout] ? 'Studio' : '0';
    } else {
      return '';
    }
  };
  const [bedroomCount, setBedroomCount] = useState(getBedroomCount());
  const [bathroomCount, setBathroomCount] = useState(getBathroomCount());

  const handleChange = async (event) => {
    const { name, value } = event?.target;
    if (name === Constants.DynamicSchemaFields.ModelBathrooms) {
      setBathroomCount(value);
    } else {
      setBedroomCount(value);
    }
    onFieldChange(event);
  };

  return (
    <div className={styles.tableRow}>
      <div className={`${styles.tableCell} ${styles.photoCell}`}>
        {modelImage && (
          <KWImage
            src={modelImage.file_url}
            width={192}
            height={136}
            alt={`Unit Type Image`}
          />
        )}
      </div>
      <div className={styles.tableCell}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <TextField
              value={model[modelName] || ''}
              onChange={onFieldChange}
              autoComplete='off'
              name={modelName}
              label='Unit Type Name'
              fullWidth
            />
          </Grid>
          <Grid item xs={3}></Grid>

          <Grid item xs={3}>
            <TextField
              select
              value={model[unitType] || ''}
              label='Type'
              name={unitType}
              autoComplete='off'
              onChange={onFieldChange}
              fullWidth
            >
              {unitTypeOptions.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              value={bedroomCount || ''}
              label='# of Bedrooms'
              name={bedrooms}
              onChange={handleChange}
              fullWidth
            >
              {bedroomOptions.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField
              select
              value={bathroomCount || ''}
              label='# of Bathrooms'
              name={bathrooms}
              onChange={handleChange}
              fullWidth
            >
              {bathroomOptions.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
