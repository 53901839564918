import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Utilities } from 'web-core';

const HeaderTitle = ({ title }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{Utilities.generateKWPageTitle(title)}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default HeaderTitle;
