import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import Icons from '../../../../constants/Icons';
import { changeUnitForm, formFieldChange } from '../../../../redux/reducers';
import Button from '../../../Button/Button';
import styles from '../../TabContent/TabContent.module.scss';
import UnitsForm from './UnitsForm';
import UnitsTable from './UnitsTable';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const UnitsTab = () => {
  const units = useSelector((state) => state.communityForm.formData.units) || [];
  const [editUnitIndex, setEditUnitIndex] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const dispatch = useDispatch();

  const showCreateUnitForm = () => {
    dispatch(changeUnitForm({}));
    setEditUnitIndex(null);
    setIsFormVisible(true);
  };

  const showEditUnitForm = (index) => {
    dispatch(changeUnitForm(units[index]));
    setEditUnitIndex(index);
    setIsFormVisible(true);
    window.scrollTo(0, 0);
  };

  const handleFormCancel = () => {
    dispatch(changeUnitForm({}));
    setEditUnitIndex(null);
    setIsFormVisible(false);
  };

  const handleFormSave = (unitSaved) => {
    dispatch(changeUnitForm({}));
    setIsFormVisible(false);
    if (Number.isInteger(editUnitIndex)) {
      // Is editing
      dispatch(
        formFieldChange(
          'units',
          units.map((unit, index) => (index !== editUnitIndex ? unit : unitSaved))
        )
      );
      setEditUnitIndex(null);
    } else {
      // Is create new
      const dateCreated = new Date().toISOString();
      dispatch(
        formFieldChange('units', [...units, { ...unitSaved, dateCreated, id: uuidv4() }])
      );
    }
  };

  const handleModelDelete = (unitDelete) => {
    setIsFormVisible(false);
    if (Number.isInteger(editUnitIndex)) {
      dispatch(
        formFieldChange(
          'units',
          units.filter((unit, index) => index !== editUnitIndex)
        )
      );
    } else {
      // delete unit
      dispatch(
        formFieldChange(
          'units',
          units.filter((unit, index) => index !== unitDelete)
        )
      );
    }
    setEditUnitIndex(null);
    dispatch(changeUnitForm({}));
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {
        <Popup
          open={isFormVisible}
          modal
          nested
          closeOnDocumentClick={false}
          closeOnEscape={false}
          lockScroll={true}
        >
          <UnitsForm
            onSave={handleFormSave}
            onDelete={handleModelDelete}
            onCancel={handleFormCancel}
          />
        </Popup>
      }
      {
        <section className={styles.formSection}>
          <div className={styles.sectionHeader}>
            <span className={styles.sectionTitle}>Unit Information</span>
            <span>
              <Button.LIGHT
                className={styles.sectionTitleButton}
                onClick={showCreateUnitForm}
              >
                + New Unit
              </Button.LIGHT>
              <Link to='/import'>
                <Button.LIGHT className={styles.sectionTitleButton}>
                  Import Units CSV/XLXS
                </Button.LIGHT>
              </Link>
            </span>
          </div>
          <UnitsTable units={units} onRowClick={showEditUnitForm} />
          <div className={styles.tabFooter}>
            <Button.DARK icon onClick={showCreateUnitForm}>
              {Icons.Plus}
            </Button.DARK>
          </div>
        </section>
      }
    </div>
  );
};

export default UnitsTab;
