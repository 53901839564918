import styles from './PropertyList.module.scss';
import { useHistory } from 'react-router-dom';

export const PropertyItem = ({ row }) => {
  const history = useHistory();
  const goToViewProperty = () => {
    if (row.original.slug) {
      history.push(`/properties/${row.original.slug}`);
    }
  };

  return (
    <tr className={styles.propertyItem} {...row.getRowProps()} onClick={goToViewProperty}>
      {row.cells.map((cell) => (
        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
      ))}
    </tr>
  );
};
