import moment from 'moment-timezone';

const convertDurationStrToMinutes = (durationStr) => {
  const matches = /(?:(\d+):?)? ?(?:(\d+)?)?/.exec(durationStr);
  return Number(matches[1] || 0) * 60 + Number(matches[2] || 0);
};

function toTimeZone(time, zone) {
  // var format = 'YYYY/MM/DD HH:mm';
  const usersTimezoneOffset = new Date().getTimezoneOffset() * -1;
  const communityTimezoneOffset = moment.tz(zone).utcOffset();
  const fixDate = new Date(time);
  fixDate.setMinutes(
    fixDate.getMinutes() + (usersTimezoneOffset - communityTimezoneOffset)
  );
  return moment.tz(fixDate, zone).format();
}

export { convertDurationStrToMinutes, toTimeZone };
