import useSWRInfinite from 'swr/infinite';
import useSWR from 'swr';
import api from '../services/api';

// A function to get the SWR key of each page,
// its return value will be accepted by `fetcher`.
// If `null` is returned, the request of that page won't start.
const getKey =
  (communityId) =>
  (pageIndex = 0, previousPageData) => {
    if (previousPageData && previousPageData?.data?.meta?.last_page) {
      return null; // reached the end
    } else {
      return `/v1/admin/communities/${communityId}/draft_changes?page=${pageIndex + 1}`; // SWR key
    }
  };

export const useCommunityQueuedChangesInfinite = (communityId) => {
  const {
    data: responses,
    error,
    size,
    setSize,
  } = useSWRInfinite(getKey(communityId), api, {
    errorRetryCount: 3,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
  const lastResponseData = responses ? responses[responses.length - 1].data : undefined;

  const loadMore = () => {
    if (lastResponseData?.meta?.last_page === false) {
      setSize(size + 1);
    }
  };

  const restartPages = () => {
    setSize(1);
  };

  const data = responses ? [].concat(...responses.map((res) => res.data)) : null;

  return {
    loading: !responses && !error,
    data: data,
    meta: responses && lastResponseData ? lastResponseData.meta : null,
    error,
    loadMore,
    restartPages,
  };
};

export const useSingleCommunityChanges = (communityId) => {
  const { data, error, isValidating } = useSWR(
    communityId ? `/v1/admin/communities/${communityId}/draft_changes` : null,
    api,
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  return {
    loading: isValidating,
    data: data && data.data ? data.data : undefined,
    error,
  };
};
