import styles from './OnboardingVideo.module.scss';
import Button from '../../Button/Button';
import ReactPlayer from 'react-player/lazy';

type OnboardingVideoProps = {
  onContinue: () => void;
};

const videoUrl =
  'https://cdn.kithward.com/videos/kithward-how-to-edit-your-community-profile.mp4';

const OnboardingVideo = ({ onContinue }: OnboardingVideoProps) => {
  return (
    <div className={styles.onboardingVideoPopup}>
      <h3>Learn How To Edit Your Kithward Community Profile</h3>
      <div className={styles.videoSection}>
        <ReactPlayer url={videoUrl} controls={true} width='100%' height='100%' />
      </div>

      <div className={styles.bottomButtons}>
        <Button.DARK onClick={onContinue}>Get Started</Button.DARK>
      </div>
    </div>
  );
};

export default OnboardingVideo;
