/* ============================================================================================== */

import produce from 'immer';
import CommunityFormAdapter from '../../adapters/CommunityFormAdapter';
import FormType from '../../constants/FormType';
import api from '../../services/api';
import { parseObjectUrlsToBlob, toFormData } from '../../services/transform';
import { getFormWithPopulatedArrays } from '../../utils/communityFormUtils';

export const CommunityFormActions = {
  // Form
  FORM_FIELD_CHANGE: 'community/FORM_FIELD_CHANGE',
  FORM_MULTIPLE_FIELDS_CHANGE: 'community/FORM_MULTIPLE_FIELDS_CHANGE',
  FORM_DATA_RESET: 'community/FORM_DATA_RESET',
  FORM_DATA_CHANGE: 'community/FORM_DATA_CHANGE',
  FORM_RESET: 'community/FORM_RESET',
  FORM_TYPE_CHANGE: 'community/FORM_TYPE_CHANGE',
  FORM_SHOW_NAVIGATION_FOOTER: 'community/FORM_SHOW_NAVIGATION_FOOTER',
  FORM_HIDE_NAVIGATION_FOOTER: 'community/FORM_HIDE_NAVIGATION_FOOTER',
  FORM_FIELDSUPDATE_PENDING: 'community/FORM_FIELDSUPDATE_PENDING',
  FORM_FIELDSUPDATE_SUCCESS: 'community/FORM_FIELDSUPDATE_SUCCESS',
  FORM_FIELDSUPDATE_HIDEFIELDS: 'community/FORM_FIELDSUPDATE_HIDEFIELDS',
  FORM_FIELDSUPDATE_RESETHIDEFIELDS: 'community/FORM_FIELDSUPDATE_RESETHIDEFIELDS',
  FORM_SET_DIRTY: 'community/FORM_SET_DIRTY',
  FORM_CLEAR_DIRTY: 'community/FORM_CLEAR_DIRTY',

  // Model Form
  MODEL_FORM_CHANGE: 'community/MODEL_FORM_CHANGE',

  // Buildings Form
  BUILDINGS_FORM_CHANGE: 'community/BUILDINGS_FORM_CHANGE',

  // Unit Form
  UNIT_FORM_CHANGE: 'community/UNIT_FORM_CHANGE',

  // Assignable Properties
  LOAD_ASSIGNABLE_PROPERTIES_PENDING: 'community/LOAD_ASSIGNABLE_PROPERTIES_PENDING',
  LOAD_ASSIGNABLE_PROPERTIES_SUCCEEDED: 'community/LOAD_ASSIGNABLE_PROPERTIES_SUCCEEDED',
  LOAD_ASSIGNABLE_PROPERTIES_FAILED: 'community/LOAD_ASSIGNABLE_PROPERTIES_FAILED',

  // Images Field change
  IMAGES_FIELD_CHANGE: 'community/IMAGES_FIELD_CHANGE',

  // Image Upload
  IMAGES_UPLOAD_PENDING: 'community/IMAGES_UPLOAD_PENDING',
  IMAGES_UPLOAD_SUCCEEDED: 'community/IMAGES_UPLOAD_SUCCEEDED',
  IMAGES_UPLOAD_FAILED: 'community/IMAGES_UPLOAD_FAILED',

  // Image Delete
  IMAGE_DELETE_PENDING: 'community/IMAGE_DELETE_PENDING',
  IMAGE_DELETE_SUCCEEDED: 'community/IMAGE_DELETE_SUCCEEDED',
  IMAGE_DELETE_FAILED: 'community/IMAGE_DELETE_FAILED',

  // Upload floor plan images where each one is for a separate Unit Type
  MODELS_IMAGES_UPLOAD_PENDING: 'community/MODELS_IMAGES_UPLOAD_PENDING',
  MODELS_IMAGES_UPLOAD_SUCCEEDED: 'community/MODELS_IMAGES_UPLOAD_SUCCEEDED',
  MODELS_IMAGES_UPLOAD_FAILED: 'community/MODELS_IMAGES_UPLOAD_FAILED',

  // uploaded additional images to a single unit type
  UNIT_TYPE_IMAGES_UPLOAD_SUCCEEDED: 'community/UNIT_TYPE_IMAGES_UPLOAD_SUCCEEDED',

  // Model Editing change
  MODEL_EDITING_CHANGE: 'community/MODEL_EDITING_CHANGE',

  // Get
  GET_SCHEMAS_PENDING: 'community/GET_SCHEMAS_PENDING',
  GET_SCHEMAS_SUCCEEDED: 'community/GET_SCHEMAS_SUCCEEDED',
  GET_SCHEMAS_FAILED: 'community/GET_SCHEMAS_FAILED',

  // Start Create New Community
  START_CREATE_PENDING: 'community/START_CREATE_PENDING',
  START_CREATE_SUCCEEDED: 'community/START_CREATE_SUCCEEDED',
  START_CREATE_FAILED: 'community/START_CREATE_FAILED',

  // Get
  GET_PENDING: 'community/GET_PENDING',
  GET_SUCCEEDED: 'community/GET_SUCCEEDED',
  GET_FAILED: 'community/GET_FAILED',

  // Save
  SAVE_PENDING: 'community/SAVE_PENDING',
  SAVE_SUCCEEDED: 'community/SAVE_SUCCEEDED',
  SAVE_FAILED: 'community/SAVE_FAILED',

  // Overlay
  CHANGE_SHOW_OVERLAY: 'community/CHANGE_SHOW_OVERLAY',

  // Copyable siblings
  LOAD_COPYABLE_SIBLINGS_PENDING: 'community/LOAD_COPYABLE_SIBLINGS_PENDING',
  LOAD_COPYABLE_SIBLINGS_SUCCEEDED: 'community/LOAD_COPYABLE_SIBLINGS_SUCCEEDED',
  LOAD_COPYABLE_SIBLINGS_FAILED: 'community/LOAD_COPYABLE_SIBLINGS_FAILED',

  // Copy community attributes from a sibling community
  COPY_COMMUNITY_ATTRIBUTES_PENDING: 'community/COPY_COMMUNITY_ATTRIBUTES_PENDING',
  COPY_COMMUNITY_ATTRIBUTES_SUCCEED: 'community/COPY_COMMUNITY_ATTRIBUTES_SUCCEED',
  COPY_COMMUNITY_ATTRIBUTES_FAILED: 'community/COPY_COMMUNITY_ATTRIBUTES_FAILED',
};

export function startCreateNewCommunity() {
  return async (dispatch) => {
    dispatch({ type: CommunityFormActions.START_CREATE_PENDING });
    try {
      const { data } = await api.post(`/v1/admin/communities`);
      dispatch({
        type: CommunityFormActions.START_CREATE_SUCCEEDED,
        payload: data,
      });
    } catch (err) {
      dispatch({ type: CommunityFormActions.START_CREATE_FAILED });
    }
  };
}

// Previously used for static form.
export const formFieldChange = (field, value) => {
  return (dispatch) => {
    dispatch({ type: CommunityFormActions.FORM_FIELD_CHANGE, payload: { field, value } });
  };
};

export const formMultipleFieldsChange = (partialNewFormData) => {
  return (dispatch) => {
    dispatch({
      type: CommunityFormActions.FORM_MULTIPLE_FIELDS_CHANGE,
      payload: partialNewFormData,
    });
  };
};

// Used for dynamic forms
export const formDataChange = (formData) => {
  return async (dispatch) => {
    await dispatch({ type: CommunityFormActions.FORM_DATA_CHANGE, payload: formData });
  };
};

export const changeModelForm = (modelForm) => {
  return (dispatch) => {
    dispatch({ type: CommunityFormActions.MODEL_FORM_CHANGE, payload: modelForm });
  };
};

export const changeBuildingsForm = (form) => {
  return (dispatch) => {
    dispatch({ type: CommunityFormActions.BUILDINGS_FORM_CHANGE, payload: form });
  };
};

export const changeUnitForm = (form) => {
  return (dispatch) => {
    dispatch({ type: CommunityFormActions.UNIT_FORM_CHANGE, payload: form });
  };
};

export const facilitiesOrServicesFieldChange = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: CommunityFormActions.FORM_FIELDSUPDATE_HIDEFIELDS,
      payload: { field, value },
    });
  };
};

export const facilitiesOrServicesRowChange = () => {
  return async (dispatch) => {
    await dispatch({ type: CommunityFormActions.FORM_FIELDSUPDATE_PENDING });
    await dispatch({ type: CommunityFormActions.FORM_FIELDSUPDATE_SUCCESS });
    await dispatch({ type: CommunityFormActions.FORM_FIELDSUPDATE_RESETHIDEFIELDS });
  };
};

export const getSchemas = (communityId) => {
  return async (dispatch) => {
    dispatch({ type: CommunityFormActions.GET_SCHEMAS_PENDING });
    try {
      const response = await api(`/v1/communities/schemas`, {
        params: { communityId: communityId },
      });
      dispatch({
        type: CommunityFormActions.GET_SCHEMAS_SUCCEEDED,
        payload: response.data,
      });
    } catch (err) {
      dispatch({ type: CommunityFormActions.GET_SCHEMAS_FAILED });
    }
  };
};

export const formReset = () => {
  return (dispatch) => {
    dispatch({ type: CommunityFormActions.FORM_RESET });
  };
};

export const setCommunityFormType = (formType) => {
  return (dispatch) => {
    dispatch({ type: CommunityFormActions.FORM_TYPE_CHANGE, payload: formType });
  };
};

export const setFormNavigationFooterVisibility = (isVisible) => {
  return (dispatch) => {
    dispatch({
      type: isVisible
        ? CommunityFormActions.FORM_SHOW_NAVIGATION_FOOTER
        : CommunityFormActions.FORM_HIDE_NAVIGATION_FOOTER,
    });
  };
};

export const getCommunity = (communityId, onNotAllowed) => {
  return async (dispatch) => {
    dispatch({ type: CommunityFormActions.GET_PENDING });
    try {
      const response = await api(`/v1/admin/communities/${communityId}`);
      dispatch({ type: CommunityFormActions.GET_SUCCEEDED, payload: response.data });
    } catch (err) {
      dispatch({ type: CommunityFormActions.GET_FAILED });
      if (err?.isNotAllowed && onNotAllowed) {
        onNotAllowed();
      }
    }
  };
};

export const deleteCommunityImage = (imageId) => {
  return async (dispatch, getState) => {
    dispatch({ type: CommunityFormActions.IMAGE_DELETE_PENDING, payload: imageId });
    try {
      const community = getState().communityForm.formData;
      const response = await api.delete(
        `/v1/admin/communities/${community.id}/community_images/${imageId}`
      );
      dispatch({ type: CommunityFormActions.IMAGE_DELETE_SUCCEEDED, payload: imageId });
      return response;
    } catch (err) {
      dispatch({ type: CommunityFormActions.IMAGE_DELETE_FAILED });
      return { errors: err };
    }
  };
};

export const loadAssignableProperties = (communityId) => {
  return async (dispatch) => {
    dispatch({ type: CommunityFormActions.LOAD_ASSIGNABLE_PROPERTIES_PENDING });
    try {
      const assignable_properties = await api.get(
        `/v1/admin/communities/${communityId}/assignable_properties`
      );
      dispatch({
        type: CommunityFormActions.LOAD_ASSIGNABLE_PROPERTIES_SUCCEEDED,
        payload: assignable_properties.data,
      });
      return assignable_properties;
    } catch (err) {
      dispatch({ type: CommunityFormActions.LOAD_ASSIGNABLE_PROPERTIES_FAILED });
      return { errors: err };
    }
  };
};

export const loadCopyableSiblings = (communityId) => {
  return async (dispatch) => {
    dispatch({ type: CommunityFormActions.LOAD_COPYABLE_SIBLINGS_PENDING });
    try {
      const copyable_siblings = await api.get(
        `/v1/admin/communities/${communityId}/eligible_siblings_to_copy_from`
      );
      dispatch({
        type: CommunityFormActions.LOAD_COPYABLE_SIBLINGS_SUCCEEDED,
        payload: copyable_siblings.data['results'],
      });
      return copyable_siblings;
    } catch (err) {
      dispatch({ type: CommunityFormActions.LOAD_COPYABLE_SIBLINGS_FAILED });
      return { errors: err };
    }
  };
};

export const changeImagesField = (images) => {
  return async (dispatch) => {
    dispatch({ type: CommunityFormActions.IMAGES_FIELD_CHANGE, payload: images });
  };
};

export const uploadCommunityImages = (images) => {
  return async (dispatch, getState) => {
    dispatch({ type: CommunityFormActions.IMAGES_UPLOAD_PENDING });
    try {
      const community = getState().communityForm;
      const responses = await Promise.all(
        images.map(async (image, index) => {
          const data = toFormData({
            image: image,
            sort_order:
              community.images.length > 0 ? community.images.length + (index + 1) : index,
          });
          return await api.post(
            `/v1/admin/communities/${community.formData.id}/community_images`,
            data
          );
        })
      );
      const datas = responses.map((r) => r.data);
      dispatch({ type: CommunityFormActions.IMAGES_UPLOAD_SUCCEEDED, payload: datas });
      return datas;
    } catch (err) {
      dispatch({ type: CommunityFormActions.IMAGES_UPLOAD_FAILED });
      return { errors: err };
    }
  };
};

// used to add multiple images where each image becomes a separate Unit Type
export const uploadModelsImages = (images) => {
  return async (dispatch, getState) => {
    dispatch({ type: CommunityFormActions.MODELS_IMAGES_UPLOAD_PENDING });
    try {
      const community = getState().communityForm.formData;
      const responses = await Promise.all(
        images.map(async (image) => {
          const data = toFormData({ image: image });
          return await api.post(
            `/v1/admin/communities/${community.id}/model_type_images`,
            data
          );
        })
      );
      const datas = responses.map((r) => r.data);
      dispatch({
        type: CommunityFormActions.MODELS_IMAGES_UPLOAD_SUCCEEDED,
        payload: datas,
      });
      return datas;
    } catch (err) {
      dispatch({ type: CommunityFormActions.MODELS_IMAGES_UPLOAD_FAILED });
      return { errors: err };
    }
  };
};

export const uploadedUnitTypeImages = (images) => {
  return async (dispatch) => {
    dispatch({
      type: CommunityFormActions.UNIT_TYPE_IMAGES_UPLOAD_SUCCEEDED,
      payload: images,
    });
  };
};

export const changeEditingModel = (modelIndex) => {
  return async (dispatch) => {
    dispatch({ type: CommunityFormActions.MODEL_EDITING_CHANGE, payload: modelIndex });
  };
};

export const updateCommunity = (version, forceSave = false) => {
  return async (dispatch, getState) => {
    await dispatch({ type: CommunityFormActions.SAVE_PENDING });
    try {
      const communityFormData = await parseObjectUrlsToBlob(
        getState().communityForm.formData
      );
      if (!communityFormData.id) {
        throw Error('Community ID is invalid');
      }
      const images = await parseObjectUrlsToBlob(getState().communityForm.images);
      const data = {
        json_data: communityFormData,
        images,
        version,
        force: forceSave,
      };
      const response = await api.patch(
        `/v1/admin/communities/${communityFormData.id}`,
        data
      );
      dispatch({ type: CommunityFormActions.SAVE_SUCCEEDED, payload: response.data });
      return response;
    } catch (err) {
      dispatch({ type: CommunityFormActions.SAVE_FAILED });
      return err;
    }
  };
};

export const changeShowOverlay = (isVisible) => {
  return async (dispatch) => {
    dispatch({ type: CommunityFormActions.CHANGE_SHOW_OVERLAY, payload: isVisible });
  };
};

export const copyIntoCommunity = (communityId) => {
  return async (dispatch, getState) => {
    await dispatch({ type: CommunityFormActions.COPY_COMMUNITY_ATTRIBUTES_PENDING });
    try {
      const community = getState().communityForm.formData;
      let data = { community_id: communityId };
      const response = await api.post(
        `/v1/admin/communities/${community.id}/copy_into`,
        data
      );
      dispatch({
        type: CommunityFormActions.COPY_COMMUNITY_ATTRIBUTES_SUCCEED,
        payload: response.data,
      });
      return response;
    } catch (err) {
      dispatch({ type: CommunityFormActions.COPY_COMMUNITY_ATTRIBUTES_FAILED });
      return { errors: err };
    }
  };
};

export const setFormDirty = () => {
  return (dispatch) => {
    dispatch({ type: CommunityFormActions.FORM_SET_DIRTY });
  };
};

export const resetFormDirty = () => {
  return (dispatch) => {
    dispatch({ type: CommunityFormActions.FORM_CLEAR_DIRTY });
  };
};

const INITIAL_STATE = {
  loading: false,
  loaded: false,
  showOverlay: false,
  isFormReadOnly: false,
  showNavigationFooter: true,
  modelIndexEditing: null,
  modelForm: {},
  buildingsForm: {},
  unitForm: {},
  isLoadingAssignableProperties: false,
  assignableProperties: [],
  isLoadingCopyableSiblings: false,
  copyableSiblings: [],
  isUploadingModelImage: false,
  isUploadingImage: false,
  imageBeingDeleted: null,
  isFormUpdating: false,
  isSaving: false,
  copyingCommunityAttributes: false,
  copySuccess: false,
  fieldsDeselected: [],
  images: [],
  model_type_images: [],
  schemas: {
    jsonSchema: {},
    uiSchema: {},
    loading: false,
  },
  formData: {
    buildings: [],
    models: [],
    units: [],
  },
  createdAt: null,
  dirty: false,
  latestEditVersion: 0,
};

const communityFormReducer = produce((draft, action) => {
  switch (action.type) {
    case 'app/RESET':
      return { ...INITIAL_STATE };

    // Schemas
    case CommunityFormActions.GET_SCHEMAS_PENDING:
      draft.schemas.loading = true;
      break;

    case CommunityFormActions.GET_SCHEMAS_SUCCEEDED: {
      draft.schemas.loading = false;
      draft.schemas.jsonSchema = action.payload.data;
      draft.schemas.uiSchema = action.payload.ui;

      // Start arrays with 1 item
      // const currentState = original(draft);
      // Object.entries(action.payload.data.properties).forEach(([key, value]) => {
      //   if(value.type === 'array' && value.items.type === 'object'){
      //     const isEmpty = !Boolean(currentState.formData[key]?.length);
      //     if(isEmpty){
      //       draft.formData[key] = [{}]
      //     }
      //   }
      // });
      break;
    }

    case CommunityFormActions.GET_SCHEMAS_FAILED:
      draft.schemas.loading = false;
      draft.schemas.jsonSchema = {};
      draft.schemas.uiSchema = {};
      break;

    // Form
    case CommunityFormActions.FORM_FIELD_CHANGE: {
      const { field, value } = action.payload;
      draft.formData[field] = value;
      draft.dirty = true;
      break;
    }

    case CommunityFormActions.FORM_MULTIPLE_FIELDS_CHANGE: {
      const partialFormData = action.payload;
      draft.formData = {
        ...draft.formData,
        ...partialFormData,
      };
      draft.dirty = true;
      break;
    }

    case CommunityFormActions.FORM_DATA_CHANGE:
      const formData = action.payload;
      draft.formData = formData;
      draft.dirty = true;
      break;

    case CommunityFormActions.FORM_DATA_RESET:
      draft.formData = INITIAL_STATE.formData;
      break;

    case CommunityFormActions.FORM_RESET:
      draft.formData = INITIAL_STATE.formData;
      draft.loaded = false;
      draft.copySuccess = false;
      draft.showNavigationFooter = INITIAL_STATE.showNavigationFooter;
      draft.dirty = false;
      break;

    case CommunityFormActions.FORM_TYPE_CHANGE: {
      const formType = action.payload;
      draft.isFormReadOnly = formType === FormType.VIEW;
      break;
    }

    case CommunityFormActions.FORM_SHOW_NAVIGATION_FOOTER:
      draft.showNavigationFooter = true;
      break;

    case CommunityFormActions.FORM_HIDE_NAVIGATION_FOOTER:
      draft.showNavigationFooter = false;
      break;

    // Edit Buildings / Unit Type / Unit Form
    // NOTE: Not setting dirty to true when form just opens(either creating a new building / unit type / unit
    //       or editing an existing one.
    case CommunityFormActions.BUILDINGS_FORM_CHANGE:
      draft.buildingsForm = getFormWithPopulatedArrays(
        action.payload,
        'buildings',
        draft.schemas.jsonSchema
      );
      break;

    // Unit Form
    case CommunityFormActions.UNIT_FORM_CHANGE: {
      draft.unitForm = getFormWithPopulatedArrays(
        action.payload,
        'units',
        draft.schemas.jsonSchema
      );
      break;
    }

    // Model Form
    case CommunityFormActions.MODEL_FORM_CHANGE:
      draft.modelForm = getFormWithPopulatedArrays(
        action.payload,
        'models',
        draft.schemas.jsonSchema
      );
      break;

    case CommunityFormActions.LOAD_ASSIGNABLE_PROPERTIES_PENDING:
      draft.isLoadingAssignableProperties = true;
      break;

    case CommunityFormActions.LOAD_ASSIGNABLE_PROPERTIES_SUCCEEDED: {
      draft.isLoadingAssignableProperties = false;
      draft.assignableProperties = action.payload;
      break;
    }

    case CommunityFormActions.LOAD_ASSIGNABLE_PROPERTIES_FAILED:
      draft.isLoadingAssignableProperties = false;
      break;

    // Images field change
    case CommunityFormActions.IMAGES_FIELD_CHANGE: {
      const images = action.payload;
      draft.images = images;
      draft.dirty = true;
      break;
    }

    // Image Upload
    case CommunityFormActions.IMAGES_UPLOAD_PENDING:
      draft.isUploadingImage = true;
      break;

    case CommunityFormActions.IMAGES_UPLOAD_SUCCEEDED: {
      draft.isUploadingImage = false;
      const newImages = action.payload;
      draft.images.push(...newImages);
      break;
    }

    case CommunityFormActions.IMAGES_UPLOAD_FAILED:
      draft.isUploadingImage = false;
      break;

    // Image Delete
    case CommunityFormActions.IMAGE_DELETE_PENDING:
      draft.imageBeingDeleted = action.payload;
      break;

    case CommunityFormActions.IMAGE_DELETE_SUCCEEDED: {
      draft.imageBeingDeleted = null;
      const deletedImageId = action.payload;
      draft.images = draft.images.filter((image) => image.id !== deletedImageId);
      break;
    }

    case CommunityFormActions.IMAGE_DELETE_FAILED:
      draft.imageBeingDeleted = null;
      break;

    // Model Image Upload
    case CommunityFormActions.MODELS_IMAGES_UPLOAD_PENDING:
      draft.isUploadingModelImage = true;
      break;

    case CommunityFormActions.MODELS_IMAGES_UPLOAD_SUCCEEDED: {
      draft.isUploadingModelImage = false;
      const newImages = action.payload;
      draft.model_type_images.push(...newImages);
      break;
    }

    case CommunityFormActions.MODELS_IMAGES_UPLOAD_FAILED:
      draft.isUploadingModelImage = false;
      break;

    case CommunityFormActions.UNIT_TYPE_IMAGES_UPLOAD_SUCCEEDED: {
      const newImages = action.payload;
      draft.model_type_images.push(...newImages);
      break;
    }

    // Model index Editing
    case CommunityFormActions.MODEL_EDITING_CHANGE:
      draft.modelIndexEditing = action.payload;
      break;

    //Services/Facilities Form Changes
    case CommunityFormActions.FORM_FIELDSUPDATE_PENDING:
      draft.isFormUpdating = true;
      break;

    case CommunityFormActions.FORM_FIELDSUPDATE_SUCCESS:
      draft.isFormUpdating = false;
      break;

    case CommunityFormActions.FORM_FIELDSUPDATE_RESETHIDEFIELDS:
      draft.fieldsDeselected = INITIAL_STATE.fieldsDeselected;
      break;

    case CommunityFormActions.FORM_FIELDSUPDATE_HIDEFIELDS:
      const draftClone = [
        ...draft.fieldsDeselected,
        { field: action.payload.field, value: action.payload.value },
      ];
      const filteredDraft = (arr, obj) =>
        arr && arr.map((t) => (t.field === obj.field ? obj : t));
      const draftToUpdate = filteredDraft(draftClone, {
        field: action.payload.field,
        value: action.payload.value,
      });
      const final = [...new Set(draftToUpdate)];
      draft.fieldsDeselected = final;
      draft.dirty = true;
      break;

    // SAVE
    case CommunityFormActions.SAVE_PENDING:
      draft.isSaving = true;
      break;

    case CommunityFormActions.SAVE_FAILED:
      draft.isSaving = false;
      break;

    // Get
    case CommunityFormActions.GET_PENDING:
      draft.loading = true;
      break;

    case CommunityFormActions.SAVE_SUCCEEDED:
    case CommunityFormActions.GET_SUCCEEDED:
      draft.isSaving = false;
      draft.loading = false;
      draft.dirty = false;
      draft.loaded = true;
      const parsedData = CommunityFormAdapter.fromApi({
        ...INITIAL_STATE.formData,
        ...action.payload,
      });
      Object.entries(parsedData).forEach(([key, value]) => {
        draft[key] = value;
      });
      break;

    case CommunityFormActions.GET_FAILED:
      draft.loading = false;
      draft.formData = INITIAL_STATE.formData;
      break;

    // Start Community Create
    case CommunityFormActions.START_CREATE_PENDING:
      draft.loading = true;
      break;

    case CommunityFormActions.START_CREATE_SUCCEEDED:
      draft.loading = false;
      const community = CommunityFormAdapter.fromApi(action.payload);
      draft.formData = community.formData;
      draft.images = community.images;
      break;

    case CommunityFormActions.START_CREATE_FAILED:
      draft.loading = false;
      draft.formData = INITIAL_STATE.formData;
      break;

    case CommunityFormActions.CHANGE_SHOW_OVERLAY:
      draft.showOverlay = action.payload;
      break;

    case CommunityFormActions.LOAD_COPYABLE_SIBLINGS_PENDING:
      draft.isLoadingCopyableSiblings = true;
      break;

    case CommunityFormActions.LOAD_COPYABLE_SIBLINGS_SUCCEEDED:
      draft.isLoadingCopyableSiblings = false;
      draft.copyableSiblings = action.payload;
      break;

    case CommunityFormActions.LOAD_COPYABLE_SIBLINGS_FAILED:
      draft.isLoadingCopyableSiblings = false;
      break;

    case CommunityFormActions.COPY_COMMUNITY_ATTRIBUTES_PENDING:
      draft.copyingCommunityAttributes = true;
      break;

    case CommunityFormActions.COPY_COMMUNITY_ATTRIBUTES_SUCCEED:
      draft.copyingCommunityAttributes = false;
      draft.copySuccess = true;
      draft.formData = action.payload;
      break;

    case CommunityFormActions.COPY_COMMUNITY_ATTRIBUTES_FAILED:
      draft.copyingCommunityAttributes = false;
      break;

    case CommunityFormActions.FORM_SET_DIRTY:
      draft.dirty = true;
      break;

    case CommunityFormActions.FORM_CLEAR_DIRTY:
      draft.dirty = false;
      break;

    default:
      break;
  }
}, INITIAL_STATE);

export default communityFormReducer;
