import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Constants } from 'web-core';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { Tooltip } from '@mui/material';

export const ModelSelect = (props) => {
  const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const formData = useSelector((state) => state.communityForm.formData);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const newOptions = formData.models
      ? formData.models
          .filter((m) => m.id)
          .map((m) => ({
            label: m[Constants.DynamicSchemaFields.ModelName],
            value: m.id,
          }))
      : [];

    setOptions(newOptions);
  }, [formData, uiSchema, jsonSchema]);

  return options.length === 0 ? (
    <Tooltip title='No Unit Types available to assign to this Unit. Create a Unit Type to associate this Unit with one.'>
      <div>
        <CustomSelect
          {...props}
          options={{
            enumOptions: options,
          }}
          disabled={options.length === 0}
        />
      </div>
    </Tooltip>
  ) : (
    <CustomSelect
      {...props}
      options={{
        enumOptions: options,
      }}
    />
  );
};
