import api from './api';

const PATH = `/v1/admin/community_claims`;

export const approveCommunityClaim = async (id, options) => {
  try {
    const { data } = options
      ? await api.post(
          `${PATH}/${id}/approve`,
          options.reassign_organization === true
            ? {
                reassign_organization: options.reassign_organization,
                property_id: options.property_id,
              }
            : {
                reassign_organization: options.reassign_organization,
              }
        )
      : await api.post(`${PATH}/${id}/approve`);
    return data;
  } catch (err) {
    throw err;
  }
};

export const rejectCommunityClaim = async (id) => {
  try {
    const { data } = await api.post(`${PATH}/${id}/reject`);
    return data;
  } catch (err) {
    throw err;
  }
};
