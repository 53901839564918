import styles from './Stats.module.scss';

export const Stats = ({ title, value }) => {
  return (
    <div className={styles.stats}>
      <h3 className={styles.statsValue}>{value.toLocaleString('en-US') || 0}</h3>
      <p className={styles.statsTitle}>{title || '-'}</p>
    </div>
  );
};
