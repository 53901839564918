import useSWR from 'swr';
import api from '../services/api';

const PATH = '/v1/admin/organizations';

export const useOrganization = (organizationId) => {
  const { data, error } = useSWR(
    organizationId ? `${PATH}/${organizationId}` : null,
    api,
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onErrorRetry: (err, key, config, revalidate, revalidateOps) => {
        console.log({ err, config });
      },
    }
  );
  return {
    loading: !data && !error,
    organization: data ? data.data.organization : null,
    error,
  };
};
