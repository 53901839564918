import { RootStateOrAny, useSelector } from 'react-redux';
import { Constants } from 'web-core';

type FeatureGatingProps = {
  children: any;
};

//This component is used in admin and web repos, if you have any modification for it do it in both.
export const FeatureGating = ({ children }: FeatureGatingProps) => {
  const account = useSelector((state: RootStateOrAny) => state.auth.user);
  const isSuperAdmin = account?.role === Constants.AccountRoles.Admin;

  // enable all feature gates in local dev when not logged in,
  // but disable in staging / prod unless super admin
  const featureEnabled =
    isSuperAdmin || (process.env.REACT_APP_ENVIRONMENT === 'development' && !account);

  return featureEnabled ? <>{children}</> : null;
};
