import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeShowOverlay,
  formDataChange,
  formMultipleFieldsChange,
  facilitiesOrServicesRowChange,
} from '../../../redux/reducers';
import { FieldTemplate, Section } from '../../DynamicForm/DynamicForm';
import { SquaredCheckbox } from '../../DynamicForm/Widgets/SquaredCheckbox/SquaredCheckbox';
import styles from './CheckboxesSelection.module.scss';
import Form from '@rjsf/core';
import Button from '../../Button/Button';

export const CheckboxesSelection = ({
  titleHeaderOpen,
  titleHeaderClosed,
  openInstructionsMessage,
  checkboxesContext,
  hasSelectionChecked,
}) => {
  const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const formData = useSelector((state) => state.communityForm.formData);
  const [isOpen, setIsOpen] = useState(!hasSelectionChecked);
  const dispatch = useDispatch();
  const hasCheckboxes =
    uiSchema?.checkboxesSelection &&
    checkboxesContext &&
    uiSchema?.checkboxesSelection[checkboxesContext];

  const handleToggleBody = async () => {
    dispatch(changeShowOverlay(!isOpen));
    setIsOpen(!isOpen);
    dispatch(facilitiesOrServicesRowChange());
    window.scrollTo(0, 0);
  };

  const handleFormDataChange = ({ formData }) => {
    dispatch(formDataChange(formData));
  };

  useEffect(() => {
    dispatch(changeShowOverlay(isOpen));
  }, []);

  return !hasCheckboxes ? null : (
    <div className={styles.checkboxesSelection} data-isopen={isOpen}>
      <div className={styles.selectionHeader} onClick={handleToggleBody}>
        <h4 className={styles.title}>
          {isOpen
            ? titleHeaderOpen || 'Edit Details'
            : titleHeaderClosed || 'Select Residence details'}
        </h4>
      </div>
      <div
        className={`${styles.selectionBody} ${
          isOpen ? styles.isBodyOpen : styles.isBodyClosed
        }`}
      >
        {isOpen ? <p className={styles.instructions}>{openInstructionsMessage}</p> : null}
        <Form
          uiSchema={uiSchema}
          schema={jsonSchema}
          formData={formData}
          ObjectFieldTemplate={ObjectCheckboxesTemplate}
          FieldTemplate={FieldTemplate}
          widgets={{
            CheckboxWidget: SquaredCheckbox,
          }}
          onChange={handleFormDataChange}
          onSubmit={() => console.log('submitted')}
          onError={() => console.log('errors')}
          formContext={{ currentCheckboxes: checkboxesContext }}
        >
          {/* Fragment used to Hide form submit button */}
          <Fragment />
        </Form>
        <Button.DARK
          className={styles.collapseSelectionButton}
          onClick={handleToggleBody}
        >
          Done
        </Button.DARK>
      </div>
    </div>
  );
};

const ObjectCheckboxesTemplate = (props) => {
  const checkboxesSelection =
    props.uiSchema?.checkboxesSelection[props.formContext.currentCheckboxes];
  if (checkboxesSelection) {
    return Object.entries(checkboxesSelection).map(([sectionTitle, sectionFields]) => {
      return (
        <CheckboxesSection
          key={sectionTitle}
          sectionTitle={sectionTitle}
          sectionFields={sectionFields}
          {...props}
        />
      );
    });
  } else {
    return props.properties.map((prop) => prop.content);
  }
};

const CheckboxesSection = ({ sectionTitle, sectionFields, ...props }) => {
  const dispatch = useDispatch();

  const isAllChildrenSelected = useSelector((state) => {
    return Object.values(sectionFields).every(
      (fieldName) => state.communityForm.formData[fieldName]
    );
  });

  const handleAllSelectChange = () => {
    const newFieldsValues = Object.values(sectionFields).reduce((acc, fieldName) => {
      return {
        ...acc,
        [fieldName]: !isAllChildrenSelected,
      };
    }, {});
    dispatch(formMultipleFieldsChange(newFieldsValues));
  };

  return (
    <Section title={sectionTitle}>
      <div className={styles.sectionContent}>
        <div className={styles.selectAllContainer}>
          <SquaredCheckbox
            id={`${sectionTitle}-selectAll`}
            value={isAllChildrenSelected}
            onChange={handleAllSelectChange}
            label='Select All'
          />
        </div>
        <div className={styles.checkboxesGrid}>
          {Object.values(sectionFields).map(
            (fieldName) =>
              props.properties.find((property) => property.name === fieldName)?.content
          )}
        </div>
      </div>
    </Section>
  );
};
