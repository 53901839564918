import { useMemo, useState, useEffect } from 'react';
import Button from '../Button/Button';
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
} from 'react-table';
import { useLocation, useHistory } from 'react-router-dom';
import styles from './Pagination.module.scss';
import { getQueryParams } from '../../utils/queryParams';

export const Pagination = ({ meta, columns, data }) => {
  const location = useLocation();
  let history = useHistory();
  const [pageInputValue, setPageInputValue] = useState(0);

  const paginationOptions = meta
    ? {
        initialState: {
          pageSize: meta['per_page'],
          pageIndex: meta['current_page'] - 1,
        },
        pageCount: meta['total_pages'],
        manualPagination: true,
      }
    : null;

  const defaultColumn = useMemo(
    () => ({
      minWidth: 150, // minWidth is only used as a limit for resizing
      width: '100%',
    }),
    []
  );
  const {
    canNextPage,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: data,
      defaultColumn,
      ...paginationOptions,
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  );

  const gotoFirstPage = () => {
    gotoPage(0);
    setPageInputValue(1);
    let params = getQueryParams();
    params.set('page', '1');
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const gotoLastPage = () => {
    gotoPage(pageCount - 1);
    setPageInputValue(pageCount);
    let params = getQueryParams();
    params.set('page', pageCount);
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const gotoPreviousPage = () => {
    previousPage();
    setPageInputValue(pageIndex);
    let params = getQueryParams();
    params.set('page', pageIndex);
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const gotoNextPage = () => {
    nextPage();
    setPageInputValue(pageIndex + 2);
    let params = getQueryParams();
    params.set('page', pageIndex + 2);
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    gotoPage(pageInputValue);
    setPageInputValue(pageInputValue);
    let params = getQueryParams();
    params.set('page', pageInputValue);
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  useEffect(() => {
    setPageInputValue(pageIndex + 1);
  }, [pageIndex]);

  return meta?.total_pages > 1 ? (
    <div className={styles.paginationControls}>
      <Button.LIGHT onClick={gotoFirstPage} disabled={pageIndex <= 0}>
        {'<<'}
      </Button.LIGHT>{' '}
      <Button.LIGHT onClick={gotoPreviousPage} disabled={pageIndex <= 0}>
        {'<'}
      </Button.LIGHT>{' '}
      <Button.LIGHT onClick={gotoNextPage} disabled={!canNextPage}>
        {'>'}
      </Button.LIGHT>{' '}
      <Button.LIGHT onClick={gotoLastPage} disabled={!canNextPage}>
        {'>>'}
      </Button.LIGHT>{' '}
      <span>
        Page{' '}
        <strong>
          {pageIndex + 1} of {pageCount}
        </strong>{' '}
      </span>
      <span>
        | Go to page:{' '}
        <form style={{ display: 'inline-block' }} onSubmit={handleSubmit}>
          <input
            type='number'
            value={String(pageInputValue) || ''}
            onChange={(ev) => setPageInputValue(Number(ev.target.value))}
            min='1'
            max={`${pageCount}`}
            style={{ width: '100px' }}
          />
        </form>
      </span>{' '}
    </div>
  ) : null;
};
