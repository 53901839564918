import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Constants, APITools } from 'web-core';
import { deleteCommunity } from '../../services/communities';
import { selectIsManager, selectIsSuperAdmin } from '../../redux/selectors/auth';
import {
  changeShowOverlay,
  getCommunity,
  getSchemas,
  updateCommunity,
  changeEditingModel,
  facilitiesOrServicesRowChange,
  getData,
  formReset,
  loadAssignableProperties,
  loadCopyableSiblings,
  resetFormDirty,
} from '../../redux/reducers';
import Button from '../Button/Button';
import KthPopup from '../KthPopup/KthPopup';
import TabBar from '../TabBar/TabBar';
import styles from './CommunityForm.module.scss';
import CommunitySelect, {
  DISPLAY_MODE_COMPRESSED,
} from './CommunitySelect/CommunitySelect';
import TabContent from './TabContent/TabContent';
import {
  BuildingsTab,
  FacilitiesTab,
  LocationTab,
  PhotosTab,
  ProfileTab,
  ServicesTab,
  UnitsTab,
  AdminTab,
  FeesTab,
  PromotionsTab,
} from './Tabs/index';
import ModelsTab from './Tabs/ModelsTab/ModelsTab';
import { QueuedChanges } from './Tabs/QueuedChanges/QueuedChanges';
import { UsersTab } from './Tabs/UsersTab/UsersTab';
import _ from 'lodash';
import { Tooltip } from '@mui/material';
import api from '../../services/api';
import { faInfoCircle, faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from 'reactjs-popup';
import React from 'react';
import CopyCommunityPopup from './CopyCommunityPopup';
import OnboardingVideo from './OnboardingVideo/OnboardingVideo';
import Icons from '../../constants/Icons';
import AmplitudeTools from '../../utils/AmplitudeTools';
import CircularPercentComplete from '../CircularPercentComplete/CircularPercentComplete';
import { faCirclePlay } from '@fortawesome/pro-light-svg-icons';
import FirstTimeOpener from './FirstTimeOpener';
import { CARE_TYPE_DESCRIPTIONS } from '../../constants/CareType';
import { FeatureGating } from '../FeatureGating/FeatureGating';
import RouterPrompt from '../RouterPrompt/RouterPrompt';
import { loadAdminNotesForCommunity } from '../../redux/reducers/adminNotes';
import ConflictPrompt from './ConflictPrompt';

const CommunityForm = ({ communityPercent, setFormSaved, formSaved }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isFirstTimePopupOpen, setIsFirstTimePopupOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [isSubmittedForApproval, setIsSubmittedForApproval] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isCopyCommunityPopupOpen, setIsCopyCommunityPopupOpen] = useState(false);
  const [isOnboardingVideoPopupOpen, setIsOnboardingVideoPopupOpen] = useState(false);
  const [showedOnboardingVideoDuringEdit, setShowedOnboardingVideoDuringEdit] =
    useState(false);
  const [scrollDirection, setScrollDirection] = useState('up');
  const [isUnpublishOpen, setIsUnpublishOpen] = useState(false);
  const [showUnpublishButton, setShowUnpublishButton] = useState(true);

  // state related to the current version to make sure some one else hasn't been editing the community
  // at the same time
  const [showResolveConflictPrompt, setShowResolveConflictPrompt] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(0);
  const [reloadFreshData, setReloadFreshData] = useState(false);

  const showOverlay = useSelector((state) => state.communityForm.showOverlay);
  const isLoading = useSelector((state) => state.communityForm.loading);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const isManager = useSelector(selectIsManager);
  const showNavigationFooter = useSelector(
    (state) => state.communityForm.showNavigationFooter
  );
  const userRole = useSelector((state) => state?.auth?.user?.role);
  const formData = useSelector((state) => state.communityForm.formData);
  const version = useSelector((state) => state.communityForm.latestEditVersion);
  const isLoaded = useSelector((state) => state.communityForm.loaded);
  const copySuccess = useSelector((state) => state.communityForm.copySuccess);
  const copyableSiblings = useSelector((state) => state.communityForm.copyableSiblings);
  const isSaving = useSelector((state) => state.communityForm.isSaving);
  const approvedPropertyCommunities = useSelector(
    (state) => state.communityForm.approvedPropertyCommunities
  );
  const dirtyFlag = useSelector((state) => state.communityForm.dirty);

  // shown when the user clicks the back arrow in our UI or goes to the queued changes tab
  // and unsaved edits have been made
  const [showUnsavedChangesPrompt, setShowUnsavedChangesPrompt] = useState(false);

  // NOTE: the value of this needs to be the index of the queued changes tab
  const [switchingToQueuedChangesTab, setSwitchingToQueuedChangesTab] = useState(false);
  // flag we can set when switching tab or care type to avoid the back button browser interception
  const [switchingTabOrCareType, setSwitchingTabOrCareType] = useState(false);
  // this is used so we can always get the latest value of switchingTabOrCareType synchronously
  const isSwitchingTabOrCareType = useCallback(() => {
    return switchingTabOrCareType;
  }, [switchingTabOrCareType]);

  const dispatch = useDispatch();
  let { id } = useParams();

  // used for back button intercepting
  const currentPathAndSearch = useRef(null);
  // NOTE: only setting this on initial load
  useEffect(() => {
    if (!!currentPathAndSearch) {
      currentPathAndSearch.current = window.location.pathname + window.location.search;
    }
  }, []);

  // call this after successfully switching care type
  const updateCurrentUrlRef = () => {
    if (!!currentPathAndSearch) {
      currentPathAndSearch.current = window.location.pathname + window.location.search;
    }
  };

  const percentCompleteForm = communityPercent[id];
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const showUnpublishedAlert = false;
  const careType = formData[Constants.DynamicSchemaFields.CareType];
  const communityName = formData[Constants.DynamicSchemaFields.Name];
  // This parameter is used when we switch from one care level to another
  const [switchedFromAnotherCareType, setSwitchedFromAnotherCareType] = useState(false);

  // NOTE: be careful about not updating state too many times on window scroll
  const scrollYRef = useRef(0);
  const scrollDirectionRef = useRef('');
  const onWindowScrolled = (e) => {
    const window = e.currentTarget;
    if (scrollYRef.current > window.scrollY && scrollDirectionRef.current !== 'up') {
      scrollDirectionRef.current = 'up';
      setScrollDirection('up');
    } else if (
      scrollYRef.current < window.scrollY &&
      scrollDirectionRef.current !== 'down'
    ) {
      scrollDirectionRef.current = 'down';
      setScrollDirection('down');
    }

    scrollYRef.current = window.scrollY;
  };

  // initialize scrollY on window load
  useEffect(() => {
    scrollYRef.current = window.scrollY;
  }, []);

  useEffect(() => {
    setCurrentVersion(version);
  }, [version]);

  useEffect(() => {
    window.addEventListener('scroll', (e) => onWindowScrolled(e));
    return () => {
      window.removeEventListener('scroll', (e) => onWindowScrolled(e));
    };
  }, []);

  const checkIfSubmittedForApproval = async () => {
    try {
      const { data } = await api.get(
        `/v1/admin/communities/${id}/pending_change_request`
      );
      if (data.community_change_request?.id) {
        setIsSubmittedForApproval(true);
      }
    } catch (err) {}
  };

  useEffect(() => {
    // profiles with < 25 keys in json_data we consider to be new profiles
    // if we add more auto generated keys (e.g. for geocoding) down the road
    // may need to update this
    if (isLoaded) {
      // this handles updating the ref after we successfully switch care types and have loaded the new care type data
      // very important to do this here
      if (switchedFromAnotherCareType) {
        updateCurrentUrlRef();
      }

      if (Object.entries(formData).length < 25) {
        // if we switched from one care type to another don't show the first time popup
        // but show the copy community from another care level popup if necessary
        if (switchedFromAnotherCareType) {
          setIsFirstTimePopupOpen(false);
          setIsCopyCommunityPopupOpen(true);
        } else {
          setIsFirstTimePopupOpen(true);
        }
      }
    } else {
      setIsFirstTimePopupOpen(false);
    }
  }, [isLoaded, switchedFromAnotherCareType]);

  useEffect(() => {
    dispatch(loadAssignableProperties(id));
    dispatch(getCommunity(id, () => history.replace('/notfound')));
    dispatch(loadCopyableSiblings(id));
  }, [id, dispatch, copySuccess, reloadFreshData]);

  useEffect(() => {
    dispatch(getSchemas(id));
    dispatch(changeShowOverlay(false));
  }, [dispatch, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTabIndex]);

  useEffect(() => {
    if (userRole === Constants.AccountRoles.Admin) {
      dispatch(loadAdminNotesForCommunity(id, true));
    }
  }, [id, dispatch, userRole]);

  useEffect(() => {
    const index = allTabs.findIndex(
      (tab) => tab.name === window.location.hash.substring(1)
    );
    const isValidIndex = Number.isInteger(index) && index >= 0;

    !window.location.hash || !isValidIndex
      ? setSelectedTabIndex(0)
      : setSelectedTabIndex(index);
  }, [window.location.hash]);

  useEffect(() => {
    return () => {
      dispatch(formReset());
    };
  }, []);

  useEffect(() => {
    setIsSubmittedForApproval(false);
    checkIfSubmittedForApproval();
  }, [id]);

  useEffect(() => {
    if (isLoaded) {
      let community = approvedPropertyCommunities.find((community) => community.id == id);
      setShowUnpublishButton(community?.status !== 'unpublished');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isLoaded]);

  // prompt user when they click close/refresh button if there exist unsaved changes
  // NOTE: though we set the message here, it seems like we couldn't change the system prompts
  useEffect(() => {
    const onRefreshOrClose = (event) => {
      if (dirtyFlag) {
        return (event.returnValue =
          'Are you sure you want to quit without saving changes?');
      }
    };

    window.addEventListener('beforeunload', onRefreshOrClose);
    return () => {
      window.removeEventListener('beforeunload', onRefreshOrClose);
    };
  }, [dirtyFlag]);

  const tabs = [
    {
      name: 'Profile',
      content: <ProfileTab />,
    },
    {
      name: 'Location',
      content: <LocationTab />,
    },
    { name: 'Photos', content: <PhotosTab /> },
    {
      name: 'Facilities',
      content: <FacilitiesTab />,
    },
    {
      name: 'Services',
      content: <ServicesTab />,
    },
    { name: 'Buildings', content: <BuildingsTab /> },
    {
      name: 'UnitTypes',
      content: <ModelsTab />,
    },
    { name: 'Units', content: <UnitsTab /> },
    { name: 'Fees', content: <FeesTab /> },
    { name: 'Promotions', content: <PromotionsTab /> },
  ];

  if (userRole && userRole === 'admin') {
    tabs.push({
      name: 'Admin',
      content: <AdminTab />,
    });
  }

  const rightTabs = [
    { name: 'Users', content: <UsersTab /> },
    { name: 'QueuedChanges', content: <QueuedChanges /> },
  ];

  const allTabs = [...tabs, ...rightTabs];

  const isFacilitiesOrServices =
    window.location.hash.substring(1) === 'Services' ||
    window.location.hash.substring(1) === 'Facilities';

  const handleSubmitForApproval = async (id) => {
    try {
      await api.post(`v1/admin/community_change_requests/`, {
        community_id: id,
      });
      setFormSaved(!formSaved);
      AmplitudeTools.logEvent(AmplitudeTools.EVENTS.SUBMIT_FOR_APPROVAL);

      enqueueSnackbar(
        'Your changes to this community have been submitted for approval. You will be notified once they have been reviewed by the Kithward team.',
        { variant: 'success' }
      );
      if (userRole && userRole === 'admin') {
        dispatch(getData());
      }
      setIsSubmittedForApproval(true);
    } catch (err) {
      console.error('Error submitting for approval', err);
      setIsSubmittedForApproval(false);
    }
  };
  // exposes draft id to consumer, can we get slug with live ID instead of an api call for the admin/draft slug
  const handlePreviewChanges = async (id) => {
    try {
      const dataRes = await api.get(`/v1/admin/communities/${id}`);
      if (dataRes && dataRes.data.slug) {
        AmplitudeTools.logEvent(AmplitudeTools.EVENTS.PREVIEW_COMMUNITY_PROFILE);

        window.open(
          `${process.env.REACT_APP_CONSUMER}/community/${dataRes.data.slug}?draft=true`,
          '_blank'
        );
      } else {
        enqueueSnackbar(
          `An error occurred opening this draft preview. Please try again or contact Kithward support.`,
          {
            variant: 'error',
          }
        );
      }
    } catch (err) {}
  };
  const handleSaveSilently = async () => {
    return await handleSave(true);
  };

  const handleSave = async (
    hideSuccessMessage,
    skipSubmitForApprovalMessage,
    forceSave = false
  ) => {
    try {
      const res = await dispatch(updateCommunity(currentVersion, forceSave));
      if (res) {
        if (res.error) {
          setShowResolveConflictPrompt(true);
        } else {
          if (isSubmittedForApproval || !!skipSubmitForApprovalMessage) {
            setFormSaved(!formSaved);
            if (!hideSuccessMessage) {
              enqueueSnackbar(`Your changes have been saved.`, {
                variant: 'success',
              });
            }
            const lev = res.data.latest_edit_version;
            setCurrentVersion(lev);
            setShowResolveConflictPrompt(false);
            return true;
          } else {
            setFormSaved(!formSaved);
            if (!hideSuccessMessage) {
              enqueueSnackbar(
                `Your changes have been saved. You can submit for approval if you are done editing.`,
                {
                  variant: 'success',
                }
              );
            }
            setCurrentVersion(res.data.latest_edit_version);
            setShowResolveConflictPrompt(false);
            return true;
          }
        }
      } else {
        enqueueSnackbar(`Error saving your changes. Please try again later.`, {
          variant: 'error',
        });
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const goToNextTab = (currentTabIndex) => {
    if (isFacilitiesOrServices) {
      dispatch(facilitiesOrServicesRowChange());
    }
    const nextTabIndex = currentTabIndex + 1;
    if (nextTabIndex && nextTabIndex <= tabs.length - 1) {
      setSelectedTitle(null);
      changeTabs(nextTabIndex);
    } else {
      setSelectedTitle(null);
      changeTabs(currentTabIndex);
    }
  };

  const handleServicesFacilitiesSave = async () => {
    dispatch(facilitiesOrServicesRowChange());
    await handleSave();
  };

  const changeTabs = (index) => {
    if (allTabs[index].name === 'QueuedChanges' && dirtyFlag) {
      setSwitchingTabOrCareType(true);
      setSwitchingToQueuedChangesTab(index);
      setShowUnsavedChangesPrompt(true);
    } else {
      switchToTab(index);
    }
  };

  const switchToTab = (index) => {
    setSwitchingTabOrCareType(true);

    let eventProperties = {};
    eventProperties[AmplitudeTools.EVENT_PROPERTIES.TAB_NAME] = allTabs[index].name;
    AmplitudeTools.logEvent(
      AmplitudeTools.EVENTS.CHANGE_EDIT_COMMUNITY_TAB,
      eventProperties
    );

    // note: make sure to include the search in case the care_type parameter is present
    history.replace(
      window.location.pathname + window.location.search + `#${allTabs[index].name}`,
      window.location.state
    );

    setSelectedTabIndex(index);
    setSelectedTitle(null);
    setShowUnsavedChangesPrompt(false);
    setSwitchingToQueuedChangesTab(false);
    setSwitchingTabOrCareType(false);
    dispatch(changeEditingModel(null));
  };

  const handleDeleteForm = async (id) => {
    try {
      await deleteCommunity(id);
      enqueueSnackbar(`Successfully deleted the community.`, {
        variant: 'success',
      });

      // very important to reset the redux store when we leave this page
      dispatch(formReset());
      setIsDeleteOpen(false);
      isManager ? history.push('/communities') : history.push('/properties');
    } catch (err) {
      const errorMessage = APITools.errorMessageFromServerResponse(err);
      enqueueSnackbar(errorMessage, { variant: 'warning' });
    }
  };

  const selectedTabContent = useMemo(
    () => allTabs[selectedTabIndex].content,
    [allTabs, selectedTabIndex]
  );

  const submitForApprovalButton = (
    <>
      <Tooltip
        title={dirtyFlag ? '' : 'There are no unsaved changes for this community.'}
        arrow
      >
        <span>
          <Button.WHITE_OUTLINED
            className='ml-50 no-wrap-text-button'
            disabled={!dirtyFlag}
            onClick={isFacilitiesOrServices ? handleServicesFacilitiesSave : handleSave}
          >
            Save
          </Button.WHITE_OUTLINED>
        </span>
      </Tooltip>
      <Tooltip
        title={dirtyFlag ? 'Please save your changes before previewing the profile.' : ''}
        arrow
      >
        <span>
          <Button.WHITE_OUTLINED
            className='ml-50 no-wrap-text-button'
            onClick={() => handlePreviewChanges(id)}
            disabled={dirtyFlag}
          >
            Preview
          </Button.WHITE_OUTLINED>
        </span>
      </Tooltip>
      <Tooltip
        title={
          isSubmittedForApproval
            ? "You can keep making changes while waiting for admin approval. You don't need to submit again if you make more changes."
            : dirtyFlag
            ? 'Please save your changes before submitting for approval'
            : ''
        }
        arrow
      >
        <span>
          <Button.WHITE_OUTLINED
            className='ml-50 no-wrap-text-button'
            onClick={() => handleSubmitForApproval(id)}
            disabled={isSubmittedForApproval || dirtyFlag}
          >
            {isSubmittedForApproval ? 'Submitted for approval' : 'Submit for approval'}
          </Button.WHITE_OUTLINED>
        </span>
      </Tooltip>
    </>
  );

  const handleOnClose = (e) => {
    e.preventDefault();
    setIsFirstTimePopupOpen(false);
  };

  const showOnboardingVideoWhileEditing = () => {
    setShowedOnboardingVideoDuringEdit(true);
    setIsOnboardingVideoPopupOpen(true);
  };

  const handleShowOnboardingVideoPopup = (e) => {
    e.preventDefault();
    setIsFirstTimePopupOpen(false);
    setIsOnboardingVideoPopupOpen(true);
  };

  const showCopyCommunityForm = () => {
    setIsCopyCommunityPopupOpen(true);
  };

  const handleCloseOnboardingVideoPopup = (e) => {
    e.preventDefault();
    setIsOnboardingVideoPopupOpen(false);
    // if this is during initial page load then show the copy community form afterwards
    // if applicable
    if (!showedOnboardingVideoDuringEdit) {
      showCopyCommunityForm();
    }
  };
  const handleClickBackward = async (e) => {
    e.preventDefault();
    if (dirtyFlag) {
      setShowUnsavedChangesPrompt(true);
    } else {
      handleBackward(); // no changes to the community, so can just go back
    }
  };

  // this event handler is for when user clicks the Backward arrow in community form page(not the backward button of the browser)
  const handleBackward = () => {
    if (history.location.state && history.location.state.returnToPath) {
      history.push({
        pathname: history.location.state.returnToPath.pathname,
        search: history.location.state.returnToPath.search,
        hash: history.location.state.returnToPath.hash,
      });
    } else {
      isManager ? history.push('/communities') : history.push('/properties');
    }
    // very important to reset the redux store when we leave this page
    // doing this after pushing the other page so that we don't trigger
    // effects on form reset
    dispatch(formReset());
  };

  const handleClickUnpublishCommunity = async () => {
    try {
      await api.patch(`/v1/admin/communities/${id}/unpublish`);
      setIsUnpublishOpen(false);
      setShowUnpublishButton(false);
      enqueueSnackbar(`Community successfully unpublished!`, {
        variant: 'success',
      });
    } catch (err) {
      setIsUnpublishOpen(false);
      let errorsToDisplay = APITools.errorMessageFromServerResponse(err);
      enqueueSnackbar(`${errorsToDisplay}`, {
        variant: 'error',
      });
    }
  };

  const handleSaveThenLeave = () => {
    setShowUnsavedChangesPrompt(false);
    handleSave(false, true).then((res) => {
      return switchingToQueuedChangesTab
        ? switchToTab(switchingToQueuedChangesTab)
        : handleBackward();
    });
  };

  const handleDiscardThenLeave = () => {
    setShowUnsavedChangesPrompt(false);
    // don't reset dirty flag when switching to queue changes tab since we are not leaving the community form
    if (!switchingToQueuedChangesTab) {
      dispatch(resetFormDirty());
    }
    // reset form to not dirty just in case
    setTimeout(() => {
      switchingToQueuedChangesTab
        ? switchToTab(switchingToQueuedChangesTab)
        : handleBackward();
    }, 500); // temp fix: need to wait for showPrompt state to be false so scrollbar is re-enabled
  };

  const handleForceSave = () => {
    return handleSave(false, true, 'true');
  };

  const handleDiscardThenLoadFreshData = () => {
    setShowResolveConflictPrompt(false);
    dispatch(resetFormDirty());
    setReloadFreshData(!reloadFreshData);
  };

  const getMessageForBrowserBackPrompt = (nextLocation, action) => {
    // NOTE: very important to use callback here so that this can immediately have access to the latest state
    if (dirtyFlag && !isSwitchingTabOrCareType()) {
      return JSON.stringify({
        currentPathAndSearch,
        nextLocation,
        action,
        message: 'All unsaved changes will be lost.',
      });
    }

    return true;
  };

  return (
    <div className={styles.communityFormContainer}>
      {showOverlay ? <div className={styles.overlay} /> : null}
      <Prompt
        when={dirtyFlag && !switchingTabOrCareType}
        message={getMessageForBrowserBackPrompt}
      />
      <Popup
        open={isFirstTimePopupOpen}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
        lockScroll={true}
      >
        <FirstTimeOpener
          communityName={communityName}
          careType={CARE_TYPE_DESCRIPTIONS[careType]}
          onCancel={handleBackward}
          onClose={handleOnClose}
          onContinue={handleShowOnboardingVideoPopup}
        />
      </Popup>
      <Popup
        open={isOnboardingVideoPopupOpen}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
        lockScroll={true}
        onClose={() => setIsOnboardingVideoPopupOpen(false)}
      >
        <OnboardingVideo onContinue={handleCloseOnboardingVideoPopup} />
      </Popup>
      <Popup
        open={isCopyCommunityPopupOpen && copyableSiblings.length > 0}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
        lockScroll={true}
      >
        <CopyCommunityPopup onClose={() => setIsCopyCommunityPopupOpen(false)} />
      </Popup>
      <Popup
        open={isCopyCommunityPopupOpen && copyableSiblings.length > 0}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
        lockScroll={true}
      >
        <CopyCommunityPopup onClose={() => setIsCopyCommunityPopupOpen(false)} />
      </Popup>
      <Popup
        open={dirtyFlag && showUnsavedChangesPrompt}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
        lockScroll={true}
      >
        <RouterPrompt
          onSave={handleSaveThenLeave}
          onDiscard={handleDiscardThenLeave}
          onCancel={() => setShowUnsavedChangesPrompt(false)}
          title={
            switchingToQueuedChangesTab
              ? 'You have unsaved changes.'
              : 'Leaving this page?'
          }
          message={
            switchingToQueuedChangesTab
              ? 'To compare your latest changes, please save first.'
              : 'Any changes you made will not be saved.'
          }
          switchingToQueueChangesTab={switchingToQueuedChangesTab}
        />
      </Popup>
      <Popup
        open={showResolveConflictPrompt}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
        lockScroll={true}
      >
        <ConflictPrompt
          title='Found conflicting edits'
          message='Someone else has recently saved changes to this community. Saving this draft will override their changes.'
          onClose={() => setShowResolveConflictPrompt(false)}
          onForceSave={handleForceSave}
          onDiscard={handleDiscardThenLoadFreshData}
        />
      </Popup>

      <div className={showOverlay === false ? styles.stickyHeader : null}>
        <div className={styles.header}>
          <div className={styles.titleSection}>
            <div className={styles.arrow} onClick={handleClickBackward}>
              {isSaving ? (
                <span className={styles.arrowLoader}>{Icons.Loader}</span>
              ) : (
                <FontAwesomeIcon icon={faArrowLeft} className={styles.backwardArrow} />
              )}
            </div>
            <div className={styles.titleAndPercent}>
              <h3 className={styles.title}>{communityName}</h3>
              {isLoading && <span className={styles.titleLoader}>{Icons.Loader}</span>}
              <div style={{ marginTop: '15px', height: '60px', width: '125px' }}>
                <FeatureGating>
                  {!isLoading &&
                    communityPercent &&
                    Object.keys(communityPercent).length > 0 && (
                      <CircularPercentComplete
                        className={'edit-form'}
                        value={communityPercent.pct_complete}
                      />
                    )}
                </FeatureGating>
              </div>
            </div>
            {scrollDirection === 'down' && (
              <CommunitySelect
                displayMode={DISPLAY_MODE_COMPRESSED}
                onSave={handleSaveSilently}
                dirtyFlag={dirtyFlag}
                setSwitchingTabOrCareType={setSwitchingTabOrCareType}
                setSwitchedFromAnotherCareType={setSwitchedFromAnotherCareType}
              />
            )}
          </div>
          <div className={styles.submitForApprovalButtons}>{submitForApprovalButton}</div>
        </div>

        {isLoading ? (
          <div className={styles.communitySelectLoader}>{Icons.Loader}</div>
        ) : (
          scrollDirection === 'up' && (
            <CommunitySelect
              onSave={handleSaveSilently}
              dirtyFlag={dirtyFlag}
              setSwitchingTabOrCareType={setSwitchingTabOrCareType}
              setSwitchedFromAnotherCareType={setSwitchedFromAnotherCareType}
            />
          )
        )}

        <TabBar
          tabs={tabs}
          rightSideTabs={rightTabs}
          onSelect={changeTabs}
          selectedTab={selectedTabIndex}
        />
      </div>
      {showUnpublishedAlert && (
        <div className={styles.unpublishedAlert}>
          <FontAwesomeIcon icon={faInfoCircle} color='#ac947a' />
          <p>
            This community has unpublished changes. When you are ready to publish these
            changes you can{' '}
            <a onClick={() => handleSubmitForApproval(id)}>submit them for approval.</a>
          </p>
        </div>
      )}
      <div
        className={styles.tabContent}
        style={showOverlay === false ? { marginTop: '190px' } : { margin: '0' }}
      >
        <TabContent
          title={_.startCase(allTabs[selectedTabIndex].name)}
          selectedTitle={selectedTitle}
          rightTopContent={allTabs[selectedTabIndex].rightTopContent}
          onTitleSelect={setSelectedTitle}
          percentCompleteForm={percentCompleteForm}
        >
          {selectedTabContent}
        </TabContent>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          {showNavigationFooter ? (
            <div className={styles.footerButtons}>
              <div className={styles.groupedFooterButtons}>
                {isSuperAdmin && allTabs[selectedTabIndex].name === 'Profile' && (
                  <Button.BLACK_OUTLINED onClick={() => setIsDeleteOpen(true)}>
                    Delete Community
                  </Button.BLACK_OUTLINED>
                )}
                {isSuperAdmin &&
                  allTabs[selectedTabIndex].name === 'Profile' &&
                  showUnpublishButton && (
                    <Button.BLACK_OUTLINED onClick={() => setIsUnpublishOpen(true)}>
                      Unpublish
                    </Button.BLACK_OUTLINED>
                  )}
                <Button.BLACK_OUTLINED onClick={() => showOnboardingVideoWhileEditing()}>
                  <FontAwesomeIcon icon={faCirclePlay} /> How-to Video
                </Button.BLACK_OUTLINED>
              </div>

              <Button.DARK onClick={() => goToNextTab(selectedTabIndex)}>
                Next Section
              </Button.DARK>
            </div>
          ) : null}
        </div>
      </div>
      <KthPopup
        isOpen={isDeleteOpen}
        isLoading={null}
        title={`Delete Community`}
        confirmButtonLabel={'Delete Community'}
        onConfirmButtonClick={() => handleDeleteForm(id)}
        onClose={() => setIsDeleteOpen(false)}
      >
        <label>
          Are you sure you want to delete this community? <strong>(CAN'T UNDO)</strong>
        </label>
      </KthPopup>
      <KthPopup
        isOpen={isUnpublishOpen}
        isLoading={null}
        title={`Unpublish Community`}
        confirmButtonLabel={'Unpublish Community'}
        onConfirmButtonClick={handleClickUnpublishCommunity}
        onClose={() => setIsUnpublishOpen(false)}
      >
        <label>Are you sure you want to unpublish this community?</label>
      </KthPopup>
    </div>
  );
};

export default CommunityForm;
