import { Constants } from 'web-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { faAngleRight, faClockRotateLeft } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import Popup from 'reactjs-popup';
import styles from './AdminNotes.module.scss';
import PostNoteSection from './PostNoteMessages';
import { getAdminNotesByCommunityId } from '../../../redux/selectors/adminNotes';
import { selectCurrentEditingModelIndex } from '../../../redux/selectors/communityForm';
import { isBuildingOrUnitTypeTab } from '../../../utils/communityFormUtils';

const AdminNotes = ({ title, section, approvedCommunityId, arrayItemId }) => {
  const { user } = useSelector((state) => state.auth);
  const adminNotesForCommunity = useSelector(
    getAdminNotesByCommunityId(approvedCommunityId)
  );
  const [openNote, setOpenNote] = useState(false);
  const [stageValue, setStageValue] = useState('');
  const [existNotes, setExistNotes] = useState({});
  const [titleSectionPopup, setTitleSectionPopup] = useState('');

  useEffect(() => {
    if (user.role === Constants.AccountRoles.Admin) {
      if (adminNotesForCommunity) {
        // we don't want admin notes for other unit types / buildings so filtering others out
        const filteredNotes = isBuildingOrUnitTypeTab(section)
          ? adminNotesForCommunity.filter((note) => note.array_item_id === arrayItemId)
          : adminNotesForCommunity;

        setStageValue(filteredNotes[0]?.stage);
        if (filteredNotes.length > 0) {
          const findSection = section.split('|').slice(0, 3).join('|');
          const hasNoteForSection = filteredNotes.find(
            (elem) => elem.section === findSection
          );
          setExistNotes(hasNoteForSection);
        }
      }
    }
  }, [adminNotesForCommunity, openNote, user.role, arrayItemId, section]);

  const handleOpenNote = () => {
    setOpenNote(true);
  };

  useEffect(() => {
    if (typeof title === 'object') {
      let { schema } = title.props;
      setTitleSectionPopup(schema.title);
    } else {
      setTitleSectionPopup(title);
    }
  }, [title]);

  return (
    <>
      <div className={styles.adminNotesContainer}>
        {existNotes && Object.keys(existNotes).length > 0 && (
          <>
            <div className={styles.adminTitleContainer}>
              <FontAwesomeIcon icon={faClockRotateLeft} onClick={handleOpenNote} />
              <p
                className={styles.titleNote}
              >{`${existNotes.account?.name} | ${existNotes?.stage}`}</p>
            </div>
            <p className={`ml-100 ${styles.dateNote}`}>
              {moment(existNotes.created_at).format('MM/DD/YYYY')}
            </p>
            <p className={`ml-100 ${styles.messageNote}`}>{existNotes.message} </p>
          </>
        )}
        <p
          className={styles.addNote}
          style={
            existNotes && Object.keys(existNotes).length > 0
              ? { marginLeft: '25px' }
              : { margin: '10px 0' }
          }
          onClick={handleOpenNote}
        >
          {'Add a Note'}
          <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faAngleRight} />
        </p>
      </div>
      <Popup
        open={openNote}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
        lockScroll={true}
      >
        <PostNoteSection
          user={user}
          title={titleSectionPopup}
          section={section}
          handleOnClose={() => setOpenNote(false)}
          stageValue={stageValue}
          approvedCommunityId={approvedCommunityId}
          arrayItemId={arrayItemId}
        />
      </Popup>
    </>
  );
};

export default AdminNotes;
