import useSWR from 'swr';
import api from '../services/api';

// load a single assessment link of a property
export const useAssessmentLink = (
  propertyId: string | number | null,
  uuid: string | undefined
) => {
  const {
    data: response,
    isValidating,
    error,
  } = useSWR(
    propertyId && uuid
      ? `/v1/admin/properties/${propertyId}/assessment_links/${uuid}`
      : null,
    api,
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      revalidateIfStale: false,
      shouldRetryOnError: false,
    }
  );

  const data = response ? response.data : null;

  return {
    loading: isValidating,
    data,
    error,
  };
};
