import Icons from '../../../../../constants/Icons';
import styles from './ModelsForm.module.scss';
import Button from '../../../../Button/Button';
import KthPopup from '../../../../KthPopup/KthPopup';
import DynamicForm from '../../../../DynamicForm/DynamicForm';
import { KWImage } from '../../../../KWImage/KWImage';
import { Constants } from 'web-core';
import { Grid, Tooltip } from '@mui/material';
import { changeModelForm } from '../../../../../redux/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { selectModelForm } from '../../../../../redux/selectors/communityForm';
import { UnitTypeImagesForm } from './UnitTypeImagesForm';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

// NOTE: this is assumed to be shown in a lightbox that takes over the screen
const ModelsForm = ({ onSave, onCancel, onDelete }) => {
  const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const units = useSelector((state) => state.communityForm.formData.units) || [];
  const modelImages = useSelector((state) => state.communityForm.model_type_images);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const origForm = useSelector(selectModelForm);
  const [form, setForm] = useState(origForm);
  const [unitTypeImages, setUnitTypeImages] = useState([]);
  const [indexImage, setIndexImage] = useState(0);

  useEffect(() => {
    let filteredImages = modelImages.filter((elem) =>
      form[Constants.DynamicSchemaFields.ModelImages]?.includes(elem.id)
    );
    setUnitTypeImages(filteredImages);
    setIndexImage(0);
  }, [form, modelImages]);

  const dispatch = useDispatch();

  const handleFormDataChange = ({ formData }) => {
    setForm(formData);
  };

  const handleImagesChange = (formData) => {
    setForm(formData);
  };

  const handleSaveForm = () => {
    dispatch(changeModelForm(form));
    onSave(form);
  };

  const handlePrevImage = () => {
    if (indexImage === 0) {
      setIndexImage(unitTypeImages.length - 1);
    } else {
      setIndexImage(indexImage - 1);
    }
  };

  const handleNextImage = () => {
    if (indexImage === unitTypeImages.length - 1) {
      setIndexImage(0);
    } else {
      setIndexImage(indexImage + 1);
    }
  };

  const handleCancelForm = () => {
    onCancel();
  };

  const handleDeleteForm = () => {
    onDelete(form);
  };

  let toolTipData;
  const unitsOfType = units.filter(
    (unit) => unit[Constants.DynamicSchemaFields.UnitModel] === form.id
  );
  if (unitsOfType && unitsOfType.length > 0) {
    toolTipData = (
      <>
        <p>
          This Unit Type cannot be deleted while it is associated with the following
          Units:
        </p>
        <ul>
          {unitsOfType.map((unit, index) =>
            unit[Constants.DynamicSchemaFields.UnitName] ? (
              <li key={index}>{unit[Constants.DynamicSchemaFields.UnitName]}</li>
            ) : (
              <li key={index}>Unnamed Unit</li>
            )
          )}
        </ul>
        <p>
          Delete these Units or modify their Unit Type association to delete this Unit
          Type.
        </p>
      </>
    );
  }
  return (
    <div className={`${styles.popupForm} ${styles.modelPopupForm}`}>
      <div className={styles.header}>
        <div>
          <h3>
            {form[Constants.DynamicSchemaFields.ModelName]
              ? form[Constants.DynamicSchemaFields.ModelName]
              : 'Unnamed Unit Type'}
          </h3>
          <p className={styles.tabDescription}>
            Add all the details about this Unit Type, so we can market it most
            effectively. Be sure to enter in this Unit Type's Property Management System
            ID in the PMS Unit Type field so you can import all your Unit data via CSV /
            XSLX upload.
          </p>
        </div>
      </div>
      <Grid container rowSpacing={0} className={styles.gridContainer}>
        <Grid item xs={5} className={styles.leftColumn}>
          <TransformWrapper
            defaultScale={1}
            defaultPositionX={0}
            defaultPositionY={0}
            wheel={{ step: 0.95 }} // smoother zooming action a bit
          >
            {({ zoomIn, zoomOut }) => (
              <div className={styles.containerImageArrows}>
                <div className={styles.containerZoomButtons}>
                  <button className={styles.zoomButtonsIn} onClick={() => zoomIn()}>
                    <FontAwesomeIcon icon={faPlus} color='#222' />
                  </button>
                  <button className={styles.zoomButtonsOut} onClick={() => zoomOut()}>
                    <FontAwesomeIcon icon={faMinus} color='#222' />
                  </button>
                </div>
                <div className={styles.containerImage}>
                  <TransformComponent>
                    <KWImage
                      src={unitTypeImages[indexImage]?.file_url}
                      className={styles.image}
                      alt={`Unit type - ${unitTypeImages[indexImage]?.id}`}
                      sizes={'50vw'}
                    />
                  </TransformComponent>
                </div>
                <div className={styles.containerArrows}>
                  <div className={styles.prevImage} onClick={handlePrevImage}>
                    <FontAwesomeIcon icon={faCaretLeft} color='#222' />
                  </div>
                  <div className={styles.nextImage} onClick={handleNextImage}>
                    <FontAwesomeIcon icon={faCaretRight} color='#222' />
                  </div>
                </div>
              </div>
            )}
          </TransformWrapper>
        </Grid>
        <Grid item xs={7} className={styles.rightColumn}>
          <UnitTypeImagesForm unitType={form} onChange={handleImagesChange} />
          <DynamicForm
            schema={jsonSchema}
            uiSchema={uiSchema}
            formData={form}
            onChange={handleFormDataChange}
            onSubmit={(ev) => ev.preventDefault()}
            noValidate
            formContext={{ currentTab: 'Model-Type' }}
            widgets={{
              modelImage: UnitTypeImagesForm,
            }}
          />
        </Grid>
      </Grid>
      <div className={`flex-kebab-row p-50 ${styles.bottomButtons}`}>
        <Button.LIGHT onClick={() => handleCancelForm()}>Cancel</Button.LIGHT>
        {unitsOfType && unitsOfType.length > 0 ? (
          <Button.DARK icon disabled onClick={null} className='ml-100'>
            <Tooltip
              title={
                toolTipData
                  ? toolTipData
                  : 'Cannot delete unit type if it is associated with a unit.'
              }
              arrow
              className={null}
            >
              {Icons.Trash}
            </Tooltip>
          </Button.DARK>
        ) : (
          <Button.DARK
            icon
            className='trashButton'
            type='button'
            onClick={() => setIsDeleteOpen(true)}
          >
            {Icons.Trash}
          </Button.DARK>
        )}
        <Button.DARK className='saveButton' onClick={handleSaveForm}>
          Save Unit Type
        </Button.DARK>
      </div>
      <KthPopup
        isOpen={isDeleteOpen}
        isLoading={null}
        title={`Delete Model`}
        confirmButtonLabel={'Delete Model'}
        onConfirmButtonClick={() => handleDeleteForm()}
        onClose={() => setIsDeleteOpen(false)}
      >
        <label>
          Are you sure you want to delete{' '}
          <strong>
            {form[Constants.DynamicSchemaFields.ModelName]
              ? form[Constants.DynamicSchemaFields.ModelName]
              : 'this unit type'}
          </strong>
          ?
        </label>
      </KthPopup>
    </div>
  );
};

export default ModelsForm;
