import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSingleCommunityChanges } from '../../../../hooks/useCommunityQueuedChangesInfinite';
import { setFormNavigationFooterVisibility } from '../../../../redux/reducers';
import { CommunityEditsItem } from '../../../CommunityEditsItem/CommunityEditsItem';
import { EmptyBox } from '../../../EmptyBox/EmptyBox';
import Loader from '../../../Loader/Loader';
import styles from './QueuedChanges.module.scss';
import { useParams } from 'react-router-dom';

export const QueuedChanges = () => {
  const dispatch = useDispatch();
  const { id } = useParams(); // NOTE: this gets the published version id from the URL
  const { data, loading } = useSingleCommunityChanges(id);
  // const draftData = useSelector((state) => state.communityForm.formData);
  // const publishedData = data?.previousData?.json_data;
  const { uiSchema } = useSelector((state) => state.communityForm.schemas);

  useEffect(() => {
    dispatch(setFormNavigationFooterVisibility(false));
    return () => dispatch(setFormNavigationFooterVisibility(true));
  }, [dispatch]);

  return (
    <div className={styles.queuedChanges}>
      <Loader isLoading={loading}>
        <div>
          {data ? (
            <CommunityEditsItem
              editItem={data}
              adminView={false}
              uiSchema={uiSchema}
              approvedCommunityId={id}
            />
          ) : (
            <EmptyBox message={`There are no community edits queued for approval.`} />
          )}
        </div>
      </Loader>
    </div>
  );
};
