import { useHistory, useParams } from 'react-router-dom';
import { ModerationNewUserForm } from '../../../components/ModerationNewUserForm/ModerationNewUserForm';

export const ModerationNewUserPage = () => {
  const { id } = useParams();
  const history = useHistory();

  const handleFormSuccess = () => {
    history.push('/users');
  };

  return <ModerationNewUserForm showHeader id={id} onSuccess={handleFormSuccess} />;
};
