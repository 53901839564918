import { MenuLink } from './MenuLink';
import { NavLink } from 'react-router-dom';
import styles from './SideMenu.module.scss';

export const MenuItem = ({ label, link, subItems, parentLink = null }) => {
  return (
    <div className={styles.menuItem}>
      {!subItems || !subItems.length ? (
        <MenuLink label={label} link={link} />
      ) : parentLink !== null ? (
        <div className={styles.nestedMenuItem}>
          <NavLink
            to={parentLink}
            className={styles.menuLink}
            activeClassName={styles.activeLink}
          >
            {label}
          </NavLink>
          <div className={styles.subItemsContainer}>
            {subItems.map((subItem) => (
              <MenuLink key={subItem.label} label={subItem.label} link={subItem.link} />
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.nestedMenuItem}>
          <span data-disabled className={styles.menuLink}>
            {label}
          </span>
          <div className={styles.subItemsContainer}>
            {subItems.map((subItem) => (
              <MenuLink key={subItem.label} label={subItem.label} link={subItem.link} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
