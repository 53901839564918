import api from './api';

const PATH = `/v1/admin/community_change_requests`;

export const approveCommunityEdit = async (id, params) => {
  try {
    const { data } = await api.post(`${PATH}/${id}/approve`, params);
    return data;
  } catch (err) {
    throw err;
  }
};

export const rejectCommunityEdit = async (id, reason, rejectionEmail) => {
  try {
    const { data } = await api.post(`${PATH}/${id}/reject`, {
      rejection_reason: reason,
      skip_rejection_email: rejectionEmail,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

export const updateInterestTags = async (propertyId, tagId, state) => {
  try {
    const { data } = await api.put(
      `/v1/admin/properties/${propertyId}/interest_taggings`,
      {
        interest_tagging: {
          interest_tag_id: tagId,
          state: state,
        },
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
};
