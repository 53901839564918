import styles from './CommunityForm.module.scss';
import Button from '../Button/Button';
import { Constants } from 'web-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImages,
  faCalendarAlt,
  faFileLines,
  faPersonRunning,
  faFileImage,
} from '@fortawesome/pro-thin-svg-icons';
import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import AmplitudeTools from '../../utils/AmplitudeTools';
import { formReset } from '../../redux/reducers';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const FirstTimeOpener = ({ communityName, careType, onContinue, onClose, onCancel }) => {
  const dispatch = useDispatch();

  const [checklist, setChecklist] = useState({
    imageList: false,
    floorplanList: false,
    description: false,
    clubList: false,
    activityList: false,
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const handleClickNext = (e) => {
    AmplitudeTools.logEvent(AmplitudeTools.EVENTS.COMPLETED_COMMUNITY_CHECKLIST);

    onContinue(e);
  };

  const handleClickCancel = async (e) => {
    e.preventDefault();
    await onClose(e);

    AmplitudeTools.logEvent(AmplitudeTools.EVENTS.CANCEL_COMMUNITY_CHECKLIST);

    // very important to reset the redux store when we leave this page
    dispatch(formReset());

    // userRole === Constants.AccountRoles.CommunityManager
    //   ? history.push('/communities')
    //   : history.push('/properties');
    onCancel();
  };

  const handleOnChange = (e) => {
    // console.log(e.target.id);
    if (e.target.checked) {
      let eventProperties = {};
      eventProperties[AmplitudeTools.EVENT_PROPERTIES.CHECKBOX] = e.target.id;

      AmplitudeTools.logEvent(
        AmplitudeTools.EVENTS.CHECK_COMMUNITY_CHECKLIST_ITEM,
        eventProperties
      );
    }

    setChecklist((currentList) => ({
      ...currentList,
      [e.target.id]: e.target.checked,
    }));
  };

  useEffect(() => {
    setIsDisabled(Object.values(checklist).some((value) => value === false));
  }, [checklist]);

  useEffect(() => {
    AmplitudeTools.logEvent(AmplitudeTools.EVENTS.VIEW_COMMUNITY_CHECKLIST_LIGHTBOX);
  }, []);

  return (
    <div className={styles.communityPopups}>
      <div className={styles.popupHeader}>
        <h4>{`Get ready to fill out ${communityName}'s ${careType} profile`}</h4>
        <div className={styles.headerDescription}>
          <p>
            {
              'Please check each box to confirm you have the materials to fill out your community profile.'
            }
          </p>
        </div>
      </div>
      <div className={styles.checklist}>
        <div className={styles.item}>
          <Checkbox disableRipple id='imageList' onChange={handleOnChange} />
          <span className={styles.checklistImage}>
            <FontAwesomeIcon icon={faImages} style={{ width: '50px', height: '50px' }} />
          </span>
          <span className={styles.checklistDescription}>
            10-20 digital images of your community in jpg, png or pdf.
          </span>
        </div>
        <div className={styles.item}>
          <Checkbox disableRipple id='floorplanList' onChange={handleOnChange} />
          <span className={styles.checklistImage}>
            <FontAwesomeIcon
              icon={faFileImage}
              style={{ width: '50px', height: '50px' }}
            />
          </span>
          <span className={styles.checklistDescription}>
            {`1 digital image for each of the floorplans available at ${careType} of your community in jpg, png or pdf format.`}
          </span>
        </div>
        <div className={styles.item}>
          <Checkbox disableRipple id='description' onChange={handleOnChange} />
          <span className={styles.checklistImage}>
            <FontAwesomeIcon
              icon={faFileLines}
              style={{ width: '50px', height: '50px' }}
            />
          </span>
          <span className={styles.checklistDescription}>
            At least a one paragraph description about your community (take this from your
            marketing materials or website if available).
          </span>
        </div>
        <div className={styles.item}>
          <Checkbox disableRipple id='clubList' onChange={handleOnChange} />
          <span className={styles.checklistImage}>
            <FontAwesomeIcon
              icon={faPersonRunning}
              style={{ width: '50px', height: '50px' }}
            />
          </span>
          <span className={styles.checklistDescription}>
            The list of clubs available at this community (enter this on the ‘Services’
            tab).
          </span>
        </div>
        <div className={styles.item}>
          <Checkbox disableRipple id='activityList' onChange={handleOnChange} />
          <span className={styles.checklistImage}>
            <FontAwesomeIcon
              icon={faCalendarAlt}
              style={{ width: '50px', height: '50px' }}
            />
          </span>
          <span className={styles.checklistDescription}>
            Your activity calendar so you can easily enter all the classes and social
            activities on the ‘Services’ tab.
          </span>
        </div>
      </div>

      <div className={styles.checklistButtons}>
        <Button.BLACK_OUTLINED onClick={handleClickCancel}>Cancel</Button.BLACK_OUTLINED>
        <Button.DARK onClick={handleClickNext} disabled={isDisabled}>
          Next
        </Button.DARK>
      </div>
    </div>
  );
};

export default FirstTimeOpener;
