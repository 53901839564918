import styles from './Header.module.scss';
import Logo from '../../assets/kw-logo-64.png';
import Wordmark from '../../assets/kw-betaLogo.png';
// import Wordmark from "../../assets/wordmark.svg";
import { Separator } from '../Separator/Separator';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/reducers';
import Button from '../Button/Button';
import { faSignOutAlt, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectIsSuperAdmin } from '../../redux/selectors/auth';

export const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const headerTitle = isSuperAdmin
    ? 'Kithward SuperAdmin'
    : user?.organization?.name || '';

  const handleLogout = () => {
    dispatch(logout(true, false)); // false = do not redirect back to the current page
  };

  return (
    <div className={styles.header}>
      <div className={styles.logoWrapper}>
        <img className={styles.logo} src={Logo} alt='Logo' />
        {/* OLD */}
        {/* <img src={Wordmark} alt="Kithward" />  */}
        {/* BETA */}
        <img className={styles.wordmark} src={Wordmark} alt='Kithward' />
      </div>
      <Separator className={styles.headerSeparator} />
      <div className={styles.headerNavigation}>
        <div>
          <span className={styles.text}>{headerTitle}</span>
        </div>
        <div className={styles.headerRightContainer}>
          <span className={styles.text}>{`${user.first_name || ''} ${
            user.last_name || ''
          }`}</span>
          <a
            href={`mailto:hello@kithward.com?subject=Kithward Support&body=Current URL: ${
              window.location
            }%0D%0A Account ID: ${user?.id || 'Not Signed In'}%0D%0A`}
          >
            <Button.TEXT icon className={styles.headerButton}>
              <FontAwesomeIcon icon={faCommentDots} /> Contact Support
            </Button.TEXT>
          </a>
          <Button.TEXT icon className={styles.headerButton} onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
          </Button.TEXT>
        </div>
      </div>
    </div>
  );
};
