import { AccountRequestsList } from '../../../components/AccountRequestsList/AccountRequestsList';
import Button from '../../../components/Button/Button';
import Icons from '../../../constants/Icons';
import HeaderTitle from '../../../components/HeaderTitle/HeaderTitle';

export const AccountRequestsPage = () => {
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    // dispatch(queryUsers(form));
  };

  return (
    <>
      <HeaderTitle title={'Account Requests'} />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Account Requests</h1>
          </div>
          <form className='search-container' onSubmit={handleSearchSubmit}>
            <input name='q' placeholder='Search Requests' />
            <Button.TEXT icon type='submit'>
              {Icons.Search}
            </Button.TEXT>
          </form>
        </div>
        <AccountRequestsList />
      </div>
    </>
  );
};
