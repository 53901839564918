import Loader from '../Loader/Loader';
import styles from '../OrganizationList/OrganizationList.module.scss';
import serviceStyles from './ServiceList.module.scss';

export const ServiceList = ({ services, geoPlaces, isLoadingMore, onItemClick }) => {
  return (
    <>
      <div className={styles.organizationListContainer}>
        {services && services.length
          ? services.map((service, index) => (
              <div className={styles.organizationItem} key={index}>
                <div
                  className={`${styles.organizationItemText}  ${serviceStyles.serviceItemText}`}
                >
                  <h4>{service.name}</h4>
                  <p>{service.description}</p>
                  <p className={serviceStyles.statesAvailable}>
                    {service.geo_places.map((gp) => gp.name).join(', ')}
                  </p>
                  {geoPlaces && (
                    <div className={styles.orgLinks}>
                      <p className='p3' onClick={() => onItemClick(service)}>
                        Edit Service
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))
          : null}
      </div>
      <Loader isLoading={isLoadingMore} />
    </>
  );
};
