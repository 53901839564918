import { FormControlLabel, Radio, FormControl, RadioGroup } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from '../../redux/reducers';
import Icons from '../../constants/Icons';
import { useCommunityClaimsInfinite } from '../../hooks/useCommunityClaimsInfinite';
import { useQueryPropertiesByOrg } from '../../hooks/useQueryProperties';
import { useSnackbar } from 'notistack';
import {
  approveCommunityClaim,
  rejectCommunityClaim,
} from '../../services/communityClaims';
import Button from '../Button/Button';
import KthPopup from '../KthPopup/KthPopup';
import Loader from '../Loader/Loader';
import { CommunityClaimItem } from './CommunityClaimItem';
import styles from './CommunityClaimsList.module.scss';
import { PropertySelect } from './PropertySelect';
import { APITools } from 'web-core';

const PopupType = {
  NONE: 'NONE',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
};

export const CommunityClaimsList = () => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state?.auth?.user?.role);
  const [popupType, setPopupType] = useState(PopupType.NONE);
  const [selectedClaimRequest, setSelectedClaimRequest] = useState(null);
  const [isConfirming, setIsConfirming] = useState(false);
  const [reassignProperty, setReassignProperty] = useState(true);
  const [rejectReason, setRejectReason] = useState('');
  const [property, setProperty] = useState({
    label: `Create a new property for this community`,
    value: 'CREATE',
  });
  const [showPopup, setShowPopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: communityClaims,
    meta,
    loading,
    restartPages,
    loadMore,
  } = useCommunityClaimsInfinite();

  const { data: organizationProperties } = useQueryPropertiesByOrg(
    selectedClaimRequest ? selectedClaimRequest.claim?.account?.organization?.id : null
  );

  const handleReasonChange = (event) => {
    setRejectReason(event.target.value);
  };

  const handleApproveConfirm = async () => {
    setIsConfirming(true);
    try {
      if (
        selectedClaimRequest?.claim.account?.organization?.id ===
        selectedClaimRequest?.organization?.id
      ) {
        const data = await approveCommunityClaim(selectedClaimRequest.claim.id);
      } else {
        const data = await approveCommunityClaim(selectedClaimRequest.claim.id, {
          reassign_organization:
            selectedClaimRequest.claim.account.role === 'community_manager'
              ? reassignProperty
              : true,
          property_id: property.value,
        });
      }
      enqueueSnackbar('Community Claim Approved', {
        variant: 'success',
      });
      handleClosePopup(true);
    } catch (err) {
      const errorMessage = APITools.errorMessageFromServerResponse(err);
      enqueueSnackbar(errorMessage, { varaint: 'warning' });
      handleClosePopup();
    } finally {
      if (userRole && userRole === 'admin') {
        dispatch(getData());
      }
      restartPages();
      setIsConfirming(false);
    }
  };

  const handleRejectConfirm = async () => {
    setIsConfirming(true);
    try {
      const data = await rejectCommunityClaim(selectedClaimRequest.claim.id);
      enqueueSnackbar('Community Claim Rejected', {
        variant: 'success',
      });
      handleClosePopup(true);
    } catch (err) {
      handleClosePopup();
    } finally {
      if (userRole && userRole === 'admin') {
        dispatch(getData());
      }
      restartPages();
      setIsConfirming(false);
    }
  };

  const handleItemApproveClick = (claim) => () => {
    setShowPopup(true);
    setPopupType(PopupType.APPROVE);
    const communityDisplayNames = claim.communities?.map(
      (community) => community.display_name || ''
    );
    const communityWithOrg =
      claim.communities?.find((community) => community.organization !== null) || null;
    setSelectedClaimRequest({
      claim,
      communityDisplayNames,
      organization: communityWithOrg ? communityWithOrg.organization : null,
    });
  };

  const handleItemRejectClick = (claim) => () => {
    setShowPopup(true);
    setPopupType(PopupType.REJECT);
    const communityDisplayNames = claim.communities?.map(
      (community) => community.display_name || ''
    );
    setSelectedClaimRequest({ claim, communityDisplayNames });
  };

  const handleClosePopup = (refreshList) => {
    setShowPopup(false);
    setPopupType(PopupType.NONE);
    setSelectedClaimRequest(null);
    setReassignProperty(true);
    setProperty({
      label: `Create a new property for this community`,
      value: 'CREATE',
    });

    if (refreshList) {
      restartPages();
    }
  };

  const handleLoadMore = () => {
    loadMore();
  };

  const handleSelectNewProperty = (event) => {
    setProperty(event.target);
  };

  const handleAssignSelection = (event) => {
    const value = event.target.value === 'true';
    setReassignProperty(value);
  };

  return (
    <div className={styles.communityClaimsList}>
      <Loader isLoading={loading}>
        <div>
          {communityClaims &&
            communityClaims.map((claim) => (
              <CommunityClaimItem
                key={String(claim.id)}
                claim={claim}
                onApproveClick={handleItemApproveClick(claim)}
                onRejectClick={handleItemRejectClick(claim)}
              />
            ))}
        </div>
      </Loader>
      {meta?.last_page === false ? (
        <Button.LIGHT icon onClick={handleLoadMore} className={styles.loadMoreButton}>
          Load more {Icons.ArrowDown}
        </Button.LIGHT>
      ) : null}
      {selectedClaimRequest ? (
        <KthPopup
          isOpen={showPopup}
          title={
            (popupType === PopupType.APPROVE ? `Approve ` : `Reject `) +
            `${selectedClaimRequest.claim.account['first_name']} ${selectedClaimRequest.claim.account['last_name']}'s Claim`
          }
          onConfirmButtonClick={
            popupType === PopupType.APPROVE ? handleApproveConfirm : handleRejectConfirm
          }
          confirmButtonLabel={
            popupType === PopupType.APPROVE ? 'Approve Claim' : 'Reject Claim'
          }
          isLoading={isConfirming}
          onClose={() => handleClosePopup()}
        >
          {popupType === PopupType.APPROVE ? (
            <div className={styles.claimRequestPopup}>
              <p>
                You are approving {selectedClaimRequest.claim.account['first_name']}{' '}
                {selectedClaimRequest.claim.account['last_name']}'s claim to manage{' '}
                {selectedClaimRequest.communityDisplayNames.join(', ')}
              </p>

              {!selectedClaimRequest.organization &&
              selectedClaimRequest.claim.account.organization ? (
                <p>
                  <b>
                    The {selectedClaimRequest.communityDisplayNames.length}{' '}
                    {selectedClaimRequest.communityDisplayNames.length > 1
                      ? 'communities are'
                      : 'communty is'}{' '}
                    not currently associated with any organization. Approval of this
                    request will assign{' '}
                    {selectedClaimRequest.communityDisplayNames.length > 1
                      ? 'them'
                      : 'it'}{' '}
                    to {selectedClaimRequest.claim.account.organization.name}
                  </b>
                </p>
              ) : selectedClaimRequest.claim.account.role === 'community_manager' &&
                selectedClaimRequest.claim.account.organization &&
                selectedClaimRequest.organization &&
                selectedClaimRequest.claim.account.organization.id ===
                  selectedClaimRequest.organization.id ? (
                <p>
                  <b>
                    The
                    {selectedClaimRequest.communityDisplayNames.length > 1
                      ? ' communities are '
                      : ' communty is '}
                    currently owned by {selectedClaimRequest.organization.name}. The user
                    requesting management of the
                    {selectedClaimRequest.communityDisplayNames.length > 1
                      ? ' communities '
                      : ' communty '}
                    is associated with this organization.
                  </b>
                </p>
              ) : selectedClaimRequest.claim.account.role === 'community_manager' &&
                selectedClaimRequest.claim.account.organization &&
                selectedClaimRequest.organization ? (
                <div>
                  <p>
                    <b>
                      The
                      {selectedClaimRequest.communityDisplayNames.length > 1
                        ? ' communities are '
                        : ' communty is '}
                      currently owned by {selectedClaimRequest.organization.name}. The
                      user requesting management of this community is associated with{' '}
                      {selectedClaimRequest.claim.account.organization.name}.
                    </b>
                  </p>
                  <p>
                    Would you like to change ownership of the
                    {selectedClaimRequest.communityDisplayNames.length > 1
                      ? ' communities '
                      : ' communty '}
                    to {selectedClaimRequest.claim.account.organization.name}?
                  </p>
                  <FormControl component='fieldset'>
                    <RadioGroup
                      aria-label='property-controlled-radio-buttons-group'
                      name='radio-buttons-group'
                      className={styles.radioGroup}
                      onChange={handleAssignSelection}
                      value={reassignProperty}
                      color='black'
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        className={styles.radioLabel}
                        label={`Yes, update the ${
                          selectedClaimRequest.communityDisplayNames.length
                        } ${
                          selectedClaimRequest.communityDisplayNames.length > 1
                            ? 'communities'
                            : 'communty'
                        } to be owned by ${
                          selectedClaimRequest.claim.account.organization.name
                        } and place within:`}
                      />
                      <PropertySelect
                        propertyOptions={organizationProperties?.results}
                        property={property}
                        onChange={handleSelectNewProperty}
                      />

                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        className={styles.radioLabel}
                        label={`No, give ${
                          selectedClaimRequest.claim.account['first_name']
                        } access but leave the ${
                          selectedClaimRequest.communityDisplayNames.length
                        } ${
                          selectedClaimRequest.communityDisplayNames.length > 1
                            ? 'communities'
                            : 'communty'
                        } as part of ${selectedClaimRequest.organization.name}`}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              ) : selectedClaimRequest.claim.account.organization &&
                selectedClaimRequest.organization ? (
                <>
                  <div>
                    <p>
                      <b>
                        The {selectedClaimRequest.communityDisplayNames.length}
                        {selectedClaimRequest.communityDisplayNames.length > 1
                          ? ' communities are '
                          : ' communty is '}
                        currently owned by {selectedClaimRequest.organization.name}. The
                        user requesting management of this community is a corporate
                        executive or admin user associated with{' '}
                        {selectedClaimRequest.claim.account.organization.name}.
                      </b>
                    </p>
                    <p>
                      <b>
                        Proceeding with this approval will reassign the{' '}
                        {selectedClaimRequest.communityDisplayNames.length}
                        {selectedClaimRequest.communityDisplayNames.length > 1
                          ? ' communities '
                          : ' communty '}
                        from {selectedClaimRequest.organization.name} to{' '}
                        {selectedClaimRequest.claim.account.organization.name}.
                      </b>
                    </p>
                  </div>
                  <PropertySelect
                    propertyOptions={organizationProperties?.results}
                    property={property}
                    onChange={handleSelectNewProperty}
                  />
                </>
              ) : null}
            </div>
          ) : (
            <>
              <p>Please explain why you're rejecting this request</p>
              <textarea
                value={rejectReason}
                onChange={handleReasonChange}
                placeholder='Claim rejection reason...'
                className={styles.textArea}
              />
            </>
          )}
        </KthPopup>
      ) : null}
    </div>
  );
};
