import useSWR from 'swr';
import api from '../services/api';

export const useCommunityAccounts = (communityId) => {
  const { data, error, mutate } = useSWR(
    `/v1/admin/communities/${communityId}/accounts`,
    api,
    {
      errorRetryCount: 3,
      revalidateOnFocus: true,
      shouldRetryOnError: false,
      onErrorRetry: (err, key, config, revalidate, revalidateOps) => {
        console.log({ err, config });
      },
    }
  );
  return {
    data: data ? data.data : [],
    loading: !data && !error,
    error,
    mutate,
  };
};
