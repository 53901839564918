import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-thin-svg-icons';
import React from 'react';

type Props = {
  width?: number;
  height?: number;
};

const SpinnerLoader: React.FC<Props> = ({ width = 50, height = 50 }) => {
  return (
    <div className='ml-auto mr-auto mt-100 fs-400 text-center'>
      <FontAwesomeIcon icon={faSpinner} width={width} height={height} spin />
    </div>
  );
};

export default SpinnerLoader;
