import { DropdownOption } from '../components/Dropdown';

export enum PhotoType {
  'Reception' = 'Reception',
  'Lobby' = 'Lobby',
  'Building Entrance' = 'Building Entrance',
  'Building Exterior' = 'Building Exterior',
  'Unit' = 'Unit',
  'Hallway' = 'Hallway',
  'Fitness' = 'Fitness',
  'Wellness' = 'Wellness',
  'Social' = 'Social',
  'Spiritual' = 'Spiritual',
  'Dining' = 'Dining',
  'Learning' = 'Learning',
  'Residents' = 'Residents',
  'Staff' = 'Staff',
  'Map' = 'Map',
  'Menu' = 'Menu',
  'Schedule' = 'Schedule',
  'Calendar' = 'Calendar',
  'Brochure' = 'Brochure',
  'Indoor' = 'Indoor',
  'Outdoor' = 'Outdoor',
}

export const photoTypeOptions: DropdownOption[] = Object.entries(PhotoType).map(
  ([key, value]) => {
    return {
      label: value,
      value,
    } as DropdownOption;
  }
);
