import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CommunityCareTypeCell } from '../../components/CommunityCareTypeCell/CommunityCareTypeCell';
import CommunityList from '../../components/CommunityList/CommunityList';
import CommunityNameCell from '../../components/CommunityList/CommunityNameCell';
import Loader from '../../components/Loader/Loader';
import { useQueryCommunity } from '../../hooks/useQueryCommunity';
import { Constants } from 'web-core';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useSelector } from 'react-redux';
import { navigateToEditCommunity } from '../../utils/navigateToEditCommunity';
import ModulePage from '../../components/ModulePage/ModulePage';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';

function CommunitiesDashboard() {
  const user = useSelector((state) => state?.auth?.user);
  const urlPage = useQueryParams().get('page') || '';
  const { queryResult, queryResultMeta, queryLoading, queryCommunities } =
    useQueryCommunity('/v1/admin/communities');
  const [communityQueryResults, setCommunityQueryResults] = useState({
    queryResult,
    queryResultMeta,
    queryLoading,
    queryCommunities,
  });
  const [query, setQuery] = useState(null);
  const history = useHistory();
  const account = useSelector((state) => state.auth.user);

  useEffect(() => {
    setCommunityQueryResults({
      queryResult,
      queryResultMeta,
      queryLoading,
      queryCommunities,
    });
  }, [queryCommunities]);

  useEffect(() => {
    setCommunityQueryResults({
      queryResult,
      queryResultMeta,
      queryLoading,
      queryCommunities,
    });
  }, [queryResult]);

  const showCommunityStatus = ({ row }) => {
    if (row?.original?.status === 'published') {
      return 'Published';
    } else if (row?.original?.status === 'unpublished') {
      return 'Unpublished';
    } else {
      return '';
    }
  };

  const showCommunityPercentageComplete = ({ row }) => {
    if (row?.original?.profile_completion) {
      let profileCompletion = row.original.profile_completion;
      return (
        <div>
          {profileCompletion.approved ? (
            <p style={{ marginBottom: 0 }}>
              Published - {profileCompletion.approved.pct_complete}%{' '}
            </p>
          ) : null}
          {profileCompletion.draft ? (
            <p>Draft - {profileCompletion.draft.pct_complete}% </p>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Community Name',
        accessor: 'name',
        minWidth: 600,
        width: 600,
        maxWidth: 600,
        Cell: ({ row }) => <CommunityNameCell row={row} />,
      },
      {
        Header: 'Care type',
        accessor: 'care_type',
        Cell: CommunityCareTypeCell,
        minWidth: 200,
        width: 200,
        maxWidth: 200,
      },
      {
        Header: 'Status',
        accessor: 'status',
        minWidth: 200,
        width: 200,
        maxWidth: 200,
        Cell: showCommunityStatus,
      },
    ];
    return cols;
  }, [queryResult]);

  const adminColumns = useMemo(() => {
    const cols = [
      {
        Header: 'Community Name',
        accessor: 'name',
        minWidth: 550,
        width: 550,
        maxWidth: 550,
        Cell: ({ row }) => <CommunityNameCell row={row} />,
      },

      {
        Header: 'Care type',
        accessor: 'care_type',
        Cell: CommunityCareTypeCell,
        minWidth: 150,
        width: 150,
        maxWidth: 150,
      },
      {
        Header: 'Status',
        accessor: 'status',
        minWidth: 150,
        width: 150,
        maxWidth: 150,
        Cell: showCommunityStatus,
      },
      {
        Header: 'Completion level',
        accessor: 'id',
        minWidth: 150,
        width: 150,
        maxWidth: 150,
        Cell: showCommunityPercentageComplete,
      },
    ];
    return cols;
  }, [queryResult]);

  const handleListItemClick = (item) => {
    const { id: communityId } = item;
    navigateToEditCommunity(history, communityId, true, null);
  };

  useEffect(() => {
    queryCommunities(query, urlPage);
  }, [urlPage]);

  return (
    <>
      <HeaderTitle title={'My Communities'} />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>My Communities</h1>
          </div>
        </div>
        <div className='communities-dashboard'>
          <div className='communities-container'>
            <div className='admin-communities'>
              <Loader isLoading={queryLoading}>
                <CommunityList
                  title='Your communities'
                  communities={communityQueryResults.queryResult}
                  meta={communityQueryResults.queryResultMeta}
                  columns={
                    account?.role === Constants.AccountRoles.Admin
                      ? adminColumns
                      : columns
                  }
                  onItemClick={handleListItemClick}
                />
                <ModulePage
                  subTitle={'How to'}
                  title={'Create your Kithward Community Profile'}
                  description={
                    'Learn how to fill out your community’s profile on Kithward.'
                  }
                  videoUrl={
                    'https://cdn.kithward.com/videos/kithward-how-to-edit-your-community-profile.mp4'
                  }
                />
              </Loader>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CommunitiesDashboard;
