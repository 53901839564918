import { Tooltip } from '@mui/material';
import { Constants } from 'web-core';
import { ModerationQueueItemStatus } from '../../constants/ModerationQueueItemStatus';
import { ModerationQueueActionButtons } from '../ModerationQueueActionButtons/ModerationQueueActionButtons';
import { AccountTypeLabels } from '../../constants/AccountType';
import { KWImage } from '../KWImage/KWImage';
import styles from './CommunityClaimsList.module.scss';

export const CommunityClaimItem = ({ claim, onApproveClick, onRejectClick }) => {
  const { account, communities, status } = claim;

  const communityNames = communities?.map(
    (community) => community.json_data[Constants.DynamicSchemaFields.Name] || ''
  );
  const communityDisplayNames = communities?.map(
    (community) => community.display_name || ''
  );
  const { first_name, last_name } = account;
  const accountName = [first_name, last_name].filter((a) => a).join(' ');
  const titleName = account.job_title ? account.job_title : null;
  const organizationName = account.organization ? account.organization['name'] : null;
  const emailAddress = account.email ? account.email : null;
  const account_type = AccountTypeLabels[account.account_type];
  const isEntireProperty =
    communities[0]?.approved_property_communities.length === communities.length;

  let accountData = (
    <>
      <p>Title: {titleName ? titleName : 'N/A'}</p>
      <p>Organization: {organizationName ? organizationName : 'N/A'}</p>
      <p>Email: {emailAddress ? emailAddress : 'N/A'}</p>
      <p>Account Type: {account_type}</p>
    </>
  );

  return (
    <div className={styles.communityClaimItem}>
      <div className={styles.itemInfoContainer}>
        <KWImage
          src={communities[0]?.cached_image_url}
          width={50}
          height={50}
          alt={communityNames[0]}
        />
        <div className={styles.itemInfo}>
          <h4>
            {isEntireProperty ? 'Property ' : 'Community '}Claim Request -{' '}
            {communities.map((community, index) => (
              <a
                href={`${process.env.REACT_APP_CONSUMER}/community/${community.id}`}
                target='_blank'
                rel='noreferrer'
              >{`${
                index === 0 ? '' : index + 1 === communities.length ? ', and ' : ', '
              }${communityDisplayNames[index]}`}</a>
            ))}
          </h4>
          <p>
            <Tooltip title={accountData} arrow className={null}>
              <span>
                {accountName}
                {account_type ? `, a ${account_type}` : ''}
              </span>
            </Tooltip>
            <span>{organizationName ? ` from ${organizationName}` : ''}</span> has
            requested to claim{' '}
            {isEntireProperty
              ? 'all communities within a property'
              : `${communities.length} of 
            ${communities[0].approved_property_communities.length} communities.`}
          </p>
        </div>
      </div>
      <ModerationQueueActionButtons
        onAccept={onApproveClick}
        onReject={onRejectClick}
        status={status === '?' ? ModerationQueueItemStatus.PENDING : status}
        hasPreview={false}
      />
    </div>
  );
};
