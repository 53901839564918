import Icons from '../../constants/Icons';
import styles from './Button.module.scss';

enum ButtonStyle {
  BROWN,
  DARK,
  LIGHT,
  WHITE,
  TEXT,
  DEFAULT,
  BLACK,
  BLACK_OUTLINED,
  WHITE_OUTLINED,
  FOCUS,
}

type StyledButtonProps = {
  icon?: boolean;
  loading?: boolean;
} & React.HTMLProps<HTMLButtonElement>;

type ButtonProps = {
  variant: ButtonStyle;
  icon: boolean;
  loading?: boolean;
} & React.HTMLProps<HTMLButtonElement>;

type ButtonType = {
  BROWN: React.FC<StyledButtonProps>;
  DARK: React.FC<StyledButtonProps>;
  DEFAULT: React.FC<StyledButtonProps>;
  LIGHT: React.FC<StyledButtonProps>;
  WHITE: React.FC<StyledButtonProps>;
  TEXT: React.FC<StyledButtonProps>;
  BLACK: React.FC<StyledButtonProps>;
  BLACK_OUTLINED: React.FC<StyledButtonProps>;
  WHITE_OUTLINED: React.FC<StyledButtonProps>;
  FOCUS: React.FC<StyledButtonProps>;
} & React.FC<ButtonProps>;

const Button: ButtonType = ({ variant, icon, loading, ...props }: ButtonProps) => {
  const getButtonStyle = () => {
    switch (variant) {
      case ButtonStyle.DEFAULT:
        return styles.default;
      case ButtonStyle.DARK:
        return styles.dark;
      case ButtonStyle.LIGHT:
        return styles.light;
      case ButtonStyle.BROWN:
        return styles.brown;
      case ButtonStyle.WHITE:
        return styles.white;
      case ButtonStyle.TEXT:
        return styles.text;
      case ButtonStyle.BLACK:
        return styles.black;
      case ButtonStyle.BLACK_OUTLINED:
        return styles.black_outlined;
      case ButtonStyle.WHITE_OUTLINED:
        return styles.white_outlined;
      case ButtonStyle.FOCUS:
        return styles.focus;
      default:
        return styles.dark;
    }
  };
  return (
    <button
      {...(props as React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >)}
      className={`${styles.button} ${icon ? styles.icon : ''} ${
        props.className || ''
      } ${getButtonStyle()}`}
      disabled={props.disabled || loading}
    >
      {loading ? Icons.Loader : props.children}
    </button>
  );
};

Button.BROWN = (props: StyledButtonProps) => (
  <Button variant={ButtonStyle.BROWN} icon={props.icon || false} {...props} />
);
Button.DARK = (props: StyledButtonProps) => (
  <Button variant={ButtonStyle.DARK} icon={props.icon || false} {...props} />
);
Button.LIGHT = (props: StyledButtonProps) => (
  <Button variant={ButtonStyle.LIGHT} icon={props.icon || false} {...props} />
);
Button.WHITE = (props: StyledButtonProps) => (
  <Button variant={ButtonStyle.WHITE} icon={props.icon || false} {...props} />
);
Button.TEXT = (props: StyledButtonProps) => (
  <Button variant={ButtonStyle.TEXT} icon={props.icon || false} {...props} />
);
Button.DEFAULT = (props: StyledButtonProps) => (
  <Button variant={ButtonStyle.DEFAULT} icon={props.icon || false} {...props} />
);
Button.BLACK = (props: StyledButtonProps) => (
  <Button variant={ButtonStyle.BLACK} icon={props.icon || false} {...props} />
);
Button.BLACK_OUTLINED = (props: StyledButtonProps) => (
  <Button variant={ButtonStyle.BLACK_OUTLINED} icon={props.icon || false} {...props} />
);
Button.WHITE_OUTLINED = (props: StyledButtonProps) => (
  <Button variant={ButtonStyle.WHITE_OUTLINED} icon={props.icon || false} {...props} />
);
Button.FOCUS = (props: StyledButtonProps) => (
  <Button variant={ButtonStyle.FOCUS} icon={props.icon || false} {...props} />
);

export default Button;
