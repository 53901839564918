import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectModelsView,
  selectUnitsView,
} from '../../../../redux/selectors/communityForm';
import CustomAutoComplete from '../CustomAutoComplete/CustomAutoComplete';

export const ViewAutoComplete = (props) => {
  const modelsView = useSelector(selectModelsView) || [];
  const unitsView = useSelector(selectUnitsView) || [];
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // Get view from Models
    // Get view from Units

    const views = [...modelsView, ...unitsView];
    const newOptions = views.map((view) => ({
      value: view,
      label: view,
    }));
    // May want to append with "props.options.enumOptions", coming from schema.
    setOptions(newOptions);
  }, [modelsView, unitsView]);

  return (
    <CustomAutoComplete {...props} options={{ ...props.options, enumOptions: options }} />
  );
};
