import { useState } from 'react';
import api from '../../services/api';
import { PropertyDetailForm } from './PropertyDetailForm';
import { CommunityDetailForm } from './CommunityDetailForm';
import { Constants } from 'web-core';

export const NewPropertyPopup = ({ onClose }) => {
  const [propertyForm, setPropertyForm] = useState({
    name: '',
    street_more: '',
    country: '',
    care_types: [],
    website_url: '',
  });
  const [page, setPage] = useState('PropertyDetail');
  const [skipValidateNew, setSkipValidateNew] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPropertyForm((currentForm) => {
      return {
        ...currentForm,
        [name]: value,
      };
    });
  };

  const handleCommunityChange = (event, careType) => {
    const { name, value } = event.target;
    setPropertyForm((currentForm) => {
      return {
        ...currentForm,
        [careType]: { ...currentForm[careType], [name]: value },
      };
    });
  };

  const handleCareTypeSelect = (event, value) => {
    const orderRule = { I: 1, A: 2, N: 3, M: 4, default: Number.MAX_VALUE };
    const selectedCareType = event.target.value;
    const selected = value;
    if (selectedCareType && selected) {
      const sortedCareTypes = [
        ...new Set([...propertyForm.care_types, selectedCareType]),
      ].sort(
        (a, b) =>
          (orderRule[a] || orderRule.default) - (orderRule[b] || orderRule.default) ||
          a > b ||
          -(a < b)
      );
      setPropertyForm((currentForm) => ({
        ...currentForm,
        care_types: sortedCareTypes,
      }));
      sortedCareTypes.forEach((serviceLevel) =>
        setPropertyForm((currentForm) => {
          return {
            ...currentForm,
            [serviceLevel]: {},
          };
        })
      );
    } else if (
      selected === false &&
      selectedCareType &&
      propertyForm.care_types.includes(selectedCareType)
    ) {
      setPropertyForm((currentForm) => ({
        ...currentForm,
        care_types: propertyForm.care_types.filter(
          (careType) => careType !== selectedCareType
        ),
      }));
    }
  };

  const handleSubmitProperty = async (propertyForm) => {
    const propertyDetails = {
      name: propertyForm.name,
      street: propertyForm.street,
      street_more: propertyForm.street_more,
      city: propertyForm.city,
      state: propertyForm.state,
      postal: propertyForm.postal,
      country: propertyForm.country,
      website_url: propertyForm.website_url,
      communities_attributes: [],
    };
    propertyForm.care_types.forEach((serviceLevel) => {
      propertyDetails.communities_attributes.push({
        json_data: {
          [Constants.DynamicSchemaFields.Name]: propertyForm[serviceLevel].name,
          [Constants.DynamicSchemaFields.CareType]: serviceLevel,
          [Constants.DynamicSchemaFields.StreetAddress]:
            propertyForm[serviceLevel].street,
          [Constants.DynamicSchemaFields.StreetAddressLineTwo]:
            propertyForm[serviceLevel].street_more,
          [Constants.DynamicSchemaFields.City]: propertyForm[serviceLevel].city,
          [Constants.DynamicSchemaFields.StateProvince]: propertyForm[serviceLevel].state,
          [Constants.DynamicSchemaFields.PostalCode]: propertyForm[serviceLevel].postal,
          [Constants.DynamicSchemaFields.Country]: propertyForm[serviceLevel].country,
        },
      });
    });
    await api.post(`/v1/admin/properties`, propertyDetails);
    setSkipValidateNew(false);
  };

  const handlePageChange = async (page, previous = false) => {
    const currentPage = propertyForm.care_types.findIndex(
      (pageName) => pageName === page
    );
    if (page === 'PropertyDetail') {
      setPage(propertyForm.care_types[0]);
    } else if (previous) {
      if (currentPage === 0) {
        setPage('PropertyDetail');
      } else {
        setPage(propertyForm.care_types[currentPage - 1]);
      }
    } else if (propertyForm.care_types.length === currentPage + 1) {
      await handleSubmitProperty(propertyForm);
      setPage(null);
      onClose();
    } else {
      setPage(propertyForm.care_types[currentPage + 1]);
    }
  };

  return (
    <>
      <PropertyDetailForm
        propertyForm={propertyForm}
        setPropertyForm={setPropertyForm}
        onPageChange={handlePageChange}
        page={page}
        onClose={onClose}
        handleChange={handleChange}
        handleCareTypeSelect={handleCareTypeSelect}
        isOpen={page === 'PropertyDetail'}
        isEditing={false}
      />
      {propertyForm.care_types.map((careType) => (
        <CommunityDetailForm
          key={careType}
          propertyForm={propertyForm}
          onPageChange={handlePageChange}
          page={page}
          onClose={onClose}
          handleChange={handleCommunityChange}
          isOpen={page === careType}
          careType={careType}
          skipValidateNew={skipValidateNew}
          handleSkipValidateNew={() => setSkipValidateNew(true)}
        />
      ))}
    </>
  );
};
