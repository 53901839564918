import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import Popup from 'reactjs-popup';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import styles from './KthPopup.module.scss';
import React, { useRef } from 'react';

interface Props {
  isOpen: boolean;
  title?: string;
  largeTitle?: boolean;
  children: React.ReactNode;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  onCancelButtonClick?: () => void;
  onConfirmButtonClick?: () => void;
  isConfirmButtonDisabled?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  hideActions?: boolean;
}

const KthPopup: React.FC<Props> = ({
  isOpen,
  title,
  largeTitle,
  children,
  cancelButtonLabel,
  confirmButtonLabel,
  onCancelButtonClick,
  onConfirmButtonClick,
  isConfirmButtonDisabled,
  isLoading,
  onClose,
  hideActions,
}) => {
  return (
    <Popup
      open={isOpen}
      modal
      nested
      closeOnDocumentClick={false}
      onClose={onClose}
      lockScroll={true}
    >
      {(close: React.MouseEventHandler<HTMLButtonElement> | undefined) => (
        <div className={styles.modal}>
          <div className={styles.header}>
            <span className={largeTitle ? styles.largeTitle : styles.title}>{title}</span>
            <button className={styles.close} onClick={close}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </div>
          <div className={styles.content}>{children}</div>
          {hideActions ? null : (
            <div className={styles.actions}>
              <Loader isLoading={isLoading}>
                {onCancelButtonClick && cancelButtonLabel ? (
                  <Button.LIGHT onClick={onCancelButtonClick}>
                    {cancelButtonLabel}
                  </Button.LIGHT>
                ) : null}
                {cancelButtonLabel && !onCancelButtonClick ? (
                  <Button.LIGHT onClick={close}>{cancelButtonLabel}</Button.LIGHT>
                ) : null}
                {onConfirmButtonClick || confirmButtonLabel ? (
                  <Button.DARK
                    onClick={onConfirmButtonClick}
                    disabled={isConfirmButtonDisabled}
                  >
                    {confirmButtonLabel}
                  </Button.DARK>
                ) : null}
              </Loader>
            </div>
          )}
        </div>
      )}
    </Popup>
  );
};

export default KthPopup;
