import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Constants } from 'web-core';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { Tooltip } from '@mui/material';

export const BuildingSelect = (props) => {
  const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const formData = useSelector((state) => state.communityForm.formData);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const newOptions = formData.buildings
      ? formData.buildings
          .filter((b) => b.id)
          .map((b) => ({
            label: b[Constants.DynamicSchemaFields.BuildingName],
            value: b.id,
          }))
      : [];

    setOptions(newOptions);
  }, [formData, uiSchema, jsonSchema]);

  return options.length === 0 ? (
    <Tooltip title='No buildings available to assign to this unit. Create a building in the Buildings tab to associate the unit with a specific building.'>
      <div>
        <CustomSelect
          {...props}
          options={{
            enumOptions: options,
          }}
          disabled={options.length === 0}
        />
      </div>
    </Tooltip>
  ) : (
    <CustomSelect
      {...props}
      options={{
        enumOptions: options,
      }}
    />
  );
};
