import { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { refreshSession, logout } from '../redux/reducers';
import jwt_decode from 'jwt-decode';
import { CookieUtils } from 'web-core';

const SessionManager = ({ children }) => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // WARNING: if you change any of this, update SessionManager.jsx in web too
  useEffect(() => {
    const currentTime = Math.round(Date.now() / 1000);

    const cookies = new Cookies();
    const accessToken = CookieUtils.getKWAccessToken(
      cookies,
      process.env.REACT_APP_ENVIRONMENT
    );
    const refreshToken = CookieUtils.getKWRefreshToken(
      cookies,
      process.env.REACT_APP_ENVIRONMENT
    );

    const decodedAccessToken = accessToken ? jwt_decode(accessToken) : null;
    const decodedRefreshToken = refreshToken ? jwt_decode(refreshToken) : null;

    const signedInUserNotSyncedWithRefreshToken = Boolean(
      refreshToken &&
        decodedRefreshToken &&
        auth &&
        auth.user &&
        decodedRefreshToken.account_id !== parseInt(auth.user.id)
    );

    const signedInUserHasNoRefreshToken = Boolean(
      !refreshToken && auth && auth.user && auth.user.id !== null
    );
    const userNotSignedInButRefreshTokenPresent = Boolean(
      refreshToken && auth && auth.user === null
    );

    // if there are 23 hours or less until expiration based on UTC timestamp, refresh session if we have a refresh token to do it with
    const shouldRefreshAccessToken =
      refreshToken &&
      (!decodedAccessToken ||
        (decodedAccessToken &&
          decodedAccessToken.exp &&
          decodedAccessToken.exp - currentTime <= 82800));

    if (signedInUserHasNoRefreshToken) {
      console.log('Signed in user has no refresh token - logging out');
      dispatch(logout(false, true)); // true = redirect back to the current page after subsequent login
    } else if (
      signedInUserNotSyncedWithRefreshToken ||
      userNotSignedInButRefreshTokenPresent ||
      shouldRefreshAccessToken
    ) {
      dispatch(refreshSession(refreshToken));
    }
  }, [location]);

  return <>{children}</>;
};

export default SessionManager;
