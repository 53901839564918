// NOTE: when starting to edit a community this should be the only method called
export const navigateToEditCommunity = (
  history,
  communityId,
  returnToCurrentPathOnBack,
  editQueryParams
) => {
  if (returnToCurrentPathOnBack) {
    let url = new URL(window.location.href);
    history.push({
      pathname: `/communities/${communityId}/edit`,
      search: editQueryParams,
      state: {
        returnToPath: {
          pathname: url.pathname,
          search: url.search,
          hash: url.hash,
        },
      },
    });
  } else {
    // otherwise we pass along pre-existing return to path
    // or nothing if there is no saved returnToPath in the state
    if (history.location.state && history.location.state.returnToPath) {
      history.push({
        pathname: `/communities/${communityId}/edit`,
        search: editQueryParams,
        state: { returnToPath: history.location.state.returnToPath },
      });
    } else {
      history.push({
        pathname: `/communities/${communityId}/edit`,
        search: editQueryParams,
      });
    }
  }
};
