import SignUpFormAdapter from '../adapters/SignupFormAdapter';
import api from './api';

const PATH = `/v1/admin/user_moderations`;

export const approveAccountRequest = async (account) => {
  try {
    const { data } = await api.post(
      `${PATH}/${account.id}/approve`,
      SignUpFormAdapter.toApi(account)
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const rejectAccountRequest = async (accountId, rejectedReason) => {
  try {
    const { data } = await api.post(`${PATH}/${accountId}/reject`, {
      rejected_reason: rejectedReason,
    });
    return data;
  } catch (err) {
    throw err;
  }
};
