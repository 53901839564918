import styles from './RouterPrompt.module.scss';
import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/pro-thin-svg-icons';

const RouterPrompt = (props) => {
  const { onSave, onDiscard, onCancel, title, message, switchingToQueueChangesTab } =
    props;

  return (
    <div className={styles.promptContainer}>
      <div className={styles.title}>
        <span>{title}</span>
        <span className={styles.closeButton} onClick={onCancel}>
          <FontAwesomeIcon icon={faXmarkCircle} size='1x' />
        </span>
      </div>
      <div className={styles.message}>
        <p>{message}</p>
      </div>
      <div className={styles.bottomButtons}>
        <Button.LIGHT onClick={(e) => onDiscard(e)}>
          {switchingToQueueChangesTab ? 'View without saving changes' : 'Discard changes'}
        </Button.LIGHT>
        <Button.BLACK className='ml-50' onClick={(e) => onSave(e)}>
          {switchingToQueueChangesTab ? 'Save then view changes' : 'Save changes'}
        </Button.BLACK>
      </div>
    </div>
  );
};

export default RouterPrompt;
