import styles from './PropertyCompletedAssessmentsList.module.scss';
import { AssessmentResponseProps } from '../../types/AssessmentResponseProps';
import React, { useMemo } from 'react';
import { MetaProps } from '../../types/MetaProps';
import { useTable } from 'react-table';
import { Pagination } from '../Pagination/Pagination';
import { format } from 'date-fns';
import Button from '../Button/Button';
import { useSelector } from 'react-redux';
import { selectIsSuperAdmin } from '../../redux/selectors/auth';

type Props = {
  assessments: AssessmentResponseProps[];
  meta: MetaProps;
};

const SURVEY_TYPE = {
  financialAssessment: 'financial-calculator',
  educationAssessment: 'education-center',
};

const PropertyCompletedAssessmentsList: React.FC<Props> = ({ assessments, meta }) => {
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  const viewAssessmentReport = async (
    propertySlug: string,
    guid: string,
    surveyType: string
  ) => {
    const url = `${process.env.REACT_APP_CONSUMER}/${surveyType}/${propertySlug}/report/${guid}`;
    try {
      window.open(url, '_blank');
    } catch (e) {
      console.error(e);
    }
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Assessment',
          minWidth: 150,
          maxWidth: 175,
          Cell: ({ row }: any) => {
            let assessment = row.original;
            const { fa_completed_at, ea_completed_at, ha_completed_at } = assessment;
            const faCompleted = fa_completed_at ? 'Financial Assessment' : null;
            const eaCompleted = ea_completed_at ? 'Educational Assessment' : null;
            const haCompleted = ha_completed_at ? 'Health Assessment' : null;
            const assessmentType = [faCompleted, eaCompleted, haCompleted]
              .filter((str) => str !== null)
              .join(' + ');
            return <>{assessmentType ? assessmentType : 'N/A'}</>;
          },
        },
        {
          Header: 'Recommended Care Level',
          minWidth: 75,
          maxWidth: 75,
          Cell: ({ row }: any) => {
            const { most_recent_recommended_care_level } = row.original;
            return (
              <>
                {most_recent_recommended_care_level
                  ? most_recent_recommended_care_level
                  : '-'}
              </>
            );
          },
        },
        {
          Header: 'Completed On',
          accessor: 'last_completed_at',
          minWidth: 75,
          maxWidth: 75,
          Cell: (props: any) => {
            const { value } = props;
            return <>{value ? format(new Date(value), 'MMMM d, yyyy hh:mm:ssa') : '-'}</>;
          },
        },
        {
          Header: 'Requested Contact',
          accessor: 'requests_to_be_contacted_label',
          minWidth: 50,
          maxWidth: 50,
          Cell: (props: any) => {
            const { value } = props;
            return <>{value}</>;
          },
        },
        {
          Header: 'Report',
          minWidth: 400,
          maxWidth: 600,
          Cell: ({ row }: any) => {
            const { uuid, result_uuid, operator_result_uuid, property } = row.original;
            const propertySlug = property.slug;
            const completedAssessmentType =
              [row.original.ea_completed_at, row.original.fa_completed_at].findIndex(
                (finishedDate) => finishedDate === row.original.last_completed_at
              ) === 0
                ? SURVEY_TYPE.educationAssessment
                : SURVEY_TYPE.financialAssessment;
            if (isSuperAdmin) {
              return (
                <div className={styles.adminButtonsWrapper}>
                  <div className={styles.adminButtons}>
                    <Button.DEFAULT
                      onClick={() =>
                        viewAssessmentReport(
                          propertySlug,
                          operator_result_uuid,
                          completedAssessmentType
                        )
                      }
                    >
                      Prospect Report
                    </Button.DEFAULT>
                    <div
                      className={styles.link}
                      onClick={() =>
                        viewAssessmentReport(propertySlug, uuid, completedAssessmentType)
                      }
                    >
                      Responder Report
                    </div>
                    <div
                      className={styles.link}
                      onClick={() =>
                        viewAssessmentReport(
                          propertySlug,
                          result_uuid,
                          completedAssessmentType
                        )
                      }
                    >
                      Viewer Report
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <Button.DEFAULT
                  onClick={() =>
                    viewAssessmentReport(
                      propertySlug,
                      result_uuid,
                      completedAssessmentType
                    )
                  }
                >
                  Prospect Report
                </Button.DEFAULT>
              );
            }
          },
        },
      ] as any,
    [isSuperAdmin]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: assessments,
  });

  return (
    <div className={styles.completedAssessmentsListContainer}>
      {rows.length > 0 ? (
        <table {...getTableProps}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={styles.assessmentItem}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className={styles.emptyList}>
          <b>No Completed Assessments Found.</b>
        </div>
      )}

      {assessments && meta ? (
        <Pagination meta={meta} columns={columns} data={assessments} />
      ) : null}
    </div>
  );
};

export default PropertyCompletedAssessmentsList;
