import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useAddressAutocomplete from '../../hooks/useAddressAutocomplete';
import api from '../../services/api';
import {
  FormControl,
  TextField,
  Box,
  Grid,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import KthPopup from '../KthPopup/KthPopup';
import Button from '../Button/Button';
import { KWImage } from '../KWImage/KWImage';
import { Link } from 'react-router-dom';
import styles from '../NewPropertyPopup/NewPropertyPopup.module.scss';
import { getCountries, getStatesByShort } from 'countrycitystatejson';
import { StringUtils, Constants, Countries } from 'web-core';
import { navigateToEditCommunity } from '../../utils/navigateToEditCommunity';

export const NewCommunityPopup = ({ property, onClose }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.auth.user);
  const [careType, setCareType] = useState('');
  const [existingCommunity, setExistingCommunity] = useState(null);
  const [communityForm, setCommunityForm] = useState({
    name: property.name || '',
    care_type: '',
    street: property.street || '',
    street_more: property.street_more || '',
    city: property.city || '',
    state: property.state || '',
    postal: property.postal || '',
    country: property.country || '',
  });
  const [skipValidateNew, setSkipValidateNew] = useState(false);
  const userRole = useSelector((state) => state?.auth?.user?.role);
  const address = useAddressAutocomplete('');

  const disabledSubmitButton =
    StringUtils.isBlank(communityForm.name) ||
    StringUtils.isBlank(communityForm.care_type) ||
    StringUtils.isBlank(communityForm.street) ||
    StringUtils.isBlank(communityForm.city) ||
    StringUtils.isBlank(communityForm.state) ||
    StringUtils.isBlank(communityForm.postal) ||
    StringUtils.isBlank(communityForm.country);

  const handleSelectAddressOption = (suggestion) => {
    const street = suggestion.address + ' ' + suggestion.text;
    address.setValue(street);
    address.setResults([]);
    const addressValues = suggestion.context;
    const city = addressValues.find((contextItem) => contextItem.id.includes('place.'));
    const state = addressValues.find((contextItem) => contextItem.id.includes('region.'));
    const postal = addressValues.find((contextItem) =>
      contextItem.id.includes('postcode.')
    );
    const country = addressValues.find((contextItem) =>
      contextItem.id.includes('country.')
    );

    //handle address changes
    let changeQueue = [
      {
        target: {
          name: 'street',
          value: street,
        },
      },
      {
        target: {
          name: 'city',
          value: city.text,
        },
      },
      {
        target: {
          name: 'postal',
          value: postal.text,
        },
      },
      {
        target: {
          name: 'country',
          value: country.text,
        },
      },
      {
        target: {
          name: 'state',
          value: state.text,
        },
      },
    ];
    changeQueue.forEach((obj) => {
      handleChange(obj);
    });
  };

  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    let countryShortName = Countries.getters.getShortCountryName(communityForm.country);
    let statesArr = getStatesByShort(countryShortName);
    if (countryShortName === 'US') {
      statesArr.splice(0, 3);
    }
    setStateOptions(statesArr || []);
  }, [communityForm]);

  useEffect(() => {
    let countriesArr = getCountries();
    countriesArr.forEach((country, index) => {
      if (country.shortName === 'US') {
        countriesArr.splice(index, 1);
        countriesArr.unshift(country);
      }
    });
    setCountryOptions(countriesArr);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCommunityForm((currentForm) => {
      return {
        ...currentForm,
        [name]: value,
      };
    });
  };

  const handleSelectCountry = (event) => {
    let countryShortName = Countries.getters.getShortCountryName(event.target.value);
    communityForm.state = '';

    let statesArr = getStatesByShort(countryShortName);
    if (countryShortName === 'US') {
      statesArr.splice(0, 3);
    }
    setStateOptions(statesArr);
    handleChange(event);
  };

  const handleCareTypeChange = (event) => {
    const { value } = event.target;
    setCareType(value);
    setCommunityForm((currentForm) => {
      return {
        ...currentForm,
        care_type: value,
      };
    });
  };

  const handleSubmitCommunity = async () => {
    const communitySubmission = {
      property_id: property.id,
      organization_id: user?.organization?.id
        ? user?.organization?.id
        : property?.organization?.id
        ? property?.organization?.id
        : null,
      json_data: {
        [Constants.DynamicSchemaFields.Name]: communityForm.name,
        [Constants.DynamicSchemaFields.CareType]: communityForm.care_type,
        [Constants.DynamicSchemaFields.StreetAddress]: communityForm.street,
        [Constants.DynamicSchemaFields.StreetAddressLineTwo]: communityForm.street_more,
        [Constants.DynamicSchemaFields.City]: communityForm.city,
        [Constants.DynamicSchemaFields.StateProvince]: communityForm.state,
        [Constants.DynamicSchemaFields.PostalCode]: communityForm.postal,
        [Constants.DynamicSchemaFields.Country]: communityForm.country,
      },
    };

    try {
      if (!skipValidateNew) {
        await api.post(`/v1/admin/communities/validate_new`, communityForm);
      }
      try {
        const { data } = await api.post(`/v1/admin/communities`, communitySubmission);
        setSkipValidateNew(false);
        enqueueSnackbar(`${data.display_name} successfully created.`);
        navigateToEditCommunity(history, data.id, true, null);
      } catch (err) {
        enqueueSnackbar(`Failed to create a community: ${err}`, {
          variant: 'default',
        });
      }
    } catch (err) {
      const { error, results } = err;
      if (error && error === 409) {
        const conflictingCommunity = results[0];
        setExistingCommunity(conflictingCommunity);
      }
    }
  };

  const handleForceCreateCommunity = () => {
    setSkipValidateNew(true);
  };

  useEffect(() => {
    if (skipValidateNew) {
      handleSubmitCommunity().then();
    }
  }, [skipValidateNew]);

  return (
    <KthPopup
      isOpen={true}
      title={`Add a Community to ${property.name}`}
      onClose={() => onClose()}
      hideActions
    >
      {!existingCommunity ? (
        <Box component='form' noValidate autoComplete='off' className={styles.createForm}>
          <Grid container spacing={1}>
            <p>What is the name of this community?</p>
            <TextField
              name='name'
              label='Community Name'
              onChange={(e) => handleChange(e)}
              defaultValue={communityForm.name}
              fullWidth
            />

            <p>What care type is offered?</p>

            <FormControl fullWidth>
              <InputLabel id='care-type-input-label'>Select Care Type...</InputLabel>
              <Select
                labelId='care-type-input-label'
                id='care-type-input'
                value={careType}
                label='Select Care Type'
                onChange={handleCareTypeChange}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                {property.communities.every(
                  (community) => community.care_type !== 'I'
                ) && <MenuItem value={'I'}>Independent Living</MenuItem>}
                {property.communities.every(
                  (community) => community.care_type !== 'A'
                ) && <MenuItem value={'A'}>Assisted Living</MenuItem>}
                {property.communities.every(
                  (community) => community.care_type !== 'M'
                ) && <MenuItem value={'M'}>Memory Care</MenuItem>}
                {property.communities.every(
                  (community) => community.care_type !== 'N'
                ) && <MenuItem value={'N'}>Skilled Nursing</MenuItem>}
              </Select>
            </FormControl>

            <p>Address:</p>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label='Address'
                  {...address}
                  isTyping={address.value !== ''}
                  defaultValue={communityForm.street}
                  autoComplete='off'
                  fullWidth
                />
                {address.results?.length > 0 && (
                  <div className={styles.autoCompleteResults}>
                    {address.results.map((result, index) => {
                      return (
                        <div
                          className={styles.suggestedAddress}
                          key={index}
                          onClick={() => {
                            handleSelectAddressOption(result);
                          }}
                        >
                          {result.place_name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='street_more'
                  label='Address Line 2'
                  onChange={handleChange}
                  value={communityForm.street_more}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name='city'
                  label='City'
                  onChange={handleChange}
                  value={communityForm.city}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  select
                  name='state'
                  label='State'
                  onChange={handleChange}
                  value={communityForm.state}
                  fullWidth
                >
                  {stateOptions.map((option) => {
                    return (
                      <MenuItem key={option.toString()} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name='postal'
                  label='Postal Code'
                  onChange={handleChange}
                  value={communityForm.postal}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  name='country'
                  label='Country'
                  onChange={handleSelectCountry}
                  value={communityForm.country}
                  fullWidth
                >
                  {countryOptions.map((country) => {
                    return (
                      <MenuItem key={country.native} value={country.name}>
                        {country.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Button.DARK
            type='button'
            onClick={() => handleSubmitCommunity()}
            disabled={disabledSubmitButton}
          >
            Create
          </Button.DARK>
        </Box>
      ) : (
        <div className={styles.createForm}>
          <p>
            It looks like you’re trying to create a community that already exists on
            Kithward. We found the below community that matches the data you provided.
          </p>
          <div className={styles.communityNameContainer}>
            <KWImage
              src={existingCommunity.cached_image_url}
              width={85}
              height={85}
              alt={'Existing Community'}
            />
            <div className={styles.communityNameInfo}>
              <b>{existingCommunity.display_name}</b>
              <p>{existingCommunity.address}</p>
            </div>
          </div>
          <p>
            You may request editing privileges for this community by claiming it below. If
            you believe you receieved this message in error please{' '}
            <a
              href={`mailto:hello@kithward.com?subject=Community Creation Error&body=Current URL: ${window.location}`}
              rel='noreferrer'
            >
              contact support.
            </a>
          </p>
          <Link
            to={`/properties/search?name=${existingCommunity.name || ''}&location=${
              existingCommunity.address || ''
            }`}
          >
            <Button.DARK type='button'>Claim this Community</Button.DARK>
          </Link>
          {userRole && userRole === 'admin' ? (
            <Button.LIGHT type='button' onClick={handleForceCreateCommunity}>
              Create community anyway
            </Button.LIGHT>
          ) : null}
        </div>
      )}
    </KthPopup>
  );
};
