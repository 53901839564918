import { useEffect, useState } from 'react';
import { TextField, Box, Grid, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import KthPopup from '../KthPopup/KthPopup';
import Button from '../Button/Button';
import styles from './NewPropertyPopup.module.scss';
import {
  CARE_TYPE_DESCRIPTIONS,
  CARE_TYPE_ABBREVIATIONS,
} from '../../constants/CareType';
import api from '../../services/api';
import { KWImage } from '../KWImage/KWImage';
import { getCountries, getStatesByShort } from 'countrycitystatejson';
import { StringUtils, Countries } from 'web-core';
import useAddressAutocomplete from '../../hooks/useAddressAutocomplete';
import { useSelector } from 'react-redux';

export const CommunityDetailForm = ({
  propertyForm,
  onPageChange,
  page,
  handleChange,
  isOpen,
  careType,
  onClose,
  skipValidateNew,
  handleSkipValidateNew,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [existingCommunity, setExistingCommunity] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const address = useAddressAutocomplete('');
  const userRole = useSelector((state) => state?.auth?.user?.role);

  const handleContinueClick = async (page, propertyForm) => {
    setIsLoading(true);
    try {
      if (!skipValidateNew) {
        await api.post(`/v1/admin/communities/validate_new`, {
          care_type: careType,
          street: propertyForm[careType]?.street,
          street_more: propertyForm[careType]?.street_more,
          city: propertyForm[careType]?.city,
          state: propertyForm[careType]?.state,
          postal: propertyForm[careType]?.postal,
          country: propertyForm[careType]?.country,
        });
      }
      onPageChange(page);
    } catch (err) {
      const { error, results } = err;
      if (error && error === 409) {
        const conflictingCommunity = results[0];
        setExistingCommunity(conflictingCommunity);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const disableContinueClick =
    StringUtils.isBlank(propertyForm[careType].name) ||
    StringUtils.isBlank(propertyForm[careType].street) ||
    StringUtils.isBlank(propertyForm[careType].city) ||
    StringUtils.isBlank(propertyForm[careType].state) ||
    StringUtils.isBlank(propertyForm[careType].postal) ||
    StringUtils.isBlank(propertyForm[careType].country);

  const handleSelectAddressOption = (suggestion) => {
    const street = suggestion.address + ' ' + suggestion.text;
    address.setValue(street);
    address.setResults([]);
    const addressValues = suggestion.context;
    const city = addressValues.find((contextItem) => contextItem.id.includes('place.'));
    const state = addressValues.find((contextItem) => contextItem.id.includes('region.'));
    const postal = addressValues.find((contextItem) =>
      contextItem.id.includes('postcode.')
    );
    const country = addressValues.find((contextItem) =>
      contextItem.id.includes('country.')
    );

    //handle address changes
    let changeQueue = [
      {
        target: {
          name: 'street',
          value: street,
        },
      },
      {
        target: {
          name: 'city',
          value: city.text,
        },
      },
      {
        target: {
          name: 'postal',
          value: postal.text,
        },
      },
      {
        target: {
          name: 'country',
          value: country.text,
        },
      },
      {
        target: {
          name: 'state',
          value: state.text,
        },
      },
    ];
    changeQueue.forEach((obj) => {
      handleChange(obj, careType);
    });
  };

  const handleSelectCountry = (event) => {
    let countryShortName = Countries.getters.getShortCountryName(event.target.value);
    propertyForm[careType].state = '';

    let statesArr = getStatesByShort(countryShortName);
    if (countryShortName === 'US') {
      statesArr.splice(0, 3);
    }
    setStateOptions(statesArr);
    handleChange(event, careType);
  };

  // allow admin to force add a property/community even there is an existing property/community in nearby area
  const handleForceAdding = () => {
    handleSkipValidateNew();
    setExistingCommunity(false);
  };

  useEffect(() => {
    let countriesArr = getCountries();
    countriesArr.forEach((country, index) => {
      if (country.shortName === 'US') {
        countriesArr.splice(index, 1);
        countriesArr.unshift(country);
      }
    });
    setCountryOptions(countriesArr);
  }, []);

  useEffect(() => {
    let countryShortName = Countries.getters.getShortCountryName(
      propertyForm[careType]?.country
    );
    let statesArr = getStatesByShort(countryShortName);
    // remove the first 3 states that are weird in our list (e.g. AA or something - possibly territories?)
    if (countryShortName === 'US') {
      statesArr.splice(0, 3);
    }
    setStateOptions(statesArr || []);
  }, [propertyForm]);

  return isOpen && !existingCommunity ? (
    <KthPopup
      isOpen
      title={propertyForm.name + ' ' + CARE_TYPE_DESCRIPTIONS[careType] + ' Community'}
      onClose={() => onClose()}
      hideActions
    >
      <div className={styles.careTypeIconGrid}>
        {propertyForm.care_types.map((careType, index) => (
          <div
            key={index}
            className={`${styles.careTypeIcon} ${
              page === careType ? styles.selected : ''
            }`}
          >
            {CARE_TYPE_ABBREVIATIONS[careType]}
          </div>
        ))}
      </div>
      <Box component='form' noValidate autoComplete='off' className={styles.createForm}>
        <Grid container spacing={1}>
          <p>Name</p>
          <TextField
            name='name'
            label='Community Name'
            defaultValue={propertyForm[careType].name}
            onChange={(e) => handleChange(e, careType)}
            fullWidth
          />

          <p>Address:</p>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label='Address'
                {...address}
                isTyping={address.value !== ''}
                autoComplete='off'
                defaultValue={propertyForm[careType].street}
                fullWidth
              />
              {address.results?.length > 0 && (
                <div className={styles.autoCompleteResults}>
                  {address.results.map((result, index) => {
                    return (
                      <div
                        className={styles.suggestedAddress}
                        key={index}
                        onClick={() => {
                          handleSelectAddressOption(result);
                        }}
                      >
                        {result.place_name}
                      </div>
                    );
                  })}
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='street_more'
                label='Address Line 2'
                onChange={(e) => handleChange(e, careType)}
                defaultValue={propertyForm[careType].street_more}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='city'
                label='City'
                onChange={(e) => handleChange(e, careType)}
                defaultValue={propertyForm[careType].city}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={7}>
                  <TextField
                    select
                    name='state'
                    label='State'
                    onChange={(e) => handleChange(e, careType)}
                    value={propertyForm[careType].state}
                    fullWidth
                  >
                    {stateOptions.map((option) => {
                      return (
                        <MenuItem key={option.toString()} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    name='postal'
                    label='Postal Code'
                    onChange={(e) => handleChange(e, careType)}
                    defaultValue={propertyForm[careType].postal}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                name='country'
                label='Country'
                onChange={handleSelectCountry}
                defaultValue={propertyForm[careType].country}
                fullWidth
              >
                {countryOptions.map((country) => {
                  return (
                    <MenuItem key={country.native} value={country.name}>
                      {country.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <Button.LIGHT type='button' onClick={() => onPageChange(page, true)}>
              Back
            </Button.LIGHT>
          </Grid>
          <Grid item xs={9}>
            <Button.DARK
              type='button'
              onClick={() => handleContinueClick(page, propertyForm)}
              loading={isLoading}
              disabled={disableContinueClick}
              icon
            >
              {page === propertyForm.care_types[propertyForm.care_types.length - 1]
                ? 'Save'
                : 'Continue'}
            </Button.DARK>
          </Grid>
        </Grid>
      </Box>
    </KthPopup>
  ) : isOpen && existingCommunity && !skipValidateNew ? (
    <KthPopup
      isOpen
      title='Existing Community Found!'
      onClose={() => onClose()}
      hideActions
    >
      <div className={styles.createForm}>
        <p>
          We cannot create this new property because it looks like you’re trying to create
          a community that already exists on Kithward. We found the below community that
          matches the data you provided.
        </p>
        <div className={styles.communityNameContainer}>
          <KWImage
            src={existingCommunity.cached_image_url}
            width={85}
            height={85}
            alt={'Existing Community'}
          />
          <div className={styles.communityNameInfo}>
            <b>{existingCommunity.display_name}</b>
            <p>{existingCommunity.address}</p>
          </div>
        </div>
        <p>
          You may request editing privileges for this community by claiming it below. If
          you believe you receieved this message in error please{' '}
          <a
            href={`mailto:hello@kithward.com?subject=Property Creation Error&body=Current URL: ${window.location}`}
            rel='noreferrer'
          >
            contact support.
          </a>
        </p>
        <Link
          to={`/properties/search?name=${existingCommunity.name || ''}&location=${
            existingCommunity.address || ''
          }`}
        >
          <Button.DARK type='button'>Claim this Community</Button.DARK>
        </Link>
        {userRole && userRole === 'admin' ? (
          <Button.LIGHT type='button' onClick={handleForceAdding}>
            Create property anyway
          </Button.LIGHT>
        ) : null}
      </div>
    </KthPopup>
  ) : null;
};
