import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SignUpFormAdapter from '../../adapters/SignupFormAdapter';
import Button from '../../components/Button/Button';
import { UserForm } from '../../components/UserForm/UserForm';
import UserList from '../../components/UserList/UserList';
import UsersSearch from '../../components/UserList/UsersSearch';
import { getUsers, queryUsers } from '../../redux/reducers/users';
import { useLocation } from 'react-router-dom';
import { AdminLoginAsUserPopup } from '../../components/AdminLoginAsUserPopup/AdminLoginAsUserPopup';
import { selectIsSuperAdmin } from '../../redux/selectors/auth';
import styles from './UsersListPage.module.scss';
import { useQueryParams } from '../../hooks/useQueryParams';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';

const INITIAL_QUERY = {
  q: '',
  account_id: '',
  organization_id: '',
};

const UserListPage = ({ userGroup, title }) => {
  const urlPage = useQueryParams().get('page') || '';
  const [showUserForm, setShowUserForm] = useState(false);
  const [userToEdit, setUserToEdit] = useState(undefined);
  const [showAdminLoginPopup, setShowAdminLoginPopup] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { isUsersLoading, users } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const [user, setUser] = useState(INITIAL_QUERY);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const urlQueryOrganizationId = useQueryParams().get('organization_id');
  const [query, setQuery] = useState({
    q: '',
    account_id: '',
    organization_id: urlQueryOrganizationId || '',
  });

  useEffect(() => {
    if (userGroup === 'all') {
      return dispatch(getUsers(urlPage));
    } else if (
      !!query.q ||
      !!query.account_id ||
      !!query.organization_id ||
      userGroup === 'user' ||
      userGroup === 'operator'
    ) {
      return dispatch(
        queryUsers(
          {
            ...query,
            group: userGroup,
          },
          urlPage
        )
      );
    }
  }, [userGroup, urlPage]);

  const handleShowUserForm = (user, toEdit) => {
    setUserToEdit(user ? SignUpFormAdapter.fromApi(user) : null);
    setShowUserForm(toEdit);
  };

  const handleUserFormClose = async (refreshList, userId) => {
    if (refreshList && userId) {
      await dispatch(getUsers()).then((response) => {
        let edittedUser = response.results.find((u) => u.id === userId);
        handleShowUserForm(edittedUser, 'editUserCommunities');
      });
    } else if (refreshList) {
      dispatch(
        userGroup === 'all'
          ? getUsers()
          : queryUsers({ ...query, group: userGroup }, urlPage)
      );
      setUserToEdit(undefined);
      setShowUserForm(false);
    } else {
      setUserToEdit(undefined);
      setShowUserForm(false);
    }
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    const searchUsers = await dispatch(queryUsers({ ...query, group: userGroup }));
    if (searchUsers.error) {
      setError(true);
      setErrorMessage(searchUsers.error);
      return;
    }
  };

  const handleAdminLoginPopupClose = () => {
    setShowAdminLoginPopup(false);
  };

  const handleShowAdminLoginPopup = (user) => {
    setSelectedAccount(user);
    setShowAdminLoginPopup(true);
  };

  const handleUserChange = useCallback(
    (newQuery) => {
      setQuery(newQuery);
      setUser(newQuery);
    },
    [setUser]
  );

  return (
    <div>
      <HeaderTitle title={title} />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>{title}</h1>
          </div>
          <div className='action-container-properties'>
            <Button.BLACK_OUTLINED onClick={() => handleShowUserForm(null, 'editUser')}>
              Create new
            </Button.BLACK_OUTLINED>
          </div>
        </div>
        {isSuperAdmin && (
          <UsersSearch
            urlQuery={query}
            isSuperAdmin={isSuperAdmin}
            onSearch={handleSearchSubmit}
            onUserChange={handleUserChange}
            userTypeTitle={title}
          />
        )}
        {error ? (
          <div className={styles.emptyList}>
            <b>{errorMessage}</b>
            <p>Please try again later.</p>
          </div>
        ) : !isUsersLoading && users.length === 0 ? (
          <div className={styles.emptyList}>
            <b>No Users Found</b>
            <p>
              Please try another search. To invite a new person to Kithward, click on
              "CREATE NEW" above.
            </p>
          </div>
        ) : (
          <UserList
            onItemClick={handleShowUserForm}
            onAdminLoginClick={handleShowAdminLoginPopup}
          />
        )}
        {showUserForm && showUserForm === 'editUser' ? (
          <UserForm
            isLoading={isUsersLoading}
            editUser={true}
            editCommunities={false}
            user={userToEdit}
            onClose={handleUserFormClose}
          />
        ) : showUserForm && showUserForm === 'editUserCommunities' ? (
          <UserForm
            isLoading={isUsersLoading}
            editUser={false}
            editCommunities={true}
            user={userToEdit}
            onClose={handleUserFormClose}
          />
        ) : null}
        <AdminLoginAsUserPopup
          isOpen={showAdminLoginPopup}
          account={selectedAccount}
          onClose={handleAdminLoginPopupClose}
        />
      </div>
    </div>
  );
};

export default UserListPage;
