import api from '../../services/api';
import styles from './GeoSelector.module.scss';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useQueryParams } from '../../hooks/useQueryParams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? 'white' : 'black',
    backgroundColor: state.isFocused ? '#ac947a' : null,
  }),

  singleValue: (provided) => ({
    ...provided,
    color: '#222',
    fontWeight: 500,
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  loadingIndicator: (provided) => ({
    display: 'none',
  }),
  container: (provided) => ({
    ...provided,
    border: 'none',
    outline: 'none',
    width: '250px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#777',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.5,
    letterSpacing: 'normal',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};

const GeoSelector = ({ locationQuery, setQuery }) => {
  const [inputValue, setInputValue] = useState(locationQuery);
  const [focus, setFocus] = useState(false);
  const urlQueryGeoPlace = useQueryParams().get('geo') || '';

  const loadOptions = async (value, callback) => {
    if (value !== null && value !== '') {
      let params = {
        q: value,
      };
      const { data } = await api.get(`/v1/geo_places`, {
        params,
      });
      let places = data.map((place) => {
        return {
          id: place.id,
          value: place.id,
          label: place.full_name,
        };
      });
      return places;
    } else if (urlQueryGeoPlace) {
      const fetchData = async () => {
        const { data } = await api.get(`/v1/geo_places/${urlQueryGeoPlace}`);
        setInputValue({
          id: data.id,
          value: data.id,
          label: data.full_name,
        });
      };
      return fetchData();
    }
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    setQuery((prevQuery) => ({
      ...prevQuery,
      geo: newValue ? newValue.value : '',
    }));
  };

  const handleOnFocus = (value) => {
    setFocus(true);
  };

  const handleOnBlur = () => {
    setFocus(false);
  };

  const CaretDownIcon = () => {
    return <FontAwesomeIcon icon={faCaretDown} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={faCaretDown} color='#959595' />
      </components.DropdownIndicator>
    );
  };
  return (
    <div className={styles.containerSelect}>
      <AsyncSelect
        styles={customStyles}
        defaultOptions
        isClearable
        indicatorsContainer
        components={{ DropdownIndicator }}
        placeholder={'Location'}
        loadOptions={loadOptions}
        arrowRenderer={CaretDownIcon}
        multi={false}
        onChange={handleInputChange}
        value={focus ? '' : inputValue}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        blurInputOnSelect={true}
        cacheOptions={false}
        noOptionsMessage={() => null}
      />
    </div>
  );
};

export default GeoSelector;
