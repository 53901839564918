import styles from './CommunityForm.module.scss';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/pro-thin-svg-icons';
import Button from '../Button/Button';

interface ConflictPromptProps {
  title: string;
  message: string;
  onClose: () => void;
  onForceSave: () => void;
  onDiscard: () => void;
}

const ConflictPrompt: React.FC<ConflictPromptProps> = ({
  title,
  message,
  onClose,
  onForceSave,
  onDiscard,
}) => {
  return (
    <div className={styles.conflictPrompt}>
      <div className={styles.header}>
        <span>
          <h3>{title}</h3>
        </span>
        <span className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faXmarkCircle} />
        </span>
      </div>
      <div className={styles.message}>{message}</div>
      <div className={styles.buttons}>
        <Button.LIGHT onClick={onDiscard}>Discard My Changes</Button.LIGHT>
        <Button.BLACK onClick={onForceSave}>Save Anyway</Button.BLACK>
      </div>
    </div>
  );
};

export default ConflictPrompt;
