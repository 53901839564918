import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useQueryProperties } from '../../hooks/useQueryProperties';
import Button from '../../components/Button/Button';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import PropertyList from '../../components/PropertyList/PropertyList';
import Loader from '../../components/Loader/Loader';
import { NewCommunityPopup } from '../../components/NewCommunityPopup/NewCommunityPopup';
import { NewPropertyPopup } from '../../components/NewPropertyPopup/NewPropertyPopup';
import { EditPropertyPopup } from '../../components/EditPropertyPopup/EditPropertyPopup';
import { StatsBar } from '../../components/StatsBar/StatsBar';
import { Link } from 'react-router-dom';
import { Constants } from 'web-core';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';
import PropertyNameCell from '../../components/PropertyResultsList/PropertyNameCell';
import { CARE_TYPE_ABBREVIATIONS } from '../../constants/CareType';
import PropertyDropdownMenu from '../../components/PropertyList/PropertyDropdownMenu';
import EditCommunityRatingPopup from '../../components/EditCommunityRatingPopup/EditCommunityRatingPopup';
import ReviewInterestTags from '../../components/CommunityEditsList/ReviewInterestTags/ReviewInterestTags';
import Popup from 'reactjs-popup';
import { Chip } from '@mui/material';

function PropertiesDashboard() {
  const location = useLocation();
  const urlQueryName = useQueryParams().get('name') || '';
  const urlQueryGeoPlace = useQueryParams().get('geo') || '';
  const urlQueryDistance = useQueryParams().get('within') || '';
  const urlQueryOrganizationId = useQueryParams().get('organization_id') || '';
  const urlPage = useQueryParams().get('page') || '';
  const history = useHistory();
  const { queryResult, queryResultMeta, queryLoading, queryProperties } =
    useQueryProperties();
  const [propertyQueryResults, setPropertyQueryResults] = useState({
    queryResult,
    queryResultMeta,
    queryLoading,
    queryProperties,
  });
  const [isNewPropertyPopupVisible, setIsNewPropertyPopupVisible] = useState(false);
  const [isNewCommunityPopupVisible, setIsNewCommunityPopupVisible] = useState(false);
  const [isEditPropertyPopupVisible, setIsEditPropertyPopupVisible] = useState(false);
  const [editedCareTypeRating, setEditedCareTypeRating] = useState(false);
  const [editCareTypeRatingPopupVisible, setEditCareTypeRatingPopupVisible] =
    useState(false);
  const [reviewInterestTagsPopupVisible, setReviewInterestTagsPopupVisible] =
    useState(false);
  const account = useSelector((state) => state.auth.user);
  const [query, setQuery] = useState({
    name: urlQueryName ?? '',
    geo: urlQueryGeoPlace ?? '',
    within: urlQueryDistance ?? '',
    organization_id: urlQueryOrganizationId ?? '',
  });
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setQuery({
      name: urlQueryName ?? '',
      geo: urlQueryGeoPlace ?? '',
      organization_id: urlQueryOrganizationId ?? '',
    });
  }, []);

  // NOTE: this handles initial page load
  useEffect(() => {
    setPropertyQueryResults({
      queryResult,
      queryResultMeta,
      queryLoading,
      queryProperties,
    });
  }, [queryProperties]);

  useEffect(() => {
    setPropertyQueryResults({
      queryResult,
      queryResultMeta,
      queryLoading,
      queryProperties,
    });
  }, [queryResult]);

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Property',
        accessor: 'name',
        minWidth: 350,
        maxWidth: 350,
        Cell: ({ row }) => <PropertyNameCell row={row} />,
      },
      {
        Header: 'Care Types',
        minWidth: 50,
        maxWidth: 80,
        accessor: 'care_type',
        Cell: ({ row }) => (
          <p>
            {row.original?.communities
              ?.map((c) => CARE_TYPE_ABBREVIATIONS[c.care_type])
              .join(', ')}
          </p>
        ),
      },
      {
        Header: 'Organization',
        accessor: 'organization.name',
        minWidth: 75,
        maxWidth: 100,
        Cell: ({ value }) => <p>{value ? value : '-'}</p>,
      },
      {
        Header: 'Premium',
        minWidth: 75,
        maxWidth: 75,
        Cell: ({ row }) =>
          row.original.premium ? (
            <div>
              <Chip
                label={'Premium'}
                variant={'outlined'}
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  fontWeight: 'bold',
                  alignSelf: 'center',
                }}
              />
            </div>
          ) : (
            <p>{'-'}</p>
          ),
      },
      {
        Header: 'Menu',
        minWidth: 50,
        maxWidth: 50,
        Cell: ({ row }) => (
          <PropertyDropdownMenu
            row={row}
            role={user?.role}
            onAddCommunity={showNewCommunityPopup}
            handleManageCareTypeRatings={(community) =>
              setEditCareTypeRatingPopupVisible(community)
            }
            handleReviewInterestTags={(propertyId) =>
              setReviewInterestTagsPopupVisible(propertyId)
            }
          />
        ),
      },
    ];
    return cols;
  }, [queryResult]);

  const adminColumns = useMemo(() => {
    const cols = [
      {
        Header: 'Property',
        accessor: 'name',
        minWidth: 500,
        maxWidth: 500,
        Cell: ({ row }) => <PropertyNameCell row={row} />,
      },
      {
        Header: 'Care Types',
        accessor: 'care_type',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ row }) => (
          <p>
            {row.original?.communities
              ?.map((c) => CARE_TYPE_ABBREVIATIONS[c.care_type])
              .join(', ')}
          </p>
        ),
      },
      // {
      //   Header: 'Leads',
      //   minWidth: 100,
      //   maxWidth: 125,
      //   Cell: ({ row }) => <p>1 Lead this month</p>
      // },
      {
        Header: 'Profile Completion',
        accessor: 'profile_completion',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ row }) => (
          <p>
            {row.original?.profile_completion
              ? `${row.original.profile_completion?.pct_complete}%`
              : '-'}
          </p>
        ),
      },
      {
        Header: 'Organization',
        accessor: 'organization.name',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ value }) => <p>{value ? value : '-'}</p>,
      },
      {
        Header: 'Premium',
        minWidth: 75,
        maxWidth: 75,
        Cell: ({ row }) =>
          row.original.premium ? (
            <div>
              <Chip
                label={'Premium'}
                variant={'outlined'}
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  fontWeight: 'bold',
                  alignSelf: 'center',
                }}
              />
            </div>
          ) : (
            <p>{'-'}</p>
          ),
      },
      {
        Header: 'Menu',
        minWidth: 50,
        maxWidth: 50,
        Cell: ({ row }) => (
          <PropertyDropdownMenu
            row={row}
            role={user?.role}
            onAddCommunity={showNewCommunityPopup}
            handleManageCareTypeRatings={(community) =>
              setEditCareTypeRatingPopupVisible(community)
            }
            handleReviewInterestTags={(propertyId) =>
              setReviewInterestTagsPopupVisible(propertyId)
            }
          />
        ),
      },
    ];
    return cols;
  }, [queryResult]);

  useEffect(() => {
    queryProperties(query, urlPage);
  }, [urlPage, editedCareTypeRating]);

  const showNewCommunityPopup = (property) => {
    setIsNewCommunityPopupVisible(property);
  };

  const handleNewCommunityPopupClose = () => {
    setIsNewCommunityPopupVisible(false);
  };

  const showNewPropertyPopup = () => {
    setIsNewPropertyPopupVisible(true);
  };

  const handleNewPropertyPopupClose = () => {
    queryProperties(query);
    setIsNewPropertyPopupVisible(false);
  };

  const showEditPropertyPopup = (property) => {
    setIsEditPropertyPopupVisible(property);
  };

  const handleEditPropertyPopupClose = () => {
    queryProperties(query);
    setIsEditPropertyPopupVisible(false);
  };

  const handleQueryChange = useCallback(
    (newQuery) => {
      setQuery(newQuery);
    },
    [setQuery]
  );

  const handleQuerySearch = (queryParams) => {
    history.push({
      pathname: location.pathname,
      search: `${queryParams.name ? `name=${queryParams.name}` : ''}${
        queryParams.geo ? `&geo=${queryParams.geo}` : ''
      }${queryParams.within ? `&within=${queryParams.within}` : ''}${
        queryParams.organization_id
          ? `&organization_id=${queryParams.organization_id}`
          : ''
      }`,
    });
    setQuery({ ...query, geo: queryParams.geo ? queryParams.geo : '' });
    queryProperties(queryParams, urlPage);
  };

  return (
    <>
      <HeaderTitle title={'Properties'} />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Properties</h1>
          </div>
          <div className='action-container-properties'>
            <Button.BLACK_OUTLINED onClick={showNewPropertyPopup}>
              Create new property
            </Button.BLACK_OUTLINED>
            <Link to='/properties/search'>
              <Button.BLACK_OUTLINED>Claim Existing Property</Button.BLACK_OUTLINED>
            </Link>
            <Link to='/import'>
              <Button.BLACK_OUTLINED>Import Unit Data</Button.BLACK_OUTLINED>
            </Link>
          </div>
        </div>
        <StatsBar />
        <div className='communities-dashboard'>
          <SearchBar
            urlQuery={query}
            hideTitle
            onQueryChange={handleQueryChange}
            onSubmit={handleQuerySearch}
          />
          <div className='communities-container'>
            <div className='admin-properties'>
              <Loader isLoading={queryLoading}>
                <PropertyList
                  title='Your properties'
                  properties={propertyQueryResults.queryResult}
                  meta={propertyQueryResults.queryResultMeta}
                  columns={
                    account?.role === Constants.AccountRoles.Admin
                      ? adminColumns
                      : columns
                  }
                />
              </Loader>
            </div>
          </div>
        </div>
        {isNewCommunityPopupVisible ? (
          <NewCommunityPopup
            property={isNewCommunityPopupVisible}
            onClose={handleNewCommunityPopupClose}
          />
        ) : null}
        {isNewPropertyPopupVisible ? (
          <NewPropertyPopup onClose={handleNewPropertyPopupClose} />
        ) : null}
        {isEditPropertyPopupVisible ? (
          <EditPropertyPopup
            isEditing={true}
            property={isEditPropertyPopupVisible}
            onClose={handleEditPropertyPopupClose}
          />
        ) : null}
        {editCareTypeRatingPopupVisible ? (
          <EditCommunityRatingPopup
            onClose={() => setEditCareTypeRatingPopupVisible(null)}
            selectedCommunity={editCareTypeRatingPopupVisible}
            onEditCareTypeRating={() => setEditedCareTypeRating(!editedCareTypeRating)}
          />
        ) : null}
        {reviewInterestTagsPopupVisible ? (
          <Popup
            open={reviewInterestTagsPopupVisible}
            modal
            nested
            closeOnDocumentClick={false}
            closeOnEscape={false}
            lockScroll={true}
            onClose={() => setReviewInterestTagsPopupVisible(null)}
          >
            <ReviewInterestTags
              slug={reviewInterestTagsPopupVisible.slug}
              communityName={reviewInterestTagsPopupVisible.name}
              onFinish={() => setReviewInterestTagsPopupVisible(null)}
              propertyId={reviewInterestTagsPopupVisible.id}
            />
          </Popup>
        ) : null}
      </div>
    </>
  );
}
export default PropertiesDashboard;
