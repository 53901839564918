import SignUpFormAdapter from '../adapters/SignupFormAdapter';
import api from './api';

const PATH = `/v1/admin/accounts`;

export const saveUser = async (form) => {
  try {
    if (!form.id) {
      throw Error('User ID is undefined');
    }
    const { data } = await api.put(`${PATH}/${form.id}`, SignUpFormAdapter.toApi(form));
    return data;
  } catch (err) {
    throw err;
  }
};

export const createUser = async (form) => {
  try {
    if (!form) return;
    const { data } = await api.post(`${PATH}`, SignUpFormAdapter.toApi(form));
    return data;
  } catch (err) {
    throw err;
  }
};

export const deleteUser = async (userId) => {
  try {
    if (!userId) {
      throw Error('User ID is undefined');
    }
    const { data } = await api.delete(`${PATH}/${userId}`);
    return data;
  } catch (err) {
    throw err;
  }
};
