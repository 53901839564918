import { useParams } from 'react-router-dom';
import { idFromSlug } from '../../../utils/urlTools';
import { useProperty } from '../../../hooks/useProperty';
import SpinnerLoader from '../../../components/Spinner/SpinnerLoader';
import HeaderTitle from '../../../components/HeaderTitle/HeaderTitle';
import PropertyDetails from '../../../components/PropertyDetails/PropertyDetails';
import { StringUtils } from 'web-core';

const PropertyPage = () => {
  const { property_slug } = useParams<{ property_slug?: string }>();
  const propertyId = idFromSlug(property_slug);
  const { data: property, error, loading } = useProperty(propertyId);
  return !property || error || loading ? (
    <SpinnerLoader />
  ) : (
    <>
      <HeaderTitle
        title={`${
          StringUtils.isBlank(property?.name) ? '' : `${property.name} - `
        }Property`}
      />
      <div className={'page-layout'}>
        <PropertyDetails property={property} />
      </div>
    </>
  );
};

export default PropertyPage;
