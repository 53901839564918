import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useFlexLayout, useResizeColumns, useSortBy, useTable } from 'react-table';
import { Constants } from 'web-core';
import { EmptyBox } from '../../../EmptyBox/EmptyBox';
import styles from '../../TabContent/TabContent.module.scss';

const BuildingsTable = ({ buildings, onRowClick }) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 150, // minWidth is only used as a limit for resizing
      width: '100%',
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: () => <div className='pl-50'>Building</div>,
        accessor: Constants.DynamicSchemaFields.BuildingName,
        minWidth: 200,
        width: 900,
        Cell: ({ value }) => <div className='pl-50'>{value}</div>,
      },
      {
        Header: 'Floors',
        className: 'text-center',
        accessor: Constants.DynamicSchemaFields.BuildingFloors,
        Cell: ({ value }) => (
          <div className='text-center'>{value ? `${value} Floors` : '-'}</div>
        ),
      },
      {
        Header: () => <div className='text-center'>Date created</div>,
        className: 'text-center',
        accessor: 'dateCreated',
        Cell: ({ value }) => (
          <div className='text-center'>
            {value ? format(new Date(value), 'MMM. d yyyy') : '-'}
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    rows, // rows for the table based on the data passed
    // page, // Instead of using 'rows', we'll use page,
  } = useTable(
    {
      columns,
      data: buildings,
      defaultColumn,
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy
  );

  return (
    <>
      <table {...getTableProps()} className={styles.reactTable}>
        <thead>
          <tr>
            {headerGroups[0].headers.map((column) => (
              <th
                key={column.Header.toString()}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={column.className || ''}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted ? (
                    <FontAwesomeIcon
                      icon={column.isSortedDesc ? faChevronUp : faChevronDown}
                    ></FontAwesomeIcon>
                  ) : null}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <BuildingRow
                key={row.id}
                row={row}
                onClick={() => {
                  onRowClick(i);
                }}
                {...row.getRowProps()}
              />
            );
          })}
        </tbody>
      </table>
      {!rows || rows.length === 0 ? (
        <EmptyBox
          message={`This community currently has no Buildings. Please click on the '+' button to add one.`}
        />
      ) : null}
    </>
  );
};

const BuildingRow = ({ row, onClick }) => {
  return (
    <>
      <tr className={styles.rowItem} onClick={onClick}>
        {row.cells.map((cell) => (
          <td key={cell.column.id + cell.row.id} {...cell.getCellProps()}>
            {cell.render('Cell')}
          </td>
        ))}
      </tr>
    </>
  );
};

export default BuildingsTable;
