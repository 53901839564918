import { createSelector } from 'reselect';
import { Constants } from 'web-core';

export const selectModelEditingImages = (state) => {
  const modelIndex = state.communityForm.modelIndexEditing;
  if (!Number.isInteger(modelIndex)) return [];

  const model = state.communityForm.modelForm;
  const modelImagesIds = model[Constants.DynamicSchemaFields.ModelImage];
  const modelImages = Array.isArray(modelImagesIds)
    ? modelImagesIds.map((id) =>
        state.communityForm.model_type_images.find((modelImage) => modelImage.id === id)
      )
    : [
        state.communityForm.model_type_images.find(
          (modelImage) => modelImage.id === modelImagesIds
        ),
      ];
  return modelImages;
};

export const selectFormData = (state) => state.communityForm.formData;

export const selectModels = (state) => state.communityForm.formData.models;
export const selectUnits = (state) => state.communityForm.formData.units;

export const selectModelForm = (state) => state.communityForm.modelForm;
export const selectBuildingsForm = (state) => state.communityForm.buildingsForm;
export const selectUnitForm = (state) => state.communityForm.unitForm;

export const selectUnitFormBuildingId = createSelector(
  selectUnitForm,
  (state) => state[Constants.DynamicSchemaFields.UnitBuildingId]
);

export const selectModelsView = createSelector(selectModels, (models) =>
  models
    ? models
        .map((model) => model[Constants.DynamicSchemaFields.ModelView])
        .filter((v) => v)
        .flat()
    : []
);
export const selectUnitsView = createSelector(selectUnits, (units) =>
  units
    ? units
        .map((unit) => unit[Constants.DynamicSchemaFields.UnitView])
        .filter((v) => v)
        .flat()
    : []
);

export const selectCurrentEditingModelIndex = (state) =>
  state.communityForm.modelIndexEditing;

export const selectAssignableProperties = (state) =>
  state.communityForm.assignableProperties;

export const selectCopyableProperties = (state) => state.communityForm.copyableSiblings;
