import {
  FormControlLabel,
  TextField,
  Box,
  Checkbox,
  Grid,
  MenuItem,
} from '@mui/material';
import KthPopup from '../KthPopup/KthPopup';
import Button from '../Button/Button';
import styles from './NewPropertyPopup.module.scss';
import { useEffect, useState } from 'react';
import { getCountries, getStatesByShort } from 'countrycitystatejson';
import { StringUtils, Countries } from 'web-core';
import useAddressAutocomplete from '../../hooks/useAddressAutocomplete';

export const PropertyDetailForm = ({
  propertyForm,
  setPropertyForm,
  onPageChange,
  page,
  handleChange,
  handleCareTypeSelect,
  isOpen,
  onClose,
  isEditing,
  onSaveClick,
}) => {
  const handleContinueClick = async (page, propertyForm) => {
    propertyForm.care_types.forEach((serviceLevel) =>
      setPropertyForm((currentForm) => {
        return {
          ...currentForm,
          [serviceLevel]: {
            name: propertyForm.name,
            care_type: serviceLevel,
            street: propertyForm.street,
            street_more: propertyForm.street_more,
            city: propertyForm.city,
            state: propertyForm.state,
            postal: propertyForm.postal,
            country: propertyForm.country,
          },
        };
      })
    );
    onPageChange(page);
  };
  const [showPopup, setShowPopup] = useState(isOpen);

  const handleSaveClick = (propertyForm) => {
    // setShowPopup(false);
    onSaveClick(propertyForm);
  };

  let propertyAddressHasBlankField =
    StringUtils.isBlank(propertyForm.name) ||
    StringUtils.isBlank(propertyForm.street) ||
    StringUtils.isBlank(propertyForm.city) ||
    StringUtils.isBlank(propertyForm.state) ||
    StringUtils.isBlank(propertyForm.postal);
  const disabledContinue = !isEditing
    ? propertyForm.care_types.length === 0 || propertyAddressHasBlankField // care types are only selected upon property creation
    : propertyAddressHasBlankField;

  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const address = useAddressAutocomplete('');

  const handleSelectCountry = (event) => {
    let countryShortName = Countries.getters.getShortCountryName(event.target.value);
    // reset the state after re-selecting a country
    propertyForm.state = '';
    let statesArr = getStatesByShort(countryShortName);
    if (countryShortName === 'US') {
      statesArr.splice(0, 3);
    }
    setStateOptions(statesArr);
    handleChange(event);
  };

  // make United States always the first option for the dropdown
  useEffect(() => {
    let countriesArr = getCountries();
    countriesArr.forEach((country, index) => {
      if (country.shortName === 'US') {
        countriesArr.splice(index, 1);
        countriesArr.unshift(country);
      }
    });
    setCountryOptions(countriesArr);
  }, []);

  useEffect(() => {
    let countryShortName = Countries.getters.getShortCountryName(propertyForm.country);
    let statesArr = getStatesByShort(countryShortName);
    if (countryShortName === 'US') {
      statesArr.splice(0, 3);
    }
    setStateOptions(statesArr || []);
  }, [propertyForm]);

  useEffect(() => {
    !page && !isEditing && setShowPopup(false);
  }, [page]);

  const handleSelectAddressOption = (suggestion) => {
    const street = `${suggestion.address ? suggestion.address + ' ' : ''}${
      suggestion.text
    }`;
    address.setValue(street);
    address.setResults([]);

    const addressValues = suggestion.context;
    const city = addressValues.find((contextItem) => contextItem.id.includes('place.'));
    const state = addressValues.find((contextItem) => contextItem.id.includes('region.'));
    const postal = addressValues.find((contextItem) =>
      contextItem.id.includes('postcode.')
    );
    const country = addressValues.find((contextItem) =>
      contextItem.id.includes('country.')
    );

    //handle address changes
    let changeQueue = [
      {
        target: {
          name: 'street',
          value: street,
        },
      },
      {
        target: {
          name: 'city',
          value: city.text,
        },
      },
      {
        target: {
          name: 'postal',
          value: postal.text,
        },
      },
      {
        target: {
          name: 'country',
          value: country.text,
        },
      },
      {
        target: {
          name: 'state',
          value: state.text,
        },
      },
    ];
    changeQueue.forEach((obj) => {
      handleChange(obj);
    });
  };

  return (
    <KthPopup
      isOpen={showPopup}
      title={isEditing ? 'Edit Property' : 'Create a New Property'}
      onClose={() => onClose()}
      hideActions
    >
      <Box component='form' noValidate autoComplete='off' className={styles.createForm}>
        <Grid container spacing={1}>
          <p>What is the name of this property?</p>
          <TextField
            name='name'
            label={'Property Name'}
            onChange={handleChange}
            value={propertyForm.name || ''}
            fullWidth
          />

          {!isEditing ? (
            <>
              <p>What types of care communities are part of this property?</p>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={6}>
                  <FormControlLabel
                    value='I'
                    control={<Checkbox checked={propertyForm.care_types.includes('I')} />}
                    label='Independent Living'
                    onChange={handleCareTypeSelect}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value='A'
                    control={<Checkbox checked={propertyForm.care_types.includes('A')} />}
                    label='Assisted Living'
                    onChange={handleCareTypeSelect}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value='M'
                    control={<Checkbox checked={propertyForm.care_types.includes('M')} />}
                    label='Memory Care'
                    onChange={handleCareTypeSelect}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value='N'
                    control={<Checkbox checked={propertyForm.care_types.includes('N')} />}
                    label='Skilled Nursing'
                    onChange={handleCareTypeSelect}
                  />
                </Grid>
              </Grid>
            </>
          ) : null}

          { isEditing &&
            <>
              <Grid container rowSpacing={1} columnSpacing={1} alignItems={'stretch'}>
                <Grid item xs={12}>
                  <TextField
                    style={{ marginTop: '8px' }}
                    name='website_url'
                    label='Website'
                    onChange={handleChange}
                    value={propertyForm.website_url || ''}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </>
          }

          <p>Address:</p>
          <Grid container rowSpacing={1} columnSpacing={1} alignItems={'stretch'}>
            <Grid item xs={12}>
              <TextField
                label='Address'
                {...address}
                isTyping={address.value !== ''}
                defaultValue={propertyForm.street}
                autoComplete='off'
                fullWidth
              />
              {address.results?.length > 0 && (
                <div className={styles.autoCompleteResults}>
                  {address.results.map((result, index) => {
                    return (
                      <div
                        className={styles.suggestedAddress}
                        key={index}
                        onClick={() => {
                          handleSelectAddressOption(result);
                        }}
                      >
                        {result.place_name}
                      </div>
                    );
                  })}
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='street_more'
                label='Address Line 2'
                onChange={handleChange}
                value={propertyForm.street_more || ''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name='city'
                label='City'
                onChange={handleChange}
                value={propertyForm.city || ''}
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                select
                name='state'
                label='State'
                onChange={handleChange}
                value={propertyForm.state || ''}
                fullWidth
              >
                {stateOptions.map((option) => {
                  return (
                    <MenuItem key={option.toString()} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                name='postal'
                label='Postal Code'
                onChange={handleChange}
                value={propertyForm.postal || ''}
                sx={{ flexGrow: 1 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                name='country'
                label='Country'
                onChange={handleSelectCountry}
                value={propertyForm.country}
                fullWidth
              >
                {countryOptions.map((country) => {
                  return (
                    <MenuItem key={country.native} value={country.name}>
                      {country.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Button.DARK
          type='button'
          onClick={
            !isEditing
              ? () => handleContinueClick(page, propertyForm)
              : () => handleSaveClick(propertyForm)
          }
          disabled={disabledContinue}
        >
          {!isEditing ? 'Continue' : 'Save Property'}
        </Button.DARK>
      </Box>
    </KthPopup>
  );
};
