import { BookedToursList } from '../../components/BookedTours/BookedToursList';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';

export const ConsumerBookedToursPage = () => {
  return (
    <>
      <HeaderTitle title={'Booked Tours'} />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Booked Tours</h1>
          </div>
        </div>
        <BookedToursList />
      </div>
    </>
  );
};
