import AuthRoutes from './auth.routes';
import AppRoutes from './app.routes';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Loader from '../components/Loader/Loader';
import { SnackbarProvider } from 'notistack';
import { ErrorManager } from '../components/ErrorManager/ErrorManager';
import SessionManager from '../utils/SessionManager';
import { Fade } from '@mui/material';
import { useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import AmplitudeUtils from 'web-core/amplitudeUtils';
import { AccountRoles } from 'web-core/constants';

const Routes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const hasRehydrated = useSelector((state) => state.app.active);
  const { user } = useSelector((state) => state.auth);

  // Identify the user to log
  useEffect(() => {
    if (user) {
      if (user.role === AccountRoles.User) {
        setIsLoading(true);
        window.location.assign(`${process.env.REACT_APP_CONSUMER}`);
      } else {
        hotjar.identify(user.id, { userName: user.name, userRole: user.role });

        AmplitudeUtils.setUserPropertiesForAccount(user);
      }

      // window.intercomSettings = {
      //   api_base: 'https://api-iam.intercom.io',
      //   app_id: 'n0zhhd71',
      //   name: user.name, // Full name
      //   email: user.email, // Email address
      //   created_at: user.created_at, // Signup date as a Unix timestamp
      // };
    }
  }, [user]);

  useEffect(() => {
    setIsLoading(!hasRehydrated);
  }, [hasRehydrated]);

  return (
    <Loader isLoading={isLoading}>
      <BrowserRouter
        getUserConfirmation={(payload, callback) => {
          const { nextLocation, currentPathAndSearch, action, message } =
            JSON.parse(payload);

          if (
            currentPathAndSearch.current !==
            nextLocation.pathname + nextLocation.search
          ) {
            const confirmed = window.confirm(message);
            callback(confirmed);

            if (!confirmed && action === 'POP') {
              window.history.forward();
            }
          }
        }}
      >
        <SnackbarProvider
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          TransitionComponent={Fade}
          preventDuplicate
          maxSnack={3}
          style={{ backgroundColor: '#e4cdb5', color: '#000' }}
        >
          <SessionManager>
            {user ? <AppRoutes /> : <AuthRoutes />}
            <ErrorManager />
          </SessionManager>
        </SnackbarProvider>
      </BrowserRouter>
    </Loader>
  );
};

export default Routes;
