import styles from '../../pages/premium/Subscriptions.module.scss';
import Button from '../Button/Button';
import api from '../../services/api';
import { APITools } from 'web-core';
import { useSnackbar } from 'notistack';
import { FeatureGating } from '../FeatureGating/FeatureGating';
import { Link } from 'react-router-dom';

const CANCELED = 'canceled';

const SubscriptionItem = ({ propertyRow, handlePricingPopupOpen, setLoading }) => {
  const { enqueueSnackbar } = useSnackbar();

  const fetchPortal = async (propertyId) => {
    try {
      setLoading(true);
      const url = `/v1/admin/properties/${propertyId}/stripe_portal`;
      const response = await api.get(url);
      if (response) {
        window.location.replace(response.data.url);
      }
    } catch (err) {
      setLoading(false);
      let errorsToDisplay = APITools.errorMessageFromServerResponse(err);
      enqueueSnackbar(`${errorsToDisplay}`, { variant: 'error' });
    }
  };
  const handleOpenPortal = async (propertyId) => {
    await fetchPortal(propertyId);
  };

  const subscription = propertyRow.original.stripe_subscription;

  if (subscription && subscription.status !== CANCELED) {
    return (
      <tr>
        <td className={styles.propertyName}>{propertyRow.original.name}</td>
        <td>
          <mark className={styles.green}>Premium</mark>
          {subscription.cancel_at_period_end ? (
            <mark className={styles.grey}>
              Expires {subscription.current_period_end_formatted}
            </mark>
          ) : null}
        </td>
        <td>
          <Button.BLACK_OUTLINED
            onClick={() => handleOpenPortal(propertyRow.original.id)}
          >
            Manage
          </Button.BLACK_OUTLINED>
        </td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td className={styles.propertyName}>
          <Link to={`/properties/${propertyRow.original.slug}`}>
            {propertyRow.original.name}
          </Link>
        </td>
        <td>
          <mark className={styles.green}>Free</mark>
        </td>
        <td>
          <FeatureGating>
            <Button.BLACK_OUTLINED
              onClick={() => handlePricingPopupOpen(propertyRow.original.id)}
            >
              Upgrade
            </Button.BLACK_OUTLINED>
          </FeatureGating>
        </td>
      </tr>
    );
  }
};

export default SubscriptionItem;
