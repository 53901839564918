import CircularProgress from '@mui/material/CircularProgress';
import styles from './CircularPercent.module.scss';

const CircularPercentComplete = (props) => {
  return (
    <div
      className={
        props.className === 'properties'
          ? styles.propertiesPercentComplete
          : styles.percentComplete
      }
    >
      <CircularProgress variant='determinate' {...props} className={styles.foreground} />
      <CircularProgress variant='determinate' value={100} className={styles.background} />
      <div>
        <p className={styles.numberPercent}>{props.value}%</p>
      </div>
    </div>
  );
};

export default CircularPercentComplete;
