export const selectSignUpErrors = (state) => {
  const errors = state.auth.errors;
  if (typeof errors === 'object' && !Array.isArray(errors)) {
    return Object.entries(state.auth.errors).map(([key, value]) => `${key}: ${value}`);
  } else {
    return errors;
  }
};

export const selectUserOrganization = (state) => state.auth.user.organization;

export const selectIsManager = (state) => state.auth.user.role === 'community_manager';
export const selectIsExecutive = (state) =>
  state.auth.user.role === 'corporate_executive';
export const selectIsSuperAdmin = (state) => state.auth.user.role === 'admin';

export const selectIsExecutiveOrAdmin = (state) =>
  state.auth.user.role === 'admin' || state.auth.user.role === 'corporate_executive';
