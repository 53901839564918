import React, { useState } from 'react';
import styles from './ApproveCommunityEditRequest.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import Button from '../../../Button/Button';
import Loader from '../../../Loader/Loader';
import CommunityDiamondRating from '../../../CommunityDiamondRating/CommunityDiamondRating';

interface ApproveCommunityEditRequestProps {
  title: string;
  onConfirm: (params: {} | null) => void;
  requestId: number;
  isLoading: boolean;
  onClose: () => void;
}

const ApproveCommunityEditRequest: React.FC<ApproveCommunityEditRequestProps> = ({
  title,
  onConfirm,
  requestId,
  isLoading,
  onClose,
}) => {
  const [rating, setRating] = useState<{
    star_rating: number | null;
    accept_generated_star_rating: boolean;
    override_star_rating: boolean;
    reason_to_override: string | null | undefined;
  } | null>(null);

  const handleOnApprove = () => {
    onConfirm(rating);
  };

  const handleOnChange = (params: {
    star_rating: number | null;
    accept_generated_star_rating: boolean;
    override_star_rating: boolean;
    reason_to_override: string | null | undefined;
  }) => {
    setRating(params);
  };

  return (
    <div className={styles.popupContainer}>
      <div className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
        <button className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      </div>

      <p className={styles.confirmPrompt}>
        Do you want to approve these Community Changes?
      </p>

      <CommunityDiamondRating
        onChange={handleOnChange}
        url={
          requestId
            ? `/v1/admin/community_change_requests/${requestId}/generate_star_rating`
            : null
        }
      />

      <div className={styles.bottomButton}>
        <Loader isLoading={isLoading}>
          <Button.BLACK
            disabled={
              !rating?.star_rating ||
              (rating?.override_star_rating && !rating?.reason_to_override)
            }
            onClick={handleOnApprove}
          >
            Approve Changes
          </Button.BLACK>
        </Loader>
      </div>
    </div>
  );
};

export default ApproveCommunityEditRequest;
