import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { uploadedUnitTypeImages } from '../../../../../redux/reducers';
import {
  selectCurrentEditingModelIndex,
  selectFormData,
} from '../../../../../redux/selectors/communityForm';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Button from '../../../../Button/Button';
import { KWImage } from '../../../../KWImage/KWImage';
import { useSnackbar } from 'notistack';
import styles from './ModelImage.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faBars } from '@fortawesome/pro-light-svg-icons';
import { Constants } from 'web-core';
import { toFormData } from '../../../../../services/transform';
import api from '../../../../../services/api';
import Tooltip from '@mui/material/Tooltip';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

// form to upload, drag and drop, & delete images for a single UnitType
export const UnitTypeImagesForm = ({ unitType, onChange }) => {
  const currentEditingModelIndex = useSelector(selectCurrentEditingModelIndex);
  const community = useSelector(selectFormData);
  const modelImages = useSelector((state) => state.communityForm.model_type_images);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [dataImages, setDataImages] = useState([]);
  const uploadInputRef = useRef(null);

  const orderMultipleImages = (arr, arrObj) => {
    let array = [];
    arr?.forEach((elem) => {
      arrObj?.forEach((element) => {
        if (elem === element.id) array.push(element);
      });
    });
    return array;
  };

  useEffect(() => {
    let orderImages = orderMultipleImages(
      unitType[Constants.DynamicSchemaFields.ModelImages],
      modelImages
    );
    setDataImages(orderImages);
  }, [modelImages]);

  if (!Number.isInteger(currentEditingModelIndex)) return null;

  const updateUnitTypeImageIds = (imageIds) => {
    const newImages = {};
    newImages[Constants.DynamicSchemaFields.ModelImages] = imageIds;
    newImages[Constants.DynamicSchemaFields.ModelImage] = imageIds[0];
    const updatedUnitType = Object.assign({}, unitType, newImages);
    onChange(updatedUnitType);
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let multipleImages = Array.from(dataImages);
    let [data] = multipleImages.splice(e.source.index, 1);
    multipleImages.splice(e.destination.index, 0, data);
    setDataImages(multipleImages);

    let imageIds = multipleImages.map((img) => img.id);
    updateUnitTypeImageIds(imageIds);
  };

  const handleDeletePhoto = (photoId) => {
    const sortedImages = dataImages.filter((photo) => photo.id !== photoId);
    setDataImages(sortedImages);
    const imageIds = dataImages
      .filter((photo) => photo.id !== photoId)
      .map((img) => img.id);
    updateUnitTypeImageIds(imageIds);
  };

  const handleImagesSelected = async (event) => {
    const fileSizeLimit = 20 * 1024 * 1024;
    if (event.target.files?.length) {
      const files = Object.values(event.target.files);
      const sizeCheck = files.every((e) => e.size < fileSizeLimit);
      if (sizeCheck) {
        try {
          const responses = await Promise.all(
            files.map(async (image) => {
              const data = toFormData({ image: image });
              return await api.post(
                `/v1/admin/communities/${community.id}/model_type_images`,
                data
              );
            })
          );
          const newImages = responses.map((r) => r.data);
          const newImageIds = newImages.map((i) => i.id);

          const existingImageIds =
            unitType[Constants.DynamicSchemaFields.ModelImages] || [];
          const allImageIds = existingImageIds.concat(newImageIds);

          updateUnitTypeImageIds(allImageIds);

          dispatch(uploadedUnitTypeImages(newImages));
        } catch (err) {
          enqueueSnackbar(
            `Error: Please retry your upload or contact us if the issue persists.`,
            { variant: 'default' }
          );
        }
      } else {
        enqueueSnackbar('Please upload a file / files smaller than 20 MB', {
          variant: 'default',
        });
      }
    }
  };

  const handleInputButtonClick = (event) => {
    event.preventDefault();
    uploadInputRef.current.click();
  };

  return (
    <section className={styles.formSection}>
      <div className={styles.sectionHeader}>
        <span className={styles.sectionTitle}>
          Images
          <span className={styles.questionIcon}>
            <Tooltip
              arrow
              placement='bottom-start'
              title='Adding more than one image will give you the ability to rearrange images / delete any unwanted images.'
            >
              <div>
                <FontAwesomeIcon icon={faCircleQuestion} />
              </div>
            </Tooltip>
          </span>
        </span>
        <span className={styles.sectionTitle}>
          <Button.LIGHT onClick={handleInputButtonClick}>ADD IMAGE(S)</Button.LIGHT>
          <input
            ref={uploadInputRef}
            style={{ display: 'none' }}
            type='file'
            multiple
            name='photoInput'
            accept='image/*, application/pdf'
            onChange={handleImagesSelected}
          />
        </span>
      </div>
      <div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable' direction='horizontal'>
            {(provided) => (
              <div
                ref={provided.innerRef}
                className={styles.containerImages}
                {...provided.droppableProps}
              >
                {dataImages?.map((img, index) => (
                  <Draggable key={img.id} draggableId={String(img.id)} index={index}>
                    {(provided) => (
                      <div
                        className={styles.containerCard}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className={styles.containerIcons}>
                          {img.file_url && (
                            <KWImage
                              src={img.file_url}
                              width={120}
                              height={120}
                              alt={'Unit Type Image'}
                            />
                          )}
                          {dataImages.length > 1 && (
                            <div>
                              <Button.BLACK icon className={styles.imageInputButtonDrag}>
                                <FontAwesomeIcon icon={faBars} />
                              </Button.BLACK>
                              <Button.BLACK
                                icon
                                className={styles.imageInputButtonTrash}
                                onClick={() => handleDeletePhoto(img.id)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button.BLACK>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </section>
  );
};
