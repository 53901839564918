import { Constants } from 'web-core';
import { Fragment } from 'react';

const Difference = ({ diff }) => {
  const filteredKeys = [
    Constants.DynamicSchemaFields.ModelImage,
    'dateCreated',
    'id',
    'sort_order',
    Constants.DynamicSchemaFields.StarRating,
    Constants.DynamicSchemaFields.GeocodedCountry,
    Constants.DynamicSchemaFields.GeocodedRegion,
    Constants.DynamicSchemaFields.GeocodedDistrict,
    Constants.DynamicSchemaFields.GeocodedPlace,
    Constants.DynamicSchemaFields.GeocodedNeighborhood,
    Constants.DynamicSchemaFields.PoiGeocodedCountry,
    Constants.DynamicSchemaFields.PoiGeocodedRegion,
    Constants.DynamicSchemaFields.PoiGeocodedDistrict,
    Constants.DynamicSchemaFields.PoiGeocodedPlace,
    Constants.DynamicSchemaFields.PoiGeocodedNeighborhood,
    Constants.DynamicSchemaFields.PoiGeocodedLatitude,
    Constants.DynamicSchemaFields.PoiGeocodedLongitude,
    Constants.DynamicSchemaFields.LocalEventGeocodedCountry,
    Constants.DynamicSchemaFields.LocalEventGeocodedRegion,
    Constants.DynamicSchemaFields.LocalEventGeocodedDistrict,
    Constants.DynamicSchemaFields.LocalEventGeocodedPlace,
    Constants.DynamicSchemaFields.LocalEventGeocodedNeighborhood,
    Constants.DynamicSchemaFields.LocalEventGeocodedLatitude,
    Constants.DynamicSchemaFields.LocalEventGeocodedLongitude,
    Constants.DynamicSchemaFields.UnitBuildingId,
    Constants.DynamicSchemaFields.UnitModel,
  ];

  if (diff === null) {
    return <Fragment />;
  }
  let renderType =
    typeof diff == 'string' || typeof diff == 'number' || typeof diff == 'boolean'
      ? 'simple'
      : !Array.isArray(diff) && typeof diff == 'object'
      ? 'object'
      : Array.isArray(diff) && typeof diff[0] !== 'object'
      ? 'simpleArray'
      : Array.isArray(diff) && typeof diff[0] === 'object'
      ? 'objArray'
      : null;

  const enumArray =
    renderType === 'object'
      ? Object.entries(diff)
          .filter((key) => !filteredKeys.includes(key[0]))
          .sort()
      : null;

  return (
    <>
      {renderType === 'simple' && (
        <p>{diff === true ? 'True' : diff === false ? 'False' : diff + ''}</p>
      )}

      {renderType === 'simpleArray' &&
        diff.map((arrayItem, index) => <p key={index}>{arrayItem}</p>)}

      {renderType === 'object' &&
        enumArray &&
        enumArray.map((detail, outerIndex) => {
          const splitKey = detail[0].split('|');
          return (
            <div key={outerIndex}>
              <b>{splitKey[splitKey.length - 1]}</b>
              <Difference diff={detail[1]} />
            </div>
          );
        })}

      {renderType === 'objArray' &&
        diff.map((arrayItem, index) => (
          <div key={index}>
            <b># {`${index + 1}`}</b>
            <Difference diff={arrayItem} />
          </div>
        ))}
      <br />
    </>
  );
};

export default Difference;
