import useSWRInfinite from 'swr/infinite';
import api from '../services/api';

const PATH = `/v1/admin/community_claims`;

// A function to get the SWR key of each page,
// its return value will be accepted by `fetcher`.
// If `null` is returned, the request of that page won't start.
const getKey = (pageIndex = 0, previousPageData) => {
  if (previousPageData && previousPageData?.data?.meta?.last_page) return null; // reached the end
  return `${PATH}?page=${pageIndex + 1}`; // SWR key
};

export const useCommunityClaimsInfinite = () => {
  const {
    data: responses,
    error,
    size,
    setSize,
  } = useSWRInfinite(getKey, api, {
    errorRetryCount: 3,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
  const lastResponseData = responses ? responses[responses.length - 1].data : undefined;

  const loadMore = () => {
    if (lastResponseData?.meta?.last_page === false) {
      setSize(size + 1);
    }
  };

  const restartPages = () => {
    setSize(1);
  };

  const data = responses ? [].concat(...responses.map((res) => res.data.results)) : null;

  return {
    loading: !responses && !error,
    data,
    meta: responses && lastResponseData ? lastResponseData.meta : null,
    error,
    loadMore,
    restartPages,
  };
};
