import React, { useMemo, useState } from 'react';
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
} from 'react-table';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import styles from './BookedToursTable.module.scss';
import { Utilities } from 'web-core';

export const BookedToursTable = ({ bookedTours = [] }) => {
  const [hoveredRow, setHoveredRow] = useState(null);

  const data = useMemo(() => {
    return bookedTours.map((tour, index) => {
      const isPastTour = new Date(tour.scheduled_at) < new Date();

      const TimeZoneAbbr =
        new Intl.DateTimeFormat('en-US', {
          timeZoneName: 'short',
          timeZone: tour.community_timezone,
        })
          .formatToParts(new Date())
          .find((part) => part.type === 'timeZoneName')?.value || '';

      return {
        id: tour.id,
        scheduled_at: `${Utilities.formattedDateInTimeZone(
          tour.scheduled_at,
          tour.community_timezone
        )} ${TimeZoneAbbr}`,
        requester_name: tour.account.name,
        requester_email: tour.account.email,
        requester_phone: tour.account.phone,
        community_name: tour.community.name,
        community_city: tour.community.city || '-',
        community_state: tour.community.state || '-',
        status: isPastTour ? 'Completed' : 'Upcoming',
        duration: tour.duration_in_minutes,
        tour,
        index,
      };
    });
  }, [bookedTours]);

  const formatDuration = (minutes) => {
    if (!minutes || minutes <= 0) return 'Unknown duration';
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : ''} ${
      mins > 0 ? `${mins} minute${mins > 1 ? 's' : ''}` : ''
    }`.trim();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Scheduled At',
        accessor: 'scheduled_at',
        Cell: ({ row }) => (
          <div className={styles.scheduleAt}>
            <span>{row.original.scheduled_at}</span>
            <span className={styles.durationCell}>
              With a duration of {formatDuration(row.original.duration)}
            </span>
          </div>
        ),
      },
      { Header: 'Requester', accessor: 'requester_name' },
      { Header: 'Email', accessor: 'requester_email' },
      { Header: 'Phone', accessor: 'requester_phone' },
      {
        Header: 'Community',
        accessor: 'community_name',
        Cell: ({ row }) => (
          <div className={styles.communityCell}>
            <span
              className={styles.communityLink}
              onClick={() => handleClickCommunityProfile(row.original.tour.community)}
            >
              {row.original.community_name}
            </span>
            <span>{`${row.original.community_city}, ${row.original.community_state}`}</span>
          </div>
        ),
      },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = useState(null);
          const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
          const handleMenuClose = () => setAnchorEl(null);

          const tour = row.original.tour;
          const actionButtons = [];

          if (tour.account.phone) {
            actionButtons.push({
              label: 'Call',
              action: () => handlePhone(tour.account.phone),
            });
          }

          if (tour.account.email) {
            actionButtons.push({
              label: 'Email',
              action: () => handleEmail(tour.account.email),
            });
          }

          actionButtons.push({
            label: 'View Search',
            action: () => handleViewSearch(tour),
          });

          return (
            <div className={styles.actionButtonContainer}>
              {/* 3-Dots Icon for Actions */}
              <IconButton
                onClick={handleMenuOpen}
                className={styles.actionIconButton}
                aria-controls='actions-menu'
                aria-haspopup='true'
              >
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </IconButton>

              {/* Dropdown Menu for Actions */}
              <Menu
                id='actions-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                className={styles.menu}
              >
                {actionButtons.map((action, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      action.action();
                      handleMenuClose();
                    }}
                    className={styles.menuItem}
                  >
                    {action.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          );
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    { columns, data, initialState: { pageSize: 10 } },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const handlePhone = (phone) => {
    window.location.href = `tel:${phone}`;
  };

  const handleEmail = (email) => {
    window.location.href = `mailto:${email}?subject=Tour Request`;
  };

  const handleViewSearch = (tour) => {
    window.open(
      `${process.env.REACT_APP_CONSUMER}/my-search/${tour.consumer_journey_id}`,
      '_blank'
    );
  };

  const handleClickCommunityProfile = (community) => {
    if (community) {
      window.open(
        `${process.env.REACT_APP_CONSUMER}/community/${community.slug}`,
        '_blank'
      );
    }
  };

  return (
    <div className={styles.tableContainer}>
      {bookedTours.length === 0 ? (
        <p>No booked tours available.</p>
      ) : (
        <table {...getTableProps()} className={styles.infoTable}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  key={row.original.id}
                  {...row.getRowProps()}
                  className={hoveredRow === row.original.index ? styles.hoveredRow : ''}
                  onMouseEnter={() => setHoveredRow(row.original.index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  {row.cells.map((cell) => (
                    <td key={cell.column.id} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
