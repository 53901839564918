import { useDispatch, useSelector } from 'react-redux';
import { formDataChange, setFormDirty } from '../../../redux/reducers';
import DynamicForm from '../../DynamicForm/DynamicForm';

const ProfileTab = () => {
  const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const formData = useSelector((state) => state.communityForm.formData);
  const dispatch = useDispatch();

  const handleFormDataChange = ({ formData }) => {
    dispatch(formDataChange(formData));
  };

  return (
    <DynamicForm
      schema={jsonSchema}
      uiSchema={uiSchema}
      formData={formData}
      onChange={handleFormDataChange}
      onSubmit={() => console.log('submitted')}
      onError={() => console.log('errors')}
      formContext={{ currentTab: 'Profile' }}
    />
  );
};

export default ProfileTab;
