import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CommunityForm from '../../components/CommunityForm/CommunityForm';
import { formReset } from '../../redux/reducers';
import { getApprovedPropertyCommunities } from '../../utils/communityFormUtils';
import { CARE_TYPE_ABBREVIATIONS } from '../../constants/CareType';
import { Constants } from 'web-core';
import AmplitudeTools from '../../utils/AmplitudeTools';
import api from '../../services/api';
import { APITools } from 'web-core';
import { useSnackbar } from 'notistack';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';

const CommunityEdit = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  let [communityName, setCommunityName] = useState('');
  let [communityPercent, setCommunityPercent] = useState({});
  let [formSaved, setFormSaved] = useState(false);
  let [amplitudeCommunityId, setAmplitudeCommunityId] = useState(0);
  const approvedPropertyCommunities = useSelector(
    (state) => state.communityForm.approvedPropertyCommunities
  );
  const approvedVersionId = useSelector(
    (state) => state.communityForm.approved_version_id
  );
  const propertyId = useSelector((state) => state.communityForm.property_id);
  const account = useSelector((state) => state.auth.user);

  useEffect(() => {
    const percentCompleteProperties = async () => {
      try {
        // don't do this before the property id is loaded
        if (propertyId && account?.role === Constants.AccountRoles.Admin) {
          const dataRes = await api(
            `/v1/admin/properties/${propertyId}/profile_completion`
          );
          setCommunityPercent(dataRes.data.profile_completion);
        }
      } catch (err) {
        let errorsToDisplay = APITools.errorMessageFromServerResponse(err);
        enqueueSnackbar(`${errorsToDisplay}`, {
          variant: 'error',
        });
      }
    };
    percentCompleteProperties();
  }, [id, propertyId, formSaved, account]);

  // NOTE: it appears that when navigating away from a community#edit page and then go to edit another one,
  //       the communityForm in the state has the previous community data in it on page load before loading from remote.
  //       need to figure out how to properly reset the communityForm state on initial page load
  useEffect(() => {
    const communities = getApprovedPropertyCommunities(
      approvedPropertyCommunities,
      approvedVersionId
    );

    if ('' + approvedVersionId !== id) {
      setCommunityName(''); // community has changed, clear the community name from the state
    } else if (communities && communities.length > 0) {
      let careType = communities.map(
        ({ care_type }) => CARE_TYPE_ABBREVIATIONS[care_type]
      )[0];
      let nameOfCommunity = communities.map(({ name }) => name)[0];
      let updatedCommunityName = `${nameOfCommunity ?? ''} (${careType ?? ''})`;
      if (updatedCommunityName !== communityName) {
        setCommunityName(updatedCommunityName);
      }

      if (amplitudeCommunityId !== communities[0].id) {
        AmplitudeTools.logCommunityEvent(
          AmplitudeTools.EVENTS.EDIT_COMMUNITY,
          communities[0]
        );
        setAmplitudeCommunityId(communities[0].id);
      }
    }
  }, [id, approvedPropertyCommunities, approvedVersionId]);

  useEffect(() => {
    dispatch(formReset());
  }, [dispatch]);

  return (
    <>
      <HeaderTitle title={communityName} />
      <CommunityForm
        communityPercent={communityPercent}
        setFormSaved={setFormSaved}
        formSaved={formSaved}
      />
    </>
  );
};

export default CommunityEdit;
