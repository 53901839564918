import CONFIG from '../../config/config';

export const PricingPopup = ({ propertyId }) => {
  return (
    <stripe-pricing-table
      pricing-table-id={CONFIG.STRIPE.pricingTableId}
      publishable-key={CONFIG.STRIPE.publishableKey}
      client-reference-id={propertyId}
    ></stripe-pricing-table>
  );
};
