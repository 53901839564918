import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  InputAdornment,
} from '@mui/material';
import Icons from '../../../../constants/Icons';
import { IconButton } from '@mui/material';
import { useCallback, useState } from 'react';

const SELECT_ALL = 'Select All';

const determineCheckedState = (props, value) => {
  if (!props.multiple) {
    return [false, false];
  } else {
    if (value && value.length === 0) {
      return [false, false];
    } else if (
      value &&
      props.options.enumOptions &&
      value.length !== props.options.enumOptions.length
    ) {
      return [true, false];
    } else if (
      value &&
      props.options.enumOptions &&
      value.length === props.options.enumOptions.length
    ) {
      return [true, true];
    } else {
      return [false, false];
    }
  }
};

export const CustomSelect = (props) => {
  // If "props.schema.type" === "boolean", placeholder is in: "props.options.placeholder" for select widget.
  const placeholder =
    props.placeholder || props.options.placeholder || props.label || 'Select...';
  const value =
    props.value && !Array.isArray(props.value) && props.multiple
      ? [props.value]
      : props.value;
  const emptyValue = props.multiple ? [] : '';
  const [checked, setChecked] = useState(determineCheckedState(props, value));

  const handleSingleSelectChange = (event) => {
    props.onChange(event.target.value);
  };

  const handleMultiSelectChange = (event) => {
    let { value } = event.target;
    if (
      (value.length > 0 &&
        value[value.length - 1] === SELECT_ALL &&
        checked[0] &&
        checked[1]) ||
      (value.length > props.options.enumOptions.length && checked[0] && checked[1]) ||
      value.length === 0
    ) {
      setChecked([false, false]);
      return props.onChange(emptyValue);
    }
    if (
      value.some((v) => v === SELECT_ALL) ||
      value.length === props.options.enumOptions.length
    ) {
      setChecked([true, true]);
      return props.onChange(props.options.enumOptions.map((item) => item.value));
    } else if (!value.some((v) => v === SELECT_ALL)) {
      setChecked([true, false]);
      return props.onChange(value);
    }
  };

  const singleSelectRenderValue = useCallback(
    (value) =>
      props.options.enumOptions.find((option) => option.value === value)?.label || '',
    [props.options.enumOptions]
  );
  const multiSelectRenderValue = useCallback(
    (selected) => selected.map(singleSelectRenderValue).join(', '),
    [singleSelectRenderValue]
  );
  const renderClearButton =
    (value && typeof value !== 'object' && value !== '') ||
    (typeof value === 'object' && value.length !== 0);
  const hideClearButton = props.hideClear || false;

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
      disabled={props.disabled}
    >
      <InputLabel id='select-multiple-checkbox-label'>{placeholder}</InputLabel>
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        labelId='select-multiple-checkbox-label'
        multiple={props.multiple}
        value={value || emptyValue}
        onChange={props.multiple ? handleMultiSelectChange : handleSingleSelectChange}
        input={<OutlinedInput label={placeholder} />}
        endAdornment={
          renderClearButton && !hideClearButton ? (
            <InputAdornment
              position='end'
              sx={{ cursor: 'pointer' }}
              onClick={() => props.onChange(emptyValue)}
            >
              {Icons.ClearInput}
            </InputAdornment>
          ) : null
        }
        IconComponent={() => null}
        renderValue={props.multiple ? multiSelectRenderValue : singleSelectRenderValue}
      >
        <MenuItem disabled value=''>
          <ListItemText secondary={placeholder} />
        </MenuItem>
        {props.multiple && (
          <MenuItem
            variant
            key={SELECT_ALL}
            value={SELECT_ALL}
            style={{ borderBottom: 'solid 1px #ac947a' }}
          >
            <Checkbox
              checked={checked[0] && checked[1]}
              indeterminate={checked[0] !== checked[1]}
            />
            <ListItemText primary={SELECT_ALL} />
          </MenuItem>
        )}
        {props.options.enumOptions.map(({ label, value }) => {
          const isChecked =
            props.multiple && Array.isArray(props?.value)
              ? props.value?.some((val) => val === value)
              : props?.value === value;
          return (
            <MenuItem key={label} value={value} variant>
              {props.multiple ? <Checkbox checked={isChecked} /> : null}
              <ListItemText primary={label} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
