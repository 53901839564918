import { APITools } from 'web-core';
import Loader from '../../components/Loader/Loader';
import { Stats } from '../../components/Stats/Stats';
import styles from './Stats.module.scss';
import api from '../../services/api';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';

export const StatsPage = () => {
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    try {
      const { data } = await api.get(`v1/admin/stats/dashboard`);
      if (data) {
        setStats(data.results);
        setLoading(false);
      }
    } catch (err) {
      setStats({});
      setLoading(false);
      let errorsToDisplay = APITools.errorMessageFromServerResponse(err);
      enqueueSnackbar(`${errorsToDisplay}`, { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <HeaderTitle title={'Stats'} />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Stats</h1>
          </div>
        </div>
        {stats && Object.keys(stats).length > 0 && (
          <div className={styles.statsBar}>
            <Loader isLoading={loading}>
              <Stats
                title='Missed Search Matches'
                value={stats?.missed_search_match_count}
              />
              <Stats title='Geo Search Logs' value={stats?.geo_search_log_count} />
              <Stats title='Viewed Communities' value={stats?.viewed_community_count} />
              <Stats title='Admin Notes' value={stats?.admin_note_count} />
            </Loader>
          </div>
        )}
      </div>
    </>
  );
};
