import { NavLink } from 'react-router-dom';
import styles from './SideMenu.module.scss';

export const MenuLink = ({ label, link }) => {
  return (
    <NavLink to={link} className={styles.menuLink} activeClassName={styles.activeLink}>
      {label}
    </NavLink>
  );
};
