import Wordmark from '../../assets/wordmark.svg';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import styles from './Auth.module.scss';

const Auth = () => {
  return (
    <div className={styles.authPage}>
      <div className={styles.authFormWrapper}>
        <div className={styles.authForm}>
          <img src={Wordmark} className={styles.authLogo} alt='Logo' />
          <h2 className={styles.authSubTitle}>Operator Portal</h2>
          <div className='mt-100 mb-100 flex-spread-row'>
            <a
              href={`${process.env.REACT_APP_CONSUMER}/auth/login`}
              className={styles.authButton}
            >
              <Button.DARK className={styles.authButton}>Login</Button.DARK>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
