import styles from './ManageRestaurantImagesForm.module.scss';
import { KWImage } from '../../../../KWImage/KWImage';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import Button from '../../../../Button/Button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const ManageRestaurantImagesForm = ({ diningImages, onClose, onSave }) => {
  const communityImages = useSelector((state) => state.communityForm['images']);
  const candidateImages = communityImages.filter(
    (image) =>
      image.image_types?.includes('Menu') || image.image_types?.includes('Dining')
  );
  const [imageList, setImageList] = useState([]);
  const [candidateList, setCandidateList] = useState(candidateImages || []);

  const reorderDiningImageList = (arr, startIndex, endIndex) => {
    const result = Array.from(arr);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // move image from community list to restaurant list
  const moveFromRestaurantListToImageList = (
    candidateList,
    imageList,
    source,
    destination
  ) => {
    const sourceClone = Array.from(candidateList);
    const destinationClone = Array.from(imageList);
    const [removed] = sourceClone.splice(source.index, 1);

    destinationClone.splice(destination.index, 0, removed);

    let result = {};
    result[source.droppableId] = sourceClone;
    result[destination.droppableId] = destinationClone;

    return result;
  };

  const handleDragEnd = (event) => {
    // console.log(event);
    const { source, destination } = event;

    // if dropped outside the droppable area
    if (!destination) return;

    let sourceId = source.droppableId;
    let destinationId = destination.droppableId;

    // drop within the same image list
    if (sourceId === destinationId) {
      const reorderArray = reorderDiningImageList(
        imageList,
        source.index,
        destination.index
      );
      setImageList(reorderArray);
    } else {
      // drag & drop to different image list
      const result = moveFromRestaurantListToImageList(
        candidateList,
        imageList,
        source,
        destination
      );
      setImageList(result[destination.droppableId]);
    }
  };

  const filterCandidateImages = (candidateArr, diningImagesArr) => {
    return candidateArr.filter(
      ({ id: candidateId }) =>
        !diningImagesArr.some(({ id: diningImageId }) => candidateId === diningImageId)
    );
  };

  const getImagesById = (sourceArray, idArray) => {
    let array = [];
    sourceArray?.forEach((sourceImg) => {
      idArray?.forEach((targetId) => {
        if (sourceImg.id === targetId) {
          array.push(sourceImg);
        }
      });
    });
    return array;
  };

  useEffect(() => {
    let filteredList = filterCandidateImages(candidateImages, imageList);
    setCandidateList(filteredList);
  }, [imageList]);

  useEffect(() => {
    let images = getImagesById(communityImages, diningImages);
    setImageList(images);
  }, [diningImages]);

  const handleUnselectRestaurantImage = (imageId) => {
    let sortDiningImages = imageList.filter((image) => image.id !== imageId);
    setImageList(sortDiningImages);
  };

  return (
    <div className={styles.popupForm}>
      <div className={[styles.headerWithSub, styles.header].join(' ')}>
        <h2>Manage Restaurant Images</h2>
        <h3>
          Drag Community Images down to the Restaurant Images list. Upload more Community
          Images on the Photos tab and mark them as 'Dining' or 'Menu' to have them appear
          here.
        </h3>
      </div>
      <div className={styles.formBody}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className={styles.communityImages}>
            <h4>Community Images</h4>
            <Droppable
              droppableId='communityImages'
              isDropDisabled={true}
              direction='horizontal'
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={styles.communityImagesContainer}
                  {...provided.droppableProps}
                >
                  {candidateList.length > 0 ? (
                    candidateList.map((image, index) => (
                      <Draggable
                        key={image.id}
                        draggableId={String(image.id)}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            className={styles.communityImageContainer}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {image.file_url && (
                              <KWImage
                                src={image.file_url}
                                height={120}
                                alt='Community Image'
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <div className={styles.emptyBox}>
                      <span>
                        Upload Community Images on the Photos tab and mark them as
                        'Dining' or 'Menu' to add images to this restaurant.
                      </span>
                    </div>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className={styles.restaurantImages}>
            <h4>{'Restaurant Images'}</h4>
            <Droppable droppableId='diningImages' direction='horizontal'>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className={styles.diningImagesContainer}
                  style={{
                    backgroundColor: snapshot.isDraggingOver ? '#f6ece1' : 'inherit',
                  }}
                  {...provided.droppableProps}
                >
                  {imageList.length > 0 &&
                    imageList.map((image, index) => (
                      <Draggable
                        key={image.id}
                        draggableId={String(image.id)}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className={styles.diningImageContainer}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {image.file_url && (
                              <KWImage
                                src={image.file_url}
                                height={120}
                                alt={'Dining Image'}
                              />
                            )}
                            {imageList.length > 0 && (
                              <Button.BLACK
                                icon
                                className={styles.cancelButton}
                                onClick={() => handleUnselectRestaurantImage(image.id)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button.BLACK>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>

      <div className={`flex-kebab-row p-50 ${styles.bottomButtons}`}>
        <Button.LIGHT type='button' onClick={onClose}>
          Cancel
        </Button.LIGHT>
        <Button.DARK type='button' onClick={(e) => onSave(e, imageList)}>
          {' '}
          Save Changes
        </Button.DARK>
      </div>
    </div>
  );
};
