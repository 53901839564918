
import merge from 'lodash/merge';

const baseConfig = {
  STRIPE: {
    pricingTableId: null,
    publishableKey: null,
  },
  Amplitude: {key: null},
};

function extendBaseConfig(overrides) {
  return merge(baseConfig, overrides);
}

function beanstalkProd() {
  return extendBaseConfig({
    STRIPE: {
      pricingTableId: 'prctbl_1M4Tn5H12M5tI1GpWJX2iVed',
      publishableKey:
        'pk_live_51LwV5ZH12M5tI1GpyWEEr03Cpx3JmQamvo5wZPt67geqbJO24nWfWEa7gN7Em5uPPwKUmqrMHKXEKk7cGJbhpBGq007xvDYY1NYesterday',
    },
    Amplitude: {key: 'c0e246dee69ee67434d58549914cae7e'},
  });
}

function beanstalkStaging() {
  return extendBaseConfig({
    STRIPE: {
      pricingTableId: 'prctbl_1MAdI7H12M5tI1GpCyXWX9fE',
      publishableKey:
          'pk_test_51LwV5ZH12M5tI1GpTLbHsy42e5RoarjbBoATjMB6aEbtOnoeECq9nMSlbrRXkQs2L6CJOz27DqgfCPXFkAFp6bo900UJXUDRGH',
    },
    Amplitude: {key: '44b24891feb339aca09b6f74b85d40d0'},
  });
}

function oldDev() {
  return extendBaseConfig({
    STRIPE: {
      pricingTableId: 'prctbl_1M5BfjH12M5tI1GpNeH6Ri4d',
      publishableKey: beanstalkStaging().STRIPE.publishableKey,
    },
    Amplitude: {key: '5d8ab0bb2530ee98ae184cc563cefeb5'},
  });
}

function getConfig() {
  if (process.env.REACT_APP_KW_CONFIG_JSON) {
    return extendBaseConfig(JSON.parse(process.env.REACT_APP_KW_CONFIG_JSON));
  }

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production': return beanstalkProd();
    case 'staging': return beanstalkStaging();
    case 'development': return oldDev();
    default:
      throw new Error(
          'No REACT_APP_KW_CONFIG_JSON and invalid value for'
          + ` REACT_APP_ENVIRONMENT: ${process.env.REACT_APP_ENVIRONMENT}`);
  }
}

const CONFIG = getConfig();
function validateConfig(c, path) {
  path = path || 'config';
  if (c === null) { return new Error('Found null value in config: ' + path); }
  if (typeof c !== 'object') { return; }
  for (const k in c) { validateConfig(c[k], path + '.' + k); }
}
validateConfig(CONFIG);
console.log("Running with config: " + JSON.stringify(CONFIG, null, 2));
export default CONFIG;

