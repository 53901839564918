import { StringUtils } from 'web-core';

/**
 * Prepend domain to relative image urls(if the url is relative) so that it returns an absolute image url
 * @param url
 * @returns domain-prepended(absolute) url
 */
export const ensureAbsoluteImageUrl = (url) => {
  // null check
  if (StringUtils.isBlank(url)) {
    return null;
  }

  // check if url is an absolute path
  if (!url.startsWith('http')) {
    // prepend domain to relative path
    url = `${process.env.REACT_APP_IMGIX}${url}`;
  }

  return url;
};
