import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Popup from 'reactjs-popup';
import Icons from '../../../../constants/Icons';
import {
  changeEditingModel,
  changeModelForm,
  formFieldChange,
} from '../../../../redux/reducers';
import {
  selectCurrentEditingModelIndex,
  selectModels,
} from '../../../../redux/selectors/communityForm';
import Button from '../../../Button/Button';
import styles from '../../TabContent/TabContent.module.scss';
import ModelsForm from './ModelsForm/ModelsForm';
import { CreateUnitTypesForm } from './ModelsImagesForm/CreateUnitTypesForm';
import ModelsTable from './ModelsTable';
import { Constants } from 'web-core';

const ModelsTab = () => {
  const models = useSelector(selectModels) || [];
  const modelIndexEditing = useSelector(selectCurrentEditingModelIndex);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [maxBedrooms, setMaxBedrooms] = useState(null);
  const [minBedrooms, setMinBedrooms] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [maxSQFT, setMaxSQFT] = useState(null);
  const [minSQFT, setMinSQFT] = useState(null);
  const [showImagesUploadForm, setShowImagesUploadForm] = useState(
    !Boolean(models.length)
  );
  const [formSubmitted, setFormSubmitted] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (modelIndexEditing == null) {
      setIsFormVisible(false);
    }
    let bedroomRangeArray = [];
    let priceRangeArray = [];
    let sqftRangeArray = [];
    if (models) {
      models.map((model) => {
        if (model[Constants.DynamicSchemaFields.ModelBedrooms]) {
          bedroomRangeArray.push(
            model[Constants.DynamicSchemaFields.ModelBedrooms].length
          );
        }
        if (model[Constants.DynamicSchemaFields.ModelBaseMarketRent]) {
          priceRangeArray.push(model[Constants.DynamicSchemaFields.ModelBaseMarketRent]);
        }
        if (model[Constants.DynamicSchemaFields.ModelMaxMarketRent]) {
          priceRangeArray.push(model[Constants.DynamicSchemaFields.ModelMaxMarketRent]);
        }
        if (model[Constants.DynamicSchemaFields.ModelMaxSquareFootage]) {
          sqftRangeArray.push(model[Constants.DynamicSchemaFields.ModelMaxSquareFootage]);
        }
        if (model[Constants.DynamicSchemaFields.ModelSquareFootage]) {
          sqftRangeArray.push(model[Constants.DynamicSchemaFields.ModelSquareFootage]);
        }
      });
      if (priceRangeArray.length >= 2) {
        setMaxBedrooms(Math.max(...bedroomRangeArray));
        setMinBedrooms(Math.min(...bedroomRangeArray));
      }
      if (priceRangeArray.length >= 2) {
        setMaxPrice(Math.max(...priceRangeArray));
        setMinPrice(Math.min(...priceRangeArray));
      }
      if (sqftRangeArray.length >= 2) {
        setMaxSQFT(Math.max(...sqftRangeArray));
        setMinSQFT(Math.min(...sqftRangeArray));
      }
    }
  }, [modelIndexEditing]);

  const showCreateModelForm = () => {
    setShowImagesUploadForm(true);
  };

  const handleOnSave = (event, newUnitTypes) => {
    event.preventDefault();
    let updatedUnitTypes = [...models, ...newUnitTypes];
    dispatch(formFieldChange('models', updatedUnitTypes));

    setShowImagesUploadForm(false);
    setFormSubmitted(true);
  };

  const showEditModelForm = (index) => {
    dispatch(changeModelForm(models[index]));
    setIsFormVisible(true);
    dispatch(changeEditingModel(index));
  };

  const handleModelDelete = (modelDelete) => {
    setIsFormVisible(false);
    if (Number.isInteger(modelIndexEditing)) {
      dispatch(
        formFieldChange(
          'models',
          models.filter((model, index) => index !== modelIndexEditing)
        )
      );
    } else {
      // delete model
      dispatch(
        formFieldChange(
          'models',
          models.filter((model, index) => index !== modelDelete)
        )
      );
    }
    dispatch(changeEditingModel(null));
    window.scrollTo(0, 0);
  };

  const handleFormCancel = () => {
    setIsFormVisible(false);
    dispatch(changeEditingModel(null));
  };

  const handleFormSave = (modelSaved) => {
    setIsFormVisible(false);
    // Is editing
    if (Number.isInteger(modelIndexEditing)) {
      dispatch(
        formFieldChange(
          'models',
          models.map((model, index) => (index !== modelIndexEditing ? model : modelSaved))
        )
      );
      dispatch(changeEditingModel(null));
    } else {
      // Save new model(s)
      const dateCreated = new Date().toISOString();
      dispatch(
        formFieldChange('models', [
          ...models,
          { ...modelSaved, dateCreated, id: uuidv4() },
        ])
      );
    }
  };

  useEffect(() => {
    if (formSubmitted) {
      if (models.length > 0) {
        let id = models[models.length - 1]?.id;
        if (id) {
          let elem = document.getElementById(id);
          elem &&
            elem.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            });
        }
      }
    }
    setFormSubmitted(false);
  }, [formSubmitted]);

  return (
    <div>
      {
        <Popup
          open={isFormVisible}
          modal
          nested
          closeOnDocumentClick={false}
          closeOnEscape={false}
          lockScroll={true}
        >
          <ModelsForm
            onSave={handleFormSave}
            onDelete={handleModelDelete}
            onCancel={handleFormCancel}
          />
        </Popup>
      }
      {
        <Popup
          open={showImagesUploadForm}
          modal
          nested
          closeOnDocumentClick={false}
          closeOnEscape={false}
          lockScroll={true}
        >
          <CreateUnitTypesForm
            onCancel={() => setShowImagesUploadForm(false)}
            onSave={handleOnSave}
          />
        </Popup>
      }
      {
        <div className={styles.tabDescription}>
          <div className='mb-100'>
            {minBedrooms &&
            isFinite(minBedrooms) &&
            maxBedrooms &&
            isFinite(maxBedrooms) ? (
              <p className={styles.tabDescription}>
                Bedroom Range:{' '}
                <span className='ml-50'>
                  {minBedrooms} - {maxBedrooms}
                </span>
              </p>
            ) : null}
            {minPrice && isFinite(minPrice) && maxPrice && isFinite(maxPrice) ? (
              <p className={styles.tabDescription}>
                Price Range:
                <span className='ml-50'>
                  ${minPrice} - ${maxPrice}
                </span>
                <Tooltip
                  className='ml-50'
                  title={
                    'Improve the shown price range by adding Unit Types with their prices'
                  }
                  arrow
                >
                  <i className={styles.toolTip}>{Icons.Help}</i>
                </Tooltip>
              </p>
            ) : null}
            {minSQFT && isFinite(minSQFT) && maxSQFT && isFinite(maxSQFT) ? (
              <p className={styles.tabDescription}>
                SQFT Range:{' '}
                <span className='ml-50'>
                  {minSQFT}ft² - {maxSQFT}ft²
                </span>
              </p>
            ) : null}
          </div>
          <section className={styles.formSection}>
            <div className={styles.sectionHeader}>
              <span className={styles.sectionTitle}>Unit Type Summary</span>
              <Button.LIGHT
                className={styles.sectionTitleButton}
                onClick={showCreateModelForm}
              >
                + New Unit Type
              </Button.LIGHT>
            </div>
            <ModelsTable onRowClick={showEditModelForm} />
            <div className={styles.tabFooter}>
              <Button.DARK icon onClick={showCreateModelForm}>
                {Icons.Plus}
              </Button.DARK>
            </div>
          </section>
        </div>
      }
    </div>
  );
};

export default ModelsTab;
