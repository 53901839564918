import styles from './UnitImportTable.module.scss';

export const UnitImportItem = ({ row }) => {
  return (
    <>
      <tr className={styles.item}>
        {row.cells.map((cell) => (
          <td key={cell.column.id + cell.row.id} {...cell.getCellProps()}>
            {cell.render('Cell')}
          </td>
        ))}
      </tr>
    </>
  );
};
