import styles from './PhotoUploader.module.scss';
import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useSnackbar } from 'notistack';
import Loader from '../Loader/Loader';
import KthPopup from '../KthPopup/KthPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

interface Props {
  propertyId: string | number;
  selectedImage: Image | null;
  file: File | null;
  setFile: (file: File | null) => void;
  setSelectedImage: (image: Image | null) => void;
  setShowPhotosPopup: (boolean: boolean) => void;
}

export interface Image {
  id: string | number;
  url: string;
}

const PhotoUploader: React.FC<Props> = ({
  propertyId,
  selectedImage,
  file,
  setFile,
  setSelectedImage,
  setShowPhotosPopup,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const apiUrl = `/v1/admin/properties/${propertyId}/banner_images`;
  const [images, setImages] = useState<Image[]>([]);
  const [imageToDelete, setImageToDelete] = useState<Image | null>(null);
  const [showDeleteImagePopup, setShowDeleteImagePopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchImages = async () => {
    const response = await api.get(apiUrl);
    setImages(response.data.results);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleUpload = async (file: File | null) => {
    const MAX_IMAGE_NUM = 30;
    if (!file) {
      enqueueSnackbar('No file selected.', { variant: 'error' });
      return;
    } else if (images.length === MAX_IMAGE_NUM) {
      enqueueSnackbar(`Cannot upload more than ${MAX_IMAGE_NUM} images.`, {
        variant: 'error',
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('image', file);
    const response = await api.post(apiUrl, formData);
    setImages([...images, response.data]);
    setLoading(false);
  };

  useEffect(() => {
    if (file) {
      handleUpload(file);
      setFile(null);
    }
  }, [file]);

  const handleSelectImage = (image: Image | null) => {
    setSelectedImage(image);
    setShowPhotosPopup(false);
  };

  const handleClickDeleteImage = (image: Image | null) => {
    if (image) {
      setImageToDelete(image);
      setShowDeleteImagePopup(true);
    }
  };

  const handleDeleteImage = async (id: string | number | null | undefined) => {
    if (id) {
      await api.delete(`${apiUrl}/${id}`);
      setImages(images.filter((image) => image.id !== id));
      if (selectedImage && selectedImage.id === id) {
        setSelectedImage(null);
      }
    }
    setShowDeleteImagePopup(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageGrid}>
        {images.map((image) => (
          <div key={image.id} className={styles.imageContainer}>
            <div className={styles.buttonContainer}>
              <img
                src={image.url}
                alt={`${image.id}`}
                onClick={() => handleSelectImage(image)}
                className={`${
                  selectedImage && selectedImage.id === image.id && styles.selected
                }`}
              />
              <div
                className={styles.deleteImageButton}
                onClick={() => handleClickDeleteImage(image)}
              >
                <FontAwesomeIcon icon={faX} width={12} size={'2x'} />
              </div>
            </div>
          </div>
        ))}
        {loading && (
          <div className={styles.loader}>
            <Loader isLoading={loading}>
              <div></div>
            </Loader>
          </div>
        )}
      </div>
      <KthPopup
        title={'Delete Image'}
        isOpen={showDeleteImagePopup}
        confirmButtonLabel={'Delete'}
        onConfirmButtonClick={() => handleDeleteImage(imageToDelete?.id)}
      >
        <div style={{ maxWidth: '20vw' }}>
          <p style={{ fontSize: '16px' }}>
            Are you sure you want to delete this image? It will continue to work in all
            assessment banners it has been used in.
          </p>
        </div>
      </KthPopup>
    </div>
  );
};

export default PhotoUploader;
