import AmplitudeUtils from 'web-core/amplitudeUtils';
import CONFIG from '../config/config';

const AMPLITUDE_KEY = CONFIG.Amplitude.key;

const AmplitudeTools = {};

AmplitudeTools.EVENTS = {
  EDIT_COMMUNITY: 'edit community',
  VIEW_COMMUNITY_CHECKLIST_LIGHTBOX: 'viewed community checklist lightbox',
  CHECK_COMMUNITY_CHECKLIST_ITEM: 'check community checklist item',
  CANCEL_COMMUNITY_CHECKLIST: 'cancel community checklist',
  COMPLETED_COMMUNITY_CHECKLIST: 'completed community checklist',
  VIEW_COPY_INTO_COMMUNITY_LIGHTBOX: 'viewed copy into community lightbox',
  NOT_COPYING_INTO_COMMUNITY: 'not copying into community',
  COPIED_INTO_COMMUNITY: 'copied into community',
  SUBMIT_FOR_APPROVAL: 'submit for approval',
  PREVIEW_COMMUNITY_PROFILE: 'preview community profile',
  EDIT_DIFFERENT_CARE_LEVEL: 'edit different care level',
  CHANGE_EDIT_COMMUNITY_TAB: 'change edit community tab',
};

AmplitudeTools.EVENT_PROPERTIES = {
  COMMUNITY_ID: 'community id',
  COMMUNITY_NAME: 'community name',
  COMMUNITY_CARE_TYPE: 'community care type',
  CHECKBOX: 'checkbox',
  TAB_NAME: 'tab name',
};

AmplitudeTools.init = () => {
  AmplitudeUtils.init(AMPLITUDE_KEY);
};

AmplitudeTools.logEvent = (eventName, eventProperties) => {
  AmplitudeUtils.logEvent(AMPLITUDE_KEY, eventName, eventProperties);
};

// fromUserAction = boolean whether they clicked logout
AmplitudeTools.handleUserLogout = (fromUserAction) => {
  AmplitudeUtils.handleUserLogout(AMPLITUDE_KEY, fromUserAction);
};

AmplitudeTools.logCommunityEvent = (eventName, community) => {
  let eventProperties = {};
  eventProperties[AmplitudeTools.EVENT_PROPERTIES.COMMUNITY_ID] = community.id;
  eventProperties[AmplitudeTools.EVENT_PROPERTIES.COMMUNITY_NAME] = community.name;
  eventProperties[AmplitudeTools.EVENT_PROPERTIES.COMMUNITY_CARE_TYPE] =
    community.care_type;
  AmplitudeTools.logEvent(eventName, eventProperties);
};

export default AmplitudeTools;
