import { useParams } from 'react-router-dom';
import EditAssessmentLinkPage from '../../../components/EditAssessmentLink/EditAssessmentLinkPage';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { APITools } from 'web-core';
import { idFromSlug } from '../../../utils/urlTools';
import { useAssessmentLink } from '../../../hooks/useAssessmentLink';
import { useProperty } from '../../../hooks/useProperty';
import HeaderTitle from '../../../components/HeaderTitle/HeaderTitle';
import SpinnerLoader from '../../../components/Spinner/SpinnerLoader';

const EditPropertyAssessmentLink = () => {
  const { property_slug, uuid } = useParams<{ property_slug: string; uuid: string }>();
  const { data: property } = useProperty(idFromSlug(property_slug));
  const {
    data: assessmentLink,
    loading,
    error,
  } = useAssessmentLink(idFromSlug(property_slug), uuid);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      console.log(error);
      const errorMessage = APITools.errorMessageFromServerResponse(error);
      enqueueSnackbar(errorMessage, {
        variant: 'warning',
      });
    }
  }, [error]);

  return !assessmentLink && loading ? (
    <SpinnerLoader />
  ) : (
    <>
      <HeaderTitle title={`Edit Assessment Link`} />
      <EditAssessmentLinkPage
        propertyId={property?.id}
        propertySlug={property_slug}
        assessmentLink={assessmentLink}
      />
    </>
  );
};

export default EditPropertyAssessmentLink;
