import _ from 'lodash';

/**
 * Return the Tab name if the fieldName is from one of the following tabs: Units, Models, Buildings
 * Tab name can be used to access "formData[tabName]"
 * @param {string} fieldName
 * @returns {string} tab name
 */
export const getFieldTabName = (fieldName) => {
  const tab = fieldName.split('|')[0];
  switch (tab) {
    case 'Building-Details':
      return 'buildings';
    case 'Model-Type':
      return 'models';
    case 'Units':
      return 'units';
    default:
      return '';
  }
};

/**
 * Returns a clone of tabForm object, with all arrays of "tabName" populated with empty objects
 * @param {Object} tabForm
 * @param {string} tabName
 * @param {Object} jsonSchema
 * @returns {Object}
 */
export const getFormWithPopulatedArrays = (tabForm, tabName, jsonSchema) => {
  return Object.entries(jsonSchema.properties).reduce((acc, [key, value]) => {
    // If the field is inside "units", "models", or "buildings", the following will include an empty object for every enum field
    // const fieldTabName = getFieldTabName(key);
    // if(fieldTabName === tabName && value.type === 'array' && value.items.type === 'object'){
    //   const isEmpty = !Boolean(acc[key]?.length);
    //   if(isEmpty){
    //     acc[key] = [{}]
    //   }
    // }
    return acc;
  }, _.cloneDeep(tabForm));
};

export const extractIndexFromDynamicFormId = (id) => {
  if (id && id.length > 0) {
    return parseInt(id.match(/_(\d+)_/)[1]);
  }
};

// NOTE: avoid referencing communityForm.formData in here or it will trigger full page re-renders
export const getApprovedPropertyCommunities = (
  approvedPropertyCommunities,
  approvedVersionId
) => {
  approvedPropertyCommunities = approvedPropertyCommunities || [];
  let currentApprovedCommunity = null;
  if (approvedPropertyCommunities.length > 0) {
    approvedPropertyCommunities.forEach((community, index) => {
      if (approvedVersionId === community.id) {
        currentApprovedCommunity = community;
      }
    });
  }

  if (currentApprovedCommunity) {
    const current = {
      id: currentApprovedCommunity.id,
      name: currentApprovedCommunity.name,
      care_type: currentApprovedCommunity.care_type,
    };

    return [
      current,
      ...approvedPropertyCommunities.filter((community) => community.id !== current.id),
    ];
  } else {
    // can happen on page load before remote request returns
    return [];
  }
};

// hide admin notes for Unit tab
export const shouldHideAdminNote = (tab) => {
  return tab === 'Units';
};

/**
 * Basically parse the piped schema field and extract the first pipe(tab), then determine
 * if the tab is Building / Unit type or not
 * @param section
 * @returns {boolean} True if current tab is Building or Unit Type, false otherwise
 */
export const isBuildingOrUnitTypeTab = (section) => {
  let tab = section.split('|')[0];
  return tab === 'Building-Details' || tab === 'Model-Type';
};
