import KithwardLogo from '../../assets/kw-logo-96.webp';
import Imgix from 'react-imgix';
import { StringUtils } from 'web-core';
import { ensureAbsoluteImageUrl } from '../../utils/ImageUtils';

export const KWImage = ({ src, alt, ...props }) => {
  let absoluteUrl = ensureAbsoluteImageUrl(src);
  const srcUrl = absoluteUrl ? absoluteUrl : KithwardLogo;

  // default imgixParams
  let imgixParams = {
    fit: 'crop',
    fm: 'jpg',
    q: 50,
  };

  let otherProps = { ...props };
  // if width/height is missing, adjust the imgixParam.fit
  if (!otherProps.height || !otherProps.width) {
    imgixParams.fit = 'fill';
  }

  const handleImgError = (event) => {
    event.target.src = KithwardLogo;
  };

  // console.info('imgixparams', imgixParams);
  // show basic image for default kithward logo and for everything while in local development
  // note we never want to use Imgix component to show our default KithwardLogo
  return StringUtils.isBlank(src) ? (
    <img {...props} src={srcUrl || ''} onError={handleImgError} alt={alt || 'Image'} />
  ) : (
    <Imgix src={srcUrl} alt={alt || 'Image'} imgixParams={imgixParams} {...props} />
  );
};
