export const AccountStatus: any = {
  PENDING: '?',
  PENDING_VERIFICATION: 'P',
  REAL: 'R',
  PENDING_PASSWORD_VERIFICATION: 'W',
};

export const AccountStatusLabel = {
  '?': 'Pending',
  P: 'Pending Verification',
  W: 'Pending Password Verification',
};

export const isPendingStatus = (status: string) => {
  return (
    status === AccountStatus.PENDING ||
    status === AccountStatus.PENDING_VERIFICATION ||
    status === AccountStatus.PENDING_PASSWORD_VERIFICATION
  );
};
