/* ============================================================================================== */

import produce from 'immer';
import SignUpFormAdapter from '../../adapters/SignupFormAdapter';
import api from '../../services/api';
import { toURLSearchParams } from '../../services/transform';

export const UsersActions = {
  // Get Users
  GET_PENDING: 'users/GET_PENDING',
  GET_SUCCEEDED: 'users/GET_SUCCEEDED',
  GET_FAILED: 'users/GET_FAILED',

  // Query Users
  QUERY_PENDING: 'users/QUERY_PENDING',
  QUERY_SUCCEEDED: 'users/QUERY_SUCCEEDED',
  QUERY_FAILED: 'users/QUERY_FAILED',
};

const USERS_BASE_API = `/v1/admin/accounts`;
const USERS_SEARCH_API = `/v1/admin/accounts/search`;

export function getUsers(page = 1) {
  return async (dispatch) => {
    dispatch({ type: UsersActions.GET_PENDING });
    try {
      const { data } = await api(`${USERS_BASE_API}`, {
        params: toURLSearchParams({ page }),
      });
      dispatch({
        type: UsersActions.GET_SUCCEEDED,
        payload: data,
      });
      return data;
    } catch (err) {
      dispatch({ type: UsersActions.GET_FAILED });
      return { error: 'An internal server error occurred' };
    }
  };
}

export function queryUsers(form = {}, page = 1) {
  return async (dispatch) => {
    dispatch({ type: UsersActions.QUERY_PENDING });
    try {
      const { data } = await api(`${USERS_SEARCH_API}`, {
        params: toURLSearchParams({ ...form, page }),
      });
      dispatch({
        type: UsersActions.QUERY_SUCCEEDED,
        payload: data,
      });
      return data;
    } catch (err) {
      dispatch({ type: UsersActions.QUERY_FAILED });
      return { error: 'An internal server error occurred' };
    }
  };
}

const INITIAL_STATE = {
  users: [],
  meta: null,
  isUsersLoading: false,
};

const usersReducer = produce((draft, action) => {
  switch (action.type) {
    case 'app/RESET': {
      return INITIAL_STATE;
    }

    // Get All
    case UsersActions.GET_PENDING: {
      draft.isUsersLoading = true;
      draft.users = [];
      break;
    }
    case UsersActions.GET_SUCCEEDED: {
      const { results, meta } = action.payload;
      draft.isUsersLoading = false;
      draft.users = results;
      draft.meta = meta;
      break;
    }

    case UsersActions.GET_FAILED: {
      draft.isUsersLoading = false;
      draft.users = [];
      draft.meta = null;
      break;
    }
    // Query
    case UsersActions.QUERY_PENDING: {
      draft.isUsersLoading = true;
      draft.users = [];
      break;
    }
    case UsersActions.QUERY_SUCCEEDED: {
      const { results, meta } = action.payload;
      draft.isUsersLoading = false;
      draft.users = results;
      draft.meta = meta;
      break;
    }

    case UsersActions.QUERY_FAILED: {
      draft.isUsersLoading = false;
      draft.users = [];
      draft.meta = null;
      break;
    }

    default:
      break;
  }
}, INITIAL_STATE);

export default usersReducer;
