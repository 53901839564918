import { useState } from 'react';
import { UserForm } from '../UserForm/UserForm';
import { ModerationNewUserForm } from '../ModerationNewUserForm/ModerationNewUserForm';
import { useModerationUserAccount } from '../../hooks/useModerationUserAccount';

export const ApproveAccountRequestPopup = ({ accountId, onClose }) => {
  const { userAccount, loading: loadingUser } = useModerationUserAccount(accountId);
  const [showCommunitiesForm, setShowCommunitiesForm] = useState(false);

  const handleFormSuccess = () => {
    if (userAccount && userAccount.role === 'community_manager') {
      setShowCommunitiesForm(true);
    } else {
      onClose(true);
    }
  };

  const handleCloseCommunitiesForm = () => {
    setShowCommunitiesForm(false);
    onClose(true);
  };

  return (
    <>
      <ModerationNewUserForm id={accountId} onSuccess={handleFormSuccess} />
      {showCommunitiesForm ? (
        <UserForm
          isLoading={loadingUser}
          editUser={false}
          editCommunities={true}
          user={userAccount}
          onClose={handleCloseCommunitiesForm}
        />
      ) : null}
    </>
  );
};
