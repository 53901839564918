import {
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import { useState } from 'react';
import { useOrganizationsInfinite } from '../../hooks/useOrganizationsInfinite';
import { useSnackbar } from 'notistack';
import { createOrganization } from '../../services/organizations';
import styles from './AssignPropertyConfirmation.module.scss';
import { assignProperty } from '../../services/properties';
import Button from '../Button/Button';

const OrganizationOrigin = {
  EXISTING: 'EXISTING',
  NEW: 'NEW',
};

export const AssignPropertyConfirmation = ({ property, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);
  const {
    organizations,
    loading: organizationLoading,
    loadMore,
  } = useOrganizationsInfinite(true);
  const [origin, setOrigin] = useState(OrganizationOrigin.EXISTING);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(
    property ? property.organization?.id : null
  );
  const [newOrganizationName, setNewOrganizationName] = useState('');

  const isInvalid = () => {
    if (origin === OrganizationOrigin.NEW) {
      if (!newOrganizationName) {
        enqueueSnackbar(`New Organization Name shouldn't be empty.`);
        return true;
      }
    } else {
      if (!selectedOrganizationId) {
        enqueueSnackbar(`Should select an Organization.`);
        return true;
      }
    }
    return false;
  };

  const handleSaveChanges = async () => {
    if (isInvalid()) return;

    try {
      setIsSaving(true);
      let org = { ...selectedOrganizationId };
      if (origin === OrganizationOrigin.NEW) {
        let formData = new FormData();
        formData.append('name', newOrganizationName);
        org = await createOrganization(formData);
      }

      const orgId = origin === OrganizationOrigin.NEW ? org.id : selectedOrganizationId;
      const data = await assignProperty(property.id, orgId);
      if (data) {
        enqueueSnackbar(`Property Assigned.`, { variant: 'success' });
        onClose(true);
      } else {
        enqueueSnackbar(`Failed to assign property to organization`, {
          variant: 'error',
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleOrganizationSelectScroll = (event) => {
    const listEl = event.currentTarget;
    const scrolledToBottom =
      listEl.scrollTop + listEl.clientHeight >= listEl.scrollHeight;
    if (scrolledToBottom) {
      loadMore();
    }
  };

  const handleOriginRadioChange = (event) => {
    const selectedOrigin = event.target.value;
    if (selectedOrigin !== origin) {
      setOrigin(selectedOrigin);
    }
  };

  const handleSelectOrganization = ({ target: { value } }) => {
    setSelectedOrganizationId(value);
  };

  return (
    property && (
      <div className={styles.assignPropertyConfirmationContent}>
        {/* Organization Select */}
        {/* New organization name */}
        <div className={styles.header}>
          <h4>{`${property.organization ? 'Reassign' : 'Assign'} ${property.name}`}</h4>
        </div>
        <div className={`${styles.organizationOriginWrapper} ${styles.fieldGroup}`}>
          <label>Organization</label>
          <FormControl component='fieldset'>
            <RadioGroup
              aria-label='care-type'
              name='radio-buttons-group'
              className={styles.originRadioGroup}
              onChange={handleOriginRadioChange}
              value={origin}
              color='black'
            >
              <FormControlLabel
                value={OrganizationOrigin.EXISTING}
                control={<Radio />}
                label='Select existing'
              />
              <FormControlLabel
                value={OrganizationOrigin.NEW}
                control={<Radio />}
                label='Create new'
              />
            </RadioGroup>
          </FormControl>

          {origin === OrganizationOrigin.EXISTING ? (
            <FormControl
              sx={{
                width: '100%',
              }}
              fullWidth
            >
              <InputLabel id='organization-origin-checkbox-label'>
                Select Organization
              </InputLabel>
              <Select
                required
                labelId='organization-origin-checkbox-label'
                name='organizationId'
                disabled={organizationLoading}
                value={selectedOrganizationId || ''}
                onChange={handleSelectOrganization}
                input={<OutlinedInput label='Organization Origin' />}
                MenuProps={{
                  PaperProps: {
                    onScroll: handleOrganizationSelectScroll,
                    sx: {
                      maxHeight: '300px',
                    },
                  },
                }}
              >
                {organizations?.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <input
              required
              name='organizationName'
              type='text'
              placeholder='New Organization Name'
              value={newOrganizationName}
              onChange={({ target: { value } }) => setNewOrganizationName(value)}
            />
          )}
        </div>
        <div className={styles.bottomButtons}>
          <Button.BLACK_OUTLINED onClick={onClose}>Cancel</Button.BLACK_OUTLINED>
          <Button.BLACK onClick={handleSaveChanges}>
            {`${property.organization ? 'Reassign' : 'Assign'} Property`}
          </Button.BLACK>
        </div>
      </div>
    )
  );
};
