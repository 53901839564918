import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCopyableProperties } from '../../../redux/selectors/communityForm';

interface Sibling {
  id: number;
  care_type: string;
  display_name: string;
}

interface Option {
  label: string;
  value: number;
}

type CopyCommunitySelectProps = {
  onSelect: (value: string) => void;
};

const CopyCommunitySelect = ({ onSelect }: CopyCommunitySelectProps) => {
  const copyableSiblings = useSelector(selectCopyableProperties);
  const [siblingOptions, setSiblingOptions] = useState([]);
  const [selectedSiblingId, setSelectedSiblingId] = useState('');

  useEffect(() => {
    let newOptions = copyableSiblings
      ? copyableSiblings?.map((sibling: Sibling) => ({
          label: sibling.display_name,
          value: sibling.id,
        }))
      : [];

    setSiblingOptions(newOptions);
  }, [copyableSiblings]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedSiblingId(event.target.value);
    onSelect(event.target.value);
  };

  return siblingOptions.length === 0 ? (
    <Tooltip title={'No Copyable community in this property'}>
      <div>
        <Select disabled={true} value={''} />
      </div>
    </Tooltip>
  ) : (
    <FormControl focused={false} fullWidth>
      <InputLabel id='copy-community-label'>Select a community to copy</InputLabel>
      <Select
        value={selectedSiblingId || ''}
        onChange={handleChange}
        labelId='copy-community-label'
        label='Select a community to copy'
        id='copy-community'
      >
        {siblingOptions && siblingOptions.length > 0
          ? siblingOptions.map((option: Option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );
};

export default CopyCommunitySelect;
