import useSWR from 'swr';
import api from '../services/api';

export const useAssessmentResponses = (
  propertyId: string | number | null | undefined
) => {
  const {
    data: responses,
    isValidating,
    error,
  } = useSWR(
    propertyId
      ? `/v1/admin/properties/${propertyId}/surveys/completed_assessments`
      : null,
    api,
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      revalidateIfStale: false,
      shouldRetryOnError: false,
    }
  );

  const data = responses ? responses.data.results : null;

  return {
    loading: isValidating,
    data,
    meta: responses?.data.meta ? responses.data.meta : null,
    error,
  };
};
