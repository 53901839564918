export const CARE_TYPE_DESCRIPTIONS = {
  I: 'Independent Living',
  A: 'Assisted Living',
  C: 'Continuing Care',
  M: 'Memory Care',
  N: 'Skilled Nursing',
};

export const CARE_TYPE_ABBREVIATIONS = {
  I: 'IL',
  A: 'AL',
  M: 'MC',
  N: 'SNF',
};
