import api from '../../../services/api';
import moment from 'moment';
import styles from './AdminNotes.module.scss';
import Button from '../../Button/Button';
import { faXmarkLarge } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Utilities, Constants } from 'web-core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadAdminNotesThread } from '../../../hooks/useAddCommunityNotes';
import { Avatar, Select, InputLabel, MenuItem, FormControl } from '@mui/material';
import { loadAdminNotesForCommunity } from '../../../redux/reducers/adminNotes';
import { isBuildingOrUnitTypeTab } from '../../../utils/communityFormUtils';

const stageOptions = [
  { label: 'Web', value: 'website' },
  { label: 'Phone', value: 'phone' },
  { label: 'Email', value: 'email' },
];

const PostNoteSection = ({
  title,
  handleOnClose,
  section,
  stageValue,
  user,
  approvedCommunityId,
  arrayItemId,
}) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');
  const [newPost, setNewPost] = useState(false);
  const [selectedValue, setSelectedValue] = useState(stageValue);
  const sectionValue = section.split('|').slice(0, 3).join('|');
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.role === Constants.AccountRoles.Admin) {
      loadAdminNotesThread(approvedCommunityId, sectionValue).then((response) => {
        // Filtering out other unit types / buildings admin notes
        isBuildingOrUnitTypeTab(section)
          ? setData(response.results.filter((note) => note.array_item_id === arrayItemId))
          : setData(response.results);
      });
    }
  }, [approvedCommunityId, newPost, sectionValue, user.role, section]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handlePostNote = async () => {
    let params = isBuildingOrUnitTypeTab(section)
      ? {
          message: value,
          section: sectionValue,
          stage: selectedValue,
          array_item_id: arrayItemId,
        }
      : {
          message: value,
          section: sectionValue,
          stage: selectedValue,
        };
    await api
      .post(`/v1/admin/communities/${approvedCommunityId}/admin_notes`, params)
      .then((response) => {})
      .catch((error) => {
        console.log('Error posting note >>', error);
        return null;
      });
    dispatch(loadAdminNotesForCommunity(approvedCommunityId, true));
    setValue('');
    setNewPost(!newPost);
    return null;
  };

  return (
    <>
      <div className={styles.postNotePopup}>
        <div className={styles.closeIcon}>
          <FontAwesomeIcon icon={faXmarkLarge} onClick={handleOnClose} color='#ac947a' />
        </div>
        <h3 className={styles.title}>{`${title} notes`}</h3>
        <p>Notes are shared across all care levels. Be sure to specify if a note applies only to a single type.</p>
        <textarea
          name='post-message'
          rows='1'
          value={value}
          placeholder='Add your note'
          onChange={handleChange}
        />
        <div className={styles.containerPostMessage}>
          <FormControl>
            <InputLabel id='stage-type'>Stage</InputLabel>
            <Select
              sx={{
                width: '635px',
                marginRight: '1px',
              }}
              labelId='stage-type'
              id='duration-select'
              value={selectedValue || stageValue}
              label='Stage'
              onChange={handleSelectChange}
            >
              {stageOptions.map((elem, index) => (
                <MenuItem key={index} value={elem.value}>
                  {elem.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={styles.containerButtonPost}>
            <Button.DARK disabled={!selectedValue || !value} onClick={handlePostNote}>
              post note
            </Button.DARK>{' '}
          </div>
        </div>
        <div className={styles.containerPrincipalHistory}>
          {data && data.length === 0 ? (
            <p>{`No admin notes have been added for ${title}`}</p>
          ) : (
            data.map((elem, index) => (
              <div className={styles.containerHistory} key={index}>
                <Avatar
                  className={styles.avatarContainer}
                  {...Utilities.lettersInsideAvatar(elem.account?.name)}
                />
                <div>
                  <div className={styles.adminTitleContainer}>
                    <p
                      className={styles.titleNote}
                    >{`${elem.account?.name} | ${elem.stage}`}</p>
                  </div>
                  <p className={`${styles.dateNote} ml-50`}>
                    {' '}
                    {moment(elem.created_at).format('MM/DD/YYYY')}
                  </p>
                  <p className={`${styles.messageNote} ml-50`}>{elem.message}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default PostNoteSection;
