import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  changeImagesField,
  deleteCommunityImage,
  uploadCommunityImages,
} from '../../../../redux/reducers';
import KthPopup from '../../../KthPopup/KthPopup';
import Button from '../../../Button/Button';
import Loader from '../../../Loader/Loader';
import styles from '../../TabContent/TabContent.module.scss';
import { PhotoRow } from './PhotoRow';
import { useSnackbar } from 'notistack';

const PhotosTab = () => {
  const images = useSelector((state) => state.communityForm.images);
  const isUploadingImage = useSelector((state) => state.communityForm.isUploadingImage);
  const isReadOnly = useSelector((state) => state.communityForm.isFormReadOnly);
  const [deleteConfirm, setDeleteConfirm] = useState({ isOpen: false, ImageId: null });
  const photoInputRef = useRef(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [dataImages, setDataImages] = useState(images);

  useEffect(() => {
    if (images !== dataImages) {
      setDataImages(images);
    }
  }, [images, dataImages]);

  const columns = useMemo(
    () => [
      {
        title: '',
        styleProps: {
          width: '85px',
        },
      },
      {
        title: '',
      },
    ],
    []
  );

  const handlePhotoSelect = async (event) => {
    const fileSizeLimit = 20 * 1024 * 1024;
    if (event.target.files?.length) {
      const files = Object.values(event.target.files);
      const sizeCheck = files.every((e) => e.size < fileSizeLimit);
      if (sizeCheck) {
        const upload = await dispatch(uploadCommunityImages(files)).then((response) => {
          return response;
        });
        if (upload.error) {
          enqueueSnackbar(
            `Error: ${upload.error}. Please retry your upload or contact us if the issue persists.`,
            {
              variant: 'default',
            }
          );
        }
      } else {
        enqueueSnackbar('Please upload a file / files smaller than 20 MB', {
          variant: 'default',
        });
      }
    }
  };

  const showDeleteForm = (id) => {
    setDeleteConfirm({ isOpen: !deleteConfirm.isOpen, imageId: id });
  };

  const handlePhotoDelete = async (photoId) => {
    const deleted = await dispatch(deleteCommunityImage(photoId)).then((response) => {
      return response;
    });
    if (deleted.errors) {
      return;
    } else {
      const sortedImages = dataImages
        .filter((photo) => photo.id !== photoId)
        .map((photo, index) => ({
          ...photo,
          sort_order: index,
        }));
      dispatch(changeImagesField(sortedImages));
      setDeleteConfirm({ isOpen: false, imageId: null });
    }
  };

  const handlePhotoChange = (modifiedPhoto) => {
    const imagesModified = dataImages.map((photo) =>
      photo.id === modifiedPhoto.id ? modifiedPhoto : photo
    );
    dispatch(changeImagesField(imagesModified));
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let newArrOfImages = Array.from(dataImages);
    let [images] = newArrOfImages.splice(e.source.index, 1);
    newArrOfImages.splice(e.destination.index, 0, images);
    const itemsSorted = newArrOfImages.map((photo, index) => ({
      ...photo,
      sort_order: index,
    }));
    setDataImages(itemsSorted);
    dispatch(changeImagesField(itemsSorted));
  };

  const photoList = (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className={styles.table}>
          {columns.map((col, index) => (
            <div
              key={index}
              className={styles.columnHeader}
              style={col.styleProps || null}
            >
              {col.title}
            </div>
          ))}
          <Droppable droppableId='droppable-1'>
            {(provider) => (
              <div
                className={styles.tableBody}
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {dataImages?.map((photo, index) => (
                  <Draggable key={photo.id} draggableId={String(photo.id)} index={index}>
                    {(provider, snapshot) => (
                      <PhotoRow
                        key={photo.id}
                        ref={provider.innerRef}
                        {...provider.draggableProps}
                        {...provider.dragHandleProps}
                        innerRef={provider.innerRef}
                        dragProps={provider.draggableProps}
                        dragHandleProps={provider.dragHandleProps}
                        className={snapshot.isDragging ? 'selected' : 'not-selected'}
                        photo={photo}
                        onDelete={showDeleteForm}
                        onChangePhoto={handlePhotoChange}
                      />
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );

  return (
    <>
      <form id='photosForm' onSubmit={(ev) => ev.preventDefault()}>
        <fieldset disabled={isReadOnly} form='photosForm'>
          <div className={styles.photosTab}>
            {!isReadOnly ? (
              <section className={styles.formSection}>
                <div className={styles.sectionHeader}>
                  <span className={styles.sectionTitle}>Photo Upload</span>
                </div>
                <div className={styles.photoUpload}>
                  {isUploadingImage ? (
                    <div className={styles.uploadLoaderImage}>
                      <Loader isLoading={isUploadingImage} />
                    </div>
                  ) : (
                    <>
                      <h3>Drag & Drop Community Photos Here</h3>
                      <h4>
                        E.g. Building Exterior, Lobby, Dining, Menus, Activity Calendars,
                        Amenities, etc.
                      </h4>
                      <hr />
                      <Button.LIGHT>Browse your computer</Button.LIGHT>
                      <input
                        ref={photoInputRef}
                        type='file'
                        multiple
                        name='photoInput'
                        accept='image/*, application/pdf'
                        onChange={handlePhotoSelect}
                      />
                    </>
                  )}
                </div>
              </section>
            ) : null}
            <section className={styles.formSection}>
              <div className={styles.sectionHeader}>
                <span className={styles.sectionTitle}>Uploads</span>
              </div>
              {photoList}
            </section>
          </div>
        </fieldset>
      </form>
      <KthPopup
        isOpen={deleteConfirm.isOpen}
        title={`Delete Community Image`}
        confirmButtonLabel={'Delete Community Image'}
        onConfirmButtonClick={() => handlePhotoDelete(deleteConfirm.imageId)}
        onClose={() => setDeleteConfirm({ isOpen: false, imageId: null })}
      >
        <label>
          Are you sure you want to delete this community image?{' '}
          <strong>(CANT UNDO)</strong>
        </label>
      </KthPopup>
    </>
  );
};

export default PhotosTab;
