import styles from './BookTour.module.scss';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { Select, TextField, InputLabel, MenuItem, FormControl } from '@mui/material';
import { useEffect, useState } from 'react';
import { convertDurationStrToMinutes, toTimeZone } from '../../utils/bookedToursUtils';
import { useSnackbar } from 'notistack';
import KthPopup from '../KthPopup/KthPopup';
import api from '../../services/api';
import { APITools } from 'web-core';

const BookTour = ({ request, community, open, setOpenBookTour }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(null);
  const [duration, setDuration] = useState(null);
  const [tomorrow, setTomorrow] = useState(null);

  useEffect(() => {
    let today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    setTomorrow(tomorrow);
  }, []);

  const durationMinutes = () => {
    let arrayOfMinutes = [];
    let h = 0;
    let m = 0;
    for (let i = 0; i < 4; i++) {
      for (m = (m + 30 - (m % 30)) % 60; m < 60; m = m + 30) {
        arrayOfMinutes.push(`${h}:${m}`);
      }
      h = (h + 1) % 24;
      arrayOfMinutes.push(`${h}:00`);
    }
    return arrayOfMinutes;
  };
  const durationOptions = durationMinutes();

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (e) => {
    setDuration(e.target.value);
  };

  const handleClose = () => {
    setValue(null);
    setDuration('');
    setOpenBookTour(false);
  };

  const handleScheduleTour = async () => {
    try {
      let params = {
        account_id: request.account_id,
        community_journey_state_id: request.community_journey_state_id,
        scheduled_at: toTimeZone(value, community.timezone),
        duration_in_minutes: convertDurationStrToMinutes(duration),
      };
      const { data } = await api.post(`/v1/admin/booked_tours`, params);
      setOpenBookTour(false);
      setValue(null);
      setDuration('');
      enqueueSnackbar(`Tour booked!`, { variant: 'success' });
    } catch (err) {
      if (!community.timezone) {
        enqueueSnackbar(`Time zone data missing.`, {
          variant: 'error',
        });
      } else {
        let errorsToDisplay = APITools.errorMessageFromServerResponse(err);
        enqueueSnackbar(`${errorsToDisplay}`, {
          variant: 'error',
        });
      }
    }
  };

  return (
    <KthPopup
      isOpen={open}
      isLoading={false}
      title={`Schedule a Tour of ${community.name} for ${request.account.name}`}
      confirmButtonLabel={'Schedule a tour'}
      onConfirmButtonClick={handleScheduleTour}
      onClose={handleClose}
      isConfirmButtonDisabled={!value || !duration}
    >
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className={styles.datePicker}>
            <DatePicker
              label='Tour Date'
              inputFormat='MM/dd/yyyy'
              value={value}
              onChange={handleChange}
              disablePast
              disableHighlightToday
              minDate={tomorrow}
              renderInput={(params) => (
                <TextField className={styles.datePickerInput} {...params} />
              )}
            />
          </div>
          <div className={styles.timePicker}>
            <TimePicker
              label='Time'
              value={value}
              ampm={false}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <p className={`p4 ${styles.timeZoneText}`}>
              Time zone: {community.timezone ?? '-'}
            </p>
          </div>
          <div className={styles.duration}>
            <FormControl fullWidth>
              <InputLabel id='duration-label'>Duration</InputLabel>
              <Select
                labelId='duration-label'
                id='duration-select'
                value={duration}
                label='Duration'
                onChange={handleSelectChange}
              >
                {durationOptions.map((elem, index) => (
                  <MenuItem key={index} value={elem}>
                    {elem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </LocalizationProvider>
      </div>
    </KthPopup>
  );
};

export default BookTour;
