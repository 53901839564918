import { useDispatch, useSelector } from 'react-redux';
import { formDataChange } from '../../../../redux/reducers';
import DynamicForm from '../../../DynamicForm/DynamicForm';
import { EmptyBox } from '../../../EmptyBox/EmptyBox';
import { CheckboxesSelection } from '../../CheckboxesSelection/CheckboxesSelection';

const ServicesTab = () => {
  const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const showOverlay = useSelector((state) => state.communityForm.showOverlay);
  const formData = useSelector((state) => state.communityForm.formData);
  const dispatch = useDispatch();

  const handleFormDataChange = ({ formData }) => {
    dispatch(formDataChange(formData));
  };

  const hasSomeFieldVisible = () => {
    const servicesCheckboxesFields = Object.values(
      uiSchema['checkboxesSelection']['Services']
    ).map((sectionFields) => sectionFields);
    const servicesCheckboxesFieldsFlat = servicesCheckboxesFields.flat();
    return servicesCheckboxesFieldsFlat.some((field) => formData[field]);
  };

  return (
    <>
      <CheckboxesSelection
        titleHeaderOpen='Edit Services Details'
        titleHeaderClosed='Edit Services Details'
        openInstructionsMessage='Select the general services available at this Community. You can add details to each service after selecting all of them.'
        checkboxesContext='Services'
        hasSelectionChecked={hasSomeFieldVisible()}
      />
      {hasSomeFieldVisible() && !showOverlay ? (
        <DynamicForm
          schema={jsonSchema}
          uiSchema={uiSchema}
          formData={formData}
          onChange={handleFormDataChange}
          onSubmit={() => console.log('submitted')}
          onError={() => console.log('errors')}
          formContext={{ currentTab: 'Services', hideRowTitle: true }}
        />
      ) : !hasSomeFieldVisible() && !showOverlay ? (
        <EmptyBox
          message={`Click the button above to select which services are present at this community at a high level. Services checked as being offered/available will be included in the next step of the form. You can modify these selections at any time by clicking the button at the top of this section.`}
        />
      ) : null}
    </>
  );
};

export default ServicesTab;
