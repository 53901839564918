import { Route, Switch, Redirect } from 'react-router-dom';
import { HealthPage } from '../pages/health/health';
import Auth from '../pages/auth/Auth';

const AuthRoutes = () => {
  return (
    <Switch>
      <Route path='/auth/'>
        <Auth />
      </Route>
      <Route exact path='/health'>
        <HealthPage />
      </Route>
      <Redirect from='/' to='/auth/' />
    </Switch>
  );
};

export default AuthRoutes;
