import useSWR from 'swr';
import api from '../services/api';

export const useProperty = (propertyId: string | number | null | undefined) => {
  const { data, error, isValidating, mutate } = useSWR(
    propertyId ? `/v1/admin/properties/${propertyId}` : null,
    api,
    {
      errorRetryCount: 3,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );
  const response = data ? data.data : null;
  return {
    data: response,
    error,
    loading: isValidating,
    mutate,
  };
};
