import { useMemo } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useFlexLayout, useResizeColumns, useSortBy, useTable } from 'react-table';
import { Constants } from 'web-core';
import { EmptyBox } from '../../../EmptyBox/EmptyBox';
import Button from '../../../Button/Button';
import styles from '../../TabContent/TabContent.module.scss';

const UnitsTable = ({ units, onRowClick }) => {
  // Its a map of { id: buildingName }
  const buildingsMap = useSelector((state) => {
    return state.communityForm.formData.buildings
      ? state.communityForm.formData.buildings
          .filter((b) => b.id)
          .reduce((acc, building) => {
            return {
              ...acc,
              [building.id]: building[Constants.DynamicSchemaFields.BuildingName],
            };
          }, {})
      : [];
  });
  const modelsMap = useSelector((state) => {
    return state.communityForm.formData.models
      ? state.communityForm.formData.models
          .filter((m) => m.id)
          .reduce((acc, model) => {
            return {
              ...acc,
              [model.id]: model[Constants.DynamicSchemaFields.ModelName],
            };
          }, {})
      : [];
  });
  const defaultColumn = useMemo(
    () => ({
      minWidth: 100, // minWidth is only used as a limit for resizing
      width: 150,
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        className: 'pl-50',
        accessor: Constants.DynamicSchemaFields.UnitName,
        Cell: ({ value }) => <div className='pl-50'>{value}</div>,
      },
      {
        Header: 'Building',
        accessor: Constants.DynamicSchemaFields.UnitBuildingId,
        Cell: ({ value }) => <div>{buildingsMap[value] || '-'}</div>,
      },
      {
        Header: 'Unit Type',
        accessor: Constants.DynamicSchemaFields.UnitModelType,
        Cell: ({ value }) => <div>{modelsMap[value] || '-'}</div>,
      },
      {
        Header: 'Availability',
        accessor: Constants.DynamicSchemaFields.UnitAvailability,
        Cell: ({ value }) => <div className='pl-50'>{value}</div>,
      },
      {
        Header: 'Edit',
        width: 100,
        className: 'pl-100',
        Cell: ({ value, row }) => (
          <Button.DARK onClick={() => onRowClick(row.index)}>Edit</Button.DARK>
        ),
      },
    ],
    []
  );

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    rows, // rows for the table based on the data passed
    // page, // Instead of using 'rows', we'll use page,
  } = useTable(
    {
      columns,
      data: units,
      defaultColumn,
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy
  );

  return (
    <>
      <table {...getTableProps()} className={styles.reactTable}>
        <thead>
          <tr>
            {headerGroups[0].headers.map((column) => (
              <th
                key={column.Header.toString()}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={column.className || ''}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted ? (
                    <FontAwesomeIcon
                      icon={column.isSortedDesc ? faChevronUp : faChevronDown}
                    ></FontAwesomeIcon>
                  ) : null}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <UnitRow
                key={row.id}
                onClick={() => onRowClick(row.index)}
                row={row}
                {...row.getRowProps()}
              />
            );
          })}
        </tbody>
      </table>
      {!rows || rows.length === 0 ? (
        <EmptyBox
          message={`This community currently has no Units. Please click on the '+' button to add one.`}
        />
      ) : null}
    </>
  );
};

const UnitRow = ({ row, onClick }) => {
  return (
    <tr className={styles.rowItem} onClick={onClick}>
      {row.cells.map((cell) => (
        <td key={cell.column.id + cell.row.id} {...cell.getCellProps()}>
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  );
};

export default UnitsTable;
