import { useState, useEffect } from 'react';
import styles from './SquaredCheckbox.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  formFieldChange,
  facilitiesOrServicesFieldChange,
} from '../../../../redux/reducers';

export const SquaredCheckbox = function ({
  id,
  value,
  onChange,
  label,
  className,
  ...props
}) {
  const dispatch = useDispatch();
  const { jsonSchema, uiSchema } = useSelector((state) => state.communityForm.schemas);
  const isFormUpdating = useSelector((state) => state.communityForm.isFormUpdating);
  const [valueToUpdate, setValueToUpdate] = useState(value);
  const formContext = props.formContext;

  const isInCheckBoxMenu = formContext && formContext.currentCheckboxes !== undefined;

  const fieldContent = formContext
    ? Object.fromEntries(
        Object.entries(jsonSchema.properties).filter(
          ([key, value]) =>
            key.includes(formContext.currentTab) &&
            value.title === label &&
            value.type === 'boolean'
        )
      )
    : undefined;

  const fieldKey = fieldContent ? Object.keys(fieldContent) : [];

  let isRow = false;

  if (formContext && formContext.currentTab && fieldKey && fieldKey.length > 0) {
    if (uiSchema.tabs[formContext.currentTab]) {
      const splitKey = fieldKey[0].split('|');
      const rows = uiSchema.tabs[formContext.currentTab][splitKey[1]]?.rows;
      if (rows) {
        rows.forEach((row) => {
          if (
            row.fields[0] === fieldKey[0] &&
            (formContext.currentTab === 'Facilities' ||
              formContext.currentTab === 'Services')
          ) {
            isRow = true;
          }
        });
      }
    }
  }
  useEffect(() => {
    if (
      isFormUpdating === true &&
      !isInCheckBoxMenu &&
      isRow &&
      valueToUpdate !== value &&
      fieldKey &&
      fieldKey.length > 0
    ) {
      if (valueToUpdate !== undefined) {
        dispatch(formFieldChange(fieldKey[0], valueToUpdate));
      }
    }
  }, [isFormUpdating]);

  const handleBooleanValueChange = () => {
    onChange(!Boolean(value));
  };

  const handleRowDeselect = () => {
    setValueToUpdate(!valueToUpdate);
    if (fieldKey && fieldKey[0]) {
      dispatch(facilitiesOrServicesFieldChange(fieldKey[0], !valueToUpdate));
    }
  };

  return (
    <div className={`${styles.squaredCheckbox} ${className || ''}`}>
      <input
        className={styles.checkboxInput}
        type='checkbox'
        checked={isRow ? valueToUpdate : Boolean(value)}
        id={id}
        name={id}
        onChange={isRow ? handleRowDeselect : doNothing}
      />
      <label
        className={styles.labelCheckbox}
        htmlFor={id}
        onClick={isRow ? handleRowDeselect : handleBooleanValueChange}
      />
      <label
        className={styles.labelText}
        htmlFor={id}
        onClick={isRow ? handleRowDeselect : handleBooleanValueChange}
      >
        {label}
      </label>
    </div>
  );
};

/* input onChange emptyFunction to avoid receiving console errors */
const doNothing = () => {};
