import styles from './EditAssessmentLinkPage.module.scss';
import React, { useState, useRef, useEffect, ChangeEvent, useMemo } from 'react';
import Button from '../Button/Button';
import { KWImage } from '../KWImage/KWImage';
import { AssessmentLinkProps } from '../../types/AssessmentLinkProps';
import api from '../../services/api';
import { useSnackbar } from 'notistack';
import { StringUtils, ScriptUtils } from 'web-core';
import PhotoUploader from '../PhotoUploader/PhotoUploader';
import { Image } from '../PhotoUploader/PhotoUploader';
import Popup from 'reactjs-popup';
import kthPopupStyles from '../../components/KthPopup/KthPopup.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faPencil } from '@fortawesome/pro-thin-svg-icons';
import { FeatureGating } from '../FeatureGating/FeatureGating';
import { Tooltip } from '@mui/material';

type Opts<E extends string | number> = { [P in E]: string };

const ASSESSMENT_TYPE: Opts<string> = {
  Educational: 'educational',
  Financial: 'financial',
};
const LINK_STYLE = {
  Banner: 'banner',
  //  Button: 'button', add back once implemented
  TextLink: 'text_link',
};
const LINK_STYLE_NAMES = {
  [LINK_STYLE.Banner]: 'Banner',
  [LINK_STYLE.TextLink]: 'Link',
};
const PREVIEW_STYLE = {
  Desktop: 'desktop',
  Tablet: 'tablet',
  Mobile: 'mobile',
};
const COLUMN_VIEW = {
  oneColumn: {
    label: '1 Column',
    value: 'oneColumn',
  },
  twoColumns: {
    label: '2 Column',
    value: 'twoColumn',
  },
};

type BannerPageProps = {
  propertyId: string | number;
  propertySlug: string;
  assessmentLink: AssessmentLinkProps;
};

const DEFAULT_BANNER_IMAGE_URL =
  'https://images.prismic.io/kithward/b6831dce-85a6-4033-82ca-c6b18f4a3e5b_kithward-senior-living-virginia-chruchville.jpeg?auto=compress,format&rect=0,1007,4032,2016&w=2000&h=1000&fm=jpg&h=1000&q=50&fit=crop';

const EditAssessmentLinkPage: React.FC<BannerPageProps> = ({
  propertyId,
  propertySlug,
  assessmentLink,
}) => {
  // default setting of the page, snake patterned key field to match the backend columns
  const [preference, setPreference] = useState<AssessmentLinkProps>({
    name: assessmentLink.name,
    link_style: assessmentLink.link_style || LINK_STYLE.Banner,
    header: assessmentLink.header || '',
    subheader: assessmentLink.subheader || '',
    assessment_type: assessmentLink.assessment_type || ASSESSMENT_TYPE.Educational,
    banner_image_id: assessmentLink.banner_image_id,
    banner_image_url: assessmentLink.banner_image_url,
    overlay_color: assessmentLink.overlay_color || '#000000',
    text_color: assessmentLink.text_color || '#ffffff',
    button_text: assessmentLink.button_text || 'Take our assessment',
    button_color: assessmentLink.button_color || '#000000',
    button_text_color: assessmentLink.button_text_color || '#ffffff',
    location: assessmentLink.location || '',
    content_identifier: assessmentLink.content_identifier || '',
    is_draft: assessmentLink.is_draft,
  });
  const [previewMode, setPreviewMode] = useState<string>(PREVIEW_STYLE.Desktop);
  const [embedTarget, setEmbedTarget] = useState<string>(previewMode + 'embed');

  const [columnView, setColumnView] = useState<string>(COLUMN_VIEW.twoColumns.value);
  const [uploadButtonStyle, setUploadButtonStyle] = useState({
    display: 'block',
  });
  const [showPencilIcon, setShowPencilIcon] = useState(true);
  // adding a 'saved' flag to prevent unnecessary update calls, only set saved to false whenever there is changes to the preferences
  // basically just prevents the action of blurring out from input without changing the banner name calls update api
  const [saved, setSaved] = useState(true);
  const [loading, setLoading] = useState(false);

  // used for force refresh iframe after saving settings, may remove if found other approach
  // const [iframeKey, setIframeKey] = useState(0);
  const uploaderRef = useRef<HTMLInputElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  const refreshBannerPreview = () => {
    // @ts-ignore
    (function (w, o) {
      // @ts-ignore
      w[o] =
        // @ts-ignore
        w[o] ||
        function () {
          // @ts-ignore
          (w[o].q = w[o].q || []).push(arguments);
        };
    })(window, 'kwembed');

    // insert new instance of the banner preview whenever there is a change of the setting saved, issue: re-creating/loading
    // an iframe will be acting like a page refresh and the iframe may be flickering because of the reloading.
    // May need to figure other way to re-render the iframe which avoid this case
    // @ts-ignore
    if (LINK_STYLE.Banner === preference.link_style) {
      // @ts-ignore
      kwembed('banner', {
        environment: process.env.REACT_APP_ENVIRONMENT,
        guid: assessmentLink.uuid,
        target: embedTarget,
        preview: true,
      });
    } else if (LINK_STYLE.TextLink === preference.link_style) {
      // @ts-ignore
      kwembed('link', {
        environment: process.env.REACT_APP_ENVIRONMENT,
        guid: assessmentLink.uuid,
        target: embedTarget,
        preview: true,
      });
    }
  };

  const embedJSUrl =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'https://cdn.kithward.com/embed/v1.js'
      : process.env.REACT_APP_ENVIRONMENT === 'staging'
      ? 'https://cdn-staging.kithward.com/embed/v1.js'
      : `${process.env.REACT_APP_CONSUMER}/embed/v1.js`;

  // Load embed code js file - making sure it only is loaded once
  useEffect(() => {
    ScriptUtils.loadScript(embedJSUrl, refreshBannerPreview);
  }, []);

  const embedCode = useMemo(() => {
    if (assessmentLink && assessmentLink.uuid) {
      const envOption =
        process.env.REACT_APP_ENVIRONMENT === 'production'
          ? ''
          : `, environment: '${process.env.REACT_APP_ENVIRONMENT}'`;
      if (preference.link_style === LINK_STYLE.Banner) {
        let str = `<script charset="utf-8" type="text/javascript" src="${embedJSUrl}" async></script>\n`;
        str += `<script>\n`;
        str += `  (function (w,o) { w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; }(window, 'kwembed'));\n`;
        str += `  kwembed('banner', { guid: "${assessmentLink.uuid}"${envOption} });\n`;
        str += `</script>\n`;
        str += `<div id='kithwardBanner'></div>\n`;
        return str;
      } else if (preference.link_style === LINK_STYLE.TextLink) {
        let str = `<script charset="utf-8" type="text/javascript" src="${embedJSUrl}" async></script>\n`;
        str += `<script>\n`;
        str += `  (function (w,o) { w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; }(window, 'kwembed'));\n`;
        str += `  kwembed('link', { guid: "${assessmentLink.uuid}"${envOption} });\n`;
        str += `</script>\n`;
        str += `<span id='kithwardLink'></span>\n`;
        return str;
      }
    }
    return null;
  }, [assessmentLink, preference.link_style]);

  const copyEmbedCode = () => {
    if (embedCode) {
      navigator.clipboard.writeText(embedCode);
      enqueueSnackbar('Copied to clipboard!');
    }
  };

  // Banner Image
  const [file, setFile] = useState<File | null>(null);
  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files![0]);
  };
  const initialImage =
    preference.banner_image_id && preference.banner_image_url
      ? { id: preference.banner_image_id, url: preference.banner_image_url }
      : null;
  const [selectedImage, setSelectedImage] = useState<Image | null>(initialImage);
  const [showPhotosPopup, setShowPhotosPopup] = useState(false);

  const saveChangesToRemote = async (data: any) => {
    const apiUrl = `/v1/admin/properties/${propertyId}/assessment_links/${assessmentLink.uuid}`;

    try {
      setLoading(true);
      await api.patch(apiUrl, data);
      await setPreference((prevState) => ({
        ...prevState,
        is_draft: true,
      }));
      setLoading(false);
      refreshBannerPreview();
    } catch (e) {
      console.log('failed to update: ', e);
      setLoading(false);
    }
  };

  const handleBannerPhotoSelectClick = () => {
    setShowPhotosPopup(true);
  };

  const handleBannerImagePopupClose = async () => {
    setShowPhotosPopup(false);
    if (selectedImage && selectedImage.id !== preference.banner_image_id) {
      await setPreference((prevState) => ({
        ...prevState,
        banner_image_url: selectedImage.url,
        banner_image_id: selectedImage.id,
      }));
      await saveChangesToRemote({ banner_image_id: selectedImage.id });
    } else if (!selectedImage && preference.banner_image_id) {
      await setPreference((prevState) => ({
        ...prevState,
        banner_image_url: null,
        banner_image_id: null,
      }));
      await saveChangesToRemote({ banner_image_id: null });
    }
  };

  const handlePreferenceChange = (key: string, value: string | number) => {
    setPreference((current) => ({
      ...current,
      [key]: value,
    }));
    setSaved(false);
  };

  // Only needed for link_style changes
  useEffect(() => {
    handleSave();
  }, [preference.link_style]);

  const updatePreviewMode = (value: string) => {
    setPreviewMode(value);
    setEmbedTarget(value + 'embed');
  };

  useEffect(() => {
    refreshBannerPreview();
  }, [embedTarget]);

  const handleSave = async () => {
    if (!saved) {
      await saveChangesToRemote(preference);
    }
  };

  const handlePublish = async () => {
    try {
      setLoading(true);
      await api.patch(
        `/v1/admin/properties/${propertyId}/assessment_links/${assessmentLink.uuid}/publish`,
        preference
      );
      await setPreference((prevState) => ({
        ...prevState,
        is_draft: false,
      }));
      setLoading(false);
      enqueueSnackbar('Your changes have been published!');
    } catch (e) {
      setLoading(false);
      console.log('failed to publish: ', e);
      return null;
    }
  };

  useEffect(() => {
    setUploadButtonStyle({
      display: preference.banner_image_url ? 'none' : 'block',
    });
  }, [preference.banner_image_url]);

  useEffect(() => {
    // need to add a few more character lengths because of global setting of padding for all inputs
    if (preference.name) {
      nameInputRef.current!.style.width = `${preference.name.length + 4}ch`;
    }
  }, [preference.name]);

  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      nameInputRef.current!.blur();
    }
  };

  return (
    <>
      <div className={styles.bannerPage}>
        <div className={styles.editorSection}>
          <div className={styles.editorHeader}>
            <a
              className={styles.backwardLink}
              href={`/properties/${propertySlug}/assessment-links`}
            >
              <FontAwesomeIcon icon={faArrowLeft} className={styles.backwardArrow} />
            </a>
            <div
              className={styles.bannerName}
              onFocus={() => setShowPencilIcon(false)}
              onBlur={() => setShowPencilIcon(true)}
            >
              <input
                type='text'
                id='name'
                ref={nameInputRef}
                className={styles.bannerNameInput}
                value={preference.name}
                onChange={(e) => handlePreferenceChange(e.target.id, e.target.value)}
                onBlur={(e) => handleSave()}
                onKeyPress={(e) => handlePressEnter(e)}
              />
              <span
                className={`${styles.pencilIcon} ${showPencilIcon ? '' : styles.hidden}`}
              >
                <FontAwesomeIcon icon={faPencil} size={'2x'} width={20} color={'#FFF'} />
              </span>
            </div>
            <Tooltip
              title={
                preference.is_draft
                  ? 'Publish your changes to make your changes to this link live.'
                  : 'There are no unpublished changes for this link.'
              }
              arrow
            >
              <div>
                <Button.WHITE_OUTLINED
                  className='no-wrap-text-button'
                  disabled={!preference.is_draft}
                  onClick={handlePublish}
                  loading={loading}
                >
                  Publish
                </Button.WHITE_OUTLINED>
              </div>
            </Tooltip>
          </div>

          <div className={styles.editorBody}>
            <div className={styles.editor}>
              <div className={styles.linkStyleSelector}>
                {/*<h4>Style:</h4>*/}

                <div className={styles.linkStyle}>
                  {`${StringUtils.capitalizeFirstLetter(
                    preference.assessment_type
                  )} Assessment ${LINK_STYLE_NAMES[preference.link_style]}`}
                </div>
              </div>

              {preference.link_style === LINK_STYLE.Banner && (
                <>
                  {/* BANNER HEADER */}
                  <TitledTextarea
                    title={'Banner Header'}
                    placeholder={'Banner Header'}
                    value={preference.header}
                    onChange={(e) => handlePreferenceChange('header', e.target.value)}
                    onSave={handleSave}
                  />

                  {/* BANNER SUBHEADER */}
                  <TitledTextarea
                    title={'Banner Subheader'}
                    placeholder={'Banner Subheader'}
                    value={preference.subheader}
                    onChange={(e) => handlePreferenceChange('subheader', e.target.value)}
                    onSave={handleSave}
                  />

                  {/* BACKGROUND IMAGE */}
                  <div className={styles.backgroundImageUploader}>
                    <h4>Background Image</h4>

                    <div
                      className={styles.backgroundImageContainer}
                      onMouseEnter={() =>
                        preference.banner_image_url
                          ? setUploadButtonStyle({ display: 'block' })
                          : null
                      }
                      onMouseLeave={() =>
                        preference.banner_image_url
                          ? setUploadButtonStyle({ display: 'none' })
                          : null
                      }
                    >
                      {preference.banner_image_url ? (
                        <KWImage
                          src={preference.banner_image_url}
                          alt={'background-image'}
                        />
                      ) : (
                        <KWImage
                          src={DEFAULT_BANNER_IMAGE_URL}
                          alt={'background-image'}
                        />
                      )}
                      <Button.BLACK
                        style={uploadButtonStyle}
                        onClick={handleBannerPhotoSelectClick}
                      >
                        Select
                      </Button.BLACK>
                    </div>

                    {/* Overlay color picker */}
                    <div className={styles.colorPicker}>
                      <ColorPicker
                        id='overlay_color'
                        value={preference.overlay_color}
                        onChange={(event) =>
                          handlePreferenceChange(event.target.id, event.target.value)
                        }
                        onSave={handleSave}
                      />
                      <label>Background Overlay Color</label>
                    </div>

                    {/* Text color picker */}
                    <div className={styles.colorPicker}>
                      <ColorPicker
                        id='text_color'
                        value={preference.text_color}
                        onChange={(event) =>
                          handlePreferenceChange(event.target.id, event.target.value)
                        }
                        onSave={handleSave}
                      />
                      <label>Text Color</label>
                    </div>
                  </div>

                  {/* BUTTON STYLE */}
                  <div className={styles.buttonStyle}>
                    {/* Button text input */}
                    <h4>Button Style</h4>
                    <div className={styles.buttonText}>
                      <label>Text</label>
                      <SingleTextInput
                        title={''}
                        description={''}
                        value={preference.button_text}
                        onChange={(e) =>
                          handlePreferenceChange('button_text', e.target.value)
                        }
                        onSave={handleSave}
                      />
                    </div>

                    {/* Button color and Button text color picker */}
                    <div className={styles.buttonColorPicker}>
                      <div className={styles.colorPickerWrapper}>
                        <ColorPicker
                          id='button_color'
                          value={preference.button_color}
                          onChange={(event) =>
                            handlePreferenceChange(event.target.id, event.target.value)
                          }
                          onSave={handleSave}
                        />
                      </div>
                      <label>Background Color</label>
                    </div>
                    <div className={styles.buttonColorPicker}>
                      <div className={styles.colorPickerWrapper}>
                        <ColorPicker
                          id='button_text_color'
                          value={preference.button_text_color}
                          onChange={(event) =>
                            handlePreferenceChange(event.target.id, event.target.value)
                          }
                          onSave={handleSave}
                        />
                      </div>
                      <label>Text Color</label>
                    </div>
                  </div>
                </>
              )}
              {preference.link_style === LINK_STYLE.TextLink && (
                <div className={styles.buttonText}>
                  <SingleTextInput
                    title={'Link Text'}
                    description={''}
                    value={preference.button_text}
                    onChange={(e) =>
                      handlePreferenceChange('button_text', e.target.value)
                    }
                    onSave={handleSave}
                  />
                </div>
              )}

              {/* LOCATION */}
              <SingleTextInput
                title={'Location On Your Website'}
                description={`A short identifier to know which page this button will be on your website, e.g. "Home Page" or "Blog"`}
                value={preference.location}
                onChange={(e) => handlePreferenceChange('location', e.target.value)}
                onSave={handleSave}
              />

              {/* CONTENT IDENTIFIER */}
              <SingleTextInput
                title={'Content Identifier (Optional)'}
                description={`A short identifier to know which specific piece of content or place on a page this button will be located on, e.g. a specific blog post "2022 Fall Activities".`}
                value={preference.content_identifier || ''}
                onChange={(e) =>
                  handlePreferenceChange('content_identifier', e.target.value)
                }
                onSave={handleSave}
              />

              {/* SAVE BUTTON */}
              <Button.BLACK onClick={handleSave}>SAVE</Button.BLACK>
            </div>

            {/* PREVIEW / RIGHT SIDE SECTION */}
            <div className={styles.previewSection}>
              <div className={styles.previewHeader}>
                <h4>Preview</h4>
                <div className={styles.buttons}>
                  {Object.entries(PREVIEW_STYLE).map(([key, value], index) => (
                    <span
                      key={index}
                      onClick={() => updatePreviewMode(value)}
                      className={`${styles.styleButton} ${
                        previewMode === value ? styles.selected : ''
                      }`}
                    >
                      {key}
                    </span>
                  ))}
                </div>
              </div>

              <div className={styles.previewContent}>
                <div className={styles.title}>
                  <h5>Your website</h5>
                  {previewMode === PREVIEW_STYLE.Desktop && (
                    <div className={`${styles.buttons}`}>
                      {Object.entries(COLUMN_VIEW).map(([key, view]) => (
                        <span
                          key={key}
                          className={`${styles.styleButton} ${styles.columnButton} ${
                            columnView === view.value ? styles.selected : ''
                          }`}
                          onClick={() => setColumnView(view.value)}
                        >
                          {view.label}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
                {previewMode === PREVIEW_STYLE.Desktop && (
                  <div className={styles.contentsPanel}>
                    <div className={styles[columnView]}>
                      <div className={styles.topPanel}>Your website content</div>
                      <div
                        className={`${styles.mainPanel} ${
                          preference.link_style === LINK_STYLE.TextLink &&
                          styles.embeddedLinkPanel
                        }`}
                        id={PREVIEW_STYLE.Desktop + 'embed'}
                      ></div>
                      <div className={styles.rightPanel}>Your website content</div>
                    </div>
                  </div>
                )}
                {previewMode === PREVIEW_STYLE.Tablet && (
                  <div className={`${styles.contentsPanel} ${styles.tabletPanel}`}>
                    <div className={styles.oneColumn}>
                      <div className={styles.topPanel}>Your website content</div>
                      <div
                        className={`${styles.mainPanel} ${
                          preference.link_style === LINK_STYLE.TextLink &&
                          styles.embeddedLinkPanel
                        }`}
                        id={PREVIEW_STYLE.Tablet + 'embed'}
                      ></div>
                    </div>
                  </div>
                )}
                {previewMode === PREVIEW_STYLE.Mobile && (
                  <div className={`${styles.contentsPanel} ${styles.mobilePanel}`}>
                    <div className={styles.oneColumn}>
                      <div className={styles.topPanel}>Your website content</div>
                      <div
                        className={`${styles.mainPanel} ${
                          preference.link_style === LINK_STYLE.TextLink &&
                          styles.embeddedLinkPanel
                        }`}
                        id={PREVIEW_STYLE.Mobile + 'embed'}
                      ></div>
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.snippetSection}>
                <h4>
                  Add This {LINK_STYLE_NAMES[preference.link_style]} To Your Website
                </h4>
                <p>
                  Copy and paste this HTML snippet in the desired location in your
                  website. We recommend creating a unique banner or link for each
                  location.
                </p>
                <div className={styles.textareaInput}>
                  <textarea
                    style={{
                      height: '380px',
                    }}
                    readOnly
                    value={embedCode || ''}
                  />
                </div>
                <div className={styles.bottomButtons}>
                  <Button.BLACK style={{ marginRight: '24px' }} onClick={copyEmbedCode}>
                    Copy
                  </Button.BLACK>
                  {/*<Button.BLACK>Email</Button.BLACK>*/}
                  <FeatureGating>
                    <a
                      href={`${process.env.REACT_APP_CONSUMER}/banners/${assessmentLink.uuid}/preview`}
                      target={'_blank'}
                    >
                      <Button.BLACK>Preview</Button.BLACK>
                    </a>
                  </FeatureGating>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        open={showPhotosPopup}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
        lockScroll={true}
        onClose={handleBannerImagePopupClose}
      >
        <div className={kthPopupStyles.modal}>
          <div className={kthPopupStyles.header}>
            <h3 className={kthPopupStyles.largeTitle}>{'Banner Images'}</h3>
            <button
              className={kthPopupStyles.close}
              onClick={handleBannerImagePopupClose}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
            <div className={kthPopupStyles.addButton}>
              <Button.LIGHT
                className={styles.addButton}
                onClick={() => uploaderRef.current?.click()}
              >
                <FontAwesomeIcon icon={faPlus} width={16} size={'2x'} />
              </Button.LIGHT>
              <input
                ref={uploaderRef}
                name='fileInput'
                type='file'
                onChange={handleFileSelect}
              />
            </div>
          </div>
          <div className={kthPopupStyles.content}>
            <PhotoUploader
              propertyId={propertyId}
              selectedImage={selectedImage}
              file={file}
              setFile={setFile}
              setSelectedImage={setSelectedImage}
              setShowPhotosPopup={setShowPhotosPopup}
            />
          </div>
        </div>
      </Popup>
    </>
  );
};

type SingleTextInputProps = {
  title: string;
  description: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
};
const SingleTextInput: React.FC<SingleTextInputProps> = ({
  title,
  description,
  value,
  onChange,
  onSave,
}) => {
  return (
    <div className={styles.singleTextSection}>
      {title ? <h4>{title}</h4> : null}
      {description ? <p>{description}</p> : null}
      <input
        type={'text'}
        className={styles.textInput}
        value={value}
        onChange={onChange}
        onBlur={onSave}
      />
    </div>
  );
};

type ColorPickerProps = {
  id: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onSave: () => void;
};
const ColorPicker: React.FC<ColorPickerProps> = ({ id, value, onChange, onSave }) => {
  return (
    <input
      type='color'
      id={id}
      value={value}
      onChange={(event) => onChange(event, event.target.id)}
      onBlur={onSave}
      style={{
        padding: '6px',
      }}
    />
  );
};

type TitledTextareaProps = {
  title: string;
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSave: () => void;
};
const TitledTextarea: React.FC<TitledTextareaProps> = ({
  title,
  placeholder,
  value,
  onChange,
  onSave,
}) => {
  return (
    <div className={styles.textareaInput}>
      <h4>{title}</h4>
      <textarea
        cols={2}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onSave}
      />
    </div>
  );
};

export default EditAssessmentLinkPage;
