import { useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import {
  createOrganizationService,
  updateOrganizationService,
  deleteOrganizationService,
} from '../../services/organizationServices';
import Button from '../Button/Button';
import styles from '../OrganizationForm/OrganizationForm.module.scss';
import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';
import { APITools } from 'web-core';

const INITIAL_SERVICE = {
  name: '',
  description: '',
};

const extractAvailableInStatesFromService = (service) => {
  let stateGeoPlaceIds = [];

  if (service) {
    if (service.geo_places.length > 0) {
      service.geo_places.forEach((geo_place) => {
        stateGeoPlaceIds.push(geo_place.id);
      });
    }
  }
  return stateGeoPlaceIds;
};

export const EditServiceForm = ({ organizationId, service, geoPlaces, onClose }) => {
  const geoPlacesById = useMemo(() => {
    const idToObjMap = {};
    if (geoPlaces) {
      geoPlaces.forEach((geoPlace) => {
        idToObjMap[geoPlace.id] = geoPlace;
      });
    }
    return idToObjMap;
  }, [geoPlaces]);

  const [availableInStates, setAvailableInStates] = useState(
    extractAvailableInStatesFromService(service)
  );
  const [form, setForm] = useState(service || INITIAL_SERVICE);
  const isEditService = Boolean(!!service);
  const { enqueueSnackbar } = useSnackbar();

  const handleFieldChange = ({ target: { name, value } }) => {
    setForm((currentForm) => ({
      ...currentForm,
      [name]: value,
    }));
  };

  const handleStateAvailabilityChange = (event) => {
    // convert strings to ints when saving to form
    const modifiedServiceForm = {
      ...form,
      geo_place_ids: event.target.value.map((val) => parseInt(val)),
    };
    setAvailableInStates(event.target.value);
    setForm(modifiedServiceForm);
  };

  const handleSaveChanges = async () => {
    let formData = {
      name: form.name,
      description: form.description,
      geo_place_ids: form.geo_place_ids,
    };
    if (organizationId && !isEditService) {
      formData.organization_id = organizationId;
    }

    try {
      const response = isEditService
        ? await updateOrganizationService(service, formData)
        : await createOrganizationService(formData);
      if (response) {
        enqueueSnackbar(`Service ${isEditService ? 'saved' : 'created'} successfully`, {
          variant: 'success',
        });
      }
      onClose(true);
    } catch (err) {
      let errorsToDisplay = APITools.errorMessageFromServerResponse(err);
      enqueueSnackbar(errorsToDisplay, { variant: 'error' });
    }
  };

  const handleDeleteService = async () => {
    if (form.id && window.confirm(`Do you really want to delete "${form.name}"?`)) {
      await deleteOrganizationService(form.id);
      onClose(true);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSaveChanges();
  };

  return (
    <>
      <form className={styles.form}>
        <label>Name</label>
        <input name='name' type='text' value={form.name} onChange={handleFieldChange} />
        <label>Description</label>
        <textarea
          name='description'
          type='text'
          value={form.description}
          onChange={handleFieldChange}
        />
        <label>State Availability</label>
        <Select
          multiple
          fullWidth
          labelId='state-multiple-checkbox-label'
          id='state-multiple-checkbox-label'
          defaultValue={availableInStates}
          onChange={handleStateAvailabilityChange}
          label='State Availability'
          name='States'
          style={{ maxWidth: '49vh' }}
          renderValue={(selected) =>
            selected.map((geoPlaceId) => geoPlacesById[geoPlaceId].name).join(', ')
          }
        >
          {geoPlaces &&
            geoPlaces.map((geoPlace) => {
              return (
                <MenuItem key={geoPlace.name} value={geoPlace.id}>
                  <Checkbox checked={availableInStates.indexOf(geoPlace.id) > -1} />
                  <ListItemText primary={geoPlace.name} />
                </MenuItem>
              );
            })}
        </Select>
      </form>
      <div className={`flex-kebab-row ${styles.bottomButtons}`}>
        <Button.BLACK onClick={handleFormSubmit}>
          {isEditService ? 'Save Changes' : 'Create Service'}
        </Button.BLACK>

        {isEditService ? (
          <Button.TEXT onClick={handleDeleteService} className={styles.deleteButton}>
            Delete Service
          </Button.TEXT>
        ) : null}
      </div>
    </>
  );
};
