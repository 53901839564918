import axios, { AxiosError } from 'axios';
import { logout, catchError } from '../redux/reducers';
import { Cookies } from 'react-cookie';
import { CookieUtils } from 'web-core';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const CONFLICT = 409;
const SERVER_ERROR = 500;
const interceptorExceptions = [
  '/pending_change_request',
  '/assignable_properties',
  '/token',
  '/moderations',
];

export const setApiInterceptors = (store: any) => {
  // Response Interceptor

  // Function that will be called to refresh authorization
  const refreshAuthLogic = (failedRequest: any): Promise<any> => {
    const cookies = new Cookies();
    const refreshToken = CookieUtils.getKWRefreshToken(
      cookies,
      process.env.REACT_APP_ENVIRONMENT
    );
    if (refreshToken) {
      console.log('Attempting to refresh access token on 401');
      return api
        .post(`/v1/auth/token`, { refresh_token: refreshToken })
        .then((tokenRefreshResponse) => {
          // save updated token to cookie
          let accessToken = tokenRefreshResponse.data.meta.access_token;
          CookieUtils.setKWAccessTokenCookie(
            cookies,
            process.env.REACT_APP_ENVIRONMENT,
            accessToken
          );

          failedRequest.response.config.headers['Authorization'] =
            'Bearer ' + accessToken;
          return Promise.resolve();
        });
    } else {
      console.log('Not attempting to refresh access token on 401 - no refreshToken');
      return Promise.resolve();
    }
  };

  createAuthRefreshInterceptor(api, refreshAuthLogic);

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error: AxiosError) {
      if (error?.config?.url) {
        //check for exception endpoints (interceptorExceptions array) first, for cases where we do not want to respond to an error
        if (
          interceptorExceptions.some(
            (exception) => error?.config?.url && error?.config?.url.includes(exception)
          )
        ) {
          return;
        }
        // csv import errors are rendered on page instead of in snackbar, and are handled as a special case
        if (error.config.url.includes('data_import')) {
          return Promise.reject(error.response?.data?.errors);
        }
        if (
          error.config.url.includes('/v1/admin/communities/') &&
          error.config.method === 'get'
        ) {
          const pathCount = error.config.url.split('/').length - 1;
          // check if call returns 500 for a get/community req which would render an empty community form, redirect to server error page instead
          if (
            pathCount === 4 &&
            error?.response?.status &&
            error?.response?.status >= SERVER_ERROR
          ) {
            return store.dispatch(catchError({ ...error.response, redirect: 500 }));
            //show 403/Forbidden page if user attempts to view a community they do not have permission to edit
          } else if (
            pathCount === 4 &&
            error?.response?.status &&
            error?.response?.status === FORBIDDEN
          ) {
            return store.dispatch(catchError({ ...error.response, redirect: 403 }));
          }
        }
      }

      if (error?.response?.status === UNAUTHORIZED) {
        //logout user if 401 error is received from server
        console.log('Logging out user on 401 response from server');
        store.dispatch(logout(false, false));
      } else if (error?.response?.status === CONFLICT) {
        return Promise.reject({
          error: error.response?.status,
          results: error.response?.data?.results,
        });
      } else {
        //return error to render in snackbar/toast message
        store.dispatch(catchError(error.response));
        return Promise.reject(error.response?.data?.errors);
      }
    }
  );

  // Request Interceptor
  api.interceptors.request.use((config) => {
    if (!config.headers.Authorization) {
      const cookies = new Cookies();
      const accessToken = CookieUtils.getKWAccessToken(
        cookies,
        process.env.REACT_APP_ENVIRONMENT
      );
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers['X-Timezone-Offset'] = new Date().getTimezoneOffset();
      }
    }
    return config;
  });
};

export default api;
