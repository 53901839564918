import { CommunityEditsList } from '../../../components/CommunityEditsList/CommunityEditsList';
import HeaderTitle from '../../../components/HeaderTitle/HeaderTitle';

export const CommunityEditsPage = () => {
  // const handleSearchSubmit = (event) => {
  //   event.preventDefault();
  //   const form = new FormData(event.target);
  //   // dispatch(queryUsers(form));
  // }

  return (
    <>
      <HeaderTitle title={'Community Edits'} />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Community Edits</h1>
          </div>
          {/* <form className="search-container" onSubmit={handleSearchSubmit}>
            <input name="q" placeholder="Search Edit Requests"/>
            <Button.TEXT icon type="submit">{Icons.Search}</Button.TEXT>
          </form> */}
        </div>
        <CommunityEditsList />
      </div>
    </>
  );
};
