import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { formDataChange } from '../../../../redux/reducers';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DynamicForm from '../../../DynamicForm/DynamicForm';
import styles from './AdminTab.module.scss';
import { useSingleCommunityChanges } from '../../../../hooks/useCommunityQueuedChangesInfinite';
import Button from '../../../Button/Button';

const AdminTab = () => {
  // const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const userRole = useSelector((state) => state.auth.user.role);
  const formData = useSelector((state) => state.communityForm.formData);
  const [showJson, setShowJson] = useState(false);
  // const dispatch = useDispatch();

  const {
    data: communityLiveAndDraft,
    loading,
    error,
  } = useSingleCommunityChanges(formData.id);

  // const handleFormDataChange = ({ formData }) => {
  //   dispatch(formDataChange(formData));
  // };

  const PrettyPrintJson = ({ data, header }) => (
    <div className={styles.jsonColumn}>
      <h3>{header}</h3>
      <br />
      <pre>{JSON.stringify(data, null, '\t')}</pre>
    </div>
  );

  const arrowIcon = (
    <FontAwesomeIcon icon={showJson ? faChevronUp : faChevronDown} color='#ac947a' />
  );

  return userRole === 'admin' ? (
    <>
      {/* Temperately comment out the DynamicForm since there is only star rating row in admin tab,
          and we moved it to approved community lightbox
      */}
      {/*<DynamicForm*/}
      {/*  schema={jsonSchema}*/}
      {/*  uiSchema={uiSchema}*/}
      {/*  formData={formData}*/}
      {/*  onChange={handleFormDataChange}*/}
      {/*  onSubmit={() => console.log('submitted')}*/}
      {/*  onError={() => console.log('errors')}*/}
      {/*  formContext={{ currentTab: 'Admin' }}*/}
      {/*/>*/}
      {communityLiveAndDraft && (
        <div>
          <div className={styles.jsonHeader}>
            <span>Community Json Data</span>
          </div>
          <Button.LIGHT
            icon
            onClick={() => setShowJson(!showJson)}
            className={styles.loadMoreButton}
          >
            {showJson ? 'Hide Json Data' : 'Show Json Data'}
            {arrowIcon}
          </Button.LIGHT>
          {showJson && !loading && !error ? (
            <div className={styles.prettyJson}>
              <PrettyPrintJson
                data={communityLiveAndDraft.previousData}
                header={'Published Community'}
              />
              <PrettyPrintJson
                data={communityLiveAndDraft.newData}
                header={'Draft Community'}
              />
            </div>
          ) : showJson && !loading && error ? (
            <div>
              <h2>Error loading community json data. Please refresh the page.</h2>
            </div>
          ) : null}
        </div>
      )}
    </>
  ) : (
    <div className='w-40 ml-auto mr-auto'>
      <h2 className='w-90 ml-auto mr-auto'>Access Forbidden</h2>
      <h3>
        You are not logged in with an account with permission to edit content in this tab.
        If you are seeing this message in error, please refresh the page or visit the page
        after ensuring you have logged in with the correct credentials.
      </h3>
    </div>
  );
};

export default AdminTab;
