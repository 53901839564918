import { useState } from 'react';
import { useSelector } from 'react-redux';
import KthPopup from '../KthPopup/KthPopup';
import Button from '../Button/Button';
import styles from './PropertyClaimPopup.module.scss';
import CommunityList from '../CommunityList/CommunityList';
import CommunityNameCell from '../CommunityList/CommunityNameCell';
import { CARE_TYPE_DESCRIPTIONS } from '../../constants/CareType';

export const PropertyClaimPopup = ({
  property,
  onClose,
  onSubmitClaim,
  fromPropertyDashboard,
}) => {
  const user = useSelector((state) => state.auth.user);
  const [communitiesToClaim, setCommunitiesToClaim] = useState(
    property.communities?.map((community) => {
      if (community.community_claim_for_account === null) {
        return community.id;
      }
    }) || []
  );

  const handleBooleanChange = (community) => {
    if (!communitiesToClaim.includes(community.id)) {
      setCommunitiesToClaim([...communitiesToClaim, community.id]);
    } else if (communitiesToClaim.includes(community.id)) {
      setCommunitiesToClaim(communitiesToClaim.filter((id) => id !== community.id));
    }
  };
  const checkStatus = (claim, community) => {
    if (user.role === 'community_manager') {
      return claim === null
        ? 'Available'
        : claim && claim.status === 'rejected'
        ? 'Unavailable'
        : claim && claim.status === 'pending'
        ? 'Claim Pending'
        : claim && claim.status === 'approved'
        ? 'Claimed'
        : undefined;
    } else if (user.role === 'admin') {
      return 'Unavailable';
    } else {
      return user?.organization?.id === community?.organization?.id
        ? 'Claimed by Organization'
        : claim && claim.status === 'pending'
        ? 'Claim Pending'
        : claim && claim.status === 'rejected'
        ? 'Unavailable'
        : 'Available';
    }
  };

  const checkDisabled = (claim, community) => {
    if (user.role === 'community_manager') {
      return claim === null ? false : true;
    } else if (user.role === 'admin') {
      return true;
    } else {
      return user?.organization?.id === community?.organization?.id
        ? true
        : claim && claim.status === 'pending'
        ? true
        : claim && claim.status === 'rejected'
        ? true
        : false;
    }
  };

  const communityClaimable = (community) =>
    community?.community_claim_for_account === null;

  const disabledButton =
    property.communities?.filter(communityClaimable).length === 0 ||
    communitiesToClaim.length === 0 ||
    user.role === 'admin' ||
    (user.role === 'corporate_executive' &&
      user?.organization?.id === property?.organization?.id);

  const columns = [
    {
      Header: '',
      accessor: 'id',
      minWidth: 50,
      width: 50,
      maxWidth: 50,
      Cell: ({ row }) => {
        const claim = row?.original?.community_claim_for_account;
        return (
          <div className={styles.squaredCheckbox}>
            <input
              className={styles.checkboxInput}
              type='checkbox'
              id='community-claim'
              name='community-claim'
              checked={communitiesToClaim.includes(row.original.id)}
              disabled={checkDisabled(claim, row.original)}
              onChange={() => {}}
            />
            <label className={styles.labelCheckbox} htmlFor='community-claim' />
          </div>
        );
      },
    },
    {
      Header: 'Community',
      accessor: 'name',
      minWidth: 340,
      width: 340,
      maxWidth: 340,
      Cell: ({ row }) => <CommunityNameCell row={row} />,
    },
    {
      Header: 'Care Type',
      accessor: 'care_type',
      minWidth: 200,
      width: 200,
      maxWidth: 200,
      Cell: ({ row }) => (
        <div>
          <p>{CARE_TYPE_DESCRIPTIONS[row.original.care_type]}</p>
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'community_claim_for_account',
      minWidth: 200,
      width: 200,
      maxWidth: 200,
      Cell: ({ row }) => {
        const claim = row?.original?.community_claim_for_account;
        const status = checkStatus(claim, row.original);
        return (
          <div>
            <p>{status}</p>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.claimPopup}>
      {fromPropertyDashboard && (
        <p>
          You must claim this community before viewing or editing it. If you have already
          submitted a claim for this property, and the status for your claim shows as
          "Claim Pending", please await administrator approval.
        </p>
      )}
      <p>
        The {property.name} property contains {property.communities?.length || '0'}{' '}
        communities. Unselect any communities below that you do <span>not</span> wish to
        claim.
      </p>
      <CommunityList
        communities={property.communities}
        onItemClick={handleBooleanChange}
        columns={columns}
      />
      <Button.DARK
        type='button'
        onClick={() => onSubmitClaim(communitiesToClaim)}
        disabled={disabledButton}
      >
        Submit Claim
      </Button.DARK>
    </div>
  );
};
