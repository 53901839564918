import { ForwardedRef, forwardRef } from 'react';
import { DropdownOption } from './Dropdown';
import styles from './Dropdown.module.scss';

export interface DropdownMenuProps {
  options: DropdownOption[];
  selectedItem: DropdownOption | undefined;
  onSelect: (item: DropdownOption) => void;
}

const DropdownMenu = forwardRef(
  (
    { options, selectedItem, onSelect }: DropdownMenuProps,
    ref: ForwardedRef<HTMLUListElement>
  ) => (
    <ul ref={ref} className={styles.dropdownMenu}>
      {options.map((item) => (
        <li
          key={item.value}
          onClick={() => onSelect(item)}
          className={`${styles.menuItem} ${
            item.value === selectedItem?.value ? styles.menuItemSelected : ''
          }`}
        >
          {item.label}
        </li>
      ))}
    </ul>
  )
);

DropdownMenu.displayName = 'DropdownMenu';

export default DropdownMenu;
