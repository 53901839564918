import styles from './InterestTag.module.scss';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-thin-svg-icons';
import Icons from '../../constants/Icons';

interface InterestTagProps {
  tagId: number;
  tagName: string;
  imageUrl: string;
  state: string;
  onChange: (tagId: number, state: string) => void;
}

type TagStatus = 'auto_detected' | 'approved' | 'rejected' | 'unknown';

const InterestTag: React.FC<InterestTagProps> = ({
  tagId,
  tagName,
  imageUrl,
  state,
  onChange,
}) => {
  const [status, setStatus] = useState<TagStatus | string>(state || 'unknown');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleApproveTag = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let updatingStatus = status;
    try {
      setIsLoading(true);
      if (status === 'approved') {
        await onChange(tagId, 'unknown');
        updatingStatus = 'unknown';
      } else {
        await onChange(tagId, 'approved');
        updatingStatus = 'approved';
      }
    } catch (e) {
      console.log(e);
    } finally {
      setStatus(updatingStatus);
      setIsLoading(false);
    }
  };

  const handleRejectTag = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let updatingStatus = status;
    try {
      setIsLoading(true);
      if (status === 'rejected') {
        await onChange(tagId, 'unknown');
        updatingStatus = 'unknown';
      } else {
        await onChange(tagId, 'rejected');
        updatingStatus = 'rejected';
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setStatus(updatingStatus);
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`${styles.tagContainer} ${
        status === 'approved'
          ? styles.approved
          : status === 'rejected'
          ? styles.rejected
          : ''
      }`}
    >
      <div className={`${styles.tagContent} ${isLoading ? styles.loading : ''}`}>
        <span className={styles.tagIcon}>
          <img src={imageUrl} alt={tagName} />
        </span>
        <p className={styles.tagName}>{tagName}</p>
        {status === 'auto_detected' && <p className={styles.suggestedTag}>(suggested)</p>}
        <div className={styles.bottomButtons}>
          <button
            disabled={isLoading}
            className={`${status === 'approved' ? styles.approveButton : ''}`}
            onClick={(e) => handleApproveTag(e)}
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>

          <button
            disabled={isLoading}
            className={`${status === 'rejected' ? styles.rejectButton : ''}`}
            onClick={(e) => handleRejectTag(e)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        {isLoading && <span className={styles.spinnerLoader}>{Icons.Loader}</span>}
      </div>
    </div>
  );
};

export default InterestTag;
