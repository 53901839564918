import styles from './ErrorPages.module.scss';

export default function Forbidden() {
  return (
    <div className={styles.errorHeader}>
      <div className={styles.errorAlert}>
        <div>
          <h1>Forbidden</h1>
        </div>
      </div>
      <div className={styles.errorText}>
        <div>
          <h2>
            You do not have permission to view this community. Please double check the
            account you used to sign in or ensure your claim for this community has been
            fully approved. If you believe you're seeing this message in error,{' '}
            <a
              href={`mailto:hello@kithward.com?subject=Forbidden Error Report&body=Current URL: ${window.location}`}
              rel='noreferrer'
            >
              you can let us know here.
            </a>
          </h2>
        </div>
      </div>
    </div>
  );
}
