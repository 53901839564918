import { Tooltip } from '@mui/material';
import Icons from '../../../constants/Icons';
import styles from './FormSectionRow.module.scss';
import AdminNotes from '../AdminNotes/AdminNotes';
import { FeatureGating } from '../../FeatureGating/FeatureGating';
import { shouldHideAdminNote } from '../../../utils/communityFormUtils';
import { useParams } from 'react-router-dom';

type FormSectionRowProps = {
  label: string;
  field: string;
  hasToolTip: boolean;
  isRequired: boolean;
  toolTipData: [
    {
      key: number;
      title: string;
      message: string;
    }
  ];
  tab: string;
  arrayId: string;
};

const FormSectionRow: React.FC<FormSectionRowProps> = ({
  label,
  hasToolTip,
  isRequired,
  toolTipData,
  children,
  field,
  tab,
  arrayId,
}) => {
  const { id } = useParams<{ id?: string }>(); // approved community id
  const toolTipResult = toolTipData.map((tooltip) => {
    if (tooltip && tooltip.title && tooltip.message) {
      return (
        <p key={tooltip.key}>
          {tooltip.title}: {tooltip.message}
        </p>
      );
    } else {
      return null;
    }
  });
  const hideAdminNote = shouldHideAdminNote(tab);

  return (
    <div className={styles.formSectionRow}>
      <div>
        <label className={`${isRequired ? 'required' : ''} ${styles.rowLabel}`}>
          {label}
          {hasToolTip && toolTipResult && (
            <Tooltip title={toolTipResult} arrow>
              <i style={{ cursor: 'pointer' }} className={styles.questionMark}>
                {Icons.Help}
              </i>
            </Tooltip>
          )}
        </label>

        {!hideAdminNote && (
          <FeatureGating>
            <AdminNotes
              title={label}
              section={field}
              approvedCommunityId={id}
              arrayItemId={arrayId}
            />
          </FeatureGating>
        )}
      </div>
      <span className={styles.questionMark}></span>
      <div className={styles.rowInputs}>{children}</div>
    </div>
  );
};

export default FormSectionRow;
