import Icons from '../../constants/Icons';
import styles from './Loader.module.scss';

const Loader = ({ isLoading, children }) => {
  return isLoading ? (
    <div className={styles.loader}>{Icons.Loader}</div>
  ) : (
    children || null
  );
};
export default Loader;
