import styles from '../PropertyList/PropertyList.module.scss';
import { KWImage } from '../KWImage/KWImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from '@mui/material';
import { CARE_TYPE_DESCRIPTIONS } from '../../constants/CareType';

const PropertyNameCell = ({ row, compact = false }) => {
  const { name, street, street_more, postal, city, state, country } = row.original || '';

  const communitywithImage = (community) => community.cached_image_url !== undefined;

  const cached_image_url =
    row?.original?.communities?.filter(communitywithImage)[0]?.cached_image_url;

  const starRating = row.original.communities[0].star_rating;
  const careTypeForStarRating = row.original.communities[0].care_type;
  return (
    <div className={styles.propertyNameContainer}>
      <KWImage src={cached_image_url} width={85} height={85} alt={`${name}`} />
      <div className={styles.propertyNameInfo}>
        <b>{name}</b>
        <div>
          {[street, street_more, city, state, postal, country]
            .filter((i) => i)
            .join(', ')}
          <Tooltip
            title={`${CARE_TYPE_DESCRIPTIONS[careTypeForStarRating]} diamond rating`}
          >
            <div>
              {starRating
                ? Array(starRating)
                    .fill(null)
                    .map((e, index) => <FontAwesomeIcon key={index} icon={faGem} />)
                : Array(5)
                    .fill(null)
                    .map((e, index) => (
                      <FontAwesomeIcon
                        key={index}
                        icon={faGem}
                        style={{ color: '#c4c4c4' }}
                      />
                    ))}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default PropertyNameCell;
