import useSWR from 'swr';
import api from '../services/api';
import produce from 'immer';
import { useCallback, useReducer } from 'react';
import { toURLSearchParams } from '../services/transform';

export const useQueryPropertiesByOrg = (orgId) => {
  const { data, error, isValidating } = useSWR(
    orgId ? `/v1/admin/properties/?organization_id=${orgId}` : null,
    api,
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  return {
    loading: isValidating,
    data: data && data.data ? data.data : undefined,
    error,
  };
};

const initialState = {
  queryResult: [],
  queryResultMeta: {},
  queryLoading: false,
};

const queryReducer = produce((draft, action) => {
  switch (action.type) {
    case 'RESET': {
      return initialState;
    }
    case 'QUERY_PENDING': {
      draft.queryLoading = true;
      break;
    }
    case 'QUERY_SUCCEEDED': {
      const { results, meta } = action.payload;
      draft.queryLoading = false;
      draft.queryResult = results;
      draft.queryResultMeta = meta;
      break;
    }
    case 'QUERY_FAILED': {
      return { ...initialState };
    }
    default:
      break;
  }
}, initialState);

export const useQueryProperties = (url = `/v1/admin/properties/`) => {
  const [{ queryResult, queryResultMeta, queryLoading }, dispatch] = useReducer(
    queryReducer,
    initialState
  );
  const queryProperties = useCallback(
    async (form = {}, page = 1) => {
      dispatch({ type: 'QUERY_PENDING' });
      try {
        const { data } = await api(url, {
          params: toURLSearchParams({ ...form, page }),
        });
        dispatch({
          type: 'QUERY_SUCCEEDED',
          payload: data,
        });
      } catch (err) {
        dispatch({ type: 'QUERY_FAILED' });
      }
    },
    [dispatch]
  );

  return { queryResult, queryResultMeta, queryLoading, queryProperties };
};
