import { debounce, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import styles from './CustomTextarea.module.scss';

export const CustomTextarea = (props) => {
  const [inputValue, setInputValue] = useState(props.value);
  const placeholder =
    props.placeholder || props.options.placeholder || props.label || 'Select...';

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    delayedHandleChange(newValue);
  };

  const delayedHandleChange = useCallback(
    debounce((newValue) => {
      props.onChange(newValue);
    }, 300),
    []
  );

  return (
    <TextField
      id='outlined-multiline-flexible'
      label={placeholder}
      multiline
      minRows={2}
      maxRows={4}
      value={inputValue}
      onChange={handleChange}
      className={styles.customTextarea}
    />
  );
};
