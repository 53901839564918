import { TourRequestsList } from '../../components/TourRequest/TourRequestsList';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';

export const ConsumerTourRequestPage = () => {
  return (
    <>
      <HeaderTitle title={'Tour Requests'} />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Tour Requests</h1>
          </div>
        </div>
        <TourRequestsList />
      </div>
    </>
  );
};
