import { useState } from 'react';
import styles from './CommunityList.module.scss';

export const CommunityExpandableItem = ({ row, onClick, canExpandItems = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (event) => {
    if (canExpandItems && row.original.status === 'rejected') {
      toggleIsOpen();
    } else {
      if (onClick) {
        onClick(row.original);
      }
    }
  };

  const toggleIsOpen = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <>
      <tr className={styles.communityItem} onClick={handleItemClick}>
        {row.cells.map((cell) => (
          <td key={cell.column.id + cell.row.id} {...cell.getCellProps()}>
            {cell.render('Cell')}
          </td>
        ))}
      </tr>
      {isOpen ? (
        <tr className={isOpen ? styles.expandableInfoOpen : styles.expandableInfoClose}>
          <td>
            TO DO: Replace this with correct field to explain why this community was
            reject (if it was).
          </td>
        </tr>
      ) : null}
    </>
  );
};
