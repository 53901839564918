import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadModelsImages } from '../../../../../redux/reducers';
import Button from '../../../../Button/Button';
import Loader from '../../../../Loader/Loader';
import { useSnackbar } from 'notistack';
import { Constants, StringUtils } from 'web-core';
import tabStyles from '../../../TabContent/TabContent.module.scss';
import formStyles from './ModelsImagesForm.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { ModelRow } from './ModelRow';

export const CreateUnitTypesForm = ({ onSave, onCancel }) => {
  const styles = { ...tabStyles, ...formStyles };
  const dispatch = useDispatch();
  const isUploadingModelImage = useSelector(
    (state) => state.communityForm.isUploadingModelImage
  );
  const [newUnitTypes, setNewUnitTypes] = useState([]);
  const isReadOnly = useSelector((state) => state.communityForm.isFormReadOnly);
  const { enqueueSnackbar } = useSnackbar();

  const canSaveUnitTypes = (modelsArray) => {
    if (modelsArray.length === 0) {
      return false;
    }

    return modelsArray.every(
      (m) =>
        !StringUtils.isBlank(m[Constants.DynamicSchemaFields.ModelName]) &&
        !StringUtils.isBlank(m[Constants.DynamicSchemaFields.ModelUnitType]) &&
        m[Constants.DynamicSchemaFields.ModelBathrooms] &&
        m[Constants.DynamicSchemaFields.ModelBedrooms]
    );
  };

  const createUnitType = (upload) => {
    return upload.map((image, index) => ({
      [Constants.DynamicSchemaFields.ModelImage]: image.id,
      [Constants.DynamicSchemaFields.ModelImages]: [image.id],
      id: uuidv4(),
      dateCreated: new Date().toISOString(),
    }));
  };

  const [isDisabled, setDisabled] = useState(!canSaveUnitTypes(newUnitTypes));

  const handlePhotoSelect = async (event) => {
    const fileSizeLimit = 20 * 1024 * 1024;
    if (event.target.files?.length) {
      const files = Object.values(event.target.files);
      const sizeCheck = files.every((e) => e.size < fileSizeLimit);
      if (sizeCheck) {
        const upload = await dispatch(uploadModelsImages(files)).then((response) => {
          return response;
        });
        if (upload.error) {
          enqueueSnackbar(
            `Error: ${upload.error}. Please retry your upload or contact us if the issue persists.`,
            {
              variant: 'default',
            }
          );
          return;
        }
        // if upload success, create new unit type object(s)
        const newUnitType = createUnitType(upload);
        // appending new unit type(s) to the current new unity array
        setNewUnitTypes((currentArray) => [...currentArray, ...newUnitType]);
      } else {
        enqueueSnackbar('Please upload a file / files smaller than 20 MB', {
          variant: 'default',
        });
      }
    }
  };

  const handleChange = (id) => {
    const isSameUnitType = (element) => element.id === id;
    const targetModel = newUnitTypes.findIndex(isSameUnitType);
    return (event) => {
      const changedModel = { ...newUnitTypes[targetModel] };
      const { name, value } = event.target;

      //add no bedrooms for Studio
      if (
        name === Constants.DynamicSchemaFields.ModelUnitType &&
        value === Constants.BedroomTypes.Studio
      ) {
        changedModel[name] = [];
        changedModel[Constants.DynamicSchemaFields.ModelHasStudioLayout] = true;
      } else {
        if (name === Constants.DynamicSchemaFields.ModelBedrooms) {
          changedModel[name] = buildArray(name, parseInt(value));
          changedModel[Constants.DynamicSchemaFields.ModelHasStudioLayout] = null;
        } else if (name === Constants.DynamicSchemaFields.ModelBathrooms) {
          changedModel[name] = buildArray(name, parseFloat(value));
        } else {
          changedModel[name] = value;
        }
      }
      const newModelsArray = newUnitTypes.map((model, i) =>
        i === targetModel ? changedModel : model
      );
      handleUnitTypesChanged(newModelsArray);
    };
  };

  //build bedrooms and bathrooms array
  const buildArray = (roomType, count) => {
    let arr = [];
    if (roomType === Constants.DynamicSchemaFields.ModelBedrooms) {
      for (let i = 0; i < count; i++) {
        let bedroom = {
          [Constants.DynamicSchemaFields.ModelBedroomType]: 'Private Room',
        };
        arr.push(bedroom);
      }
    } else {
      let fullBathrooms = parseInt(count);
      for (let i = 0; i < fullBathrooms; i++) {
        let bathroom = {
          [Constants.DynamicSchemaFields.ModelBathroomType]:
            Constants.BathroomTypes.FullBathroom,
        };
        arr.push(bathroom);
      }
      if (count - fullBathrooms > 0) {
        arr.push({
          [Constants.DynamicSchemaFields.ModelBathroomType]:
            Constants.BathroomTypes.HalfBathroom,
        });
      }
    }
    return arr;
  };

  const handleUnitTypesChanged = (unitTypesArray) => {
    setNewUnitTypes(unitTypesArray);
  };

  useEffect(() => {
    setDisabled(!canSaveUnitTypes(newUnitTypes));
  }, [newUnitTypes]);

  return (
    <div className={styles.popupForm}>
      <div className={styles.header}>
        <div>
          <h3 className={styles.floorplanUploadHeader}>Unit Type Upload</h3>
        </div>
      </div>
      <div className={[styles.tabContent, styles.floorplanFormBody].join(' ')}>
        <form id='floorplanImagesForm' onSubmit={(ev) => ev.preventDefault()}>
          <fieldset disabled={isReadOnly} form='floorplanImagesForm'>
            <div className={styles.photosTab}>
              {!isReadOnly && (
                <section className={styles.popupFormSection}>
                  <div
                    className={[styles.photoUpload, styles.floorplanPhotoUpload].join(
                      ' '
                    )}
                  >
                    <h3>Drag & Drop Unique Floor Plans Here</h3>
                    <h4>
                      Each image uploaded will create a new Unit Type at this community.
                    </h4>
                    <hr />
                    <Button.LIGHT>Browse your computer here</Button.LIGHT>
                    <input
                      type='file'
                      multiple
                      name='photoInput'
                      accept='image/*, application/pdf'
                      onChange={handlePhotoSelect}
                    />
                  </div>
                </section>
              )}
              {newUnitTypes.length > 0 && (
                <div className={styles.popupFormSection}>
                  <section
                    className={[styles.formSection, styles.popupFormSection].join(' ')}
                  >
                    <div className={styles.sectionHeader}>
                      <span className={styles.sectionTitle}>Uploads</span>
                    </div>
                    <Loader isLoading={isUploadingModelImage}>
                      <div className={styles.table}>
                        <div className={styles.tableBody}>
                          {newUnitTypes &&
                            newUnitTypes.map((unitType, i) => (
                              <ModelRow
                                key={String(unitType.id)}
                                model={unitType}
                                onFieldChange={handleChange(unitType.id)}
                              />
                            ))}
                        </div>
                      </div>
                    </Loader>
                  </section>
                </div>
              )}
            </div>
          </fieldset>
        </form>
      </div>
      <div className={`flex-kebab-row p-50 ${styles.bottomButtons}`}>
        <Button.LIGHT type='button' onClick={onCancel}>
          Cancel
        </Button.LIGHT>
        <Button.DARK disabled={isDisabled} onClick={(e) => onSave(e, newUnitTypes)}>
          Save Unit Types
        </Button.DARK>
      </div>
    </div>
  );
};
