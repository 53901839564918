import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

type Props = {
  onChange: Function;
  value: any;
};

export const MonthPicker: React.FC<Props> = ({ onChange, value }) => {
  const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 5));
  const maxDate = new Date();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={['year', 'month']}
        label='Select Month'
        inputFormat='MMMM yyyy'
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => <TextField {...params} helperText={null} />}
      />
    </LocalizationProvider>
  );
};
