import SignUpFormAdapter from '../adapters/SignupFormAdapter';
import api from '../services/api';

export const useAccounts = () => {
  const createAccount = async (user) => {
    try {
      const { data } = await api.post(
        `/v1/admin/accounts`,
        SignUpFormAdapter.toApi(user)
      );
      return data;
    } catch (err) {}
  };

  return { createAccount };
};
