import produce from 'immer';
import { useCallback, useReducer } from 'react';
import { queryOrganizations as queryOrganizationsAPI } from '../services/organizations';

const initialState = {
  queryResult: [],
  queryResultMeta: {},
  queryLoading: false,
};

const queryReducer = produce((draft, action) => {
  switch (action.type) {
    case 'RESET': {
      return initialState;
    }
    case 'QUERY_PENDING': {
      draft.queryLoading = true;
      break;
    }
    case 'QUERY_SUCCEEDED': {
      const { results, meta } = action.payload;
      draft.queryLoading = false;
      draft.queryResult = results;
      draft.queryResultMeta = meta;
      break;
    }
    case 'QUERY_FAILED': {
      return { ...initialState };
    }
    default:
      break;
  }
}, initialState);

export const useQueryOrganizations = () => {
  const [{ queryResult, queryResultMeta, queryLoading }, dispatch] = useReducer(
    queryReducer,
    initialState
  );

  const queryOrganizations = useCallback(
    async (form = {}, page = 1) => {
      dispatch({ type: 'QUERY_PENDING' });
      try {
        const data = await queryOrganizationsAPI(form, page);
        dispatch({
          type: 'QUERY_SUCCEEDED',
          payload: data,
        });
      } catch (err) {
        dispatch({ type: 'QUERY_FAILED' });
      }
    },
    [dispatch]
  );

  return { queryResult, queryResultMeta, queryLoading, queryOrganizations };
};
