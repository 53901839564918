import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import initSubscriber from 'redux-subscriber';
import { composeWithDevTools } from 'redux-devtools-extension';

import createRootReducer from './reducers';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

let initialState = {};
let enhancers = [];
let middleware = [thunk];
let store;

//-- Routing --------
let reducer = createRootReducer();

//-- Redux tools ----------
if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
  let __REDUX_DEVTOOLS_EXTENSION__ = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION__({ trace: true }));
  }
}

//-- Persistence ---------
const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['app', 'router'],
  stateReconciler: autoMergeLevel2,
};
reducer = persistReducer(persistConfig, reducer);

const initStore = (preloadedState = initialState) => {
  const devToolsOptions =
    process.env.NODE_ENV === 'development' && typeof window !== 'undefined'
      ? { trace: true, traceLimit: 25 }
      : {};

  const composeEnhancers = composeWithDevTools(devToolsOptions);

  return createStore(
    reducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware))
  );
};

export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }
  return _store;
};

export const useStore = (initialState) => {
  const store = initializeStore(initialState);
  const persistor = persistStore(store);
  const subscribe = initSubscriber(store);
  return { persistor, subscribe, store };
};
