import { ModerationQueueItemStatus } from '../../constants/ModerationQueueItemStatus';
import styles from './ModerationQueueActionButtons.module.scss';

const actionClass = {
  ACCEPTED: styles.accepted,
  REJECTED: styles.rejected,
  PENDING: styles.pending,
};

export const ModerationQueueActionButtons = ({
  status,
  hasPreview,
  onAccept,
  onReject,
  onPreview = undefined,
}) => {
  const statusUpper = status.toUpperCase();

  const actionButtons = [
    <div
      key={'Accept'}
      className={`${styles.actionStatus} ${styles.actionButton}`}
      onClick={onAccept}
    >
      Accept
    </div>,
    <div
      key={'Reject'}
      className={`${styles.actionStatus} ${styles.actionButton}`}
      onClick={onReject}
    >
      Reject
    </div>,
    hasPreview ? (
      <div
        key={'Preview'}
        className={`${styles.actionStatus} ${styles.actionButton}`}
        onClick={onPreview}
      >
        Preview
      </div>
    ) : null,
  ];

  return (
    <div className={styles.moderationQueueActionButtons}>
      <div className={`${styles.actionStatus} ${actionClass[statusUpper]}`}>
        {statusUpper}
      </div>
      {statusUpper !== ModerationQueueItemStatus.PENDING ? null : (
        <div className={styles.actionButtonsContainer}>{actionButtons}</div>
      )}
    </div>
  );
};
