import { useMemo } from 'react';
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
} from 'react-table';
import { PropertyItem } from './PropertyItem';
import { Pagination } from '../Pagination/Pagination';
import styles from './PropertyList.module.scss';
import { useQueryParams } from '../../hooks/useQueryParams';

const PropertyList = ({ properties = [], columns, meta }) => {
  const nameValue = useQueryParams().get('name') || '';
  const geoValue = useQueryParams().get('geo') || '';
  const withinValue = useQueryParams().get('within') || '';
  const organizationValue = useQueryParams().get('organization_id') || '';
  const defaultColumn = useMemo(
    () => ({
      minWidth: 150,
      width: '100%',
    }),
    []
  );

  const paginationOptions = meta
    ? {
        initialState: {
          pageSize: meta['per_page'],
          pageIndex: meta['current_page'] - 1,
        },
        pageCount: meta['total_pages'],
        manualPagination: true,
      }
    : null;

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data: properties,
      defaultColumn,
      ...paginationOptions,
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  );

  return (
    <div className={styles.propertyListContainer}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              key={`${headerGroup.Header?.toString() || ''}`}
              {...headerGroup.getHeaderGroupProps({
                style: { width: '100%' },
              })}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className='p4'
                  disabled
                  key={column.Header.toString()}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map((row, i) => {
              prepareRow(row);
              return <PropertyItem key={String(row.id)} row={row} />;
            })
          ) : (nameValue.length > 0 ||
              geoValue.length > 0 ||
              withinValue.length > 0 ||
              organizationValue.length > 0) &&
            page.length === 0 ? (
            <tr>
              <td className={styles.emptyList}>
                <b>No Properties Found</b>
                <p>Please try another search.</p>
              </td>
            </tr>
          ) : meta && Object.keys(meta).length > 0 ? (
            <tr>
              <td className={styles.emptyList}>
                <b>Welcome to Kithward!</b>
                <p>
                  Kithward's admin portal enables you to manage the way your senior living
                  communities appear on the Kithward platform. To get started, use one of
                  the links above to create or search for an existing community to claim
                  for your organization. You can invite colleagues to join you from the
                  “Users” section in the left navigation panel. If you have any questions
                  or concerns, feel free to reach out to our team using the "Contact
                  Support" link in the upper right hand corner of the page.
                </p>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
      {properties && meta && (
        <Pagination meta={meta} columns={columns} data={properties} />
      )}
    </div>
  );
};

export default PropertyList;
