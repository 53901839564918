const fromApi = (community: any) => ({
  approvedPropertyCommunities: community.approved_property_communities,
  approved_version_id: community.approved_version_id,
  property_id: community.property_id,
  images: community.images,
  model_type_images: community.model_type_images,
  formData: {
    id: community.id,
    ...community.json_data,
  },
  createdAt: community.created_at,
  latestEditVersion: community.latest_edit_version,
});

const CommunityFormAdapter = {
  fromApi,
};

export default CommunityFormAdapter;
