import { CARE_TYPE_ABBREVIATIONS } from '../../constants/CareType';
import styles from './PropertyResultsList.module.scss';

export const PropertyCareTypesCell = ({ row }) => {
  const careTypeArray =
    row?.original?.communities?.map((community) => {
      return CARE_TYPE_ABBREVIATIONS[community.care_type];
    }) || [];

  return careTypeArray ? (
    <div className={`pl-50 ${styles.careTypesCell}`}>{careTypeArray.join(', ')}</div>
  ) : null;
};
