import React, { useState } from 'react';
import styles from './InformationRequestsTable.module.scss';

const ExpandableMessage = ({ message }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={`${styles.messageContainer} ${expanded ? styles.expanded : ''}`}>
      <p className={styles.messageText}>{message}</p>
      {message.length > 100 && (
        <span className={styles.showMore} onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Show Less' : 'Show More'}
        </span>
      )}
    </div>
  );
};

export default ExpandableMessage;
