import { CARE_TYPE_DESCRIPTIONS } from '../../constants/CareType';

export const CommunityCareTypeCell = ({ row }) => {
  const careTypeLabel = row.original?.care_type
    ? CARE_TYPE_DESCRIPTIONS[row.original?.care_type]
    : '-';
  return (
    <div>
      <p style={{ margin: '0' }}>{String(careTypeLabel)}</p>
    </div>
  );
};
