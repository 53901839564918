import { Constants } from 'web-core';
import api from './api';

export const createNewCommunity = async ({ name = '', careType }) => {
  try {
    const { data } = await api.post(`/v1/admin/communities`, {
      json_data: {
        [Constants.DynamicSchemaFields.Name]: name,
        [Constants.DynamicSchemaFields.CareType]: careType,
      },
    });
    return data;
  } catch (err) {
    throw err;
  }
};

/**
 * Assign a Community to an Organization
 * @param {number} communityId
 * @param {number} organizationId
 * @returns
 */
export const assignCommunity = async (communityId, organizationId) => {
  try {
    const { data } = await api.patch(`/v1/admin/communities/${communityId}/update_live`, {
      organization_id: organizationId,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

/**
 * Claim a Community
 * @param {number} communityId
 * @returns 'data' from response
 */
export const claimCommunity = async (communityIds) => {
  try {
    const { data } = await api.post(`/v1/admin/community_claims`, {
      community_ids: communityIds,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

export const deleteCommunity = async (communityId) => {
  try {
    const { data } = await api.delete(`/v1/admin/communities/${communityId}`);
    return data;
  } catch (err) {
    throw err;
  }
};

export const assignFromUserForm = async (accountId, communityId) => {
  try {
    const { data } = await api.patch(
      `/v1/admin/accounts/${accountId}/assign_to_community`,
      { community_id: communityId }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const removeFromUserForm = async (accountId, communityId) => {
  try {
    const { data } = await api.patch(
      `/v1/admin/accounts/${accountId}/remove_from_community`,
      { community_id: communityId }
    );
    return data;
  } catch (err) {
    throw err;
  }
};
