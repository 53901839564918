import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectModels,
  selectUnits,
  selectUnitForm,
} from '../../../../redux/selectors/communityForm';
import Icons from '../../../../constants/Icons';
import styles from './UnitsTab.module.scss';
import { changeUnitForm } from '../../../../redux/reducers';
import { Tooltip } from '@mui/material';
import _ from 'lodash';
import { Constants } from 'web-core';

export const UnitFieldSelect = ({ props, array }) => {
  const dispatch = useDispatch();
  const models = useSelector(selectModels) || [];
  const units = useSelector(selectUnits) || [];
  const unitForm = useSelector(selectUnitForm);
  const [populated, setPopulated] = useState(false);

  const targetModel = models
    ? models.find((m) => m.id === unitForm[Constants.DynamicSchemaFields.UnitModel])
    : undefined;
  const thisUnit = units ? units.find((u) => u.id === unitForm.id) : undefined;

  let row;
  let field;
  let modelFieldOverridden = false;

  if (!array && props.props && props.props.children[0].props.name) {
    const key = props.props.children[0].props.name;
    row = key.split('|')[2];
    field = key.split('|')[3];

    if (targetModel && thisUnit && thisUnit[key] && unitForm[key]) {
      modelFieldOverridden = true;
    }
  } else if (array) {
    modelFieldOverridden = true;
  }

  const isArray = row && !field;
  const isChildOfArray = !row && !field;

  const modelFieldKey = `Model-Type|Model Information|${row}${
    field && field !== undefined ? `|${field}` : ''
  }`;

  const unitFieldKey = `Units|Unit Information|${row}${
    field && field !== undefined ? `|${field}` : ''
  }`;

  const populateFormFieldFromModel = () => {
    let newField = { [unitFieldKey]: {} };
    if (Array.isArray(targetModel[modelFieldKey])) {
      const itemArray = [];
      targetModel[modelFieldKey].forEach((arrayFieldKey, index) => {
        const nestedFields = {};
        Object.keys(arrayFieldKey).forEach((nestedFieldKey) => {
          const [, , itemKeyRow, itemKeyField] = nestedFieldKey.split('|');
          const unitItemFieldKey = `Units|Unit Information|${itemKeyRow}|${itemKeyField}`;
          if (targetModel[modelFieldKey][index][nestedFieldKey]) {
            const newArrayItem = Object.assign(nestedFields, {
              [unitItemFieldKey]: targetModel[modelFieldKey][index][nestedFieldKey],
            });
          }
        });
        itemArray.push(nestedFields);
      });
      newField[unitFieldKey] = itemArray;
      const finalUnitForm = _.assign(newField, unitForm);
      dispatch(changeUnitForm(finalUnitForm));
    } else if (targetModel[modelFieldKey]) {
      newField[unitFieldKey] = targetModel[modelFieldKey];
      const finalUnitForm = _.assign(newField, unitForm);
      dispatch(changeUnitForm(finalUnitForm));
    }
  };

  const revertToFormFromInherit = () => {
    populateFormFieldFromModel();
    setPopulated(false);
  };

  const revertToInheritFromOverride = () => {
    const formToUpdate = Object.assign({}, unitForm);
    if (formToUpdate[unitFieldKey]) {
      delete formToUpdate[unitFieldKey];
      dispatch(changeUnitForm(formToUpdate));
    }
    setPopulated(true);
  };

  useEffect(() => {
    if (targetModel) {
      setPopulated(true);
    } else {
      setPopulated(false);
    }
  }, [targetModel]);

  return modelFieldOverridden === true ? (
    <div className={styles.overrideContents}>
      {targetModel && !isChildOfArray && (
        <div className={styles.undoButton} onClick={() => revertToInheritFromOverride()}>
          <Tooltip
            title={
              "You've chosen to enter custom details for this field. Click here to re-link this field to inherit details from the associated unit type instead."
            }
            arrow
          >
            {Icons.Undo}
          </Tooltip>
        </div>
      )}
      {props}
    </div>
  ) : populated && !isArray ? (
    <div className={styles.populatedContent}>
      <div className={styles.editButton} onClick={() => revertToFormFromInherit()}>
        <Tooltip
          title={
            "These details are sourced from the unit type you've assigned to this unit. Click here to customize details in this field."
          }
          arrow
        >
          {Icons.Pencil}
        </Tooltip>
      </div>

      <p>
        {targetModel &&
          modelFieldKey &&
          field &&
          `${field}: ${
            targetModel[modelFieldKey] !== undefined ? targetModel[modelFieldKey] : '-'
          }`}
      </p>
    </div>
  ) : populated && isArray ? (
    <div className={styles.populatedContent}>
      <div className={styles.editButton} onClick={() => revertToFormFromInherit()}>
        <Tooltip
          title={
            "These details are sourced from the unit type you've assigned to this unit. Click here to customize details in this field."
          }
          arrow
        >
          {Icons.Pencil}
        </Tooltip>
      </div>

      {targetModel &&
        row &&
        targetModel[`Model-Type|Model Information|${row}`] &&
        Array.isArray(targetModel[`Model-Type|Model Information|${row}`]) &&
        targetModel[`Model-Type|Model Information|${row}`].map((room, index) => {
          return (
            <div key={index}>
              <p className={styles.arrayTitle}>
                {row.slice(0, -1)} #{index + 1}
              </p>
              {Object.entries(room).map(([key, value]) => {
                return value !== false ? (
                  <p key={key} className={styles.arrayValues}>
                    {key.split('|')[3]}
                    <span>{value !== true ? ': ' + value.toString() : null}</span>
                  </p>
                ) : null;
              })}
            </div>
          );
        })}
    </div>
  ) : (
    <div className={styles.overrideContents}>
      {targetModel && (
        <div className={styles.undoButton} onClick={() => setPopulated(!populated)}>
          <Tooltip
            title={
              "You've chosen to enter custom details for this field. Click here to re-link this field to inherit details from the associated unit type instead."
            }
            arrow
          >
            {Icons.Undo}
          </Tooltip>
        </div>
      )}
      {props}
    </div>
  );
};
