import { useState } from 'react';
import Button from '../../components/Button/Button';
import { OrganizationForm } from '../../components/OrganizationForm/OrganizationForm';
import { OrganizationList } from '../../components/OrganizationList/OrganizationList';
import Icons from '../../constants/Icons';
import { useOrganizationsInfinite } from '../../hooks/useOrganizationsInfinite';
import styles from './OrganizationListPage.module.scss';
import { useHistory } from 'react-router-dom';
import KthPopup from '../../components/KthPopup/KthPopup';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';

export const OrganizationListPage = () => {
  const [showOrganizationForm, setShowOrganizationForm] = useState(false);
  const [organizationToEdit, setOrganizationToEdit] = useState(undefined);
  const {
    organizations,
    loading: organizationLoading,
    meta,
    loadMore,
    restartPages,
  } = useOrganizationsInfinite(true);
  const [query, setQuery] = useState('');
  const history = useHistory();

  const handleShowOrganizationForm = (org) => {
    setShowOrganizationForm(true);
    setOrganizationToEdit(org);
  };

  const handleOrganizationFormClose = (refreshList) => {
    setOrganizationToEdit(undefined);
    setShowOrganizationForm(false);
    if (refreshList) {
      restartPages();
    }
  };
  const handleNavigateUser = (org) => {
    console.log(org, 'ORG ID');
    history.push('/users');
  };

  const handleLoadMore = () => {
    loadMore();
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // queryOrganizations({ name: query });
  };

  const handleQueryChange = (event) => {
    setQuery(event?.target?.value);
  };

  const getHeaderTitle = () => {
    if (organizationToEdit) {
      const name = `${organizationToEdit.name || ''}`;
      return `Edit ${name}`;
    } else {
      return 'Create New Organization';
    }
  };

  return (
    <>
      <HeaderTitle title={'Organizations'} />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Organizations</h1>
          </div>
          <form className='search-container' onSubmit={handleSearchSubmit}>
            {/* Hidden while backend doesnt support that search */}
            {/* <input name="q" value={query} onChange={handleQueryChange} placeholder="Search Organizations" />
            <Button.TEXT icon type="submit">{Icons.Search}</Button.TEXT> */}
          </form>
          <div className='action-container-properties'>
            <Button.BLACK_OUTLINED onClick={() => handleShowOrganizationForm()}>
              Create new
            </Button.BLACK_OUTLINED>
          </div>
        </div>
        <OrganizationList
          onItemUser={handleNavigateUser}
          organizations={organizations}
          isLoadingMore={organizationLoading}
          onItemClick={handleShowOrganizationForm}
        />
        {meta?.last_page === false ? (
          <Button.LIGHT icon onClick={handleLoadMore} className={styles.loadMoreButton}>
            Load more {Icons.ArrowDown}
          </Button.LIGHT>
        ) : null}
        <KthPopup
          isOpen={showOrganizationForm}
          title={getHeaderTitle()}
          hideActions
          onClose={() => setShowOrganizationForm(false)}
        >
          <OrganizationForm
            organization={organizationToEdit}
            onClose={handleOrganizationFormClose}
          />
        </KthPopup>
      </div>
    </>
  );
};
