import { useState } from 'react';
import { Autocomplete, Chip, TextField, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const CustomAutoComplete = (props) => {
  const value = props.value ? props.value : props.multiple === true ? [] : '';
  const [inputValue, setInputValue] = useState(
    props.value
      ? props.value
      : props.schema.type === 'string'
      ? ''
      : props.schema.type === 'integer'
      ? null
      : ''
  );
  const [multiInputValue, setMultiInputValue] = useState(
    props.schema.type === 'integer' ? null : ''
  );

  const placeholder =
    props.placeholder || props.options.placeholder || props.label || 'Select...';

  const options = props.options.enumOptions;

  const groupedOptions = options.map((option) => {
    return {
      placeholder: placeholder,
      ...option,
    };
  });

  const handleInputBlurOnMultiple = async (event, inputValue) => {
    event.preventDefault();
    if (Array.isArray(value) && multiInputValue !== '') {
      const newValues = value.map((item) =>
        typeof item === 'object' ? item.value : item
      );
      newValues.push(multiInputValue);
      const uniqValues = [...new Set(newValues)];
      await props.onChange(uniqValues);
    }
    setMultiInputValue(props.schema.type === 'integer' ? null : '');
  };

  const handleMultiChange = (event, values) => {
    event.preventDefault();
    if (values) {
      const newValues = values.map((item) =>
        typeof item === 'object' ? item.value : item
      );
      const uniqValues = [...new Set(newValues)];
      props.onChange(uniqValues);
    }
    setMultiInputValue(props.schema.type === 'integer' ? null : '');
  };

  const handleSingleChange = (event, value) => {
    if (value || inputValue !== null) {
      if (event.type === 'blur') {
        if (inputValue !== '') {
          props.onChange(
            props.schema.type === 'integer'
              ? parseInt(inputValue)
              : inputValue !== null
              ? inputValue
              : ''
          );
        }
      } else if (value) {
        props.onChange(
          props.schema.type === 'integer' && value.value
            ? parseInt(value.value)
            : props.schema.type === 'integer' && value
            ? parseInt(value)
            : props.schema.type !== 'integer' && value.value
            ? value.value
            : value
        );
      }
    }
  };

  return props.multiple === true ? (
    <Autocomplete
      multiple
      disableCloseOnSelect
      clearOnBlur={false}
      className={props.className}
      options={groupedOptions}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value}
      value={value || []}
      groupBy={(option) => option.placeholder}
      onChange={handleMultiChange}
      onInputChange={(event, newInputValue) => {
        setMultiInputValue(newInputValue);
      }}
      inputValue={multiInputValue}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
      disabled={props.disabled}
      freeSolo
      renderTags={(values, getTagProps) =>
        // Spead "values" because there could be single string value, from old stored data. (Or if Autocomplete accept "non-multiple" values)
        (typeof values === 'string' ? [values] : [...values]).map((option, index) => {
          const label =
            props.options.enumOptions.find((op) => op.value === option)?.label || option;
          const formattedLabel = props.labelFormatter
            ? props.labelFormatter(label)
            : label;
          return (
            <Chip
              label={formattedLabel}
              {...getTagProps({ index })}
              style={{
                backgroundColor: '#ac947a',
                color: '#fff',
              }}
            />
          );
        })
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} onBlur={handleInputBlurOnMultiple} label={placeholder} />
      )}
    />
  ) : (
    <Autocomplete
      freeSolo
      clearOnBlur={false}
      options={groupedOptions}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      inputValue={inputValue || ''}
      value={value + ''}
      onChange={handleSingleChange}
      groupBy={(option) => option.placeholder}
      onBlur={(event) => {
        event.preventDefault();
        event.defaultMuiPrevented = true;
        handleSingleChange(event, inputValue);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.defaultMuiPrevented = true;
          handleSingleChange(event, inputValue);
        }
      }}
      renderInput={(params) => <TextField {...params} label={placeholder} />}
    />
  );
};

export default CustomAutoComplete;
