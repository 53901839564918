import { useMemo } from 'react';
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
} from 'react-table';
import { CommunityExpandableItem } from './CommunityExpandableItem';
import { Pagination } from '../Pagination/Pagination';
import styles from './CommunityList.module.scss';

const CommunityList = ({
  title,
  communities = [],
  columns,
  onItemClick,
  canExpandItems,
  meta,
}) => {
  const uniqueCommunities = useMemo(
    () => communities.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i),
    [communities]
  );

  const defaultColumn = useMemo(
    () => ({
      minWidth: 40, // minWidth is only used as a limit for resizing
      width: '100%',
    }),
    []
  );

  const paginationOptions = meta
    ? {
        initialState: {
          pageSize: meta['per_page'],
          pageIndex: meta['current_page'] - 1,
        },
        pageCount: meta['total_pages'],
        manualPagination: true,
      }
    : null;

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    // rows, // rows for the table based on the data passed
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
  } = useTable(
    {
      columns,
      data: uniqueCommunities,
      defaultColumn,
      ...paginationOptions,
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  );

  return (
    <div className={styles.communityListContainer}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              key={`${headerGroup.Header?.toString() || ''}`}
              {...headerGroup.getHeaderGroupProps({
                style: { width: '100%' },
              })}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className='p4'
                  disabled
                  key={column.Header.toString()}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <CommunityExpandableItem
                key={String(row.id)}
                row={row}
                onClick={onItemClick}
                canExpandItems={canExpandItems}
                {...row.getRowProps()}
              />
            );
          })}
        </tbody>
      </table>
      {uniqueCommunities && meta && (
        <Pagination meta={meta} columns={columns} data={uniqueCommunities} />
      )}
    </div>
  );
};

export default CommunityList;
