import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
} from 'react-table';
import { useParams } from 'react-router-dom';
import { getSurveys } from '../../redux/reducers/surveys';
import Loader from '../Loader/Loader';
import styles from './SurveyList.module.scss';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SurveyList = () => {
  const { accountId } = useParams();
  const dispatch = useDispatch();

  // Get surveys from Redux store
  const { surveys, isSurveysLoading, meta } = useSelector((state) => state.surveys);

  console.log('surveys', surveys);
  console.log('isSurveysLoading', isSurveysLoading);
  console.log('meta', meta);
  useEffect(() => {
    dispatch(getSurveys(accountId));
  }, [dispatch, accountId]);

  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'id', width: 70 },
      { Header: 'Survey Type', accessor: 'survey_type' },
      {
        Header: 'Questions & Answers',
        accessor: 'qa_pairs',
        Cell: ({ value }) => (
          <table className={styles.innerTable}>
            <thead>
              <tr>
                <th className={styles.qaHeader}>Question</th>
                <th className={styles.qaHeader}>Answer</th>
              </tr>
            </thead>
            <tbody>
              {value.map((qa, index) => (
                <tr key={index}>
                  <td className={styles.answerCell}>{qa.answer}</td>
                  <td className={styles.questionCell}>{qa.question}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ),
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
    ],
    []
  );

  const tableInstance = useTable(
    { columns, data: surveys, initialState: { pageSize: 10 } },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  return (
    <div>
      <HeaderTitle title='Surveys' />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Surveys for Account {accountId}</h1>
          </div>
        </div>

        <Loader isLoading={isSurveysLoading}>
          <div className={styles.surveyListContainer}>
            {surveys.length === 0 ? (
              <p>No surveys available.</p>
            ) : (
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          key={column.id}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className='p4'
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (
                              <FontAwesomeIcon
                                icon={column.isSortedDesc ? faChevronUp : faChevronDown}
                              />
                            ) : null}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr key={row.id} {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td key={cell.column.id} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default SurveyList;
