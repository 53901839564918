import { useDispatch, useSelector } from 'react-redux';
import { formDataChange } from '../../../../redux/reducers';
import DynamicForm from '../../../DynamicForm/DynamicForm';
import { EmptyBox } from '../../../EmptyBox/EmptyBox';
import { CheckboxesSelection } from '../../CheckboxesSelection/CheckboxesSelection';
import { RestaurantPhotoSelect } from '../../../DynamicForm/Widgets/RestaurantPhotoSelect/RestaurantPhotoSelect';

const FacilitiesTab = () => {
  const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const showOverlay = useSelector((state) => state.communityForm.showOverlay);
  const formData = useSelector((state) => state.communityForm.formData);
  const dispatch = useDispatch();

  const handleFormDataChange = ({ formData }) => {
    dispatch(formDataChange(formData));
  };

  const hasSomeFieldVisible = () => {
    const facilitiesCheckboxesFields = Object.values(
      uiSchema['checkboxesSelection']['Facilities']
    ).map((sectionFields) => sectionFields);
    const facilitiesCheckboxesFieldsFlat = facilitiesCheckboxesFields.flat();
    return facilitiesCheckboxesFieldsFlat.some((field) => formData[field]);
  };

  return (
    <>
      <CheckboxesSelection
        titleHeaderOpen='Edit Facilities Details'
        titleHeaderClosed='Edit Facilities Details'
        openInstructionsMessage='Select the general facilities available at this Community. You can add details to each facility after selecting all of them.'
        checkboxesContext='Facilities'
        hasSelectionChecked={hasSomeFieldVisible()}
      />
      {hasSomeFieldVisible() && !showOverlay ? (
        <DynamicForm
          schema={jsonSchema}
          uiSchema={uiSchema}
          formData={formData}
          onChange={handleFormDataChange}
          onSubmit={() => console.log('submitted')}
          onError={(e) => console.log(e)}
          formContext={{ currentTab: 'Facilities', hideRowTitle: true }}
          widgets={{
            restaurantPhotoSelect: RestaurantPhotoSelect,
          }}
        />
      ) : !hasSomeFieldVisible() && !showOverlay ? (
        <EmptyBox
          message={`Click the button above to select which facilities are present at this community at a high level. Faciliites checked as being offered/available will be included in the next step of the form. You can modify these selections at any time by clicking the button at the top of this section.`}
        />
      ) : null}
    </>
  );
};

export default FacilitiesTab;
