import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQueryProperties } from '../../../hooks/useQueryProperties';
import { useSnackbar } from 'notistack';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { claimCommunity } from '../../../services/communities';
import { SearchBar } from '../../../components/SearchBar/SearchBar';
import { PropertyClaimPopup } from '../../../components/PropertyClaimPopup/PropertyClaimPopup';
import { PropertyCareTypesCell } from '../../../components/PropertyResultsList/PropertyCareTypesCell';
import Loader from '../../../components/Loader/Loader';
import Button from '../../../components/Button/Button';
import PropertyResultsList from '../../../components/PropertyResultsList/PropertyResultsList';
import PropertyNameCell from '../../../components/PropertyResultsList/PropertyNameCell';
import styles from './PropertiesSearchAndClaim.module.scss';
import { useLocation } from 'react-router-dom';
import KthPopup from '../../../components/KthPopup/KthPopup';
import { APITools } from 'web-core';

export const PropertiesSearchAndClaimPage = () => {
  const urlQueryName = useQueryParams().get('name') || '';
  const urlQueryGeoPlace = useQueryParams().get('geo') || '';
  const urlPage = useQueryParams().get('page') || '';
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.auth.user);
  const { queryResult, queryResultMeta, queryLoading, queryProperties } =
    useQueryProperties(`/v1/admin/properties/search`);
  const [propertyQueryResults, setPropertyQueryResults] = useState({
    queryResult,
    queryResultMeta,
    queryLoading,
    queryProperties,
  });
  const [query, setQuery] = useState({
    name: urlQueryName,
    geo: urlQueryGeoPlace,
  });
  const [isClaimPopupOpen, setIsClaimPopupOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    queryProperties(query, urlPage);
    setPropertyQueryResults({
      queryResult,
      queryResultMeta,
      queryLoading,
      queryProperties,
    });
  }, [location.search, queryProperties]);

  useEffect(() => {
    setPropertyQueryResults({
      queryResult,
      queryResultMeta,
      queryLoading,
      queryProperties,
    });
  }, [queryResult]);

  useEffect(() => {
    queryProperties(query, urlPage);
  }, [urlPage]);

  const handleSubmitClaim = async (communitiesToClaim) => {
    try {
      await claimCommunity(communitiesToClaim);
      queryProperties(query);
      enqueueSnackbar(`Claim submitted!`, {
        variant: 'success',
      });
      setIsClaimPopupOpen(false);
    } catch (err) {
      const errorMessage = APITools.errorMessageFromServerResponse(err);
      enqueueSnackbar(errorMessage, { variant: 'warning' });
    }
  };

  const handleCloseClaimPopup = () => {
    setIsClaimPopupOpen(false);
  };

  const checkStatus = (communities, property) => {
    const communityStatuses = communities.map(
      (community) => community.community_claim_for_account?.status || undefined
    );
    if (user.role === 'community_manager') {
      return communityStatuses.every((status) => status === 'rejected')
        ? 'Unavailable'
        : communityStatuses.every((status) => status === 'pending')
        ? 'Pending'
        : communityStatuses.every((status) => status === 'approved')
        ? 'Claimed'
        : 'Claim';
    } else {
      return user?.organization?.id === property?.organization?.id
        ? 'Claimed'
        : communityStatuses.every((status) => status === 'rejected')
        ? 'Unavailable'
        : communityStatuses.every((status) => status === 'pending')
        ? 'Pending'
        : communityStatuses.every((status) => status === 'approved')
        ? 'Claimed'
        : 'Claim';
    }
  };
  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Property Name',
        accessor: 'name',
        minWidth: 400,
        width: 400,
        maxWidth: 400,
        Cell: ({ row }) => <PropertyNameCell row={row} />,
      },
      {
        Header: () => <div className='pl-50'>Care type(s)</div>,

        accessor: 'care_type',
        minWidth: 200,
        width: 200,
        maxWidth: 200,
        Cell: PropertyCareTypesCell,
      },
      {
        Header: () => <div className='pl-50'>Organization</div>,
        accessor: 'organization.name',
        minWidth: 200,
        width: 200,
        maxWidth: 200,
        Cell: ({ value }) => <div className='pl-50'>{value}</div>,
      },
      {
        Header: '',
        accessor: 'id',
        minWidth: 200,
        width: 200,
        maxWidth: 200,
        Cell: ({ row }) => {
          const buttonStatus = checkStatus(row.original.communities, row.original);
          return user.role !== 'admin' ? (
            <div className={styles.claimButton}>
              <Button.DARK
                disabled={buttonStatus !== 'Claim'}
                onClick={() => setIsClaimPopupOpen(row.original)}
              >
                {buttonStatus}
              </Button.DARK>
            </div>
          ) : null;
        },
      },
    ];
    return cols;
  }, [queryResult]);

  const handleQueryChange = useCallback(
    (newQuery) => {
      setQuery(newQuery);
    },
    [setQuery]
  );

  const handleQuerySearch = (query) => {
    queryProperties(query);
  };

  return (
    <div className={`page-layout communities-dashboard ${styles.searchAndClaimPage}`}>
      <div className='header bordered'>
        <div className='header-title'>
          <h1>Search and Claim</h1>
        </div>
      </div>

      <SearchBar
        urlQuery={{ name: urlQueryName, geo: urlQueryGeoPlace }}
        onQueryChange={handleQueryChange}
        onSubmit={handleQuerySearch}
      />
      <div className='communities-container'>
        <div className='admin-communities'>
          <Loader isLoading={queryLoading}>
            <PropertyResultsList
              title='Your properties'
              properties={propertyQueryResults.queryResult}
              meta={propertyQueryResults.queryResultMeta}
              columns={columns}
            />

            <KthPopup
              isOpen={isClaimPopupOpen !== false}
              title={`Claim ${isClaimPopupOpen.name}`}
              onClose={handleCloseClaimPopup}
              hideActions
            >
              <PropertyClaimPopup
                property={isClaimPopupOpen}
                onClose={handleCloseClaimPopup}
                onSubmitClaim={handleSubmitClaim}
              />
            </KthPopup>
          </Loader>
        </div>
      </div>
    </div>
  );
};
