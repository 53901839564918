import { useState, useCallback, useEffect } from 'react';
import Button from '../Button/Button';
import styles from './UserList.module.scss';
import { Input, ListItemText, MenuItem, Select, debounce } from '@mui/material';
import { useOrganizationsInfinite } from '../../hooks/useOrganizationsInfinite';

const UsersSearch = ({
  isSuperAdmin,
  onSearch,
  onUserChange,
  urlQuery,
  userTypeTitle,
}) => {
  const { organizations, loading: organizationLoading } =
    useOrganizationsInfinite(isSuperAdmin);
  const [query, setQuery] = useState(urlQuery);

  const handleQueryChange = (e) => {
    const { name, value } = e.target;
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: value,
    }));
  };

  useEffect(() => {
    delayedOnQueryChange(query);
  }, [query]);

  const delayedOnQueryChange = useCallback(
    (newQuery) => {
      onUserChange(newQuery);
    },
    [onUserChange]
  );

  return (
    <div className={`communities-dashboard`} style={{ paddingBottom: '0px' }}>
      <div className='search-bar-container'>
        <h3>Search {userTypeTitle}</h3>
        <form className='search-bar' onSubmit={onSearch}>
          <input
            name='q'
            value={query.q || ''}
            onChange={handleQueryChange}
            placeholder='Name or Email'
          />
          <div className='separator__user' />
          <input
            name='account_id'
            value={query.account_id || ''}
            onChange={handleQueryChange}
            placeholder='Account ID'
          />
          <div className='separator__user' />
          <div className='search-bar-dropdown'>
            <Select
              displayEmpty
              disableUnderline
              name='organization_id'
              disabled={organizationLoading}
              value={query.organization_id || ''}
              onChange={handleQueryChange}
              input={<Input label='Organization' sx={{ width: '100%' }} />}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: '300px',
                  },
                },
              }}
            >
              <MenuItem value=''>
                <ListItemText primary='Organization' />
              </MenuItem>
              {organizations?.map(({ id, name }) => {
                if (id && name) {
                  return (
                    <MenuItem key={id} value={id}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </div>
          <div className='separator__user' />
          <Button.DARK className='search-button' type='submit'>
            Search
          </Button.DARK>
        </form>
      </div>
    </div>
  );
};

export default UsersSearch;
