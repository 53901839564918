import { Provider as ReduxProvider } from 'react-redux';
import { useStore } from '../../redux/store';
import Routes from '../../routes/index.routes';
import { StyledEngineProvider } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CookiesProvider } from 'react-cookie';
import { setApiInterceptors } from '../../services/api';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { hotjar } from 'react-hotjar';
import AmplitudeTools from '../../utils/AmplitudeTools';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ac947a',
    },
  },
});

Sentry.init({
  dsn: 'https://e8dc0238890a494a8dd42784a6850ed0@o187005.ingest.sentry.io/6202254',
  integrations: [
    new BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
  tracesSampleRate: 0.1,
  enabled: process.env.REACT_APP_ENVIRONMENT !== 'development',
  environment: process.env.REACT_APP_ENVIRONMENT || 'staging',
  // excluding the health page
  denyUrls: [/\/health\/?/i],
});

const MEASUREMENT_ID =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? 'G-KEPV02D12Z' : 'G-4724RTBX29';

ReactGA.initialize(MEASUREMENT_ID);

const HOTJAR_ID = process.env.REACT_APP_ENVIRONMENT === 'production' ? 2972339 : 2972348;

hotjar.initialize(HOTJAR_ID, 6);

AmplitudeTools.init();

function App() {
  const { store } = useStore();

  useEffect(() => {
    setApiInterceptors(store);
  }, [store]);

  return (
    <ReduxProvider store={store}>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <Routes />
          </StyledEngineProvider>
        </ThemeProvider>
      </CookiesProvider>
    </ReduxProvider>
  );
}

export default App;
