import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryProperties } from '../../hooks/useQueryProperties';
import styles from './Subscriptions.module.scss';
import { useTable } from 'react-table';
import SubscriptionItem from '../../components/SubscriptionItem/SubscriptionItem';
import KthPopup from '../../components/KthPopup/KthPopup';
import { PricingPopup } from '../../components/PricingPopup/PricingPopup';
import Loader from '../../components/Loader/Loader';
import { useParams } from 'react-router-dom';
import UpsellPage from '../../components/UpsellPage/UpsellPage';
import { useQueryParams } from '../../hooks/useQueryParams';
import { Pagination } from '../../components/Pagination/Pagination';
import { Storage } from 'web-core';
import CONFIG from '../../config/config';
import { useSelector } from 'react-redux';
import { FeatureGating } from '../../components/FeatureGating/FeatureGating';

const SubscriptionDashboard = () => {
  const userRole = useSelector((state) => state?.auth?.user?.role);
  const [loading, setLoading] = useState(false);

  const [showPricingPopup, setShowPricingPopup] = useState(false);
  const propertyIdRef = useRef();
  const handlePricingPopupOpen = (propertyId) => {
    propertyIdRef.current = propertyId;
    setShowPricingPopup(true);
  };
  const handlePricingPopupClose = () => {
    setShowPricingPopup(false);
  };

  const [showCheckoutPopup, setShowCheckoutPopup] = useState(false);
  const { urlParam } = useParams();
  useEffect(() => {
    if (urlParam === 'checkout') {
      setShowCheckoutPopup(true);
    }
  }, []);
  const handleCheckoutPopupClose = () => {
    setShowPricingPopup(false);
    window.location.replace('/premium');
  };

  const orgIdKey = 'organization_id';

  // Check if admin user is being redirected to page from Stripe.
  // If so add organization_id param to url.
  if (userRole === 'admin') {
    const storedOrgId = Storage.get(orgIdKey);
    if (storedOrgId && window.location.pathname === '/premium') {
      const urlWithOrg = `/premium?organization_id=${storedOrgId}`;
      window.location.replace(urlWithOrg);
      Storage.remove(orgIdKey);
    }
  }

  let apiUrl;
  const urlOrganizationId = useQueryParams().get(orgIdKey);
  if (urlOrganizationId) {
    apiUrl = `/v1/admin/properties/managed?organization_id=${urlOrganizationId}`;
    Storage.set(orgIdKey, urlOrganizationId);
  } else {
    apiUrl = '/v1/admin/properties/managed';
  }
  const { queryResult, queryProperties, queryResultMeta } = useQueryProperties(apiUrl);
  useEffect(() => {
    queryProperties();
  }, []);

  const paginationOptions = queryResultMeta
    ? {
        initialState: {
          pageSize: queryResultMeta['per_page'],
          pageIndex: queryResultMeta['current_page'] - 1,
        },
        pageCount: queryResultMeta['total_pages'],
        manualPagination: true,
      }
    : null;

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 350,
        width: 350,
        maxWidth: 350,
      },
      {
        Header: 'Plan',
        accessor: 'stripe_subscription.status',
        width: 100,
      },
    ];
  }, [queryResult]);
  const data = queryResult ? queryResult : [];
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    ...paginationOptions,
  });

  return (
    <div className='page-layout'>
      <UpsellPage />
      <div className={styles.propertyListContainer}>
        <Loader isLoading={loading}>
          {urlOrganizationId ? <h4>Properties</h4> : <h4>Your Properties</h4>}
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className='p4' {...column.getHeaderProps()}>
                      {column.Header}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((propertyRow) => {
                prepareRow(propertyRow);
                return (
                  <SubscriptionItem
                    propertyRow={propertyRow}
                    {...propertyRow.getRowProps()}
                    handlePricingPopupOpen={handlePricingPopupOpen}
                    setLoading={setLoading}
                  />
                );
              })}
            </tbody>
          </table>
          {data && queryResultMeta && (
            <Pagination meta={queryResultMeta} columns={columns} data={data} />
          )}
        </Loader>
      </div>
      <KthPopup isOpen={showPricingPopup} hideActions onClose={handlePricingPopupClose}>
        <PricingPopup propertyId={propertyIdRef.current} />
      </KthPopup>
      <KthPopup isOpen={showCheckoutPopup} hideActions onClose={handleCheckoutPopupClose}>
        <p>Thanks for upgrading.</p>
        <p>It may take a minute for your subscription to update.</p>
      </KthPopup>
    </div>
  );
};

export default SubscriptionDashboard;
