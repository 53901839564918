import styles from './PropertyAssessmentLinksList.module.scss';
import React, { useMemo } from 'react';
import { AssessmentLinkProps } from '../../types/AssessmentLinkProps';
import { useTable, Column } from 'react-table';
import { useHistory } from 'react-router-dom';
import { Pagination } from '../Pagination/Pagination';
import { format } from 'date-fns';
import { StringUtils } from 'web-core';

type PropertyAssessmentLinkListProps = {
  assessmentLinks: AssessmentLinkProps[];
  propertySlug: string | undefined;
  meta: any; // TODO -add standard type for pagination meta
};

const PropertyAssessmentLinksList: React.FC<PropertyAssessmentLinkListProps> = ({
  assessmentLinks,
  meta,
  propertySlug,
}) => {
  const history = useHistory();
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Name',
          accessor: 'name',
          minWidth: 150,
        },
        {
          Header: 'Type',
          accessor: 'assessment_type',
          minWidth: 100,
          Cell: (props: any) => {
            const { value } = props;
            return <>{value ? StringUtils.capitalizeFirstLetter(value) : '-'}</>;
          },
        },
        {
          Header: 'Style',
          accessor: 'link_style_name',
          minWidth: 100,
          Cell: (props: any) => {
            const { value } = props;
            return <>{value ? value : '-'}</>;
          },
        },
        {
          Header: 'Location',
          accessor: 'location',
          minWidth: 150,
        },
        {
          Header: 'Identifier',
          accessor: 'content_identifier',
          minWidth: 150,
        },
        {
          Header: 'Created',
          accessor: 'created_at',
          minWidth: 100,
          Cell: (props: any) => {
            const { value } = props;
            return <>{value ? format(new Date(value), 'MMMM d, yyyy') : '-'}</>;
          },
        },
      ] as Column<AssessmentLinkProps>[],
    [assessmentLinks]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: assessmentLinks,
  });

  const onItemClick = (uuid: string | undefined) => {
    if (uuid && propertySlug) {
      history.push({
        pathname: `/properties/${propertySlug}/assessment-links/${uuid}/edit`,
      });
    }
  };

  return (
    <div className={styles.assessmentLinkListContainer}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps({
                style: { width: '100%' },
              })}
              key={`${headerGroup.Header?.toString() || ''}`}
            >
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.Header?.toString()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                className={styles.linkItem}
                {...row.getRowProps()}
                onClick={() => onItemClick(row.original.uuid)}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {assessmentLinks && meta && (
        <Pagination meta={meta} columns={columns} data={assessmentLinks} />
      )}
    </div>
  );
};

export default PropertyAssessmentLinksList;
