import styles from './RejectCommunityEditRequest.module.scss';
import { SquaredCheckbox } from '../../../DynamicForm/Widgets/SquaredCheckbox/SquaredCheckbox';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import Loader from '../../../Loader/Loader';
import Button from '../../../Button/Button';

interface RejectCommunityEditRequestProps {
  title: string;
  reason: string;
  onChangeReason: (event: React.ChangeEvent) => void;
  silentRejection: boolean;
  onSkipChange: (event: React.ChangeEvent) => void;
  isLoading: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const RejectCommunityEditRequest: React.FC<RejectCommunityEditRequestProps> = ({
  title,
  reason,
  onChangeReason,
  silentRejection,
  onSkipChange,
  isLoading,
  onConfirm,
  onClose,
}) => {
  return (
    <div className={styles.rejectPopup}>
      <div className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
        <button className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      </div>

      <p className={styles.rejectPrompt}>
        Please explain why you're rejecting these changes
      </p>

      <textarea
        value={reason}
        onChange={onChangeReason}
        placeholder='Type the reason...'
        className={styles.textArea}
      />

      <SquaredCheckbox
        className={styles.silentRejectFlag}
        id={''}
        label={'Silently Reject Changes'}
        value={silentRejection}
        onChange={onSkipChange}
      />

      <div className={styles.bottomButton}>
        <Loader isLoading={isLoading}>
          <Button.BLACK onClick={onConfirm}>Reject Changes</Button.BLACK>
        </Loader>
      </div>
    </div>
  );
};

export default RejectCommunityEditRequest;
