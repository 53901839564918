import { diff } from 'deep-diff';
import { Constants } from 'web-core';

const filteredKeys = [
  Constants.DynamicSchemaFields.StarRating,
  Constants.DynamicSchemaFields.GeocodedCountry,
  Constants.DynamicSchemaFields.GeocodedRegion,
  Constants.DynamicSchemaFields.GeocodedDistrict,
  Constants.DynamicSchemaFields.GeocodedPlace,
  Constants.DynamicSchemaFields.GeocodedNeighborhood,
  Constants.DynamicSchemaFields.PoiGeocodedCountry,
  Constants.DynamicSchemaFields.PoiGeocodedRegion,
  Constants.DynamicSchemaFields.PoiGeocodedDistrict,
  Constants.DynamicSchemaFields.PoiGeocodedPlace,
  Constants.DynamicSchemaFields.PoiGeocodedNeighborhood,
  Constants.DynamicSchemaFields.PoiGeocodedLatitude,
  Constants.DynamicSchemaFields.PoiGeocodedLongitude,
  Constants.DynamicSchemaFields.LocalEventGeocodedCountry,
  Constants.DynamicSchemaFields.LocalEventGeocodedRegion,
  Constants.DynamicSchemaFields.LocalEventGeocodedDistrict,
  Constants.DynamicSchemaFields.LocalEventGeocodedPlace,
  Constants.DynamicSchemaFields.LocalEventGeocodedNeighborhood,
  Constants.DynamicSchemaFields.LocalEventGeocodedLatitude,
  Constants.DynamicSchemaFields.LocalEventGeocodedLongitude,
  Constants.DynamicSchemaFields.UnitBuildingId,
  Constants.DynamicSchemaFields.UnitModel,
];

const convertObjectKeys = (key) => {
  switch (key) {
    case 'models':
      return Constants.DynamicSchemaFields.ModelName;
    case 'buildings':
      return Constants.DynamicSchemaFields.BuildingName;
    case 'units':
      return Constants.DynamicSchemaFields.UnitName;
    default:
      return key;
  }
};

// If both published value and draft value are falsy ones, we will not show the differences
// to the queue changes.(e.g. if published value is null and draft value is an empty string)
export const checkDiffCondition = (diff) => {
  if (
    typeof diff?.publishedValue === 'boolean' ||
    typeof diff?.draftValue === 'boolean'
  ) {
    return true;
  } else {
    return !!diff?.publishedValue || !!diff?.draftValue;
  }
};

export const isValidFieldValue = (value) => {
  if (value == null) {
    return false;
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  } else if (typeof value === 'object') {
    return Object.entries(value).length > 0;
  } else if (typeof value === 'boolean') {
    return true;
  } else {
    return !!value;
  }
};

export const fieldNameComponent = (fieldDiff, row) => {
  return (
    <b>
      {fieldDiff.elementField
        ? fieldDiff.elementField
        : fieldDiff.arrayField
        ? fieldDiff.arrayField
        : fieldDiff.field === row.title || fieldDiff.field === fieldDiff.fieldName
        ? ''
        : fieldDiff.field}
    </b>
  );
};

// we don't insert array_item_id when we are adding / removing a whole array(e.g. a building or unit type)
// since there are multiple fields in an array item, we don't show admin notes fields there.
export const hideAdminNotesOnCommunityEdits = (arrayItemId) => {
  return !arrayItemId;
};

/**
 * generate field name for queue changes display
 * @param key dynamic schema key
 * @returns {*|string|null}
 */
export const generateFieldName = (key) => {
  const splitKey = key ? key.split('|') : undefined;
  const row = splitKey ? splitKey[splitKey.length - 2] : undefined;
  const field = splitKey ? splitKey[splitKey.length - 1] : undefined;
  return row === field
    ? field
    : row && field
    ? row + ' // ' + field
    : row && !field
    ? row
    : field && !row
    ? field
    : null;
};

export const parseSchemaKey = (key) => {
  let splitArr = key ? convertObjectKeys(key).split('|') : undefined;
  let [tab, section, row, field] = splitArr;
  return [tab, section, row, field];
};

/**
 * Parse the path property from a diff object
 *
 * @param path the array of path. There may be more than one path
 *             array and object diff will have 3-4 paths
 *             array diff nested in an object will have 5 paths
 * @returns {{}} the object contains all diff path properties
 */
const parseArrayPath = (path) => {
  if (!Array.isArray(path)) return {};

  let diffProps = {};
  let length = path.length;
  if (length >= 5) {
    diffProps.elementField = [...path[4].split('|')].pop();
  }

  if (length >= 4) {
    diffProps.elementIndex = path[3];
  }

  if (length >= 3) {
    diffProps.arrayField = [...path[2].split('|')].pop();
    diffProps.arrayKey = path[2];
    diffProps.arrayIndex = path[1];
  }

  diffProps.field = [...path[0].split('|')].pop();

  return diffProps;
};

const getSchemaKey = (diff) => {
  return Array.isArray(diff?.path) ? diff?.path[0] : undefined;
};

/**
 * Generate fieldName for array type diffs
 * @param diffProps
 * @returns {`${string|*}`}
 */
const getComplexObjectFieldName = (diffProps) => {
  let complexObjFieldName = diffProps.field;
  if (complexObjFieldName === 'models') {
    complexObjFieldName = 'Unit Type';
  } else if (complexObjFieldName === 'units') {
    complexObjFieldName = 'Unit';
  } else if (complexObjFieldName === 'buildings') {
    complexObjFieldName = 'Building';
  }

  return `${
    diffProps.elementField
      ? `${complexObjFieldName} #${diffProps.arrayIndex + 1} / ${diffProps.arrayField} #${
          diffProps.elementIndex + 1
        }`
      : diffProps.arrayField
      ? `${complexObjFieldName} #${diffProps.arrayIndex + 1}`
      : complexObjFieldName
  }`;
};

// Note: Admin Note "section" field only has 3 pipes
const generateArrayTypeFieldsForAdminNotes = (note, data) => {
  let [tab, section, row] = note.section.split('|');
  let key;
  switch (tab) {
    case 'Building-Details':
      key = 'buildings';
      break;
    case 'Model-Type':
      key = 'models';
      break;
    default:
      key = '';
      break;
  }
  let keyName = convertObjectKeys(key);
  let rowName;

  let id = note.array_item_id;
  if (id && key && data[key]) {
    const ele = data[key].find((e) => e.id === id);
    if (ele) {
      rowName = ele[keyName] + ' // ' + row;
    }
  } else {
    rowName = row;
  }
  return [tab, section, rowName];
};

export const generateDiffGrid = (adminNotes, publishedData, draftData) => {
  const translatedDiffs = [];
  const initialDiffs = diff(publishedData.json_data, draftData.json_data);
  // const initialDiffs = diff(publishedData, draftData);
  if (!initialDiffs) {
    return;
  }

  // console.log(initialDiffs);

  initialDiffs.forEach((diffItem) => {
    const key = getSchemaKey(diffItem);
    const arr = parseSchemaKey(key);
    const [tab, section, row, field] = arr;
    const diffProps = parseArrayPath(diffItem.path);

    if (!key) {
      return;
    }
    if (filteredKeys.includes(key)) {
      return;
    }

    // case when adding a new simple value(strings, numbers, but not array, object)
    // NOTE: adding a field in an array type field will also treat as adding a new simple value
    //       hence need to not handling array; Adding an array from scratch will also be recognized
    //       as a 'N' kind, but this should be treated as 'A' kind.
    // adding check for isArray for diffItem value, and skip handling in this case.
    if (
      diffItem.kind === 'N' &&
      draftData.json_data[key] !== undefined &&
      !Array.isArray(diffItem.rhs)
    ) {
      if (key === Constants.DynamicSchemaFields.PropertyId) {
        pushPropertyIdDiffsIfNecessary(translatedDiffs, publishedData, draftData);
      } else if (key === 'buildings' || key === 'models') {
        const currentElement = draftData.json_data[key][diffProps.arrayIndex];
        let { arrayKey } = diffProps;
        let [arrayTab, arraySection, arrayRow, arrayField] =
          arrayKey && parseSchemaKey(arrayKey);
        translatedDiffs.push({
          key,
          tab,
          section,
          row: currentElement[convertObjectKeys(key)] + ' // ' + arrayRow, // e.g. Building A // Description
          field: arrayField,
          fieldName: currentElement[convertObjectKeys(key)], // get building/model's name
          id: currentElement.id, // array type diff needs array_item_id in order for admin notes to work
          arrayKey,
          arrayField: diffProps.arrayField,
          elementField: diffProps.elementField,
          publishedValue: diffItem.lhs,
          draftValue: diffItem.rhs,
        });
      } else {
        translatedDiffs.push({
          key,
          tab,
          section,
          row,
          field,
          fieldName: getComplexObjectFieldName(diffProps),
          arrayField: diffProps.arrayField,
          elementField: diffProps.elementField,
          publishedValue: null,
          draftValue: diffItem.rhs,
        });
      }
    } else if (
      // case when editing a field
      diffItem.kind === 'E' &&
      publishedData.json_data[key] !== undefined &&
      draftData.json_data[key] !== undefined
    ) {
      if (key === Constants.DynamicSchemaFields.PropertyId) {
        pushPropertyIdDiffsIfNecessary(translatedDiffs, publishedData, draftData);
      } else if (key === 'buildings' || key === 'models') {
        const currentElement = draftData.json_data[key][diffProps.arrayIndex];
        let { arrayKey } = diffProps;
        let [arrayTab, arraySection, arrayRow, arrayField] =
          arrayKey && parseSchemaKey(arrayKey);
        translatedDiffs.push({
          key,
          tab,
          section,
          row: currentElement[convertObjectKeys(key)] + ' // ' + arrayRow,
          field: arrayField,
          fieldName: currentElement[convertObjectKeys(key)],
          id: currentElement.id,
          arrayKey,
          arrayField: diffProps.arrayField,
          elementField: diffProps.elementField,
          publishedValue: diffItem.lhs,
          draftValue: diffItem.rhs,
        });
      } else {
        translatedDiffs.push({
          key,
          tab,
          section,
          row,
          field,
          fieldName: getComplexObjectFieldName(diffProps),
          arrayField: diffProps.arrayField,
          elementField: diffProps.elementField,
          publishedValue: diffItem.lhs,
          draftValue: diffItem.rhs,
        });
      }
    } else if (
      // case when deleting a value in a field whose value is a simple value.
      diffItem.kind === 'D' &&
      publishedData.json_data[key] !== undefined &&
      !draftData.json_data[key] &&
      !Array.isArray(diffItem.lhs)
    ) {
      if (key === Constants.DynamicSchemaFields.PropertyId) {
        pushPropertyIdDiffsIfNecessary(translatedDiffs, publishedData, draftData);
      } else if (key === 'buildings' || key === 'models') {
        const currentElement = publishedData.json_data[key][diffProps.arrayIndex];
        let { arrayKey } = diffProps;
        let [arrayTab, arraySection, arrayRow, arrayField] =
          arrayKey && parseSchemaKey(arrayKey);
        translatedDiffs.push({
          key,
          tab,
          section,
          row: currentElement[convertObjectKeys(key)] + ' // ' + arrayRow,
          field: arrayField,
          fieldName: currentElement[convertObjectKeys(key)],
          id: currentElement.id,
          arrayKey,
          arrayField: diffProps.arrayField,
          elementField: diffProps.elementField,
          publishedValue: diffItem.lhs,
          draftValue: diffItem.rhs,
        });
      } else {
        translatedDiffs.push({
          key,
          tab,
          section,
          row,
          field,
          fieldName: getComplexObjectFieldName(diffProps),
          arrayField: diffProps.arrayField,
          elementField: diffProps.elementField,
          publishedValue: diffItem.lhs,
          draftValue: null,
        });
      }
    } else if (
      diffItem.kind === 'A' ||
      Array.isArray(diffItem.lhs) ||
      Array.isArray(diffItem.rhs) ||
      key === 'buildings' ||
      key === 'models' ||
      key === 'units'
    ) {
      // deep-diff recognizes differences as Array type only if we are adding/removing an element into an array
      // if we are editing one of the element, the difference type will be determined as Edit(kind === 'E')

      // NOTE: Adding/removing a new array for model / building will be recognized as kind 'N'/'D', but we want to
      //       treat them as an 'A' type so adding a check for diffItem values types, if the type is an array, we
      //       handle the diffItem as Array regardless of its original kind.
      const { item } = diffItem;
      if (item) {
        translatedDiffs.push({
          key,
          tab,
          section,
          row:
            key === 'buildings' || key === 'models' || key === 'units'
              ? key.charAt(0).toUpperCase() + key.slice(1)
              : row,
          field,
          fieldName: `${getComplexObjectFieldName(diffProps)}${
            diffItem.index ? ` #${diffItem.index + 1}` : ''
          }`,
          publishedValue:
            item.kind === 'N' ? null : item.kind === 'D' ? item.lhs : undefined,
          draftValue: item.kind === 'N' ? item.rhs : item.kind === 'D' ? null : undefined,
        });
      } else {
        translatedDiffs.push({
          key,
          tab,
          section,
          row:
            key === 'buildings' || key === 'models' || key === 'units'
              ? key.charAt(0).toUpperCase() + key.slice(1)
              : row,
          field: field === undefined ? '' : field,
          fieldName: `${getComplexObjectFieldName(diffProps)}${
            diffItem.index ? ` #${diffItem.index + 1}` : ''
          }`,
          publishedValue:
            diffItem.kind === 'N'
              ? null
              : diffItem.kind === 'D'
              ? diffItem.lhs
              : undefined,
          draftValue:
            diffItem.kind === 'N'
              ? diffItem.rhs
              : diffItem.kind === 'D'
              ? null
              : undefined,
        });
      }
    }
  });

  // fill fields that does not contain changes, but with admin note(s) to translatedDiffs.
  Array.isArray(adminNotes) &&
    adminNotes.forEach((note) => {
      // if an admin notes contains a valid array_item_id, meaning this admin notes belongs to
      // an array field item.
      let [tab, section, row, field] = note.array_item_id
        ? generateArrayTypeFieldsForAdminNotes(note, publishedData.json_data)
        : parseSchemaKey(note.section);
      // Don't add array field type admin notes belong to a deleted building / model / unit
      // TODO: This is just a temp. fix for the edge case where we load admin notes for deleted
      //       building / model / unit, eventually we need to implement something like remove all
      //       corresponding admin notes if we delete a building / model / unit.
      if (translatedDiffs.findIndex((diff) => diff.row === row) === -1 && !!row) {
        translatedDiffs.push({
          key: note.section,
          tab,
          section,
          row,
          field,
          fieldName: row,
          id: note.array_item_id, // we need array_item_id for array type admin notes
          publishedValue: null,
          draftValue: null,
        });
      }
    });

  // console.log(adminNotes);
  // console.log(translatedDiffs);

  return translatedDiffs.filter(
    (v, i, a) =>
      a.findIndex(
        (t) =>
          t.key === v.key &&
          t.row === v.row &&
          t.publishedValue === v.publishedValue &&
          t.draftValue === v.draftValue
      ) === i
  );
};

const getPropertyObject = (obj) => {
  if (obj === null || obj.property === null) {
    return null;
  } else {
    const propertyName = obj['property']['name'].toString();
    const streetAddress = obj['property']['street'];
    const city = obj['property']['city'];
    const state = obj['property']['state'];
    const postal = obj['property']['postal'];
    const country = obj['property']['country'];
    let address = [streetAddress, city, state, postal, country].join(', ');
    return [propertyName, address];
  }
};

const pushPropertyIdDiffsIfNecessary = (translatedDiffs, publishedData, draftData) => {
  if (
    publishedData.property_id !==
    draftData.json_data[Constants.DynamicSchemaFields.PropertyId]
  ) {
    let [tab, section, row, field] = parseSchemaKey(
      Constants.DynamicSchemaFields.PropertyId
    );
    translatedDiffs.push({
      key: Constants.DynamicSchemaFields.PropertyId,
      tab,
      section,
      row,
      field,
      fieldName: 'Property',
      publishedValue: getPropertyObject(publishedData),
      draftValue: getPropertyObject(draftData),
    });
  }
};
