import { Link, useHistory, useParams } from 'react-router-dom';
import { idFromSlug } from '../../../utils/urlTools';
import HeaderTitle from '../../../components/HeaderTitle/HeaderTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-thin-svg-icons';
import PropertyAssessmentLinksList from '../../../components/PropertyAssessmentLinksList/PropertyAssessmentLinksList';
import { useAssessmentLinks } from '../../../hooks/useAssessmentLinks';
import Button from '../../../components/Button/Button';
import api from '../../../services/api';
import { APITools } from 'web-core';
import { useSnackbar } from 'notistack';
import Popup from 'reactjs-popup';
import { useRef } from 'react';

const LinkTypeEducational = 'educational';
const LinkTypeFinancial = 'financial';
const StyleBanner = 'banner';
const StyleLink = 'text_link';

const PropertyAssessmentLinksDashboard = () => {
  const history = useHistory();
  const { property_slug } = useParams<{ property_slug?: string }>();
  const propertyId = idFromSlug(property_slug);
  // fetch all assessment links belongs to property
  const { data, meta, property, loading } = useAssessmentLinks(propertyId);
  const menuRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const createNewAssessmentLink = async (assessmentType: string, linkStyle: string) => {
    try {
      const assessmentProps = {
        link_style: linkStyle,
        assessment_type: assessmentType,
      };
      const { data } = await api.post(
        `/v1/admin/properties/${propertyId}/assessment_links`,
        assessmentProps
      );

      const assessmentLink = data;
      // @ts-ignore
      menuRef.current.close();
      history.push({
        pathname: `/properties/${property_slug}/assessment-links/${assessmentLink.uuid}/edit`,
      });
    } catch (e) {
      console.log('error creating assessment link', e);
      enqueueSnackbar(APITools.errorMessageFromServerResponse(e), {
        variant: 'warning',
      });
    }
  };

  return !data && loading ? (
    <div className='ml-auto mr-auto mt-100 fs-400 text-center'>
      <FontAwesomeIcon icon={faSpinner} width={50} height={50} spin />
    </div>
  ) : (
    <>
      <HeaderTitle title={`Assessment Links${property ? ` - ${property.name}` : ''}`} />
      <div className='page-layout' style={{ width: '100%' }}>
        <div className={'header-breadcrumbs'}>
          {property ? (
            <Link to={`/properties/${property.slug}`}>{property.name}</Link>
          ) : (
            <br />
          )}
        </div>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Assessment Links</h1>
          </div>
          <div className='action-container-properties'>
            <Popup
              ref={menuRef}
              trigger={
                <div>
                  <Button.BLACK_OUTLINED style={{ minWidth: '200px' }}>
                    Create a Link
                  </Button.BLACK_OUTLINED>
                </div>
              }
              position={'bottom right'}
              className='dropdown-menu-popup'
              arrow={false}
              closeOnEscape={true}
              closeOnDocumentClick={true}
              overlayStyle={{
                backgroundColor: 'transparent',
              }}
            >
              <div className='menu'>
                <div
                  className='menu-item'
                  onClick={() =>
                    createNewAssessmentLink(LinkTypeEducational, StyleBanner)
                  }
                >
                  Educational Assessment Banner
                </div>
                <div
                  className='menu-item'
                  onClick={() => createNewAssessmentLink(LinkTypeEducational, StyleLink)}
                >
                  Educational Assessment Link
                </div>
                <div
                  className='menu-item'
                  onClick={() => createNewAssessmentLink(LinkTypeFinancial, StyleBanner)}
                >
                  Financial Assessment Banner
                </div>
                <div
                  className='menu-item'
                  onClick={() => createNewAssessmentLink(LinkTypeFinancial, StyleLink)}
                >
                  Financial Assessment Link
                </div>
              </div>
            </Popup>
          </div>
        </div>
        <div className='communities-dashboard'>
          <div className='communities-container'>
            <p>
              Create Banners and Links to your Educational and Financial Assessments for
              embedding in your website, blog and in other marketing materials.
            </p>
            <div className='admin-communities'>
              <PropertyAssessmentLinksList
                assessmentLinks={data}
                meta={meta}
                propertySlug={property_slug}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyAssessmentLinksDashboard;
