import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Constants } from 'web-core';
import { selectUnitFormBuildingId } from '../../../../redux/selectors/communityForm';
import { getOrdinal } from '../../../../utils/ordinalNumber';
import { CustomSelect } from '../../../DynamicForm/Widgets/CustomSelect/CustomSelect';

export const BuildingFloorSelect = (props) => {
  const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const formData = useSelector((state) => state.communityForm.formData);
  const [options, setOptions] = useState([]);
  const unitFormBuildingId = useSelector(selectUnitFormBuildingId);

  useEffect(() => {
    if (unitFormBuildingId) {
      const unitBuilding = formData.buildings
        ? formData.buildings.find((b) => b.id === unitFormBuildingId)
        : undefined;
      const buildingFloors = unitBuilding
        ? unitBuilding[Constants.DynamicSchemaFields.BuildingFloors]
        : undefined;

      const newOptions = !buildingFloors
        ? []
        : Array.from({ length: buildingFloors }, (_, index) => ({
            label: `${getOrdinal(index + 1)} Floor`,
            value: index + 1,
          }));

      setOptions(newOptions);
    } else {
      const newOptions = Array.from({ length: 50 }, (_, index) => ({
        label: `${getOrdinal(index + 1)} Floor`,
        value: index + 1,
      }));
      setOptions(newOptions);
    }
  }, [formData, uiSchema, jsonSchema, unitFormBuildingId]);

  return (
    <CustomSelect
      {...props}
      options={{
        enumOptions: options,
      }}
    />
  );
};
