import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Constants } from 'web-core';
import { changeBuildingsForm } from '../../../../redux/reducers';
import { selectBuildingsForm } from '../../../../redux/selectors/communityForm';
import Button from '../../../Button/Button';
import Icons from '../../../../constants/Icons';
import KthPopup from '../../../KthPopup/KthPopup';
import { Tooltip } from '@mui/material';
import DynamicForm from '../../../DynamicForm/DynamicForm';
import styles from './BuildingsForm.module.scss';

const BuildingsForm = ({ onSave, onDelete, onCancel }) => {
  const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const units = useSelector((state) => state.communityForm.formData.units) || [];
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const origForm = useSelector(selectBuildingsForm);
  const [form, setForm] = useState(origForm);
  const dispatch = useDispatch();

  const handleFormDataChange = ({ formData }) => {
    setForm(formData);
  };

  const handleSaveForm = () => {
    dispatch(changeBuildingsForm(form));
    onSave(form);
  };

  const handleCancelForm = () => {
    onCancel();
  };

  const handleDeleteForm = () => {
    onDelete(form);
  };

  let toolTipData;
  const unitsInBuilding = units.filter(
    (unit) => unit[Constants.DynamicSchemaFields.UnitBuildingId] === form.id
  );
  if (unitsInBuilding && unitsInBuilding.length > 0) {
    toolTipData = (
      <>
        <p>
          This Building cannot be deleted while it is associated with the following Units:
        </p>
        <ul>
          {unitsInBuilding.map((unit, index) =>
            unit[Constants.DynamicSchemaFields.UnitName] ? (
              <li key={index}>{unit[Constants.DynamicSchemaFields.UnitName]}</li>
            ) : (
              <li key={index}>Unnamed Unit</li>
            )
          )}
        </ul>
        <p>
          Delete these Units or modify their building association in order to delete this
          building.
        </p>
      </>
    );
  }

  return (
    <div className={styles.popupForm}>
      <div className={styles.header}>
        <div>
          <h3>
            {form[Constants.DynamicSchemaFields.BuildingName]
              ? form[Constants.DynamicSchemaFields.BuildingName]
              : 'Unnamed Building'}
          </h3>
          <h3 className={styles.tabDescription}>
            Add all the details about this Building, so that prospective residents can see
            which amenities are closest to units in it.
          </h3>
        </div>
      </div>
      <div className={styles.popupFormSection}>
        <DynamicForm
          schema={jsonSchema}
          uiSchema={uiSchema}
          formData={form}
          onChange={handleFormDataChange}
          onSubmit={(ev) => ev.preventDefault()}
          noValidate
          formContext={{ currentTab: 'Building-Details' }}
        />
      </div>
      <div className={`flex-kebab-row p-50 ${styles.bottomButtons}`}>
        <Button.LIGHT type='button' onClick={handleCancelForm}>
          Cancel
        </Button.LIGHT>
        {unitsInBuilding && unitsInBuilding.length > 0 && toolTipData ? (
          <Button.DARK icon disabled onClick={null} className='ml-100'>
            <Tooltip title={toolTipData} arrow className={null}>
              {Icons.Trash}
            </Tooltip>
          </Button.DARK>
        ) : (
          <Button.DARK
            icon
            className='trashButton'
            type='button'
            onClick={() => setIsDeleteOpen(true)}
          >
            {Icons.Trash}
          </Button.DARK>
        )}
        <Button.DARK className='saveButton' onClick={handleSaveForm}>
          Save Building
        </Button.DARK>
      </div>
      <KthPopup
        isOpen={isDeleteOpen}
        isLoading={null}
        title={`Delete Building`}
        confirmButtonLabel={'Delete Building'}
        onConfirmButtonClick={() => handleDeleteForm()}
        onClose={() => setIsDeleteOpen(false)}
      >
        <label>
          Are you sure you want to delete{' '}
          <strong>
            {form[Constants.DynamicSchemaFields.BuildingName]
              ? `building ${form[Constants.DynamicSchemaFields.BuildingName]}`
              : 'this Building'}
          </strong>
          ?
        </label>
      </KthPopup>
    </div>
  );
};

export default BuildingsForm;
