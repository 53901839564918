/* ============================================================================================== */

import produce from 'immer';
import { loadAdminNotes } from '../../hooks/useAddCommunityNotes';

export const AdminNotesActions = {
  LOADING_FOR_COMMUNITY: 'adminNotes/LOADING_FOR_COMMUNITY',
  LOADED_FOR_COMMUNITY: 'adminNotes/LOADED_FOR_COMMUNITY',
  ERROR_LOADING_FOR_COMMUNITY: 'adminNotes/ERROR_LOADING_FOR_COMMUNITY',
};

// TODO - implement force reload - if already loaded in store then don't do remote request again
export function loadAdminNotesForCommunity(communityId, forceReload) {
  return async (dispatch) => {
    dispatch({ type: AdminNotesActions.LOADING_FOR_COMMUNITY });
    try {
      let adminNotesResponse = await loadAdminNotes(communityId);
      if (adminNotesResponse) {
        dispatch({
          type: AdminNotesActions.LOADED_FOR_COMMUNITY,
          payload: {
            community_id: communityId,
            admin_notes: adminNotesResponse.results,
          },
        });
      } else {
        dispatch({ type: AdminNotesActions.ERROR_LOADING_FOR_COMMUNITY });
      }
    } catch (err) {
      dispatch({
        type: AdminNotesActions.ERROR_LOADING_FOR_COMMUNITY,
        error: err,
      });
    }
  };
}

const INITIAL_STATE = {
  communities: {},
};

const adminNotesReducer = produce((draft, action) => {
  switch (action.type) {
    case 'app/RESET':
      return INITIAL_STATE;

    case AdminNotesActions.LOADING_FOR_COMMUNITY:
      break;

    case AdminNotesActions.LOADED_FOR_COMMUNITY:
      const communityId = '' + action.payload.community_id; // community id as string always
      const adminNotes = action.payload.admin_notes;
      draft.communities[communityId] = adminNotes;
      break;

    case AdminNotesActions.ERROR_LOADING_FOR_COMMUNITY:
      break;

    default:
      break;
  }
}, INITIAL_STATE);

export default adminNotesReducer;
