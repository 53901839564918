import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomSelect } from '../CustomSelect/CustomSelect';

export const FacilitiesSelect = (props) => {
  const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const formData = useSelector((state) => state.communityForm.formData);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const checkedFacilitiesFieldNames = () => {
      const facilitiesCheckboxesFields = Object.values(
        uiSchema['checkboxesSelection']['Facilities']
      ).map((sectionFields) => sectionFields);
      const facilitiesCheckboxesFieldsFlat = facilitiesCheckboxesFields.flat();
      const facilitiesCheckboxesFieldsChecked = facilitiesCheckboxesFieldsFlat.filter(
        (field) => formData[field]
      );
      return facilitiesCheckboxesFieldsChecked;
    };
    const fieldNames = checkedFacilitiesFieldNames();
    const newOptions = fieldNames.map((field) => ({
      value: field,
      label: jsonSchema.properties[field]?.title,
    }));
    // May want to append with "props.options.enumOptions", coming from schema.
    setOptions(newOptions);
  }, [formData, uiSchema, jsonSchema]);

  return <CustomSelect {...props} options={{ ...props.options, enumOptions: options }} />;
};
