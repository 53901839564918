import api from '../services/api';

export const loadAdminNotes = async (communityId) => {
  try {
    const { data } = await api.get(
      `v1/admin/communities/${communityId}/admin_notes/newest`
    );
    return data;
  } catch (error) {
    console.log('Error loading admin notes >>', error);
    return null;
  }
};

export const loadAdminNotesThread = async (communityId, section) => {
  try {
    const { data } = await api.get(
      `v1/admin/communities/${communityId}/admin_notes/thread?section=${section}`
    );
    return data;
  } catch (error) {
    console.log('Error loading adminNotes thread >>', error);
    return null;
  }
};
