import {
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
  InputLabel,
  ListItemText,
} from '@mui/material';

export const PropertySelect = ({ propertyOptions, property, onChange }) => {
  const propertiesToOptions =
    propertyOptions && Array.isArray(propertyOptions)
      ? propertyOptions.map((property) => ({
          label: property.name ? property.name : `Unnamed Property (ID: ${property.id})`,
          value: property.id,
        }))
      : [
          {
            label: `Create a new property for this community`,
            value: 'CREATE',
          },
        ];

  if (propertyOptions && Array.isArray(propertyOptions)) {
    propertiesToOptions.unshift({
      label: `Create a new property for this community`,
      value: 'CREATE',
    });
  }

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
      fullWidth
    >
      <InputLabel id='property-dropdown'>Select Property</InputLabel>
      <Select
        labelId='property-dropdown'
        name='propertyId'
        value={property.value || ''}
        onChange={onChange}
        input={<OutlinedInput label='Select Property' />}
        MenuProps={{
          disableScrollLock: true,
          PaperProps: {
            sx: {
              maxHeight: '300px',
            },
          },
        }}
      >
        {propertiesToOptions?.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
