import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from '../../redux/reducers';
import Icons from '../../constants/Icons';
import { useAccountRequestsInfinite } from '../../hooks/useAccountRequestsInfinite';
import { ApproveAccountRequestPopup } from '../ApproveAccountRequestPopup/ApproveAccountRequestPopup';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import { RejectAccountRequestPopup } from '../RejectAccountRequestPopup/RejectAccountRequestPopup';
import { AccountRequestsItem } from './AccountRequestsItem';
import styles from './AccountRequestsList.module.scss';
import KthPopup from '../KthPopup/KthPopup';

export const AccountRequestsList = () => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state?.auth?.user?.role);
  const {
    data: accountRequests,
    meta,
    loading,
    restartPages,
    loadMore,
  } = useAccountRequestsInfinite();
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedName, setSelectedName] = useState('');

  useEffect(() => {
    if (userRole && userRole === 'admin') {
      dispatch(getData());
    }
  }, [dispatch, accountRequests, userRole]);

  const handleItemApproveClick = (accountId) => () => {
    setShowApprovePopup(true);
    setSelectedAccountId(accountId);
  };

  const handleItemRejectClick = (accountId) => () => {
    setShowRejectPopup(true);
    setSelectedAccountId(accountId);
  };

  const handleClosePopup = (refreshList) => {
    setShowApprovePopup(false);
    setShowRejectPopup(false);
    setSelectedAccountId(null);

    if (refreshList) {
      restartPages();
    }
  };

  const handleLoadMore = () => {
    loadMore();
  };

  useEffect(() => {
    if (selectedAccountId) {
      let account = accountRequests.find((acc) => acc.id === selectedAccountId);
      setSelectedName(account.name);
    }
  }, [selectedAccountId, accountRequests]);

  return (
    <div className={styles.accountRequestsList}>
      <Loader isLoading={loading}>
        <div>
          {accountRequests
            ? [
                accountRequests.map((account) => (
                  <AccountRequestsItem
                    key={String(account.id)}
                    account={account}
                    onApproveClick={handleItemApproveClick(account.id)}
                    onRejectClick={handleItemRejectClick(account.id)}
                  />
                )),
              ]
            : null}
        </div>
      </Loader>
      {meta?.last_page === false ? (
        <Button.LIGHT icon onClick={handleLoadMore} className={styles.loadMoreButton}>
          Load more {Icons.ArrowDown}
        </Button.LIGHT>
      ) : null}
      <KthPopup
        isOpen={showApprovePopup}
        title={`Approve Account Request`}
        hideActions
        onClose={handleClosePopup}
      >
        <ApproveAccountRequestPopup
          accountId={selectedAccountId}
          onClose={handleClosePopup}
        />
      </KthPopup>
      <KthPopup
        isOpen={showRejectPopup}
        title={`Reject ${selectedName ? selectedName + "'s" : ''} Account Request`}
        hideActions
        onClose={handleClosePopup}
      >
        <RejectAccountRequestPopup
          accountId={selectedAccountId}
          onClose={handleClosePopup}
        />
      </KthPopup>
    </div>
  );
};
