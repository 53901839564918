import _ from 'lodash';
import { useEffect, useState } from 'react';
import styles from './TabBar.module.scss';

const TabBar = ({ onSelect, selectedTab, tabs, rightSideTabs }) => {
  if (!tabs) return null;

  const Tab = ({ tab, index }) => (
    <div
      onClick={() => onSelect(index)}
      className={`${styles.tab} ${selectedTab === index ? styles.selectedTab : ''}`}
    >
      <span className={styles.tabTitle}>{_.startCase(tab.name)}</span>
    </div>
  );

  return (
    <div className={styles.tabBarContainer}>
      <div className={styles.tabBar}>
        {tabs ? (
          <div className={styles.tabs}>
            {tabs.map((tab, index) => (
              <Tab tab={tab} index={index} key={tab.name} />
            ))}
          </div>
        ) : null}

        {rightSideTabs ? (
          <div className={styles.tabs}>
            {rightSideTabs.map((tab, index) => (
              <Tab tab={tab} index={tabs.length + index} key={tab.name} />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TabBar;
