import { useMemo } from 'react';
import { useTable, useFlexLayout, useResizeColumns } from 'react-table';
import { UnitImportItem } from './UnitImportItem';
import { StatusCell } from './StatusCell';
import { Constants } from 'web-core';
import styles from './UnitImportTable.module.scss';

const UnitImportTable = ({ units }) => {
  const checkErrorStatus = (value, errors) => {
    const errorList = errors.map((error) => Object.keys(error)[0]);
    if (errorList && errorList.includes(value)) {
      return true;
    }
    return false;
  };

  const columns = useMemo(
    () => [
      {
        Header: () => <div className='pl-50'>State</div>,
        accessor: 'status',
        minWidth: 50,
        width: 50,
        maxWidth: 50,
        Cell: ({ value }) => <StatusCell status={value} />,
      },
      {
        Header: () => <div className='pl-50'>Community</div>,
        accessor: 'community_name',
        minWidth: 200,
        width: 200,
        maxWidth: 200,
        Cell: ({ value, row }) => {
          const fieldError = checkErrorStatus('community_name', row.original.errors);
          return <div className={`${fieldError ? styles.red : ''} pl-50`}>{value}</div>;
        },
      },
      {
        Header: () => <div className='pl-50'>Care Type</div>,
        accessor: 'care_level',
        minWidth: 100,
        width: 100,
        maxWidth: 100,
        Cell: ({ value, row }) => {
          const fieldError = checkErrorStatus('care_level', row.original.errors);
          return <div className={`${fieldError ? styles.red : ''} pl-50`}>{value}</div>;
        },
      },
      {
        Header: () => <div className='pl-50'>Unit Type</div>,
        accessor: 'matching_model',
        minWidth: 200,
        width: 200,
        maxWidth: 200,
        Cell: ({ value, row }) => {
          const fieldError = checkErrorStatus('matching_model', row.original.errors);
          return (
            <div className={`${fieldError ? styles.red : ''} pl-50`}>
              {value && value[Constants.DynamicSchemaFields.ModelName]
                ? value[Constants.DynamicSchemaFields.ModelName]
                : ''}
            </div>
          );
        },
      },
      {
        Header: () => <div className='pl-50'>Unit</div>,
        accessor: 'unit',
        minWidth: 100,
        width: 100,
        maxWidth: 100,
        Cell: ({ value, row }) => {
          const fieldError = checkErrorStatus('unit', row.original.errors);
          return (
            <div className={`${fieldError ? styles.red : ''} pl-50`}>
              {value ? value : ''}
            </div>
          );
        },
      },
      {
        Header: () => <div className='pl-50'>Occupancy</div>,
        accessor: 'vacant',
        minWidth: 100,
        width: 100,
        maxWidth: 100,
        Cell: ({ value, row }) => {
          const fieldError = checkErrorStatus('vacant', row.original.errors);
          return (
            <div className={`${fieldError ? styles.red : ''} pl-50`}>
              {value === true ? 'Vacant' : value === false ? 'Occupied' : ''}
            </div>
          );
        },
      },
      {
        Header: () => <div className='pl-50'>Market Rent</div>,
        accessor: 'unit_market_rate',
        minWidth: 100,
        width: 100,
        maxWidth: 100,
        Cell: ({ value, row }) => {
          const fieldError = checkErrorStatus('unit_market_rate', row.original.errors);
          return <div className={`${fieldError ? styles.red : ''} pl-50`}>{value}</div>;
        },
      },
      {
        Header: () => <div className='pl-50'></div>,
        accessor: 'errors',
        minWidth: 250,
        width: 250,
        maxWidth: 250,
        Cell: ({ value }) => {
          const messages = value.map((e) => Object.values(e)).join('\n') || '';
          return <div className={`${styles.red} ${styles.errors} pl-50`}>{messages}</div>;
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data: units,
    },
    useFlexLayout,
    useResizeColumns
  );

  return (
    <div className={styles.listContainer}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              key={`${headerGroup.Header?.toString() || ''}`}
              {...headerGroup.getHeaderGroupProps({
                style: { width: '100%' },
              })}
            >
              {headerGroup.headers.map((column) => (
                <th disabled key={column.Header.toString()} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length > 0 &&
            rows.map((row) => {
              prepareRow(row);
              return (
                <UnitImportItem row={row} columns={columns} {...row.getRowProps()} />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default UnitImportTable;
