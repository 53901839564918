import Button from '../Button/Button';
import GeoSelector from '../GeoSelector/GeoSelector';
import { useSelector } from 'react-redux';
import { selectIsSuperAdmin } from '../../redux/selectors/auth';
import { useOrganizationsInfinite } from '../../hooks/useOrganizationsInfinite';
import { useCallback, useEffect, useState } from 'react';
import { debounce, Input, ListItemText, MenuItem, Select } from '@mui/material';

const DISTANCE_OPTIONS = ['5mi', '10mi', '20mi', '50mi', '100mi', '200mi'];
const DISTANCE_LABELS = {
  '5mi': '5 miles',
  '10mi': '10 miles',
  '20mi': '20 miles',
  '50mi': '50 miles',
  '100mi': '100 miles',
  '200mi': '200 miles',
};
const INITIAL_QUERY = {
  name: '',
  geo: '',
  organization_id: '',
};

export const SearchBar = ({
  hideTitle = false,
  onQueryChange,
  onSubmit,
  nameOnly = false,
  urlQuery,
}) => {
  const [query, setQuery] = useState(urlQuery ? urlQuery : INITIAL_QUERY);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const { organizations, loading: organizationLoading } =
    useOrganizationsInfinite(isSuperAdmin);

  useEffect(() => {
    delayedOnQueryChange(query);
  }, [query]);

  const delayedOnQueryChange = useCallback(
    debounce((newQuery) => {
      onQueryChange(newQuery);
    }, 1000),
    [onQueryChange]
  );

  const submitQueryCommunities = (e) => {
    e.preventDefault();
    onSubmit(query);
  };

  const handleQueryChange = (event) => {
    const { name, value } = event.target;
    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: value,
    }));
  };

  return (
    <div className='search-bar-container'>
      {!hideTitle ? <h3>Search Properties</h3> : null}
      <form className='search-bar' onSubmit={submitQueryCommunities}>
        <input
          name='name'
          value={query.name}
          onChange={handleQueryChange}
          placeholder='Name'
        />
        <div className='separator' />
        <GeoSelector locationQuery={query.geo} setQuery={setQuery} />
        <div className='separator' />
        <div className='search-bar-dropdown'>
          <Select
            displayEmpty
            disableUnderline
            name='within'
            value={query.within || ''}
            onChange={handleQueryChange}
            input={<Input label='Distance' sx={{ width: '100%' }} />}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: '300px',
                },
              },
            }}
          >
            <MenuItem value=''>
              <ListItemText primary='Distance' />
            </MenuItem>
            {DISTANCE_OPTIONS &&
              DISTANCE_OPTIONS.map((value, index) => {
                return (
                  <MenuItem key={index} value={DISTANCE_OPTIONS[index]}>
                    <ListItemText primary={DISTANCE_LABELS[value]} />
                  </MenuItem>
                );
              })}
          </Select>
        </div>
        <div className='separator' />
        {isSuperAdmin && (
          <>
            <div className='search-bar-dropdown'>
              <Select
                displayEmpty
                disableUnderline
                name='organization_id'
                disabled={organizationLoading}
                value={query.organization_id || ''}
                onChange={handleQueryChange}
                input={<Input label='Organization' sx={{ width: '100%' }} />}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '300px',
                    },
                  },
                }}
              >
                <MenuItem value=''>
                  <ListItemText primary='Organization' />
                </MenuItem>
                {organizations &&
                  organizations.map((org) => {
                    return (
                      org && (
                        <MenuItem key={org.id} value={org.id}>
                          <ListItemText primary={org.name} />
                        </MenuItem>
                      )
                    );
                  })}
              </Select>
            </div>
            <div className='separator' />
          </>
        )}
        <Button.DARK className='search-button' type='submit'>
          Search
        </Button.DARK>
      </form>
    </div>
  );
};
