import { useState } from 'react';
import { useOrganizationServicesInfinite } from '../../hooks/useOrganizationServicesInfinite';
import { useQueryParams } from '../../hooks/useQueryParams';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';
import Button from '../../components/Button/Button';
import styles from '../organizations/OrganizationListPage.module.scss';
import Icons from '../../constants/Icons';
import KthPopup from '../../components/KthPopup/KthPopup';
import { OrganizationForm } from '../../components/OrganizationForm/OrganizationForm';
import { StringUtils } from 'web-core';
import { ServiceList } from '../../components/Services/ServiceList';
import { EditServiceForm } from '../../components/Services/EditServiceForm';
import { useStateGeoPlaces } from '../../hooks/useStateGeoPlaces';
import { useQueryOrganizations } from '../../hooks/useQueryOrganizations';
import { useOrganization } from '../../hooks/useOrganizations';

export const ServicesPage = () => {
  const [showEditServiceForm, setShowEditServiceForm] = useState(false);
  const [serviceToEdit, setServiceToEdit] = useState(null);

  const urlQueryOrganizationId = useQueryParams().get('organization_id') || '';
  const {
    organizationServices,
    loading: loadingServices,
    meta,
    loadMore,
    reloadAtPageOne,
  } = useOrganizationServicesInfinite(true, urlQueryOrganizationId);

  const {
    organization,
    loading: loadingOrganization,
    error: errorLoadingOrganization,
  } = useOrganization(urlQueryOrganizationId);

  // load the geoplaces for all 50 states
  const {
    geoPlaces: stateGeoPlaces,
    loading: loadingGeoPlaces,
    error: errorLoadingGeoPlaces,
  } = useStateGeoPlaces();

  // pass in null to create new one
  const handleShowEditServiceForm = (organizationService) => {
    setServiceToEdit(organizationService);
    setShowEditServiceForm(true);
  };

  const handleCloseEditServiceForm = (refreshList) => {
    setServiceToEdit(undefined);
    setShowEditServiceForm(false);
    if (refreshList) {
      reloadAtPageOne();
    }
  };

  const handleLoadMore = () => {
    loadMore();
  };

  const getEditFormTitle = () => {
    if (serviceToEdit) {
      return `Edit Service`;
    } else {
      return 'Add New Service';
    }
  };

  return (
    <>
      <HeaderTitle
        title={organization ? `Services - ${organization.name}` : 'Services'}
      />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>
              {StringUtils.isBlank(organization?.name)
                ? 'Services'
                : `Services offered by ${organization.name}`}
            </h1>
          </div>
          {urlQueryOrganizationId && stateGeoPlaces && (
            <div className='action-container-properties'>
              <Button.BLACK_OUTLINED onClick={() => handleShowEditServiceForm()}>
                Add New Service
              </Button.BLACK_OUTLINED>
            </div>
          )}
        </div>
        <ServiceList
          services={organizationServices}
          geoPlaces={stateGeoPlaces}
          isLoading={loadingServices}
          onItemClick={handleShowEditServiceForm}
        />
        {meta?.last_page === false && meta?.total_pages !== 0 ? (
          <Button.LIGHT icon onClick={handleLoadMore} className={styles.loadMoreButton}>
            Load more {Icons.ArrowDown}
          </Button.LIGHT>
        ) : null}
        <KthPopup
          isOpen={showEditServiceForm}
          title={getEditFormTitle()}
          hideActions
          onClose={() => setShowEditServiceForm(false)}
        >
          <EditServiceForm
            organizationId={urlQueryOrganizationId}
            service={serviceToEdit}
            geoPlaces={stateGeoPlaces}
            onClose={handleCloseEditServiceForm}
          />
        </KthPopup>
      </div>
    </>
  );
};
