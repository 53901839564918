import { TextField } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { CustomMasks } from '../../../../constants/CustomMasks';

export const CustomInput = (props) => {
  const { placeholder, label, schema, onChange, id, disabled, required, value } = props;
  const inputLabel = placeholder || label || 'Type...';
  const inputType = schema.type === 'string' ? 'text' : 'number';
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (event) => {
    // remove leading spaces for text field
    let newValue =
      schema.type === 'string' ? event.target.value.trimStart() : event.target.value;

    if (newValue === props.value) {
      // original value - hasn't changed, so don't do anything
      // this is to prevent false-positive dirty flag setting
      // on page load when a community has a phone number field with a value
      // and the value gets auto-formatted even though the value has already been formatted
      // and doesn't change
    } else {
      if (inputType === 'number') {
        let filteredNumber = newValue.replace(/^[0]+/, '');
        // we want to allow removing a value / leaving an empty value in number fields.
        if (filteredNumber !== '') {
          newValue = parseInt(filteredNumber);
        }

        // the onAccept event that formats the input value is asynchronous, we need to set a synchronous
        // cache to prevent the cursor moving to the end of the input.
        // source: https://dev.to/kwirke/solving-caret-jumping-in-react-inputs-36ic#:~:text=at%20the%20end.-,How%20to%20solve%20it,-Make%20always%20a

        // Need to allow setting an empty value, otherwise emptying input is not actually removing
        // the value, the number field will remain the same value as the previous state. (e.g. "9" => "", the actual value of the
        // number field will still be "9")
        if (!!newValue || newValue === '') {
          setInputValue(newValue);
          onChange(newValue);
        }
      } else {
        setInputValue(newValue);
        onChange(newValue);
      }
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <TextField
      id={id}
      disabled={disabled}
      required={required}
      // type={inputType}
      onWheel={(e) => e.target.blur()}
      onChange={handleChange}
      value={inputValue || ''}
      label={inputLabel}
      error={inputType === 'number' && !schema.pattern && value < 0}
      InputProps={{
        inputComponent: schema.pattern ? PatternCustomMask : undefined,
      }}
      // May need to use shrink prop to fix when settings form data in a dynamic way and avoid label error.
      // https://mui.com/components/text-fields/#shrink
      InputLabelProps={{ shrink: Boolean(value) }}
      inputProps={
        schema.pattern
          ? { pattern: CustomMasks[schema.pattern] }
          : inputType === 'number'
          ? { pattern: '[0-9]*' }
          : { pattern: null }
      }
      fullWidth
    />
  );
};

const PatternCustomMask = forwardRef(function PatternCustomMask(props, ref) {
  const { onChange, pattern, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={pattern}
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
