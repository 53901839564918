import { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import styles from './InlineUnitTypeInput.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { formFieldChange } from '../../../redux/reducers';

export const InlineUnitTypeInput = ({ value, formKey, row, disabled, label }) => {
  const dispatch = useDispatch();
  const models = useSelector((state) => state.communityForm.formData.models) || [];
  const [number, setNumber] = useState(value);

  const handleCellChange = (index, fieldName, value) => {
    let newData = models.map((model, i) =>
      i !== index ? model : { ...model, [fieldName]: value }
    );
    dispatch(formFieldChange('models', newData));
  };

  const handleValueChange = (event) => {
    const valueWithoutLeadingZeros = String(event?.target.value).replace(/^[0]+/, '');
    const valueToInt = parseInt(valueWithoutLeadingZeros);
    setNumber(valueToInt);
    if (valueToInt && !isNaN(valueToInt)) {
      handleCellChange(row.index, formKey, valueToInt);
    }
  };

  return (
    <div className={`${styles.singleInput} text-center`}>
      <TextField
        className={styles.currencyInput}
        disabled={disabled}
        onChange={handleValueChange}
        value={number || ''}
        label={label}
        InputProps={{
          startAdornment: !disabled ? (
            <InputAdornment position='start'>$</InputAdornment>
          ) : null,
        }}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
      />
    </div>
  );
};
