import KthPopup from '../KthPopup/KthPopup';
import { Cookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import api from '../../services/api';
import { AuthActions } from '../../redux/reducers';
import { useHistory } from 'react-router-dom';
import styles from './AdminLoginAsUserPopup.module.scss';
import { AccountTypeLabels } from '../../constants/AccountType';
import { APITools, CookieUtils } from 'web-core';

export const AdminLoginAsUserPopup = ({ account, onClose, isOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAdminLogin = async () => {
    let response;
    dispatch({ type: AuthActions.LOGIN_PENDING });
    try {
      response = await api.post(`/v1/auth/login_as_user`, {
        id: account.id,
      });
      if (response.data?.meta) {
        const cookies = new Cookies();
        CookieUtils.setKWAccessTokenCookie(
          cookies,
          process.env.REACT_APP_ENVIRONMENT,
          response.data?.meta.access_token
        );
        CookieUtils.setKWRefreshTokenCookie(
          cookies,
          process.env.REACT_APP_ENVIRONMENT,
          response.data?.meta.refresh_token
        );

        dispatch({
          type: AuthActions.LOGIN_SUCCEEDED,
          payload: { data: response.data },
        });

        if (
          response.data?.role === 'corporate_executive' ||
          response.data?.role === 'admin'
        ) {
          history.push('/properties');
        } else if (response.data?.role === 'community_manager') {
          history.push('/communities');
        } else {
          window.location.assign(`${process.env.REACT_APP_CONSUMER}`);
        }
        enqueueSnackbar(`Successfully logged in as: ${response.data.role}`, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(`Login as user failed! Empty meta data.`, { variant: 'error' });
      }
    } catch (err) {
      dispatch({
        type: AuthActions.LOGIN_FAILED,
        errors: err,
      });
      let errorsToDisplay = APITools.errorMessageFromServerResponse(err);
      enqueueSnackbar(errorsToDisplay, { variant: 'error' });
    }
  };

  return (
    <KthPopup
      isOpen={isOpen}
      title={`Logging As User: ${account?.first_name} ${account?.last_name}`}
      onClose={() => onClose()}
      onConfirmButtonClick={handleAdminLogin}
      confirmButtonLabel='Login'
    >
      <div className={styles.adminLoginAsUserPopupContent}>
        <p>{`Are you sure you want to login as this user?`}</p>
        <div className={styles.userInfo}>
          <p>{`Name: ${account?.first_name} ${account?.last_name}`}</p>
          <p>{`Role: ${AccountTypeLabels[account?.account_type]}`}</p>
        </div>
      </div>
    </KthPopup>
  );
};
