export const idFromSlug = (slug: string | number | null | undefined) => {
  if (typeof slug === 'number') {
    return `${slug}`;
  } else if (typeof slug === 'string') {
    let match = slug.match(/([\w-]*?)(-?)(\d+)\s*$/);
    if (match && match[3]) {
      return match[3];
    }
  }

  return null;
};
