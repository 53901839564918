import { AccountType } from '../constants/AccountType';
import { ConsumerSignUpForm, OperatorSignUpForm } from '../types/SignUpForm.type';
import AccountTypeAdapter from './AccountTypeAdapter';
import _ from 'lodash';

const fromApiOperator = (form: any): any => ({
  ..._.mapKeys(form, (v, k) => _.camelCase(k)),
  accountType: AccountTypeAdapter.fromApi(form?.account_type),
});

const toApiOperator = (form: OperatorSignUpForm) => ({
  account_type: AccountTypeAdapter.toApi(form.accountType as AccountType),
  first_name: form.firstName,
  last_name: form.lastName,
  email: form.email,
  job_title: form.jobTitle,
  company_name: form.companyName,
  phone: form.phone,
  organization_id: form.organizationId,
});

const toApiConsumer = (form: ConsumerSignUpForm) => ({
  account_type: AccountTypeAdapter.toApi(form.accountType as AccountType),
  first_name: form.firstName,
  last_name: form.lastName,
  email: form.email,
  password: form.password,
  password_confirmation: form.passwordConfirmation,
});

const toApi = (form: OperatorSignUpForm | ConsumerSignUpForm) => {
  return isOperatorAccount(form.accountType)
    ? toApiOperator(form as OperatorSignUpForm)
    : toApiConsumer(form as ConsumerSignUpForm);
};

export const isOperatorAccount = (accountType: AccountType | undefined) => {
  switch (accountType) {
    case AccountType.COMMUNITY_MANAGER:
    case AccountType.CORPORATE_EXECUTIVE:
    case AccountType.BOTH_EXECUTIVE_AND_MANAGER:
      return true;
    case AccountType.PROSPECTIVE_RESIDENT:
    case AccountType.ADULT_CHILD:
    default:
      return false;
  }
};

const SignUpFormAdapter = {
  toApi,
  fromApi: fromApiOperator,
};

export default SignUpFormAdapter;
