import { useMemo } from 'react';
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
} from 'react-table';
import { PropertyResultItem } from './PropertyResultItem';
import { Pagination } from '../Pagination/Pagination';
import styles from './PropertyResultsList.module.scss';

const PropertyResultsList = ({ properties = [], columns, meta, onAddCommunity }) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 150,
      width: '100%',
    }),
    []
  );

  const paginationOptions = meta
    ? {
        initialState: {
          pageSize: meta['per_page'],
          pageIndex: meta['current_page'] - 1,
        },
        pageCount: meta['total_pages'],
        manualPagination: true,
      }
    : null;

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data: properties,
      defaultColumn,
      ...paginationOptions,
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  );

  return (
    <div className={styles.propertyListContainer}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              key={`${headerGroup.Header?.toString() || ''}`}
              {...headerGroup.getHeaderGroupProps({
                style: { width: '100%' },
              })}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className='p4'
                  disabled
                  key={column.Header.toString()}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <PropertyResultItem
                  key={String(row.id)}
                  row={row}
                  onAddCommunity={onAddCommunity}
                  columns={columns}
                  defaultColumn={defaultColumn}
                  {...row.getRowProps()}
                />
              </>
            );
          })}
        </tbody>
      </table>
      {properties && meta && (
        <Pagination meta={meta} columns={columns} data={properties} />
      )}
    </div>
  );
};

export default PropertyResultsList;
