import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
} from 'react-table';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { AccountType, AccountTypeLabels } from '../../constants/AccountType';
import UserNameCell from './UserNameCell';
import Loader from '../Loader/Loader';
import styles from './UserList.module.scss';
import { Pagination } from '../Pagination/Pagination';
import AccountTypeAdapter from '../../adapters/AccountTypeAdapter';
import { AccountStatusLabel, isPendingStatus } from '../../constants/AccountStatus';
import { selectIsSuperAdmin } from '../../redux/selectors/auth';
import { StringUtils } from 'web-core';

const UserList = ({ onItemClick, providedUsers, onAdminLoginClick }) => {
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const { users, isUsersLoading, meta } = useSelector((state) => state.users);
  const thisUser = useSelector((state) => state.auth.user);

  const idColumn = {
    Header: 'ID',
    accessor: 'id',
    id: 'id',
    width: 70,
    Cell: ({ value }) => <p>{value}</p>,
  };

  const orgColumn = {
    Header: 'Organization',
    accessor: 'organization.name',
    id: 'organization',
    width: 180,
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'first_name',
        Cell: UserNameCell,
      },
      idColumn,
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ row, value }) => (
          <>
            <p className={styles.userEmail}>{value}</p>
            {isPendingStatus(row.original.status) ? (
              <p className={styles.emailPending}>
                {AccountStatusLabel[row.original.status]}
              </p>
            ) : null}
          </>
        ),
      },
      orgColumn,
      {
        Header: 'Role',
        accessor: 'account_type',
        Cell: ({ value }) => (
          <div className={styles.tableContent}>
            {value ? AccountTypeLabels[value] : '-'}
          </div>
        ),
      },
      {
        Header: 'Communities',
        accessor: 'assigned_communities',
        Cell: ({ row, value }) =>
          row.original.is_admin === true ? (
            <div className={styles.tableContent}>All</div>
          ) : row.original.role === 'corporate_executive' ? (
            <div className={styles.tableContent}>All in Organization</div>
          ) : (
            <div className={styles.tableContent}>
              <>
                {value.map((community, index) => (
                  <p key={index}>{community.display_name}</p>
                ))}
                {isSuperAdmin &&
                value.length === 0 &&
                !StringUtils.isBlank(row.original.cm_signup_communities) ? (
                  <p className={styles.userDetails}>
                    <b>Self-Reported Communities Managed:</b>
                    <br /> {row.original.cm_signup_communities}
                  </p>
                ) : null}
              </>
            </div>
          ),
      },
      {
        Header: 'Actions',
        accessor: 'Edit',
        width: 80,
        Cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = useState(null);
          const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
          const handleMenuClose = () => setAnchorEl(null);

          const actionButtons = [];

          if (
            row.original.account_type ===
            AccountTypeAdapter.toApi(AccountType.COMMUNITY_MANAGER)
          ) {
            actionButtons.push({
              label: 'Edit Communities',
              action: () => onItemClick(row.original, 'editUserCommunities'),
            });
          }

          if (
            row.original.role !== 'community_manager' ||
            ![
              'corporate_executive_account',
              'community_manager_and_corporate_executive_account',
            ].includes(row.original.account_type)
          ) {
            actionButtons.push({
              label: 'Edit Profile',
              action: () => onItemClick(row.original, 'editUser'),
            });
          }

          if (row.original.role === 'user') {
            actionButtons.push({
              label: 'See Surveys',
              action: () =>
                window.open(
                  `${process.env.PUBLIC_URL}/surveys/${row.original.id}`,
                  '_blank'
                ),
            });
          }

          if (
            !isPendingStatus(row.original.status) &&
            isSuperAdmin &&
            row.original.role !== 'user'
          ) {
            actionButtons.push({
              label: 'Login As User',
              action: () => onAdminLoginClick(row.original),
            });
          }

          return (
            <div className={styles.actionButtonContainer}>
              {/* 3-Dots Icon for Actions */}
              <IconButton
                onClick={handleMenuOpen}
                className={styles.actionIconButton}
                aria-controls='actions-menu'
                aria-haspopup='true'
              >
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </IconButton>

              {/* Dropdown Menu for Actions */}
              <Menu
                id='actions-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                className={styles.menu}
              >
                {actionButtons.map((action, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      action.action();
                      handleMenuClose();
                    }}
                    className={styles.menuItem}
                  >
                    {action.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          );
        },
      },
    ],
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // minWidth: 100, // minWidth is only used as a limit for resizing
      // width: '100%',
    }),
    []
  );

  const paginationOptions = meta
    ? {
        initialState: {
          pageSize: meta['per_page'],
          pageIndex: meta['current_page'] - 1,
        },
        pageCount: meta['total_pages'],
        manualPagination: true,
      }
    : null;

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    // rows, // rows for the table based on the data passed
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    setHiddenColumns,
    // The rest of these things are super handy, too ;)
  } = useTable(
    {
      columns,
      data: !providedUsers ? users : providedUsers,
      defaultColumn,
      ...paginationOptions,
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (!isSuperAdmin) {
      console.log(columns);
      setHiddenColumns([idColumn.id, orgColumn.id]);
    }
  }, []);

  return (
    <Loader isLoading={isUsersLoading}>
      <div className={styles.userListContainer}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                key={`${headerGroup.Header?.toString() || ''}`}
                {...headerGroup.getHeaderGroupProps({
                  style: { width: '100%' },
                })}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.Header.toString()}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className='p4'
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        <FontAwesomeIcon
                          icon={column.isSortedDesc ? faChevronUp : faChevronDown}
                        />
                      ) : null}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return <UserRow key={row.id} row={row} {...row.getRowProps()} />;
            })}
          </tbody>
        </table>
        {!providedUsers ? (
          <Pagination
            meta={meta}
            columns={columns}
            data={!providedUsers ? users : providedUsers}
          />
        ) : null}
      </div>
    </Loader>
  );
};

const UserRow = ({ row }) => {
  return (
    <>
      <tr className={styles.userItem}>
        {row.cells.map((cell) => (
          <td
            key={cell.column.id + cell.row.id}
            {...cell.getCellProps({ className: cell.column.tdClassName })}
          >
            {cell.render('Cell')}
          </td>
        ))}
      </tr>
    </>
  );
};

export default UserList;
