import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Icons from '../../../../constants/Icons';
import { changeBuildingsForm, formFieldChange } from '../../../../redux/reducers';
import Button from '../../../Button/Button';
import Popup from 'reactjs-popup';
import styles from '../../TabContent/TabContent.module.scss';
import BuildingsForm from './BuildingsForm';
import BuildingsTable from './BuildingsTable';

const BuildingsTab = () => {
  const buildings = useSelector((state) => state.communityForm.formData.buildings) || [];
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editBuildingIndex, setEditBuildingIndex] = useState(null);
  const dispatch = useDispatch();

  const showCreateBuildingsForm = () => {
    dispatch(changeBuildingsForm({}));
    setEditBuildingIndex(null);
    setIsFormVisible(true);
  };

  const showEditBuildingsForm = (index) => {
    dispatch(changeBuildingsForm(buildings[index]));
    setEditBuildingIndex(index);
    setIsFormVisible(true);
  };

  const handleBuildingDelete = (buildingDelete) => {
    setIsFormVisible(false);
    if (Number.isInteger(editBuildingIndex)) {
      dispatch(
        formFieldChange(
          'buildings',
          buildings.filter((building, index) => index !== editBuildingIndex)
        )
      );
    } else {
      // delete building
      dispatch(
        formFieldChange(
          'buildings',
          buildings.filter((building, index) => index !== buildingDelete)
        )
      );
    }
    setEditBuildingIndex(null);
    setIsFormVisible(false);
    window.scrollTo(0, 0);
  };

  const handleFormCancel = () => {
    dispatch(changeBuildingsForm({}));
    setEditBuildingIndex(null);
    setIsFormVisible(false);
  };

  const handleFormSave = (buildingSaved) => {
    dispatch(changeBuildingsForm({}));
    setIsFormVisible(false);
    if (Number.isInteger(editBuildingIndex)) {
      // Is editing
      dispatch(
        formFieldChange(
          'buildings',
          buildings.map((building, index) =>
            index !== editBuildingIndex ? building : buildingSaved
          )
        )
      );
      setEditBuildingIndex(null);
    } else {
      // Is create new
      const dateCreated = new Date().toISOString();
      dispatch(
        formFieldChange('buildings', [
          ...buildings,
          { ...buildingSaved, dateCreated, id: uuidv4() },
        ])
      );
    }
  };

  return (
    <div>
      {
        <Popup
          open={isFormVisible}
          modal
          nested
          closeOnDocumentClick={false}
          closeOnEscape={false}
          lockScroll={true}
        >
          <BuildingsForm
            onSave={handleFormSave}
            onDelete={handleBuildingDelete}
            onCancel={handleFormCancel}
          />
        </Popup>
      }
      {
        <section className={styles.formSection}>
          <div className={styles.sectionHeader}>
            <span className={styles.sectionTitle}>Building Information</span>
            <Button.LIGHT
              className={styles.sectionTitleButton}
              onClick={showCreateBuildingsForm}
            >
              + New Building
            </Button.LIGHT>
          </div>
          <BuildingsTable buildings={buildings} onRowClick={showEditBuildingsForm} />
          <div className={styles.tabFooter}>
            <Button.DARK icon onClick={showCreateBuildingsForm}>
              {Icons.Plus}
            </Button.DARK>
          </div>
        </section>
      }
    </div>
  );
};

export default BuildingsTab;
