import { useState } from 'react';
import styles from './AssessmentReportPage.module.scss';
import Bar from '../../../components/AssessmentReport/Bar';
import { MonthPicker } from '../../../components/MonthPicker/MonthPicker';
import { useParams } from 'react-router-dom';
import { useAssessmentReport } from '../../../hooks/useAssessmentReport';
import SpinnerLoader from '../../../components/Spinner/SpinnerLoader';
import { StringUtils } from 'web-core';
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { getQueryParams } from '../../../utils/queryParams';
import HeaderTitle from '../../../components/HeaderTitle/HeaderTitle';

interface Report {
  assessment_events: AssessmentEvents;
  aggregate_answers: AggregateAnswers;
}

type AssessmentEvents = {
  link_views: number;
  starts: number;
  completions: number;
  prospects: number;
}

type AggregateAnswers = {
  [key: string]: {
    title: string;
    type: string;
    average: number;
    rows: {
      [key: string]: {
        label: string;
        columns: {
          [key: string]: {
            label: string;
            percent: number;
            count: number;
          };
        };
      };
    };
    answers: {
      [key: string]: {
        label: string;
        percent: number;
        count: number;
      };
    };
  };
}

const AssessmentReportPage = () => {
  const { property_slug } = useParams<{ property_slug: string; type: string }>();
  let queryParams = getQueryParams();
  const [surveyType, setSurveyType] = useState(
    queryParams.get('type') || 'education_assessment'
  );
  const [date, setDate] = useState(new Date());

  const { report, property, loading, error } = useAssessmentReport(
    property_slug,
    surveyType,
    date
  );
  const hasValidData = report && !loading && !error;

  const multiBarColors = [
    '#ac947a',
    '#cab29a',
    '#e4cdb5',
    '#f6ece1',
    '#fafafa',
    '#f0f0f0',
    '#f3f3f3',
    '#d7d7d7',
    '#c6c6c6',
    '#aaa',
    '#999',
    '#777',
    '#555',
    '#222',
    '#000',
  ];

  return (
    <>
      <HeaderTitle
        title={`Assessment Responses Report${property ? ` - ${property.name}` : ''}`}
      />
      <div className={'page-layout'}>
        <div className={'header-breadcrumbs'}>
          {property ? (
            <Link to={`/properties/${property.slug}`}>{property.name}</Link>
          ) : (
            <br />
          )}
        </div>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Assessment Responses Report</h1>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.reportControls}>
            <MonthPicker value={date} onChange={setDate} />
            <FormControl className={styles.assessmentTypeSelect}>
              <InputLabel id='assessment-type-select'>Assessment Type</InputLabel>
              <Select
                labelId='assessment-type-select'
                value={surveyType}
                label='Assessment Type'
                onChange={(e) => {
                  setSurveyType(e.target.value);
                }}
              >
                <MenuItem value='education_assessment'>Educational Assessment</MenuItem>
                <MenuItem value='financial_assessment'>Financial Assessment</MenuItem>
              </Select>
            </FormControl>
          </div>
          {hasValidData ? (
            <>
              <h2>Assessment Conversions</h2>
              {Object.entries(report.assessment_events as AssessmentEvents).map(([event, count]) => (
                <Bar
                  label={StringUtils.capitalizeFirstLetter(event.replace(/_/g, ' '))}
                  count={count}
                  percent={report.assessment_events.link_views > 0 ?
                    Math.round((count / report.assessment_events.link_views) * 100) : 0}
                />
              ))}
              <div style={{margin: '20px'}}/>

              <h2>Question Responses</h2>
              {Object.entries(report.aggregate_answers as AggregateAnswers).map(([questionKey, questionData]) => (
                <div key={questionKey} className={styles.question}>
                  <h4>{questionData.title}</h4>
                  {questionData.type === 'option' ? (
                    <>
                      {Object.entries(questionData.answers).map(
                        ([answerKey, answerData]) => (
                          <Bar
                            label={StringUtils.capitalizeFirstLetter(answerData.label)}
                            count={answerData.count}
                            percent={answerData.percent}
                          />
                        )
                      )}
                    </>
                  ) : questionData.type === 'average' ? (
                    <p className={styles.average}>
                      {questionData.average.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0,
                      })}
                    </p>
                  ) : questionData.type === 'matrix' ? (
                    <div className={styles.matrixContainer}>
                      {Object.entries(questionData.rows).map(([rowKey, rowData]) => (
                        <div key={rowKey} className={styles.answer}>
                          <p className={styles.label}>
                            {StringUtils.capitalizeFirstLetter(rowData.label)}
                          </p>
                          <div className={styles.multiBarContainer}>
                            {Object.entries(rowData.columns).map(
                              ([colKey, colData], index) => (
                                <Tooltip
                                  title={`${colData.label} - ${colData.count} (${colData.percent}%)`}
                                  arrow
                                >
                                  <div
                                    key={colKey}
                                    className={styles.multiBar}
                                    style={{
                                      width: `${colData.percent}%`,
                                      backgroundColor: multiBarColors[index % multiBarColors.length],
                                    }}
                                  />
                                </Tooltip>
                              )
                            )}
                          </div>
                        </div>
                      ))}
                      <div className={styles.legendContainer}>
                        {Object.values(Object.values(questionData.rows)[0].columns).map(
                          (col, index) => (
                            <div key={index} className={styles.legend}>
                              <div
                                className={styles.colorIndicator}
                                style={{ backgroundColor: multiBarColors[index % multiBarColors.length] }}
                              />
                              <p>{col.label}</p>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </>
          ) : (
            <SpinnerLoader />
          )}
        </div>
      </div>
    </>
  );
};

export default AssessmentReportPage;
