export const TabKeys = [
  'Profile',
  'Location',
  'Facilities',
  'Services',
  'Building-Details',
  'Model-Type',
  'Units',
  'Fees',
  'Promotions',
];
