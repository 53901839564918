import styles from './UserList.module.scss';
import { useSelector } from 'react-redux';
import { selectIsSuperAdmin } from '../../redux/selectors/auth';

const UserNameCell = ({ row }) => {
  const { first_name, organization, last_name, job_title, phone } = row.original;
  const name = `${first_name || ''} ${last_name || ''}`;
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  return (
    <>
      <p className={`p4 ${styles.userName}`}>{name}</p>
      <p className={`p4 ${styles.userDetails}`}>{job_title}</p>
      {isSuperAdmin && <p className={`p4 ${styles.userDetails}`}>{organization?.name}</p>}
      <p className={`p4 ${styles.userDetails}`}>{phone}</p>
    </>
  );
};

export default UserNameCell;
