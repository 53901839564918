import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../Button/Button';
import styles from './CommunitySelect.module.scss';
import { CARE_TYPE_DESCRIPTIONS } from '../../../constants/CareType';
import { Constants } from 'web-core';
import AmplitudeTools from '../../../utils/AmplitudeTools';
import { formReset, resetFormDirty } from '../../../redux/reducers';
import { navigateToEditCommunity } from '../../../utils/navigateToEditCommunity';
import Popup from 'reactjs-popup';
import RouterPrompt from '../../RouterPrompt/RouterPrompt';
import { useState } from 'react';

export const DISPLAY_MODE_COMPRESSED = 'compressed';

// if displayMode is DISPLAY_MODE_COMPRESSED, then we only show the current care type and you can't change to another one
const CommunitySelect = ({
  onSave,
  dirtyFlag,
  setSwitchingTabOrCareType,
  setSwitchedFromAnotherCareType,
  displayMode = '',
}) => {
  const [showUnsavedChangesPrompt, setShowUnsavedChangesPrompt] = useState(false);
  // save the community we want to change to when need to go through async prompts before switching
  const [switchingToCommunityId, setSwitchingToCommunityId] = useState(null);
  const currentCommunityId = useSelector(
    (state) => state.communityForm.approved_version_id
  );
  const approvedPropertyCommunities = useSelector(
    (state) => state.communityForm.approvedPropertyCommunities
  );
  const formData = useSelector((state) => state.communityForm.formData);
  const currentCareType = formData?.[Constants.DynamicSchemaFields.CareType];
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedCommunity =
    switchingToCommunityId &&
    approvedPropertyCommunities.find(
      (c) => String(c.id) === String(switchingToCommunityId)
    );

  const checkDirtyFlagBeforeSwitchingCareType = (event) => {
    setSwitchingToCommunityId(event.target.value);
    if (dirtyFlag) {
      setSwitchingTabOrCareType(true);
      setShowUnsavedChangesPrompt(true);
    } else {
      handleChangeToEditCommunity(event.target.value);
    }
  };

  const handleSaveAndSwitch = async () => {
    const saveSuccess = await onSave();
    if (saveSuccess) {
      handleChangeToEditCommunity(switchingToCommunityId);
    }
  };

  const handleDiscardAndSwitch = () => {
    dispatch(resetFormDirty()); // reset the dirty flag when discarding changes
    handleChangeToEditCommunity(switchingToCommunityId);
  };

  const handleChangeToEditCommunity = (communityId) => {
    // const selectedId = event.target.value;
    const selectedCareType = approvedPropertyCommunities.find(
      (care_type) => care_type.id === Number(communityId)
    );
    if (currentCommunityId !== communityId) {
      // make sure to save any changes before we change the care type.
      AmplitudeTools.logEvent(AmplitudeTools.EVENTS.EDIT_DIFFERENT_CARE_LEVEL);
      dispatch(formReset());
      setSwitchingTabOrCareType(false);
      setShowUnsavedChangesPrompt(false);
      setSwitchedFromAnotherCareType(true);
      navigateToEditCommunity(
        history,
        communityId,
        false,
        `care_type=${selectedCareType.care_type}`
      );
    }
  };

  return approvedPropertyCommunities ? (
    <div
      className={
        displayMode === DISPLAY_MODE_COMPRESSED
          ? styles.communitySelectCompressed
          : styles.communitySelect
      }
    >
      <Popup
        open={showUnsavedChangesPrompt && dirtyFlag}
        closeOnEscape={false}
        closeOnDocumentClick={false}
        lockScroll={true}
      >
        <RouterPrompt
          onSave={handleSaveAndSwitch}
          onDiscard={handleDiscardAndSwitch}
          onCancel={() => {
            setShowUnsavedChangesPrompt(false);
            setSwitchingTabOrCareType(false);
          }}
          title={
            selectedCommunity
              ? `Switch to ${CARE_TYPE_DESCRIPTIONS[selectedCommunity.care_type]}?`
              : 'Switch care type?'
          }
          message={'Changes you made will be lost.'}
        />
      </Popup>
      {displayMode === DISPLAY_MODE_COMPRESSED
        ? approvedPropertyCommunities.map(({ id, care_type }) =>
            currentCareType === care_type ? (
              <Button.FOCUS className='mr-50 no-wrap-text-button' key={id} value={id}>
                {CARE_TYPE_DESCRIPTIONS[care_type]}
              </Button.FOCUS>
            ) : null
          )
        : approvedPropertyCommunities.map(({ id, care_type }) =>
            currentCareType === care_type ? (
              <Button.FOCUS className='mr-50' key={id} value={id}>
                {CARE_TYPE_DESCRIPTIONS[care_type]}
              </Button.FOCUS>
            ) : (
              <Button
                className='mr-50'
                key={id}
                onClick={checkDirtyFlagBeforeSwitchingCareType}
                value={id}
              >
                {CARE_TYPE_DESCRIPTIONS[care_type]}
              </Button>
            )
          )}
    </div>
  ) : (
    <></>
  );
};

export default CommunitySelect;
