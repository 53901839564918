import { Link, useHistory, useParams } from 'react-router-dom';
import { useAssessmentResponses } from '../../../hooks/useAssessmentResponses';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { APITools } from 'web-core';
import SpinnerLoader from '../../../components/Spinner/SpinnerLoader';
import HeaderTitle from '../../../components/HeaderTitle/HeaderTitle';
import { useProperty } from '../../../hooks/useProperty';
import PropertyCompletedAssessmentsList from '../../../components/PropertyCompletedAssessmentsList/PropertyCompletedAssessmentsList';

const PropertyAssessmentResponsesDashboard = () => {
  const { propertyId } = useParams<{ propertyId?: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { data, meta, loading, error } = useAssessmentResponses(propertyId);
  const { data: property } = useProperty(propertyId);

  const hasValidData = data && !loading && !error;

  useEffect(() => {
    if (error) {
      let errorMessage = APITools.errorMessageFromServerResponse(error);
      enqueueSnackbar(errorMessage, {
        variant: 'warning',
      });
    }
  }, [enqueueSnackbar, error]);

  return hasValidData ? (
    <>
      <HeaderTitle
        title={`Completed Assessments${property ? ` - ${property.name}` : ''}`}
      />
      <div className='page-layout' style={{ width: '100%' }}>
        <div className={'header-breadcrumbs'}>
          {property ? (
            <Link to={`/properties/${property.slug}`}>{property.name}</Link>
          ) : (
            <br />
          )}
        </div>
        <div className='header bordered' style={{ flexDirection: 'column' }}>
          <div className='header-title'>
            <h1>Completed Assessments</h1>
          </div>
        </div>

        <div className='communities-dashboard'>
          <div className='communities-container'>
            <p>Recently completed assessments for {property.name}.</p>
            <div className='admin-communities'>
              <PropertyCompletedAssessmentsList assessments={data} meta={meta} />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <SpinnerLoader />
  );
};

export default PropertyAssessmentResponsesDashboard;
