/* ============================================================================================== */

import produce from 'immer';
import api from '../../services/api';
import { CookieUtils } from 'web-core';
import { Cookies } from 'react-cookie/cjs';
import AmplitudeTools from '../../utils/AmplitudeTools';

export const AuthActions = {
  RESET_ERRORS: 'auth/RESET_ERRORS',

  // LogIn
  LOGIN_PENDING: 'auth/LOGIN_PENDING',
  LOGIN_SUCCEEDED: 'auth/LOGIN_SUCCEEDED',
  LOGIN_FAILED: 'auth/LOGIN_FAILED',

  // SignUp
  SIGNUP_PENDING: 'auth/SIGNUP_PENDING',
  SIGNUP_SUCCEEDED: 'auth/SIGNUP_SUCCEEDED',
  SIGNUP_FAILED: 'auth/SIGNUP_FAILED',

  // Welcome Set Password
  SET_PASSWORD_PENDING: 'auth/SET_PASSWORD_PENDING',
  SET_PASSWORD_SUCCEEDED: 'auth/SET_PASSWORD_SUCCEEDED',
  SET_PASSWORD_FAILED: 'auth/SET_PASSWORD_FAILED',

  // Logout
  LOGOUT: 'auth/LOGOUT',
};

export const resetErrors = () => {
  return async (dispatch) => {
    dispatch({ type: AuthActions.RESET_ERRORS });
  };
};

// function for refreshing the currently logged in user in the session, including a refresh of the cookie - not used for manual form logins.
export function refreshSession(refreshToken) {
  return async (dispatch) => {
    dispatch({ type: AuthActions.LOGIN_PENDING });
    try {
      const response = await api.post(`/v1/auth/token`, { refresh_token: refreshToken });
      // save updated token to cookie
      const cookies = new Cookies();
      CookieUtils.setKWAccessTokenCookie(
        cookies,
        process.env.REACT_APP_ENVIRONMENT,
        response.data.meta.access_token
      );

      dispatch({
        type: AuthActions.LOGIN_SUCCEEDED,
        payload: { data: response.data },
      });
    } catch (err) {
      dispatch({
        type: AuthActions.LOGIN_FAILED,
        errors: err,
      });
    }
  };
}

// pass true to redirect back to the current page after logging back in
export function logout(fromUserAction, redirectBack) {
  return async (dispatch) => {
    // clear our cookies
    const cookies = new Cookies();
    CookieUtils.removeKWCookies(cookies, process.env.REACT_APP_ENVIRONMENT);

    AmplitudeTools.handleUserLogout(fromUserAction);

    // Using 'app/RESET' to have logout as an app cleaner. If there is collateral effects, could use 'AuthActions.LOGOUT'
    dispatch({ type: 'app/RESET' });
    if (redirectBack) {
      const refPath = window.location.href; // pass full URL since login is in web project on main kithward domain
      window.location.assign(
        `${process.env.REACT_APP_CONSUMER}/auth/login?return_to=${encodeURIComponent(
          refPath
        )}`
      );
    } else {
      window.location.assign(`${process.env.REACT_APP_CONSUMER}/auth/login`);
    }
  };
}

const INITIAL_STATE = {
  loading: false,
  isAuthenticated: false,
  user: null,
  errors: [],
};

const authReducer = produce((draft, action) => {
  switch (action.type) {
    case 'app/RESET':
      return INITIAL_STATE;

    case AuthActions.RESET_ERRORS:
      draft.errors = [];
      draft.loading = false;
      break;

    // LOGIN
    case AuthActions.LOGIN_PENDING:
      draft.loading = true;
      break;

    case AuthActions.LOGIN_SUCCEEDED:
      const data = action.payload.data;
      draft.loading = false;
      draft.isAuthenticated = true;
      draft.user = data;
      draft.errors = [];
      break;

    case AuthActions.LOGIN_FAILED:
      draft.loading = false;
      draft.isSaving = false;
      draft.isAuthenticated = false;
      draft.errors = action.errors;
      break;

    // Sign Up
    case AuthActions.SIGNUP_PENDING:
      draft.loading = true;
      break;

    case AuthActions.SIGNUP_SUCCEEDED:
      draft.errors = [];
      draft.loading = false;
      break;

    case AuthActions.SIGNUP_FAILED:
      draft.errors = action.errors;
      draft.loading = false;
      break;

    // Set Password
    case AuthActions.SET_PASSWORD_PENDING:
      draft.loading = true;
      break;

    case AuthActions.SET_PASSWORD_SUCCEEDED:
      draft.errors = [];
      draft.loading = false;
      break;

    case AuthActions.SET_PASSWORD_FAILED:
      draft.errors = action.errors;
      draft.loading = false;
      break;

    default:
      break;
  }
}, INITIAL_STATE);

export default authReducer;
