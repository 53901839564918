import { useCallback, useEffect, useMemo, useState } from 'react';
import { AssignPropertyConfirmation } from '../../../components/AssignCommunityPopup/AssignPropertyConfirmation';
import Button from '../../../components/Button/Button';
import { SearchBar } from '../../../components/SearchBar/SearchBar';
import Loader from '../../../components/Loader/Loader';
import styles from './AssignPropertyPage.module.scss';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { PropertyCareTypesCell } from '../../../components/PropertyResultsList/PropertyCareTypesCell';
import { useQueryProperties } from '../../../hooks/useQueryProperties';
import Popup from 'reactjs-popup';
import PropertyNameCell from '../../../components/PropertyResultsList/PropertyNameCell';
import PropertyResultsList from '../../../components/PropertyResultsList/PropertyResultsList';
import HeaderTitle from '../../../components/HeaderTitle/HeaderTitle';

export const AssignPropertyPage = () => {
  const urlPage = useQueryParams().get('page') || '';
  const { queryResult, queryResultMeta, queryLoading, queryProperties } =
    useQueryProperties();
  const [query, setQuery] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    queryProperties(query, urlPage);
  }, [urlPage]);

  const handleAssignProperty = useMemo(
    () => (event) => {
      event.preventDefault();
      event.stopPropagation();
      const { value: id } = event.target;
      const property = queryResult.find((p) => p.id === Number(id));
      setSelectedProperty(property);
      setShowPopup(true);
    },
    [queryResult, setSelectedProperty]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Property Name',
        accessor: 'name',
        width: 450,
        Cell: ({ row }) => <PropertyNameCell row={row} />,
      },
      {
        Header: 'Care type',
        accessor: 'care_type',
        width: 150,
        Cell: ({ row }) => <PropertyCareTypesCell row={row} />,
      },
      {
        Header: 'Organization',
        accessor: 'organization.name',
        width: 200,
      },
      {
        Header: '',
        id: 'claim',
        accessor: 'id',
        width: 100,
        Cell: ({ cell, row }) => (
          <Button.DARK
            value={cell.value}
            className={styles.assignButton}
            onClick={handleAssignProperty}
          >
            {row.original.organization ? 'Reassign' : 'Assign'}
          </Button.DARK>
        ),
      },
    ],
    [handleAssignProperty]
  );

  const handleQueryChange = useCallback(
    (newQuery) => {
      setQuery(newQuery);
    },
    [setQuery]
  );

  const handleQuerySearch = (query) => {
    queryProperties(query);
  };

  const handlePopupClose = (refreshProperties = false) => {
    setSelectedProperty(null);
    setShowPopup(false);
    if (refreshProperties) {
      queryProperties(query);
    }
  };

  return (
    <>
      <HeaderTitle title={'Assign Properties'} />
      <div className={`page-layout communities-dashboard ${styles.assignPropertyPage}`}>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Assign Properties</h1>
          </div>
        </div>
        <SearchBar onQueryChange={handleQueryChange} onSubmit={handleQuerySearch} />
        <div className='communities-container'>
          <div className='admin-communities'>
            <Loader isLoading={queryLoading}>
              <PropertyResultsList
                title='Result found'
                properties={queryResult}
                meta={queryResultMeta}
                columns={columns}
              />
            </Loader>
          </div>
        </div>
        <Popup
          open={showPopup}
          modal
          nested
          closeOnEscape={false}
          closeOnDocumentClick={false}
          lockScroll={true}
          onClose={() => setShowPopup(false)}
        >
          <AssignPropertyConfirmation
            onClose={handlePopupClose}
            property={selectedProperty}
          />
        </Popup>
      </div>
    </>
  );
};
