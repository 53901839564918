import useSWR from 'swr';
import api from '../services/api';

export const useStats = () => {
  const { data, error } = useSWR('/v1/admin/stats', api, {
    errorRetryCount: 3,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onErrorRetry: (err, key, config, revalidate, revalidateOps) => {
      console.log({ err, config });
    },
  });
  return {
    loading: !data && !error,
    stats: data ? data.data.results : null,
    error,
  };
};
