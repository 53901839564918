import styles from './ReviewInterestTags.module.scss';
import React, { useEffect, useState } from 'react';
import Button from '../../Button/Button';
import InterestTag from '../../InterestTag/InterestTag';
import useSWR from 'swr';
import api from '../../../services/api';
import { useSnackbar } from 'notistack';
import Loader from '../../Loader/Loader';
import { updateInterestTags } from '../../../services/communityEdits';

interface ReviewInterestTagsProps {
  slug: string;
  communityName: string;
  onFinish: () => void;
  propertyId: string;
}

const ReviewInterestTags: React.FC<ReviewInterestTagsProps> = ({
  slug,
  communityName,
  onFinish,
  propertyId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isValidating, error } = useSWR(
    propertyId ? `/v1/admin/properties/${propertyId}/interest_taggings` : null,
    api,
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );
  const dataFromApi = data?.data;
  const currentTags = dataFromApi?.results;
  const meta = dataFromApi?.meta;

  const handleUpdateInterestTag = async (tagId: number, state: string) => {
    await updateInterestTags(propertyId, String(tagId), state);
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'warning' });
    }
  }, [enqueueSnackbar, error]);

  return !error ? (
    <div className={styles.container}>
      <div className={styles.title}>
        <h4>
          {'Review Interest Tags for '}
          <a
            href={`${process.env.REACT_APP_CONSUMER}/community/${slug}?draft=true`}
            target='_blank'
            rel='noreferrer'
          >
            {communityName}
          </a>
        </h4>
      </div>
      <p className={styles.description}>
        Approve any interest tags for them to be visible on the website.
      </p>
      <Loader isLoading={isValidating}>
        <div className={styles.tagsContainer}>
          {meta &&
            meta.interest_tags.map(
              (tag: { id: number; name: string; image_path: string; state: string }) => {
                let tagInfo =
                  currentTags &&
                  currentTags.find(
                    (t: { interest_tag: { id: number } }) => t.interest_tag.id === tag.id
                  );
                return (
                  <InterestTag
                    key={tag.id}
                    tagId={tag.id}
                    tagName={tag.name}
                    imageUrl={tag.image_path}
                    state={tagInfo?.state}
                    onChange={handleUpdateInterestTag}
                  />
                );
              }
            )}
        </div>
      </Loader>
      <div className={styles.bottomButton}>
        <Button.BLACK onClick={onFinish}>DONE</Button.BLACK>
      </div>
    </div>
  ) : null;
};

export default ReviewInterestTags;
