import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsExecutive, selectIsSuperAdmin } from '../../redux/selectors/auth';
import { MenuItem } from './MenuItem';
import { getData } from '../../redux/reducers';
import { useDispatch } from 'react-redux';
import styles from './SideMenu.module.scss';

export const SideMenu = ({}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const isExecutive = useSelector(selectIsExecutive);
  const moderationData = useSelector((state) => state?.moderation?.moderation?.results);

  useEffect(() => {
    if (auth && auth.isAuthenticated && isSuperAdmin) {
      fetchMenuData();
    }
  }, []);

  const fetchMenuData = async () => {
    await dispatch(getData());
  };

  const communityManagerMenuItems = [
    {
      label: 'Properties',
      parentLink: '/properties',
      subItems: [
        { label: 'My Communities', link: '/communities' },
        //{ label: 'Kithward Premium', link: '/premium' }
      ],
    },
    { label: 'Users', link: '/users' },
  ];

  const executiveMenuItems = [
    {
      label: 'Properties',
      //parentL
      link: '/properties',
      //subItems: [{ label: 'Kithward Premium', link: '/premium' }],
    },
    { label: 'Users', link: '/users' },
  ];

  const superAdminMenuItems = [
    { label: 'Organizations', link: '/organizations' },
    {
      label: 'Properties',
      parentLink: '/properties',
      subItems: [{ label: 'Communities', link: '/communities' }],
    },
    {
      label: 'Users',
      parentLink: '/users',
      subItems: [
        {
          label: `Consumers ${
            moderationData?.total_user_accounts
              ? `(${moderationData.total_user_accounts})`
              : ''
          }`,
          link: '/consumers',
        },
        {
          label: `Operators ${
            moderationData?.total_operator_accounts
              ? `(${moderationData.total_operator_accounts})`
              : ''
          }`,
          link: '/operators',
        },
      ],
    },
    {
      label: 'Operator Admin',
      subItems: [
        {
          label: `Account Requests ${
            moderationData?.total_account_requests
              ? `(${moderationData.total_account_requests})`
              : ''
          }`,
          link: '/moderation/account-requests',
        },
        {
          label: `Community Claims ${
            moderationData?.total_community_claim_requests
              ? `(${moderationData.total_community_claim_requests})`
              : ''
          }`,
          link: '/moderation/community-claims',
        },
        {
          label: `Community Edits ${
            moderationData?.total_community_edit_requests
              ? `(${moderationData.total_community_edit_requests})`
              : ''
          }`,
          link: '/moderation/community-edits',
        },
        {
          label: `Assign Properties`,
          link: '/moderation/assign-property',
        },
      ],
    },
    {
      label: 'Consumer Admin',
      subItems: [
        {
          label: `Info Requests ${
            moderationData?.total_pending_leads
              ? `(${moderationData.total_pending_leads})`
              : ''
          }`,
          link: '/consumer-admin/information-requests',
        },
        {
          label: `Tour Requests ${
            moderationData?.total_pending_tour_requests
              ? `(${moderationData.total_pending_tour_requests})`
              : ''
          }`,
          link: '/consumer-admin/tour-requests',
        },
        {
          label: `Booked Tours`,
          link: '/consumer-admin/booked-tours',
        },
        // { label: `Followups Required` : ""}`, link: '/moderation/community-edits' },
        // { label: `Consumer Calendar` : ""}`, link: '/moderation/community-edits' },
        // { label: `Help Tickets` : ""}`, link: '/moderation/community-edits' },
      ],
    },
    {
      label: 'Stats',
      link: '/stats',
    },
  ];

  const menuItems = isSuperAdmin
    ? superAdminMenuItems
    : isExecutive
    ? executiveMenuItems
    : communityManagerMenuItems;
  return (
    <div className={`${styles.sideMenu}`}>
      <div className={styles.menuItemsContainer}>
        {menuItems.map((item) => (
          <MenuItem key={item.label} {...item} />
        ))}
      </div>
    </div>
  );
};
