import produce from 'immer';
import api from '../../services/api';

export const SurveysActions = {
  GET_PENDING: 'surveys/GET_PENDING',
  GET_SUCCEEDED: 'surveys/GET_SUCCEEDED',
  GET_FAILED: 'surveys/GET_FAILED',
};

const SURVEYS_BASE_API = `v1/admin/surveys/account_surveys`;

export function getSurveys(accountId) {
  return async (dispatch) => {
    dispatch({ type: SurveysActions.GET_PENDING });
    try {
      const { data } = await api(`${SURVEYS_BASE_API}/${accountId}`);
      dispatch({
        type: SurveysActions.GET_SUCCEEDED,
        payload: data,
      });
      return data;
    } catch (err) {
      dispatch({ type: SurveysActions.GET_FAILED });
      return { error: 'An internal server error occurred' };
    }
  };
}

const INITIAL_STATE = {
  surveys: [],
  meta: null,
  isSurveysLoading: false,
};

const surveysReducer = produce((draft, action) => {
  switch (action.type) {
    case SurveysActions.GET_PENDING: {
      draft.isSurveysLoading = true;
      draft.surveys = [];
      draft.meta = null;
      break;
    }
    case SurveysActions.GET_SUCCEEDED: {
      const { surveys, meta } = action.payload;
      draft.isSurveysLoading = false;
      draft.surveys = surveys;
      draft.meta = meta;
      break;
    }
    case SurveysActions.GET_FAILED: {
      draft.isSurveysLoading = false;
      draft.surveys = [];
      draft.meta = null;
      break;
    }
    default:
      break;
  }
}, INITIAL_STATE);

export default surveysReducer;
