import { combineReducers } from 'redux';

import app from './app';
import communityForm from './communityForm';
import users from './users';
import auth from './auth';
import errors from './errors';
import moderation from './moderation';
import adminNotes from './adminNotes';
import surveys from './surveys';
import activities from './activities';

export * from './app';
export * from './communityForm';
export * from './users';
export * from './auth';
export * from './errors';
export * from './moderation';
export * from './surveys';
export * from './activities';

const combine = () => {
  return combineReducers({
    app,
    communityForm,
    users,
    auth,
    errors,
    moderation,
    adminNotes,
    surveys,
    activities,
  });
};

export default combine;
