import styles from './CommunityList.module.scss';
import { KWImage } from '../KWImage/KWImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from '@mui/material';

const CommunityNameCell = ({ row, compact = false }) => {
  const { cached_image_url } = row.original;

  const {
    display_name,
    name,
    address,
    street,
    postal,
    city,
    state,
    country,
    star_rating,
    location_address,
  } = row.original || '';

  return (
    <div className={styles.communityNameContainer}>
      <KWImage src={cached_image_url} width={85} height={85} alt={`${name}`} />
      <div className={styles.communityNameInfo}>
        <b>{compact ? display_name : name}</b>
        <div>
          {compact ? [street, city].filter((i) => i).join(', ') : `${location_address}`}
          {!compact && (
            <Tooltip title='Published diamond rating'>
              <div>
                {star_rating
                  ? Array(star_rating)
                      .fill(null)
                      .map((e, index) => <FontAwesomeIcon key={index} icon={faGem} />)
                  : Array(5)
                      .fill(null)
                      .map((e, index) => (
                        <FontAwesomeIcon
                          key={index}
                          icon={faGem}
                          style={{ color: '#c4c4c4' }}
                        />
                      ))}
              </div>
            </Tooltip>
          )}
        </div>
        <p>{compact ? [state, postal, country].filter((i) => i).join(', ') : null}</p>
      </div>
    </div>
  );
};

export default CommunityNameCell;
