import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Constants } from 'web-core';
import { selectModels, selectUnitForm } from '../../../../redux/selectors/communityForm';
import { ModelSelect } from '../../../DynamicForm/Widgets/ModelSelect/ModelSelect';

export const ModelSelectPopulateUnit = (props) => {
  // should get this from existing form data instead of the selector!
  // or figure out how to not refresh the page when just the unit form changes
  const unitForm = useSelector(selectUnitForm);
  const models = useSelector(selectModels) || [];

  const unitModelId =
    unitForm[Constants.DynamicSchemaFields.UnitModelType] ||
    models.find(
      (m) =>
        m[Constants.DynamicSchemaFields.ModelName] ===
        unitForm[Constants.DynamicSchemaFields.UnitModelType]
    )?.id ||
    '';

  const handleModelSelectChange = (value) => {
    props.onChange(value);
  };

  return (
    <ModelSelect {...props} value={unitModelId} onChange={handleModelSelectChange} />
  );
};
