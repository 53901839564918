import React, { useMemo } from 'react';
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
} from 'react-table';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './MySearchItem.module.scss';

const MySearchItem = ({ items = [] }) => {
  console.log('Items: ', items);

  const data = items.length > 0 ? items : [];

  const columns = useMemo(
    () => [
      { Header: 'Created At', accessor: 'created_at' },
      { Header: 'User', accessor: 'user.name' },
      { Header: 'Email', accessor: 'user.email' },
      { Header: 'Phone', accessor: 'user.phone' },
      { Header: 'Community', accessor: 'geo_place.name' },
      { Header: 'Country', accessor: 'geo_place.country' },
      {
        Header: 'State',
        accessor: 'geo_place.region',
        Cell: ({ row }) =>
          row.original.geo_place?.region || row.original.geo_place?.state,
      },
      { Header: 'District', accessor: 'geo_place.district' },
      { Header: 'Postcode', accessor: 'geo_place.postcode' },
    ],
    []
  );

  const defaultColumn = useMemo(() => ({}), []);

  const tableInstance = useTable(
    { columns, data, defaultColumn, initialState: { pageSize: 10 } },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  return (
    <div className={styles.searchTableContainer}>
      {items.length === 0 ? (
        <p>No search activities available.</p>
      ) : (
        <table {...getTableProps()} className={styles.searchTable}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        <FontAwesomeIcon
                          icon={column.isSortedDesc ? faChevronUp : faChevronDown}
                        />
                      ) : null}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td key={cell.column.id} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MySearchItem;
