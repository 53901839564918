import styles from './DateSelect.module.scss';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { useState } from 'react';
import moment from 'moment';

export const DateSelect = ({ endDate, onChange }) => {
  const [date, setDate] = useState(endDate || null);
  const handleOnChange = (value) => {
    let dateStr;
    if (value == null || isNaN(value.getTime())) {
      // invalid date passed through
      dateStr = null;
    } else {
      // make a date object with no time so it is TZ independent
      const localDate = moment();
      localDate.set({
        year: value.getFullYear(),
        month: value.getMonth(),
        date: value.getDate(),
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      dateStr = localDate.format('MM/DD/YYYY');
    }

    setDate(dateStr);
    onChange(dateStr);
  };

  const handleOnError = (reason, value) => {
    console.log('Error in date: ' + value + ', ' + reason);
  };

  return (
    <div className={styles.datePicker}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          clearable
          label='End Date'
          onChange={handleOnChange}
          onError={handleOnError}
          value={date}
          inputFormat='MM/dd/yyyy'
          inputProps={{ readOnly: true }}
          closeOnSelect={true}
          renderInput={(params) => (
            <TextField {...params} className={styles.datePickerInput} />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};
