import api from './api';
import { toURLSearchParams } from './transform';

const PATH = '/v1/admin/organizations';

export const createOrganization = async (formData) => {
  try {
    const { data } = await api.post(`${PATH}`, formData);
    return data;
  } catch (err) {
    throw err;
  }
};

export const updateOrganization = async (organization, formData) => {
  try {
    if (!organization.id) return;

    const { data } = await api.put(`${PATH}/${organization.id}`, formData);
    return data;
  } catch (err) {
    throw err;
  }
};

export const queryOrganizations = async (form = {}, page = 1) => {
  try {
    const { data } = await api.get(`${PATH}`, {
      params: toURLSearchParams({ ...form, page }),
    });
    return data;
  } catch (err) {
    throw err;
  }
};

export const deleteOrganization = async (organizationId) => {
  try {
    if (!organizationId) {
      throw Error('Organization ID is undefined');
    }
    const { data } = await api.delete(`${PATH}/${organizationId}`);
    return data;
  } catch (err) {
    throw err;
  }
};
