import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KWImage } from '../../../KWImage/KWImage';
import styles from './RestaurantPhotoSelect.module.scss';
import Button from '../../../Button/Button';
import { Constants } from 'web-core';
import { extractIndexFromDynamicFormId } from '../../../../utils/communityFormUtils';
import { formDataChange } from '../../../../redux/reducers';
import Popup from 'reactjs-popup';
import { ManageRestaurantImagesForm } from '../../../CommunityForm/Tabs/FacilitiesTab/RestaurantImagesForm/ManageRestaurantImagesForm';

export const RestaurantPhotoSelect = (props) => {
  const { id } = props;
  const formData = useSelector((state) => state.communityForm.formData);
  const communityImages = useSelector((state) => state.communityForm.images);
  const diningEstablishments =
    formData[Constants.DynamicSchemaFields.DiningEstablishments];
  const index = extractIndexFromDynamicFormId(id);
  const [diningImagesIds, setDiningImagesIds] = useState(
    (diningEstablishments &&
      diningEstablishments[index]?.[Constants.DynamicSchemaFields.DiningImages]) ||
      []
  );
  const [diningImages, setDiningImages] = useState([]);
  const [showManageImagesPopup, setShowManageImagesPopup] = useState(false);
  const dispatch = useDispatch();

  const getImagesById = (sourceArray, idArray) => {
    let array = [];
    idArray?.forEach((id) => {
      sourceArray?.forEach((img) => {
        if (img.id === id) {
          array.push(img);
        }
      });
    });
    return array;
  };

  const handleShowManageImagesPopup = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowManageImagesPopup(true);
  };

  const handleSaveRestaurantImages = (e, imageList) => {
    e.preventDefault();
    let newDiningEstablishmentArr = [];
    let newDiningImagesArr = [];
    imageList?.forEach((img) => {
      newDiningImagesArr.push(img.id);
    });
    for (let i = 0; i < diningEstablishments.length; i++) {
      if (index === i) {
        // copy target diningEstablishment
        let newDiningEstablishmentObj = {
          ...diningEstablishments[index],
          [Constants.DynamicSchemaFields.DiningImages]: newDiningImagesArr,
        };
        newDiningEstablishmentArr[i] = newDiningEstablishmentObj;
      } else {
        newDiningEstablishmentArr[i] = diningEstablishments[i];
      }
    }

    dispatch(
      formDataChange({
        ...formData,
        [Constants.DynamicSchemaFields.DiningEstablishments]: newDiningEstablishmentArr,
      })
    );

    setShowManageImagesPopup(false);
  };

  useEffect(() => {
    setDiningImagesIds(
      diningEstablishments?.[index]?.[Constants.DynamicSchemaFields.DiningImages] || []
    );
  }, [diningEstablishments]);

  useEffect(() => {
    let images = getImagesById(communityImages, diningImagesIds);
    setDiningImages(images);
  }, [diningImagesIds]);

  return (
    <div>
      <div className={styles.header}>
        <Button.LIGHT
          onClick={handleShowManageImagesPopup}
        >{`Manage images(${diningImagesIds.length})`}</Button.LIGHT>
      </div>

      <div className={styles.imagesContainer}>
        {diningImages.length > 0 &&
          diningImages?.slice(0, 4).map((image, index) => (
            <div className={styles.imageCard} key={index}>
              {image.file_url && (
                <KWImage src={image.file_url} height={120} alt='Dining image' />
              )}
            </div>
          ))}
      </div>

      <Popup
        open={showManageImagesPopup}
        modal
        nested
        closeOnDocumentClick={false}
        closeOnEscape={false}
        lockScroll={true}
      >
        <ManageRestaurantImagesForm
          onClose={() => setShowManageImagesPopup(false)}
          onSave={handleSaveRestaurantImages}
          diningImages={diningImagesIds}
        />
      </Popup>
    </div>
  );
};
