export function requestAppReset() {
  return { type: 'app/RESET' };
}

const INITIAL_STATE = {
  active: false,
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return { ...state, active: true };

    default:
      return state;
  }
};

export default appReducer;
