import produce from 'immer';
import api from '../../services/api';

export const ModerationActions = {
  SET_DATA: 'moderation/SET_DATA',
};

export const getData = () => {
  return async (dispatch) => {
    try {
      const response = await api(`/v1/admin/moderations`);
      dispatch({ type: ModerationActions.SET_DATA, payload: response.data });
    } catch (err) {
      console.error(err);
    }
  };
};

const INITIAL_STATE = {};

const moderationReducer = produce((draft, action) => {
  switch (action.type) {
    case ModerationActions.SET_DATA:
      draft.moderation = action.payload;
      break;

    default:
      break;
  }
}, INITIAL_STATE);

export default moderationReducer;
