import useSWR from 'swr';
import api from '../services/api';
import { idFromSlug } from '../utils/urlTools';

export const useAssessmentReport = (
  property_slug: string | number,
  surveyType: string,
  date: Date
) => {
  const month = date.getMonth() + 1; // add 1, so January, for example, is 1 not 0
  const year = date.getFullYear();
  const {
    data: data,
    isValidating,
    error,
  } = useSWR(
    property_slug
      ? `/v1/admin/surveys/operator_report?property_id=${idFromSlug(
          property_slug
        )}&survey_type=${surveyType}&month=${month}&year=${year}`
      : null,
    api,
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      revalidateIfStale: false,
      shouldRetryOnError: false,
    }
  );

  const report = data ? data.data.report : null;
  const property = data ? data.data.property : null;

  return {
    loading: isValidating,
    report,
    property,
    error,
  };
};
