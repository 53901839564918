import { useEffect, useState } from 'react';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAssignableProperties } from '../../../../redux/selectors/communityForm';

export const PropertySelect = (props) => {
  const [options, setOptions] = useState([]);

  const formData = useSelector((state) => state.communityForm.formData);
  const assignableProperties = useSelector(selectAssignableProperties);

  useEffect(() => {
    if (formData.id) {
      try {
        const newOptions = assignableProperties
          ? assignableProperties.map((p) => ({
              label: p.name,
              value: p.id,
            }))
          : [];
        setOptions(newOptions);
      } catch (err) {
        console.log(err);
        return { errors: err };
      }
    }
  }, [formData, assignableProperties]);

  return options.length === 0 ? (
    <Tooltip title='No assignable properties for this community.'>
      <div>
        <CustomSelect
          {...props}
          options={{
            enumOptions: options,
          }}
          value={''}
          disabled={options.length === 0}
        />
      </div>
    </Tooltip>
  ) : (
    <CustomSelect
      hideClear={true}
      {...props}
      options={{
        enumOptions: options,
      }}
    />
  );
};
