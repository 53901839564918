import styles from './PropertyResultsList.module.scss';

export const PropertyResultItem = ({ row }) => {
  return (
    <>
      <tr className={styles.propertyItem}>
        {row.cells.map((cell) => (
          <td key={cell.column.id + cell.row.id} {...cell.getCellProps()}>
            {cell.render('Cell')}
          </td>
        ))}
      </tr>
    </>
  );
};
