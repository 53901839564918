import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFlexLayout, useResizeColumns, useTable } from 'react-table';
import { Constants } from 'web-core';
import { EmptyBox } from '../../../EmptyBox/EmptyBox';
import styles from '../../TabContent/TabContent.module.scss';
import Button from '../../../Button/Button';
import { InlineUnitTypeInput } from '../../InlineUnitTypeInput/InlineUnitTypeInput';
import KithwardLogo from '../../../../assets/kw-logo-96.webp';
import { KWImage } from '../../../KWImage/KWImage';

const ModelsTable = ({ onRowClick }) => {
  const data = useSelector((state) => state.communityForm.formData.models) || [];
  const modelTypeImages = useSelector((state) => state.communityForm.model_type_images);

  const findModelFirstImage = (modelTypeImages, imageId) => {
    return modelTypeImages.find((image) => image.id === imageId);
  };

  const defaultColumn = useMemo(
    () => ({
      minWidth: 100, // minWidth is only used as a limit for resizing
      width: 140,
    }),
    []
  );

  const stopOnRowClick = (event) => {
    if (event.type === 'click') {
      event.stopPropagation();
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => <div className='pl-50' />,
        accessor: Constants.DynamicSchemaFields.ModelImage,
        Cell: ({ value }) => {
          let modelFirstImage = value
            ? findModelFirstImage(modelTypeImages, value)
            : null;
          return (
            <div className='pl-50' style={{ padding: '10% 5% 10% 9%' }}>
              {!value ? (
                <KWImage width={85} height={85} alt={`Unit Type Image`} />
              ) : modelFirstImage && modelFirstImage?.file_url ? (
                <KWImage
                  src={modelFirstImage.file_url}
                  width={85}
                  height={85}
                  alt={`Unit Type Image`}
                />
              ) : (
                KithwardLogo
              )}
            </div>
          );
        },
      },
      {
        Header: () => <div className='pl-50'>Name</div>,
        accessor: Constants.DynamicSchemaFields.ModelName,
        Cell: ({ value }) => <div className='pl-50'>{value}</div>,
      },
      {
        Header: 'Type',
        className: 'text-center',
        accessor: Constants.DynamicSchemaFields.ModelUnitType,
        Cell: ({ value }) => (
          <div className='text-center'>{value ? `${value}` : '-'}</div>
        ),
      },
      {
        Header: 'Square FT',
        className: 'text-center',
        accessor: Constants.DynamicSchemaFields.ModelSquareFootage,
        Cell: ({ value }) => (
          <div className='text-center'>{value ? `${value} SQFT` : '-'}</div>
        ),
      },
      {
        Header: 'Bedrooms',
        className: 'text-center',
        Cell: ({ row }) => {
          let floorplan = row.original;
          return (
            <div className='text-center'>
              {floorplan[Constants.DynamicSchemaFields.ModelHasStudioLayout]
                ? 'STUDIO'
                : floorplan[Constants.DynamicSchemaFields.ModelBedrooms]
                ? floorplan[Constants.DynamicSchemaFields.ModelBedrooms].length
                : '-'}
            </div>
          );
        },
      },
      {
        Header: 'Bathrooms',
        className: 'text-center',
        accessor: Constants.DynamicSchemaFields.ModelBathrooms,
        Cell: ({ value }) => {
          let bathrooms = 0;
          value &&
            value.forEach((bathroom) => {
              if (
                bathroom[Constants.DynamicSchemaFields.ModelBathroomType] ===
                'Full Bathroom'
              ) {
                bathrooms += 1;
              } else if (
                bathroom[Constants.DynamicSchemaFields.ModelBathroomType] ===
                'Half Bathroom'
              ) {
                bathrooms += 0.5;
              }
            });
          return (
            <div className='text-center'>{bathrooms > 0 ? `${bathrooms} B` : '-'}</div>
          );
        },
      },
      {
        Header: 'Base Monthly Rent',
        className: 'text-center',
        accessor: Constants.DynamicSchemaFields.ModelBaseMarketRent,
        Cell: ({ value, row }) => (
          <div onClick={(e) => stopOnRowClick(e)}>
            <InlineUnitTypeInput
              value={value}
              formKey={Constants.DynamicSchemaFields.ModelBaseMarketRent}
              row={row}
              disabled={false}
              label={'Base'}
            />
          </div>
        ),
      },
      {
        Header: 'Max Monthly Rent',
        className: 'text-center',
        accessor: Constants.DynamicSchemaFields.ModelMaxMarketRent,
        Cell: ({ value, row }) => (
          <div
            onClick={(e) => stopOnRowClick(e)}
            className={`${styles.inlineInput} text-center`}
          >
            <InlineUnitTypeInput
              value={value}
              formKey={Constants.DynamicSchemaFields.ModelMaxMarketRent}
              row={row}
              disabled={false}
              label={'Max'}
            />
          </div>
        ),
      },
      {
        Header: () => (
          <span>
            2nd Resident
            <br />
            Base Monthly Rent
          </span>
        ),
        className: 'text-center',
        accessor: Constants.DynamicSchemaFields.ModelSecondResidentBaseRent,
        Cell: ({ value, row }) => (
          <div
            onClick={(e) => stopOnRowClick(e)}
            className={`${styles.inlineInput} text-center`}
          >
            <InlineUnitTypeInput
              value={value}
              formKey={Constants.DynamicSchemaFields.ModelSecondResidentBaseRent}
              row={row}
              disabled={false}
              label={'Base'}
            />
          </div>
        ),
      },
      {
        Header: () => (
          <span>
            2nd Resident
            <br />
            Max Monthly Rent
          </span>
        ),
        className: 'text-center',
        accessor: Constants.DynamicSchemaFields.ModelSecondResidentMaxRent,
        Cell: ({ value, row }) => (
          <div
            onClick={(e) => stopOnRowClick(e)}
            className={`${styles.inlineInput} text-center`}
          >
            <InlineUnitTypeInput
              value={value}
              formKey={Constants.DynamicSchemaFields.ModelSecondResidentMaxRent}
              row={row}
              disabled={false}
              label={'Max'}
            />
          </div>
        ),
      },
      {
        Header: 'Edit',
        width: 100,
        className: 'pl-100',
        Cell: ({ value, row }) => (
          <Button.DARK onClick={() => onRowClick(row.index)}>Edit</Button.DARK>
        ),
      },
    ],
    [modelTypeImages] // very important or else the images won't appear right after upload
  );

  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    rows, // rows for the table based on the data passed
    // page, // Instead of using 'rows', we'll use page,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFlexLayout,
    useResizeColumns
    // useSortBy,
  );
  return (
    <>
      <table {...getTableProps()} className={styles.reactTable}>
        <thead>
          <tr>
            {headerGroups[0].headers.map((column) => (
              <th
                key={column.Header.toString()}
                {...column.getHeaderProps()}
                className={column.className || ''}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <ModelRow
                key={row.id}
                onClick={() => onRowClick(row.index)}
                row={row}
                {...row.getRowProps()}
              />
            );
          })}
        </tbody>
      </table>
      {!rows || rows.length === 0 ? (
        <EmptyBox
          message={`This community currently has no Unit Types. Please click on the '+' button to add one.`}
        />
      ) : null}
    </>
  );
};

const ModelRow = ({ row, onClick }) => {
  return (
    <tr className={styles.rowItem} onClick={onClick} id={row.original.id}>
      {row.cells.map((cell) => (
        <td key={cell.column.id + cell.row.id} {...cell.getCellProps()}>
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  );
};

export default ModelsTable;
