import { useStats } from '../../hooks/useStats';
import Loader from '../Loader/Loader';
import { Stats } from '../Stats/Stats';
import styles from './StatsBar.module.scss';

export const StatsBar = ({}) => {
  const { stats, loading } = useStats();

  return (
    <div className={styles.statsBar}>
      <Loader isLoading={loading}>
        {stats?.total_properties || stats?.total_properties === 0 ? (
          <Stats title='Properties' value={stats?.total_properties} />
        ) : null}
        {stats?.total_communities || stats?.total_communities === 0 ? (
          <Stats title='Communities' value={stats?.total_communities} />
        ) : null}
        {stats?.total_buildings || stats?.total_buildings === 0 ? (
          <Stats title='Buildings' value={stats?.total_buildings} />
        ) : null}
        {stats?.total_units || stats?.total_units === 0 ? (
          <Stats title='Units' value={stats?.total_units} />
        ) : null}
        {stats?.total_accounts || stats?.total_accounts === 0 ? (
          <Stats title='Users' value={stats?.total_accounts} />
        ) : null}
        {stats?.pending_approvals || stats?.pending_approvals === 0 ? (
          <Stats title='Pending Approvals' value={stats?.pending_approvals} />
        ) : null}
      </Loader>
    </div>
  );
};
