import produce from 'immer';
import api from '../../services/api';

export const ActivitiesActions = {
  GET_PENDING: 'activities/GET_PENDING',
  GET_SUCCEEDED: 'activities/GET_SUCCEEDED',
  GET_FAILED: 'activities/GET_FAILED',
};

const ACTIVITIES_BASE_API = `v1/admin/activities`;

export function getActivities(accountId) {
  return async (dispatch) => {
    dispatch({ type: ActivitiesActions.GET_PENDING });
    try {
      const { data } = await api(`${ACTIVITIES_BASE_API}?owner_id=${accountId}`);
      dispatch({
        type: ActivitiesActions.GET_SUCCEEDED,
        payload: data.activities,
      });
      return data.activities;
    } catch (err) {
      dispatch({ type: ActivitiesActions.GET_FAILED });
      return { error: 'An internal server error occurred' };
    }
  };
}

const INITIAL_STATE = {
  activities: {},
  meta: null,
  isActivitiesLoading: false,
};

const activitiesReducer = produce((draft, action) => {
  switch (action.type) {
    case ActivitiesActions.GET_PENDING: {
      draft.isActivitiesLoading = true;
      draft.activities = {};
      draft.meta = null;
      break;
    }
    case ActivitiesActions.GET_SUCCEEDED: {
      const { activities, meta } = action.payload;
      draft.isActivitiesLoading = false;
      draft.activities = action.payload;
      draft.meta = meta;
      break;
    }
    case ActivitiesActions.GET_FAILED: {
      draft.isActivitiesLoading = false;
      draft.activities = {};
      draft.meta = null;
      break;
    }
    default:
      break;
  }
}, INITIAL_STATE);

export default activitiesReducer;
