import { useDispatch, useSelector } from 'react-redux';
import useAddressAutocomplete from '../../../../hooks/useAddressAutocomplete';
import { TextField } from '@mui/material';
import { StringUtils, Constants } from 'web-core';
import styles from './MapboxAutocomplete.module.scss';
import { formDataChange } from '../../../../redux/reducers';
import { useState } from 'react';
import { extractIndexFromDynamicFormId } from '../../../../utils/communityFormUtils';

const POI = 'poi';

export const MapboxAutocomplete = (props) => {
  const { onChange } = props;
  const formData = useSelector((state) => state.communityForm.formData);
  const pois = formData[Constants.DynamicSchemaFields.PointsOfInterest];
  const localEvents = formData[Constants.DynamicSchemaFields.LocalEvents];
  const address = useAddressAutocomplete('', POI);
  const dispatch = useDispatch();
  const targetIndex = extractIndexFromDynamicFormId(props.id);
  const poiNameLabel = StringUtils.extractLastSegmentFromConstant(
    Constants.DynamicSchemaFields.PoiName
  );
  const localEventLocationLabel = StringUtils.extractLastSegmentFromConstant(
    Constants.DynamicSchemaFields.LocalEventLocation
  );
  const [showResults, setShowResults] = useState(true);

  const handleSelectPoiOption = (suggestion) => {
    const poiName = suggestion.text;
    address.setValue(poiName);
    address.setResults([]);
    const addressValues = suggestion.context;
    const street = suggestion.properties?.address || suggestion.text;
    const city = addressValues.find((contextItem) => contextItem.id.includes('place.'));
    const state = addressValues.find((contextItem) => contextItem.id.includes('region.'));
    const postal = addressValues.find((contextItem) =>
      contextItem.id.includes('postcode.')
    );

    // get index of current POI/Local Event form
    const targetIndex = extractIndexFromDynamicFormId(props.id);

    if (props.label === poiNameLabel) {
      const newPoi = {
        [Constants.DynamicSchemaFields.PoiName]: poiName,
        [Constants.DynamicSchemaFields.PoiStreetAddress]: street,
        [Constants.DynamicSchemaFields.PoiCity]: city?.text || '',
        [Constants.DynamicSchemaFields.PoiState]: state?.text || '',
        [Constants.DynamicSchemaFields.PoiPostal]: postal?.text || '',
      };
      let newArr = [];
      for (let i = 0; i < pois.length; i++) {
        newArr[i] = parseInt(targetIndex) === i ? newPoi : pois[i];
      }
      dispatch(
        formDataChange({
          ...formData,
          [Constants.DynamicSchemaFields.PointsOfInterest]: newArr,
        })
      );
    } else if (props.label === localEventLocationLabel) {
      let currentEventName =
        localEvents[targetIndex]?.[Constants.DynamicSchemaFields.LocalEventName];
      const newLocalEvent = {
        [Constants.DynamicSchemaFields.LocalEventName]: currentEventName || '',
        [Constants.DynamicSchemaFields.LocalEventLocation]: poiName,
        [Constants.DynamicSchemaFields.LocalEventStreetAddress]: street,
        [Constants.DynamicSchemaFields.LocalEventCity]: city?.text || '',
        [Constants.DynamicSchemaFields.LocalEventState]: state?.text || '',
        [Constants.DynamicSchemaFields.LocalEventPostal]: postal?.text || '',
      };
      let newArr = [];
      for (let i = 0; i < localEvents.length; i++) {
        newArr[i] = parseInt(targetIndex) === i ? newLocalEvent : localEvents[i];
      }
      dispatch(
        formDataChange({
          ...formData,
          [Constants.DynamicSchemaFields.LocalEvents]: newArr,
        })
      );
    }
  };

  const handleChange = async (event) => {
    const newValue = event.target.value;
    onChange(newValue);
    await address.onChange(event);
  };

  return (
    <div>
      <TextField
        label={props.label}
        onChange={handleChange}
        onFocus={() => setShowResults(true)}
        onBlur={() => setShowResults(false)}
        value={
          props.label === poiNameLabel
            ? pois && pois[targetIndex]
              ? pois[targetIndex][Constants.DynamicSchemaFields.PoiName]
              : ''
            : localEvents && localEvents[targetIndex]
            ? localEvents[targetIndex][Constants.DynamicSchemaFields.LocalEventLocation]
            : ''
        }
        autoComplete='off'
        fullWidth
      />
      {address.results?.length > 0 && showResults && (
        <div className={styles.autoCompleteResults}>
          {address.results.map((poi, index) => {
            const { place_name } = poi;
            let delimiterIndex = place_name.indexOf(',');
            let name = place_name.substring(0, delimiterIndex);
            let address = place_name.substring(delimiterIndex + 1);
            return (
              <div
                className={styles.suggestedAddress}
                key={index}
                onMouseDown={() => handleSelectPoiOption(poi)}
              >
                <span className={styles.poiName}>{name}</span>
                <span className={styles.poiAddress}>{address}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
