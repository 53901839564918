import styles from './PropertyDetails.module.scss';
import { Property } from '../../types/Property';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button/Button';
import React, { useEffect, useRef, useState } from 'react';
import { CARE_TYPE_DESCRIPTIONS } from '../../constants/CareType';
import { faGem } from '@fortawesome/pro-light-svg-icons';
import { navigateToEditCommunity } from '../../utils/navigateToEditCommunity';
import { useHistory } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Constants } from 'web-core';
import { Account } from '../../types/Account';
import KthPopup from '../KthPopup/KthPopup';
import { PropertyClaimPopup } from '../PropertyClaimPopup/PropertyClaimPopup';
import { claimCommunity } from '../../services/communities';
import { useSnackbar } from 'notistack';
import { useAssessmentLinks } from '../../hooks/useAssessmentLinks';
import { AssessmentLinkProps } from '../../types/AssessmentLinkProps';
import api from '../../services/api';
import PropertyCompletedAssessmentsList from '../PropertyCompletedAssessmentsList/PropertyCompletedAssessmentsList';
import { useAssessmentResponses } from '../../hooks/useAssessmentResponses';
import { Community } from '../../types/Community';
import { selectIsSuperAdmin } from '../../redux/selectors/auth';

const PUBLISHED_STATUS = 'published';
const UNPUBLISHED_STATUS = 'unpublished';
const MAX_STAR_RATING = 5;
// TODO: add health assessment after implementation finish
const ASSESSMENT_LINK_TYPES = {
  educational: 'Educational Assessment',
  financial: 'Financial Assessment',
};

const PropertyDetails = ({ property }: { property: Property }) => {
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const propertyNameRef = useRef<HTMLInputElement>(null);
  const [propertyName, setPropertyName] = useState('');
  const [showPencilIcon, setShowPencilIcon] = useState(true);
  const [claimPopup, setClaimPopup] = useState<string | boolean>(false);
  const history = useHistory();
  const user = useSelector<RootStateOrAny, Account>((state) => state.auth.user);
  const { enqueueSnackbar } = useSnackbar();
  const { data: assessmentLinks } = useAssessmentLinks(property.id);
  const { data: responses, meta } = useAssessmentResponses(property.id);

  useEffect(() => {
    setPropertyName(property.name);
  }, [property.name]);

  useEffect(() => {
    if (propertyName) {
      propertyNameRef.current!.style.width = `${propertyName.length + 4}ch`;
    }
  }, [propertyName]);

  const handleChangePropertyName = async () => {
    //  TODO: add change property name endpoint;
    try {
      await api.patch(`v1/admin/properties/${property.id}`, {
        name: propertyName,
      });
      enqueueSnackbar(`Property name updated`, {
        variant: 'success',
      });
    } catch (err) {
      // Errors already handled in the API snackbar handler
      console.warn(err);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      propertyNameRef.current!.blur();
    }
  };

  const handleNavigateToEditCommunity = (id: number) => {
    if (user?.role === Constants.AccountRoles.CommunityManager) {
      if (user.assigned_communities.some((community) => community.id === id)) {
        navigateToEditCommunity(history, id, true, null);
      } else {
        setClaimPopup('prompt');
      }
    } else {
      navigateToEditCommunity(history, id, true, null);
    }
  };

  const handleSubmitClaim = async (communitiesToClaim: number | number[]) => {
    try {
      await claimCommunity(communitiesToClaim);
      enqueueSnackbar(`Claim submitted!`, {
        variant: 'success',
      });
      setClaimPopup(false);
    } catch (err) {
      setClaimPopup(false);
    }
  };

  const handleNavigateToAssessmentLinksPage = () => {
    if (property.premium || isSuperAdmin) {
      history.push(`/properties/${property.slug}/assessment-links`);
    } else {
      enqueueSnackbar(
        `An active premium subscription is required to embed links to our assessments in your website and marketing channels.`,
        {
          variant: 'info',
          autoHideDuration: 2000,
        }
      );
      handleNavigateToUpgradePremium();
    }
  };

  const handleNavigateToAssessmentReportPage = (assessmentType: string) => {
    if (property.premium || isSuperAdmin) {
      // TODO - straighten out an make these constants more consistent!
      const reportType =
        assessmentType === 'educational'
          ? 'education_assessment'
          : 'financial_assessment';
      history.push(`/properties/${property.slug}/assessment-report?type=${reportType}`);
    } else {
      enqueueSnackbar(`An active premium subscription is required to view reports.`, {
        variant: 'info',
        autoHideDuration: 2000,
      });
      handleNavigateToUpgradePremium();
    }
  };

  const handleNavigateToCompletedAssessments = () => {
    if (property.premium || isSuperAdmin) {
      history.push(`/properties/${property.id}/assessment-responses`);
    } else {
      enqueueSnackbar(
        `An active premium subscription is required to view your prospects.`,
        {
          variant: 'info',
          autoHideDuration: 2000,
        }
      );
      handleNavigateToUpgradePremium();
    }
  };

  const handleNavigateToUpgradePremium = () => {
    // TODO - implement page to upgrade a single property instead of going to the organization upgrade page
    if (property.organization_id && !property.premium) {
      history.push(`/premium?organization_id=${property.organization_id}`);
    } else {
      enqueueSnackbar(`Invalid organization ID`, {
        variant: 'warning',
      });
    }
  };

  const showCommunityStatus = (community: Community) => {
    if (community.status === PUBLISHED_STATUS) {
      return 'Published';
    } else if (community.status === UNPUBLISHED_STATUS) {
      return 'Unpublished';
    } else {
      return '';
    }
  };

  return (
    <div className={styles.editPropertyPage}>
      <div className={styles.title}>
        {property.organization?.name ? (
          <p className={styles.organizationName}>{property.organization.name}</p>
        ) : null}
        <div className={styles.header}>
          <div
            className={styles.name}
            onFocus={() => setShowPencilIcon(false)}
            onBlur={() => setShowPencilIcon(true)}
          >
            <input
              type={'text'}
              id={'name'}
              ref={propertyNameRef}
              className={styles.propertyNameInput}
              value={propertyName}
              onChange={(e) => setPropertyName(e.target.value)}
              onBlur={() => handleChangePropertyName()}
              onKeyPress={(e) => handleKeyPress(e)}
            />
            <span
              className={`${styles.editNameIcon} ${showPencilIcon ? '' : styles.hidden}`}
              onClick={() => propertyNameRef.current!.focus()}
            >
              <FontAwesomeIcon icon={faPencil} size={'2x'} width={16} color={'#ac947a'} />
            </span>
          </div>
          <div>
            <Button.BLACK onClick={handleNavigateToUpgradePremium}>
              {property.premium ? 'PREMIUM' : 'Upgrade to Premium'}
            </Button.BLACK>
          </div>
        </div>
        <div className={styles.address}>
          {property.location_address ? property.location_address : '-'}
        </div>
      </div>

      {/* TODO: Add stat section and wire up backend */}
      {/*<div className={styles.statSection}></div>*/}

      {/* Community Profiles Section */}
      <div className={styles.communityProfileSection}>
        <div className={styles.subHeader}>
          <h3>Community Profile</h3>
          {property.communities[0].status === PUBLISHED_STATUS ? (
            <a
              href={`${process.env.REACT_APP_CONSUMER}/community/${property.communities[0].slug}`}
              target={'_blank'}
              rel='noreferrer'
            >
              <p className={styles.navigator}>{'View Profile'}</p>
            </a>
          ) : null}
        </div>
        <p className={'p2 mb-100'}>
          Completing your Kithward community profile helps us generate accurate and
          personalized reports when prospective residents fill out our educational and
          financial assessments. In addition, prospective residents will more easily be
          able to discover your community using our advanced community search tools.
        </p>
        {property.communities.length > 0 ? (
          <div className={styles.communityProfileBody}>
            {/* COLUMN HEADER */}
            <div className={styles.columnHeader}>Care Level</div>
            <div className={styles.columnHeader}>Diamond Rating</div>
            <div className={styles.columnHeader}>Status</div>
            <div className={styles.columnHeader}>Profile Completion</div>
            <div className={styles.columnHeader}>&nbsp;</div>

            {/* ROWS - COMMUNITY DATA */}
            {property.communities.map((community, index) => [
              <div
                className={styles.careType}
                key={`${community.name}-${community.care_type}`}
              >
                <h4>{CARE_TYPE_DESCRIPTIONS[community.care_type]}</h4>
              </div>,
              <div key={`rating-${index}`}>
                {community.star_rating ? (
                  Array(MAX_STAR_RATING)
                    .fill(null)
                    .map((e, index) => (
                      <FontAwesomeIcon
                        key={index}
                        icon={faGem}
                        size={'2x'}
                        width={24}
                        color={index < community.star_rating ? '#ac947a' : '#99999940'}
                      />
                    ))
                ) : (
                  <p>{'-'}</p>
                )}
              </div>,
              <div key={`status-${index}`}>{showCommunityStatus(community)}</div>,
              <div key={`profile-completion-${index}`}>
                <span>
                  {community.profile_completion.approved &&
                    `Published - ${community.profile_completion.approved.pct_complete}%`}
                </span>
                <br />
                <span>
                  {community.profile_completion.draft &&
                    `Draft - ${community.profile_completion.draft.pct_complete}%`}
                </span>
              </div>,
              <p
                onClick={() => handleNavigateToEditCommunity(community.id)}
                className={styles.navigator}
                key={`navigator-${index}`}
              >
                Edit Profile
              </p>,
            ])}
          </div>
        ) : (
          <p className={styles.emptyCommunityList}>
            There is no communities in this property.
          </p>
        )}
      </div>

      {/* Assessments Section */}
      <div className={styles.assessmentSection}>
        <div className={styles.subHeader}>
          <h3>Assessments</h3>

          <p className={styles.navigator} onClick={handleNavigateToAssessmentLinksPage}>
            {'Manage Links'}
          </p>
        </div>
        <p className={'p2 mb-100'}>
          {`Embed our education and financial assessments in your website, blog and marketing materials to help inform prospective residents and reduce the number of touch points to move in.`}
        </p>

        <div className={styles.assessmentsTypeList}>
          {Object.entries(ASSESSMENT_LINK_TYPES).map(([type, label], index) => {
            const numLinks = assessmentLinks?.filter(
              (link: AssessmentLinkProps) => link.assessment_type === type
            ).length;
            const numLinksStr =
              numLinks === undefined
                ? ''
                : numLinks === 1
                ? '1 Link'
                : `${numLinks} Links`;

            return (
              <div className={styles.assessments} key={index}>
                <div>
                  <p>{label}</p>
                </div>
                <div
                  className={styles.linkCount}
                  onClick={() => handleNavigateToAssessmentLinksPage()}
                >
                  {numLinksStr}
                </div>
                <div className={styles.completedCount}> </div>
                <div className={styles.newProspectsCount}> </div>
                <div
                  className={styles.viewReportLink}
                  onClick={() => handleNavigateToAssessmentReportPage(type)}
                >
                  {'Responses Report'}
                </div>
                <div>
                  <a
                    href={`${process.env.REACT_APP_CONSUMER}/${
                      label === ASSESSMENT_LINK_TYPES.educational
                        ? 'education-center'
                        : 'financial-calculator'
                    }/${
                      property.slug
                    }/start?utm_campaign=kithward-admin&utm_source=admin.kithward.com&utm_medium=link&preview=1`}
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    <p className={styles.previewAssessmentLink}>{'Preview'}</p>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* PROSPECTS SECTION: Need to make sure only visible to CM/CE if property is premium OR can only interact with it if premium */}
      <div className={styles.prospectSection}>
        <div className={styles.subHeader}>
          <h3>Prospects</h3>
        </div>

        {/* Just show up to the first five completed assessments here */}
        {responses ? (
          <div className={styles.prospectList}>
            <PropertyCompletedAssessmentsList
              assessments={responses.slice(0, 5)}
              meta={meta}
            />
          </div>
        ) : null}

        {/* Clicking view more will redirect to the completed assessments page which list all completed assessments there */}
        {responses?.length > 0 ? (
          <Button.BLACK
            onClick={handleNavigateToCompletedAssessments}
            className={styles.viewMoreButton}
          >
            {'View More'}
          </Button.BLACK>
        ) : null}
      </div>

      <KthPopup
        isOpen={claimPopup === 'prompt'}
        title={`Claim required!`}
        onClose={() => setClaimPopup(false)}
        onConfirmButtonClick={() => setClaimPopup('claim')}
        confirmButtonLabel={'Proceed'}
      >
        <div>
          <p>You must claim this community before editing it.</p>
          <p>Would you like to proceed with submitting a claim?</p>
        </div>
      </KthPopup>
      <KthPopup
        isOpen={claimPopup === 'claim'}
        title={`Claim Community`}
        onClose={() => setClaimPopup(false)}
        hideActions
      >
        <PropertyClaimPopup
          property={property}
          onClose={() => setClaimPopup(false)}
          onSubmitClaim={handleSubmitClaim}
          fromPropertyDashboard
        />
      </KthPopup>
    </div>
  );
};

export default PropertyDetails;
