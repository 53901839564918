import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Grid,
} from '@mui/material';
import { memo, useState } from 'react';
import { photoTypeOptions } from '../../../../constants/PhotoType';
import Button from '../../../Button/Button';
import { KWImage } from '../../../KWImage/KWImage';
import styles from '../../TabContent/TabContent.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faBars } from '@fortawesome/pro-light-svg-icons';
import { ImageUtils } from 'web-core';

export const PhotoRow = memo(
  ({ photo, onDelete, onChangePhoto, innerRef, dragProps, dragHandleProps, key }) => {
    const [imageTypes, setImageTypes] = useState(
      ImageUtils.toPhotoTagsArr(photo.image_types) || ''
    );

    const handleAltChange = (event) => {
      const modifiedPhoto = {
        ...photo,
        caption: event.target.value,
      };
      onChangePhoto(modifiedPhoto);
    };

    const handlePhotoTypeChange = (event) => {
      const modifiedPhoto = {
        ...photo,
        image_types: event.target.value,
      };
      setImageTypes(event.target.value);
      onChangePhoto(modifiedPhoto);
    };

    return (
      <div className={styles.tableRow} {...dragProps} {...dragHandleProps} ref={innerRef}>
        <Grid container spacing={0}>
          <Grid item xs={1} className={styles.divIconHamburger}>
            <Button.WHITE
              className={styles.cellButtonHamburger}
              style={{ backgroundColor: 'transparent' }}
            >
              <FontAwesomeIcon icon={faBars} color='#ac947a' />
            </Button.WHITE>
          </Grid>
          <Grid item xs={1} className={styles.photoCell}>
            {photo && (
              <KWImage
                src={photo.file_url}
                width={192}
                height={136}
                alt={`Unit Type Image`}
              />
            )}
          </Grid>
          <Grid item xs={10}>
            <div style={{ marginLeft: '12px' }}>
              <textarea
                draggable='false'
                key={`text-column-${key}`}
                defaultValue={photo.caption}
                onChange={handleAltChange}
                className={styles.photoAltText2}
                placeholder='Description...'
              />
            </div>
            <FormControl
              style={{
                minWidth: '200px',
              }}
              fullWidth
            >
              <div className={styles.tableRow}>
                <Grid container spacing={0}>
                  <Grid item xs={5}>
                    <InputLabel id='demo-simple-select-label'>Image Type</InputLabel>
                    <Select
                      draggable='false'
                      multiple
                      fullWidth
                      labelId='photo-multiple-checkbox-label'
                      id='photo-multiple-checkbox-label'
                      defaultValue={imageTypes}
                      onChange={handlePhotoTypeChange}
                      label='Image Type'
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {photoTypeOptions.map(({ label, value }) => {
                        return (
                          <MenuItem key={label} value={label}>
                            <Checkbox checked={imageTypes.indexOf(label) > -1} />
                            <ListItemText primary={label} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={3}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'end',
                        justifyContent: 'end',
                      }}
                    >
                      <Button.DEFAULT
                        className={styles.cellButtonTrash}
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() => onDelete(photo.id)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button.DEFAULT>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    );
  }
);
