import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActivities } from '../../redux/reducers/activities';
import Loader from '../Loader/Loader';
import Accordion from './Accordion';
import MySearchItem from './MySearchItem';
import styles from './MySearchList.module.scss';
import { useParams } from 'react-router-dom';

const MySearchList = () => {
  const { accountId } = useParams();
  const dispatch = useDispatch();

  const { activities = {}, isLoading = false } = useSelector((state) => state.activities);

  useEffect(() => {
    if (accountId) {
      dispatch(getActivities(accountId));
    }
  }, [dispatch, accountId]);

  return (
    <div className={styles.searchContainer}>
      <h2>My Searches</h2>
      <Loader isLoading={isLoading}>
        {Object.keys(activities).length === 0 ? (
          <p>No searches available.</p>
        ) : (
          Object.entries(activities).map(([type, items]) => (
            <Accordion key={type} title={type || 'Unknown Type'}>
              <MySearchItem items={items} />
            </Accordion>
          ))
        )}
      </Loader>
    </div>
  );
};

export default MySearchList;
