import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { Fragment, useCallback, useState } from 'react';
import { useQueryCommunity } from '../../hooks/useQueryCommunity';
import { Constants } from 'web-core';
import { debounce } from 'lodash';
import styles from './CommunityAutoComplete.module.scss';
import Icons from '../../constants/Icons';
import Button from '../Button/Button';
import { KWImage } from '../KWImage/KWImage';

export const CommunityAutoComplete = ({ onSelect }) => {
  const [value, setValue] = useState(null);
  const { queryResult, queryLoading, queryCommunities } =
    useQueryCommunity(`/v1/admin/communities/`);

  const delayedHandleChange = useCallback(
    debounce((newInputValue) => {
      const queryForm = {
        name: newInputValue,
      };
      queryCommunities(queryForm);
    }, 1000),
    []
  );

  const handleInputChange = (event, newInputValue) => {
    delayedHandleChange(newInputValue);
    setValue(newInputValue);
  };

  const handleAssign = (community) => {
    if (community) {
      onSelect('Add', community);
    }
  };

  return (
    <Autocomplete
      id='community-autocomplete'
      sx={{ width: 680 }}
      getOptionLabel={(option) =>
        typeof option === 'string'
          ? option
          : option.json_data[Constants.DynamicSchemaFields.Name]
      }
      filterOptions={(x) => x}
      freeSolo
      options={queryResult.filter((c) => c.json_data[Constants.DynamicSchemaFields.Name])}
      autoComplete
      includeInputInList
      filterSelectedOptions
      disableCloseOnSelect
      value={value}
      loading={queryLoading}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loadingText={'Loading...'}
      onSelect={() => {}}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Search Communities'
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {queryLoading ? <div>{Icons.Loader}</div> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const communityName = option.json_data[Constants.DynamicSchemaFields.Name] || ' ';
        const careType = option.json_data[Constants.DynamicSchemaFields.CareType] || ' ';
        const streetAddress =
          option.json_data[Constants.DynamicSchemaFields.StreetAddress] || ' ';
        const postal = option.json_data[Constants.DynamicSchemaFields.PostalCode] || ' ';
        const city = option.json_data[Constants.DynamicSchemaFields.City] || ' ';
        const state =
          option.json_data[Constants.DynamicSchemaFields.StateProvince] || ' ';
        const orgName = option.organization?.name || ' ';
        return (
          <div className={styles.communitySearchResult} key={option.id}>
            <KWImage
              src={option.cached_image_url}
              width={85}
              height={85}
              alt={communityName}
            />
            <div className={styles.communityInfo}>
              <Typography variant='body2' color='text.secondary'>
                {communityName} ({careType})
              </Typography>
              <Typography variant='body2' className={styles.communityDetails}>
                {orgName !== ' ' ? orgName : null}
              </Typography>
              <Typography variant='body2' className={styles.communityDetails}>
                {streetAddress !== ' ' && `${streetAddress}, `} {city} {state} {postal}
              </Typography>
            </div>
            <div>
              <Button.LIGHT onClick={() => handleAssign(option)}>Add</Button.LIGHT>
            </div>
          </div>
        );
      }}
    />
  );
};
