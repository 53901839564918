import Popup from 'reactjs-popup';
import styles from './EditCommunityRatingPopup.module.scss';
import { CARE_TYPE_DESCRIPTIONS } from '../../constants/CareType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import CommunityDiamondRating from '../CommunityDiamondRating/CommunityDiamondRating';
import { useState } from 'react';
import Button from '../Button/Button';
import api from '../../services/api';
import { APITools } from 'web-core';
import { useSnackbar } from 'notistack';

const EditCommunityRatingPopup = ({
  onClose,
  selectedCommunity,
  onEditCareTypeRating,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rating, setRating] = useState(null);
  const handleOnConfirm = async () => {
    try {
      const res = await api.put(
        `/v1/admin/star_ratings?community_id=${selectedCommunity.id}`,
        rating
      );
      if (res.status === 200) {
        onClose();
        onEditCareTypeRating();
      }
    } catch (e) {
      let errorMessage = APITools.errorMessageFromServerResponse(e);
      enqueueSnackbar(errorMessage, {
        variant: 'warning',
      });
    }
  };

  return (
    <Popup
      open
      modal
      nested
      closeOnDocumentClick={false}
      closeOnEscape={false}
      lockScroll={true}
      onClose={onClose}
    >
      <div className={styles.ratingPopupContainer}>
        <div className={styles.header}>
          <h4>
            {`${selectedCommunity?.name} - ${
              CARE_TYPE_DESCRIPTIONS[selectedCommunity?.care_type]
            }`}
          </h4>
          <button className={styles.closeButton} onClick={onClose}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        </div>
        <CommunityDiamondRating
          onChange={(params) => setRating(params)}
          url={
            selectedCommunity
              ? `/v1/admin/star_ratings/generate?community_id=${selectedCommunity.id}`
              : null
          }
        />
        <div className={styles.confirmButton}>
          <Button.BLACK
            onClick={handleOnConfirm}
            disabled={
              !rating?.star_rating ||
              (rating?.override_star_rating && !rating?.reason_to_override)
            }
          >
            Confirm
          </Button.BLACK>
        </div>
      </div>
    </Popup>
  );
};

export default EditCommunityRatingPopup;
