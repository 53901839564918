import Form from '@rjsf/core';
import { Fragment } from 'react';
import Button from '../Button/Button';
import FormSectionRow from '../CommunityForm/FormSectionRow/FormSectionRow';
import styles from './DynamicForm.module.scss';
import { BuildingSelect } from './Widgets/BuildingSelect/BuildingSelect';
import CustomAutoComplete from './Widgets/CustomAutoComplete/CustomAutoComplete';
import { CustomInput } from './Widgets/CustomInput/CustomInput';
import { CustomRadio } from './Widgets/CustomRadio/CustomRadio';
import { CustomSelect } from './Widgets/CustomSelect/CustomSelect';
import { CustomTextarea } from './Widgets/CustomTextarea/CustomTextarea';
import { FacilitiesSelect } from './Widgets/FacilitiesSelect/FacilitiesSelect';
import { ModelSelect } from './Widgets/ModelSelect/ModelSelect';
import { SquaredCheckbox } from './Widgets/SquaredCheckbox/SquaredCheckbox';
import { ViewAutoComplete } from './Widgets/ViewAutoComplete/ViewAutoComplete';
import { UnitFieldSelect } from '../CommunityForm/Tabs/UnitsTab/UnitFieldSelect';
import { useSelector } from 'react-redux';
import { PropertySelect } from './Widgets/PropertySelect/PropertySelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { Constants } from 'web-core';
import { MapboxAutocomplete } from './Widgets/MapboxAutocomplete/MapboxAutocomplete';
import { RestaurantPhotoSelect } from './Widgets/RestaurantPhotoSelect/RestaurantPhotoSelect';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { isBuildingOrUnitTypeTab } from '../../utils/communityFormUtils';

// If uiSchema for a inputField has "halfColumn" property, match left + right columns in one div
const Properties = ({ elements }) => {
  let firstHalfColumnContent = null;
  return elements.reduce((acc, element) => {
    const isHalfColumn = element.content.props.uiSchema.halfColumn;
    if (isHalfColumn) {
      if (firstHalfColumnContent) {
        acc.push(
          <div className={styles.dualColumn}>
            {firstHalfColumnContent}
            {element.content}
          </div>
        );
        firstHalfColumnContent = null;
      } else {
        firstHalfColumnContent = element.content;
      }
    } else {
      // If has left side but not right side column, just add previous as entire column.
      if (firstHalfColumnContent) {
        acc.push(firstHalfColumnContent);
      }
      acc.push(element.content);
    }
    return acc;
  }, []);
};

const TabFormObject = (props) => {
  const tabUI = props.uiSchema.tabs[props.formContext.currentTab];
  const fieldsHidden = useSelector((state) => state.communityForm.fieldsDeselected);
  return Object.entries(tabUI).map(([sectionTitle, section]) => {
    const sectionRows = section.rows.map((row) => {
      let showRow = true;
      if (row.dependsOn) {
        showRow = props.formData[row.dependsOn] === true;
      }
      if (!showRow) return null;

      const rowFields = row.fields.map((field) => {
        if (field === Constants.DynamicSchemaFields.CareType) {
          return null;
        }
        //Field should be an "object" with "key" property OR string.
        const fieldKey = typeof field === 'object' ? field.fieldKey : field;
        const fieldContent = props.properties.find(
          (property) => property.name === fieldKey
        )?.content;
        if (typeof field === 'object') {
          let showField = true;
          if (field.dependsOn) {
            showField = props.formData[field.dependsOn] === true;
          }
          return showField ? fieldContent : null;
        } else {
          // If field is in checkboxSelection && fieldValue is NOT true, dont show in tab form
          const currentTabCheckboxes =
            props.uiSchema.checkboxesSelection[props.formContext.currentTab];
          if (currentTabCheckboxes) {
            const sectionCheckboxes = currentTabCheckboxes[sectionTitle];
            if (
              sectionCheckboxes &&
              sectionCheckboxes.includes(fieldKey) &&
              props.formData[fieldKey] !== true
            ) {
              return null;
            }
          }
        }

        return fieldContent || null;
      });
      const rowHasFields = rowFields.some((field) => field);
      const rowDeselected =
        rowHasFields &&
        fieldsHidden.some(
          (hiddenField) =>
            hiddenField.field === rowFields[0].key && hiddenField.value === false
        ) &&
        (props.formContext.currentTab === 'Facilities' ||
          props.formContext.currentTab === 'Services');

      if (rowHasFields) {
        const toolTipData = rowFields.map((field, index) => {
          if (field) {
            const splitString = field.key.split('|');
            const schemaTitle = splitString[splitString.length - 1].replace('Has', '');
            return {
              key: index,
              title: field.props.schema.title ? field.props.schema.title : schemaTitle,
              message: field.props.schema.tooltip,
            };
          }
        });
        if (props.formContext.hideRowTitle && rowFields.length > 1) {
          return (
            <Row
              key={row.title}
              title={rowFields[0]}
              hasToolTip={row.tooltip !== undefined}
              toolTipData={toolTipData}
              required={row.isRequired}
              field={row.fields[0]}
            >
              {!rowDeselected ? rowFields.slice(1) : [' ', ' ']}
            </Row>
          );
        } else if (props.formContext.hideRowTitle && rowFields.length === 1) {
          return (
            <Row
              key={row.title}
              title={rowFields[0]}
              hasToolTip={row.tooltip !== undefined}
              toolTipData={toolTipData}
              required={row.isRequired}
              field={row.fields[0]}
            >
              {[' ']}
            </Row>
          );
        } else {
          return (
            <Row
              key={row.title}
              title={row.title}
              hasToolTip={row.tooltip !== undefined}
              toolTipData={toolTipData}
              required={row.isRequired}
              field={row.fields[0]}
              tab={props.formContext?.currentTab}
              arrayId={
                isBuildingOrUnitTypeTab(props.formContext.currentTab)
                  ? props.formData.id
                  : undefined
              }
            >
              {!rowDeselected ? rowFields : [' ', ' ']}
            </Row>
          );
        }
      } else {
        return null;
      }
    });

    const sectionHasRows = sectionRows.some((row) => row);
    return sectionHasRows ? (
      <Section key={sectionTitle} title={sectionTitle}>
        {sectionRows}
      </Section>
    ) : null;
  });
};

export const Row = ({
  title,
  hasToolTip,
  toolTipData,
  required,
  children,
  field,
  tab,
  arrayId,
}) => {
  const hasChildren = children.some((child) => child);
  return hasChildren ? (
    <>
      <FormSectionRow
        label={title}
        hasToolTip={hasToolTip}
        toolTipData={toolTipData}
        isRequired={required}
        field={field}
        tab={tab}
        arrayId={arrayId}
      >
        {children}
      </FormSectionRow>
    </>
  ) : null;
};

export const Section = ({ title, children }) => {
  return (
    <section className={styles.formSection}>
      <div className={styles.sectionHeader}>
        <span className={styles.sectionTitle}>{title}</span>
      </div>
      {children}
    </section>
  );
};

export const ObjectTemplate = (props) => {
  if (props.uiSchema?.tabs) {
    if (props.uiSchema.tabs[props.formContext.currentTab]) {
      return <TabFormObject {...props} />;
    } else {
      return null;
    }
  } else {
    return <Properties elements={props.properties} />;
  }
};

/**
 *
 * @param {id, classNames, label, help, required, description, errors, children} props
 * https://react-jsonschema-form.readthedocs.io/en/latest/advanced-customization/custom-templates/#fieldtemplate
 * @returns
 */
export const FieldTemplate = (props) => {
  const { role, inherit } = props.schema;
  if (role && role === 'hidden') {
    return <div style={{ display: 'none' }}>{props.children}</div>;
  } else if (inherit && inherit === true) {
    return <UnitFieldSelect array={false} props={props.children} />;
  } else {
    return props.children;
  }
  // Return just children to hide "label"
  // But if needed, can add "errors" to be shown
};

// https://react-jsonschema-form.readthedocs.io/en/latest/advanced-customization/custom-templates/#arrayfieldtemplate
export const ArrayFieldTemplate = (props) => {
  if (props.schema.inherit) {
    return (
      <>
        {props.items &&
          props.items.map((arrayItem, index) => {
            return (
              <div key={index} className={styles.listItem}>
                <div className={styles.fieldsContainer}>
                  <UnitFieldSelect array={true} props={arrayItem.children} />
                  {arrayItem.hasRemove ? (
                    <div className={styles.trashArrayButton}>
                      <Button.DEFAULT
                        icon
                        disabled={!arrayItem.hasMoveUp}
                        onClick={arrayItem.onReorderClick(
                          arrayItem.index,
                          arrayItem.index - 1
                        )}
                      >
                        <FontAwesomeIcon icon={faAngleUp} />
                      </Button.DEFAULT>
                      <Button.DEFAULT
                        icon
                        disabled={!arrayItem.hasMoveDown}
                        onClick={arrayItem.onReorderClick(
                          arrayItem.index,
                          arrayItem.index + 1
                        )}
                      >
                        <FontAwesomeIcon icon={faAngleDown} />
                      </Button.DEFAULT>
                      <Button.DEFAULT
                        icon
                        onClick={arrayItem.onDropIndexClick(arrayItem.index)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button.DEFAULT>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        {props.canAdd ? (
          <div className={styles.arrayButton}>
            <Button.BROWN icon onClick={props.onAddClick}>
              {props.schema.addNewText ? props.schema.addNewText : `Add ${props.title}`}
            </Button.BROWN>
          </div>
        ) : null}
      </>
    );
  } else {
    return (
      <>
        {props.items &&
          props.items.map((arrayItem, index) => {
            return (
              <div key={index} className={styles.listItem}>
                <div className={styles.fieldsContainer}>
                  {arrayItem.children}
                  {arrayItem.hasRemove ? (
                    <div className={styles.trashArrayButton}>
                      <Button.DEFAULT
                        icon
                        disabled={!arrayItem.hasMoveUp}
                        onClick={arrayItem.onReorderClick(
                          arrayItem.index,
                          arrayItem.index - 1
                        )}
                      >
                        <FontAwesomeIcon icon={faAngleUp} />
                      </Button.DEFAULT>
                      <Button.DEFAULT
                        icon
                        disabled={!arrayItem.hasMoveDown}
                        onClick={arrayItem.onReorderClick(
                          arrayItem.index,
                          arrayItem.index + 1
                        )}
                      >
                        <FontAwesomeIcon icon={faAngleDown} />
                      </Button.DEFAULT>
                      <Button.DEFAULT
                        icon
                        onClick={arrayItem.onDropIndexClick(arrayItem.index)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button.DEFAULT>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        {props.canAdd ? (
          <div className={styles.arrayButton}>
            <Button.BROWN icon onClick={props.onAddClick}>
              {props.schema.addNewText ? props.schema.addNewText : `Add ${props.title}`}
            </Button.BROWN>
          </div>
        ) : null}
      </>
    );
  }
};

const widgets = {
  SelectWidget: CustomSelect,
  CheckboxWidget: SquaredCheckbox,
  TextWidget: CustomInput,
  TextareaWidget: CustomTextarea,
  EmailWidget: CustomInput,
  URLWidget: CustomInput,
  radio: CustomRadio,
  autoComplete: CustomAutoComplete,
  multiSelect: (props) => <CustomSelect {...props} multiple={true} />,
  facilitiesSelect: FacilitiesSelect,
  buildingSelect: BuildingSelect,
  modelSelect: ModelSelect,
  viewAutoComplete: ViewAutoComplete,
  propertySelect: PropertySelect,
  mapboxAutocomplete: MapboxAutocomplete,
  description: CustomTextarea,
  // "restaurantPhotoSelect": RestaurantPhotoSelect, // using in one specific component, enable if need for multiple components
  // FileWidget: CustomFile,
};

// const CustomSchemaField = function(props) {
//   console.log(props);
//   return (
//     <div id="custom">
//       <p>Yeah, I'm pretty dumb2.</p>
//       <div>My props are: {JSON.stringify(props)}</div>
//       <hr/>
//     </div>
//   );
// };

const fields = {
  // SchemaField: CustomSchemaField
};

const DynamicForm = (props) => {
  return (
    <Form
      {...props}
      ObjectFieldTemplate={ObjectTemplate}
      ArrayFieldTemplate={ArrayFieldTemplate}
      FieldTemplate={FieldTemplate}
      onSubmit={(ev, nativeEvent) => nativeEvent.preventDefault()}
      widgets={{
        ...widgets,
        ...props.widgets,
      }}
      fields={fields}
    >
      {/* Fragment used to Hide form submit button */}
      {props.children ? props.children : <Fragment />}
    </Form>
  );
};

export default DynamicForm;
