import KithwardLogo from '../../assets/kw-logo-96.webp';
import { ModerationQueueItemStatus } from '../../constants/ModerationQueueItemStatus';
import { ModerationQueueActionButtons } from '../ModerationQueueActionButtons/ModerationQueueActionButtons';
import { AccountType, AccountTypeLabels } from '../../constants/AccountType';
import styles from './AccountRequestsList.module.scss';
import AccountTypeAdapter from '../../adapters/AccountTypeAdapter';

export const AccountRequestsItem = ({ account, onApproveClick, onRejectClick }) => {
  const {
    first_name,
    last_name,
    job_title,
    company_name,
    cm_signup_communities,
    email,
    phone,
    status,
    account_type,
  } = account;

  const handleImgError = (event) => {
    event.target.src = KithwardLogo;
  };

  return (
    <div className={styles.accountRequestsItem}>
      <div className={styles.itemInfoContainer}>
        <img
          src={KithwardLogo}
          onError={handleImgError}
          width={50}
          height={50}
          alt={`${first_name}`}
        />
        <div className={styles.itemInfo}>
          <h4>New Account Request</h4>
          <p>{`${first_name} ${last_name} has requested to create a new account`}</p>
          <div className={styles.infoGrid}>
            <p>Name</p>
            <p>
              {first_name} {last_name}
            </p>

            <p>Account Type</p>
            <p>{account_type ? AccountTypeLabels[account_type] : '-'}</p>

            <p>Title</p>
            <p>{job_title || '-'}</p>

            <p>Company Name</p>
            <p>{company_name || '-'}</p>

            {account_type === AccountTypeAdapter.toApi(AccountType.COMMUNITY_MANAGER) && (
              <>
                <p>Manages</p>
                <p>{cm_signup_communities || '-'}</p>
              </>
            )}

            <p>Email</p>
            <p>{email || '-'}</p>

            <p>Phone</p>
            <p>{phone || '-'}</p>
          </div>
        </div>
      </div>
      <div className={styles.actionButtons}>
        <ModerationQueueActionButtons
          onAccept={onApproveClick}
          onReject={onRejectClick}
          status={status === '?' ? ModerationQueueItemStatus.PENDING : status}
          hasPreview={false}
        />
      </div>
    </div>
  );
};
