import useSWR from 'swr';
import api from '../services/api';

// fetch all assessment links within the property
export const useAssessmentLinks = (propertyId: string | number | null) => {
  const { data: responses, isValidating } = useSWR(
    propertyId ? `/v1/admin/properties/${propertyId}/assessment_links` : null,
    api,
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      revalidateIfStale: false,
      shouldRetryOnError: false,
    }
  );

  const data = responses ? responses.data.results : null;

  return {
    loading: isValidating,
    data,
    meta: responses && responses.data.meta ? responses.data.meta : null,
    property: responses && responses.data.property ? responses.data.property : null,
  };
};
