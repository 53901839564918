import { CommunityClaimsList } from '../../../components/CommunityClaimsList/CommunityClaimsList';
import HeaderTitle from '../../../components/HeaderTitle/HeaderTitle';

export const CommunityClaimsPage = () => {
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    // dispatch(queryUsers(form));
  };

  return (
    <>
      <HeaderTitle title={'Community Claims'} />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Community Claims</h1>
          </div>
          {/* <form className="search-container" onSubmit={handleSearchSubmit}>
          <input name="q" placeholder="Search Claims"/>
          <Button.TEXT icon type="submit">{Icons.Search}</Button.TEXT>
        </form> */}
        </div>
        <CommunityClaimsList />
      </div>
    </>
  );
};
