import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { IMaskInput } from 'react-imask';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOrganizationsInfinite } from '../../hooks/useOrganizationsInfinite';
import { useSnackbar } from 'notistack';
import { selectIsSuperAdmin } from '../../redux/selectors/auth';
import { createUser, deleteUser, saveUser } from '../../services/users';
import { assignFromUserForm, removeFromUserForm } from '../../services/communities';
import {
  AccountType,
  AccountTypeLabels,
  accountTypeOptions,
} from '../../constants/AccountType';
import { CustomMasks } from '../../constants/CustomMasks';
import CommunityList from '../CommunityList/CommunityList';
import CommunityNameCell from '../CommunityList/CommunityNameCell';
import Button from '../Button/Button';
import KthPopup from '../KthPopup/KthPopup';
import styles from './UserForm.module.scss';
import { CommunityAutoComplete } from '../CommunityAutoComplete/CommunityAutoComplete';
import { getData } from '../../redux/reducers';
import { Constants, StringUtils } from 'web-core';

const INITIAL_USER = {
  firstName: '',
  lastName: '',
  companyName: '',
  jobTitle: '',
  email: '',
  phone: '',
  accountType: '',
  organizationId: '',
  // photo: '',
};

const accountTypesToShow = [
  AccountType.COMMUNITY_MANAGER,
  AccountType.CORPORATE_EXECUTIVE,
  AccountType.BOTH_EXECUTIVE_AND_MANAGER,
];
const filteredAccountTypeOptions = accountTypeOptions.filter((option) =>
  accountTypesToShow.includes(option.value)
);

export const UserForm = ({
  editUser,
  isLoading,
  editCommunities,
  user,
  onClose,
  fromCommunity,
}) => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state?.auth?.user?.role);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const thisUser = useSelector((state) => state.auth.user);
  const [form, setForm] = useState(user || INITIAL_USER);
  const {
    organizations,
    loading: organizationLoading,
    loadMore,
  } = useOrganizationsInfinite(isSuperAdmin);
  const isEditUser = Boolean(user);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [assignConfirmation, setAssignConfirmation] = useState({
    show: false,
    type: undefined,
    community: undefined,
  });
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [showEditUserPopup, setShowEditUserPopup] = useState(true);

  useEffect(() => {
    if (!isEditUser) {
      setForm((currentForm) => {
        let defaultFormData =
          thisUser.role === Constants.AccountRoles.CommunityManager
            ? {
                accountType: AccountType.COMMUNITY_MANAGER,
                organizationId: thisUser.organization?.id || '',
              }
            : thisUser.role === Constants.AccountRoles.CorporateExecutive && {
                organizationId: thisUser.organization?.id || '',
              };
        return {
          ...currentForm,
          ...defaultFormData,
        };
      });
    }
  }, [thisUser]);

  const handleChange = ({ target: { name, value } }) => {
    setForm((currentForm) => ({
      ...currentForm,
      [name]: value,
    }));
  };

  const isInvalidForm = () => {
    if (!form.accountType) {
      enqueueSnackbar(`Account Type should be selected`, {
        variant: 'error',
      });
      return true;
    }
    return false;
  };

  const handleSaveChanges = async () => {
    if (isInvalidForm()) {
      return;
    }
    setIsSaving(true);
    try {
      const data = isEditUser ? await saveUser(form) : await createUser(form);
      enqueueSnackbar(`User ${isEditUser ? 'saved' : 'created'} successfully`, {
        variant: 'success',
      });
      if (isEditUser) {
        setShowEditUserPopup(false);
        onClose(true);
      } else if (!isEditUser) {
        if (fromCommunity) {
          await assignFromUserForm(data.id, fromCommunity);
          onClose();
        } else {
          onClose(true, data.id);
        }
      }
    } catch (err) {
    } finally {
      if (userRole && userRole === 'admin') {
        dispatch(getData());
      }
      setIsSaving(false);
    }
  };

  const handleDeleteUser = async () => {
    if (form.id) {
      await deleteUser(form.id);
      setIsDeleteOpen(false);
      onClose(true);
    }
  };

  const handleDeleteUserOpen = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };

  const toggleAssignmentConfirmation = (type, community) => {
    if (type == undefined || type == null) {
      setAssignConfirmation({
        show: false,
        type: undefined,
        community: undefined,
      });
    } else {
      setAssignConfirmation({ show: true, type: type, community: community });
    }
  };

  const handleOrganizationSelectScroll = (event) => {
    const listEl = event.currentTarget;
    const scrolledToBottom =
      listEl.scrollTop + listEl.clientHeight >= listEl.scrollHeight;
    if (scrolledToBottom) {
      loadMore();
    }
  };

  const getHeaderTitle = () => {
    if (isEditUser) {
      const hasName = Boolean(form.firstName || form.lastName);
      const name = `${form.firstName || ''} ${form.lastName || ''}${hasName ? "'s" : ''}`;
      return `Edit ${name} ${editCommunities && !editUser ? 'communities' : 'profile'}`;
    } else {
      return 'Create New User';
    }
  };

  const handleAssign = async (userId, community) => {
    try {
      await assignFromUserForm(userId, community.id);
      setAssignConfirmation({
        show: false,
        type: undefined,
        community: undefined,
      });
      onClose(true, userId);
      enqueueSnackbar(`User's assigned communities updated successfully`, {
        variant: 'success',
      });
    } catch (err) {}
  };

  const handleRemove = async (userId, community) => {
    try {
      await removeFromUserForm(userId, community.id);
      setAssignConfirmation({
        show: false,
        type: undefined,
        community: undefined,
      });
      if (fromCommunity) {
        onClose();
      } else {
        onClose(true, userId);
      }
      enqueueSnackbar(`User's assigned communities updated successfully`, {
        variant: 'success',
      });
    } catch (err) {}
  };

  const columns = [
    {
      Header: '',
      accessor: 'name',
      minWidth: 400,
      width: 400,
      maxWidth: 400,
      Cell: ({ row }) => <CommunityNameCell row={row} compact={true} />,
    },
    {
      Header: '',
      id: 'claimed',
      accessor: 'id',
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      Cell: ({ row }) => {
        const isClaimedForAccount = user.assignedCommunities.some(
          (community) => community.id === row.original.id
        );
        return isClaimedForAccount ? (
          <div className={styles.claimedTag}>
            <b>
              Claimed <FontAwesomeIcon icon={faCheckCircle} />
            </b>
          </div>
        ) : null;
      },
    },
    {
      Header: '',
      id: 'claim',
      accessor: 'id',
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      Cell: ({ cell, row }) => {
        const isClaimedForAccount = user.assignedCommunities.some(
          (community) => community.id === row.original.id
        );
        return (
          <div className={styles.removeButton}>
            <Button.DARK
              value={cell.value}
              onClick={() => toggleAssignmentConfirmation('Remove', row.original)}
            >
              Remove
            </Button.DARK>
          </div>
        );
      },
    },
  ];
  return editUser && !editCommunities ? (
    <KthPopup
      isOpen={showEditUserPopup}
      isLoading={isSaving}
      title={getHeaderTitle()}
      onClose={() => onClose()}
    >
      <>
        <form className={styles.form}>
          <label>First Name</label>
          <input
            name='firstName'
            type='text'
            value={form.firstName}
            onChange={handleChange}
          />

          <label>Last Name</label>
          <input
            name='lastName'
            type='text'
            value={form.lastName}
            onChange={handleChange}
          />

          <label>Title</label>
          <input
            name='jobTitle'
            type='text'
            value={form.jobTitle}
            onChange={handleChange}
          />

          <label>Email</label>
          <input name='email' type='email' value={form.email} onChange={handleChange} />

          <label>Phone</label>
          <IMaskInput
            mask={CustomMasks['phone-us']}
            ref={ref}
            defaultValue={form.phone}
            onAccept={(value, mask) =>
              handleChange({ target: { name: 'phone', value: value } })
            }
            placeholder='Phone number'
          />
          <label>Account Type</label>
          {form.role !== 'admin' &&
          (isSuperAdmin || thisUser.role === 'corporate_executive') ? (
            <FormControl
              sx={{
                width: '100%',
              }}
              fullWidth
            >
              <InputLabel id='account-type-checkbox-label'>
                Select Account Type
              </InputLabel>
              <Select
                required
                labelId='account-type-checkbox-label'
                name='accountType'
                value={form?.accountType || ''}
                onChange={handleChange}
                input={<OutlinedInput label='Select Account Type' />}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '300px',
                    },
                  },
                }}
              >
                {filteredAccountTypeOptions?.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    <ListItemText primary={label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : !isEditUser ? (
            <>
              <span>Community Manager</span>
            </>
          ) : (
            <span>
              {user && user.role === 'admin'
                ? 'Kithward SuperAdmin'
                : user && user.accountType
                ? AccountTypeLabels[user.accountType]
                : '-'}
            </span>
          )}

          <label>Organization</label>
          {isSuperAdmin ? (
            <FormControl
              sx={{
                width: '100%',
              }}
              fullWidth
            >
              <InputLabel id='organization-origin-checkbox-label'>
                Select Organization
              </InputLabel>
              <Select
                required
                labelId='organization-origin-checkbox-label'
                name='organizationId'
                disabled={organizationLoading}
                value={form?.organizationId || form?.organization?.id || ''}
                onChange={handleChange}
                input={<OutlinedInput label='Select Organization' />}
                MenuProps={{
                  PaperProps: {
                    onScroll: handleOrganizationSelectScroll,
                    sx: {
                      maxHeight: '300px',
                    },
                  },
                }}
              >
                {organizations?.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : !isEditUser ? (
            <>
              <span>
                {thisUser && thisUser.organization ? thisUser.organization.name : '-'}
              </span>
            </>
          ) : (
            <span>{user && user.organization ? user.organization.name : '-'}</span>
          )}
        </form>
        <div className={styles.containerButtons}>
          <Button.DARK onClick={handleSaveChanges} disabled={false}>
            {isEditUser ? 'Save Changes' : 'Create User'}
          </Button.DARK>
          {isEditUser ? (
            <Button.TEXT
              onClick={() => handleDeleteUserOpen()}
              className={styles.deleteUserButton}
            >
              Delete this user
            </Button.TEXT>
          ) : null}
        </div>

        <KthPopup
          isOpen={isDeleteOpen}
          isLoading={isSaving}
          title={`Delete ${form.firstName} ${form.lastName}'s Profile`}
          confirmButtonLabel={'Delete User'}
          onConfirmButtonClick={handleDeleteUser}
          onClose={() => handleDeleteUserOpen()}
        >
          <label>
            Are you sure you want to delete{' '}
            <strong>
              {form.firstName} {form.lastName}
            </strong>
            ?
          </label>
        </KthPopup>
      </>
    </KthPopup>
  ) : !editUser && editCommunities ? (
    <>
      <KthPopup
        isOpen={form.accountType === AccountType.COMMUNITY_MANAGER}
        isLoading={isLoading}
        title={getHeaderTitle()}
        cancelButtonLabel={'Confirm'}
        onCancelButtonClick={() => onClose()}
        onClose={() => onClose()}
        className={styles.claimForm}
      >
        <>
          {user && !StringUtils.isBlank(user.cmSignupCommunities) ? (
            <p>Self-Reported Managed Communities: {user.cmSignupCommunities}</p>
          ) : null}
          <CommunityAutoComplete onSelect={toggleAssignmentConfirmation} />
          <CommunityList
            communities={user && user.assignedCommunities ? user.assignedCommunities : []}
            columns={columns}
          />
          <KthPopup
            isOpen={assignConfirmation.show}
            title={'Confirm change'}
            cancelButtonLabel={'Cancel'}
            confirmButtonLabel={'Confirm'}
            onConfirmButtonClick={
              assignConfirmation.type === 'Add' && assignConfirmation.community
                ? () => handleAssign(user.id, assignConfirmation.community)
                : assignConfirmation.type === 'Remove' && assignConfirmation.community
                ? () => handleRemove(user.id, assignConfirmation.community)
                : toggleAssignmentConfirmation
            }
            onClose={() => toggleAssignmentConfirmation()}
          >
            {assignConfirmation.type === 'Add' ? (
              <label>
                Are you sure you want to add {form.firstName} {form.lastName} as a manager
                of{' '}
                <strong>
                  {
                    assignConfirmation.community.json_data[
                      Constants.DynamicSchemaFields.Name
                    ]
                  }
                </strong>
                ?
              </label>
            ) : assignConfirmation.type === 'Remove' ? (
              <label>
                Are you sure you want to remove {form.firstName} {form.lastName} as a
                manager of <strong>{assignConfirmation.community.name}</strong>?
              </label>
            ) : null}
          </KthPopup>
        </>
      </KthPopup>
    </>
  ) : null;
};

/// profile photo & form option, when needed

// const handlePhotoSelect = (event) => {
//   setForm((currentForm)=>({
//     ...currentForm,
//     photo: event.target.files[0],
//   }))
// }

{
  /*
  <label htmlFor="photo">Photo</label>
    <div className={styles.photoInputContainer}>
    <label htmlFor="photo">{form.photo ? form.photo.name : 'Select a Photo'}</label>
    <input
      type="file"
      id="photo"
      name="photo"
      accept="image/*"
      onChange={handlePhotoSelect}
    />
  </div> */
}
