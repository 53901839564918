import { useState } from 'react';

const useAddressAutocomplete = (initialValue, type = 'address') => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);

  const handleChange = async (event) => {
    setValue(event.target.value);

    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event.target.value}.json?country=US&access_token=pk.eyJ1Ijoia2l0aHdhcmQiLCJhIjoiY2pvb3cyNWczMHN1OTNxbHczeTF5bm81eSJ9.Cg_TKcKgnCJfIF8e-c2jJA&autocomplete=true&types=${type}`;
      const response = await fetch(endpoint);
      const results = await response.json();
      setResults(results?.features);
    } catch (error) {
      console.log('Error fetching data, ', error);
      setError(error);
    }
  };

  return {
    value: value && !error ? value : error ? 'error' : undefined,
    onChange: handleChange,
    setValue,
    results,
    setResults,
  };
};

export default useAddressAutocomplete;
