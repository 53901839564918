import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { resetError } from '../../redux/reducers';
import { useHistory } from 'react-router-dom';

export const ErrorManager = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const error = useSelector((state) => state.errors.error) || null;

  const alertError = async (error) => {
    const serverMessage = error.data.errors || null;
    const statusCode = error.status || null;

    // redirects for server responses that would render an empty page/community form
    if (error && error.redirect && error.redirect === 500) {
      history.push('/error');
      dispatch(resetError());
      return;
    }

    if (error && error.redirect && error.redirect === 403) {
      history.push('/forbidden');
      dispatch(resetError());
      return;
    }

    if (statusCode && statusCode === 404) {
      if (serverMessage && serverMessage.includes("Couldn't find Community with 'id'")) {
        history.push('/notfound');
        dispatch(resetError());
        return;
      }
    }

    // normal non-redirect handling, show snackbar/toast message for the remote request error
    if (
      serverMessage &&
      typeof serverMessage === 'object' &&
      !Array.isArray(serverMessage)
    ) {
      const objectError = Object.entries(serverMessage).map(
        ([key, value]) => `${key.charAt(0).toUpperCase() + key.slice(1)} ${value}`
      );
      await enqueueSnackbar(`We're sorry, an error occurred. ${objectError}`, {
        variant: 'warning',
      });
    } else if (serverMessage && Array.isArray(serverMessage)) {
      if (typeof serverMessage[0] === 'string') {
        await enqueueSnackbar(`We're sorry, an error occurred. ${serverMessage[0]}`, {
          variant: 'warning',
        });
      }
    } else if (serverMessage && typeof serverMessage === 'string') {
      await enqueueSnackbar(`We're sorry, an error occurred. ${serverMessage}`, {
        variant: 'warning',
      });
    }
    dispatch(resetError());
  };

  useEffect(() => {
    if (error !== null) {
      alertError(error);
    }
  }, [error]);

  return <></>;
};
