import styles from './CommunityDiamondRating.module.scss';
import Loader from '../Loader/Loader';
import { Rating, TextareaAutosize, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import api from '../../services/api';
import { useSnackbar } from 'notistack';

interface CommunityDiamondRatingProps {
  onChange: (params: {
    star_rating: number | null;
    accept_generated_star_rating: boolean;
    override_star_rating: boolean;
    reason_to_override: string | null | undefined;
  }) => void;
  url: string | null;
}

const CommunityDiamondRating: React.FC<CommunityDiamondRatingProps> = ({
  url,
  onChange,
}) => {
  const [hoverValue, setHoverValue] = useState<number | null>(null);
  const [currentRating, setCurrentRating] = useState<number | null>(null);
  const [overriddenNote, setOverriddenNote] = useState<string | null>(null);
  const [isOverridden, setIsOverridden] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { data, error, isValidating } = useSWR(url, api, {
    errorRetryCount: 3,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
  const results = data?.data?.results;
  const generatedRating = results?.generated_star_rating;
  const reason = results?.reason_for_override;
  const publishedStarRating = results?.current_star_rating || 0;

  let textBoxNote;
  if (reason) {
    textBoxNote =
      'Please re-add a note about why you are overriding the auto-calculated rating, even if it is the same as the currently published rating.';
  } else {
    textBoxNote =
      'Please add a note about why you are overriding the auto-calculated rating, even if it is the same as the currently published rating.';
  }

  useEffect(() => {
    if (results) {
      setCurrentRating(generatedRating);
      setOverriddenNote(results.reason_to_override);
    }
  }, [results]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'warning' });
    }
  }, [enqueueSnackbar, error]);

  const onRatingChange = (value: number) => {
    if (value > 0) {
      setCurrentRating(value);
      if (value === generatedRating) {
        setIsOverridden(false);
        setOverriddenNote(null);
      } else {
        setIsOverridden(true);
      }
    }
  };

  const handleRatingNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setOverriddenNote(value);
  };

  useEffect(() => {
    onChange({
      star_rating: currentRating,
      accept_generated_star_rating: !isOverridden,
      override_star_rating: isOverridden,
      reason_to_override: overriddenNote,
    });
  }, [currentRating, isOverridden, overriddenNote]);

  return (
    <Loader isLoading={isValidating}>
      <div className={styles.content}>
        <div className={styles.publishedRating}>
          <span>Published Rating: </span>
          <Rating
            key='published-rating'
            defaultValue={publishedStarRating}
            emptyIcon={<FontAwesomeIcon icon={faGem} style={{ color: '#c4c4c4' }} />}
            icon={<FontAwesomeIcon icon={faGem} style={{ color: '#a8947d' }} />}
            readOnly={true}
          />
          <p>{reason ? `"${reason}"` : ''}</p>
        </div>

        <hr />

        <div className={styles.draftRating}>
          <span>New Rating: </span>
          <Tooltip
            open={hoverValue === generatedRating}
            title={
              "This is the auto-calculated Diamond Rating based on this community's profile."
            }
            placement={'top'}
          >
            <span className={styles.ratingWrapper}>
              <Rating
                key='current-rating'
                value={currentRating || 0}
                onChange={(event, value) => onRatingChange(Number(value))}
                emptyIcon={<FontAwesomeIcon icon={faGem} style={{ color: '#c4c4c4' }} />}
                icon={<FontAwesomeIcon icon={faGem} style={{ color: '#a8947d' }} />}
                onChangeActive={(event, value) => setHoverValue(Number(value))}
              />
            </span>
          </Tooltip>
          {isOverridden ? (
            <TextareaAutosize
              maxRows={3}
              style={{
                width: '350px',
                marginTop: '12px',
                resize: 'vertical',
                minHeight: '100px',
              }}
              placeholder={textBoxNote}
              onChange={(e) => handleRatingNotesChange(e)}
            />
          ) : generatedRating !== publishedStarRating ? (
            <p style={{ fontSize: '10px', maxWidth: '350px' }}>
              Note: The auto-calculated rating ({generatedRating}) is different than the
              published rating. If this rating does not fit this community, please
              override it with a reason. Otherwise, click approve changes and the
              calculated rating will be applied. Please reach out to the engineering team
              if the calculated ratings could be improved.
            </p>
          ) : null}
        </div>
      </div>
    </Loader>
  );
};

export default CommunityDiamondRating;
