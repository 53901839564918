import styles from './CommunityForm.module.scss';
import Button from '../Button/Button';
import CopyCommunitySelect from './CopyCommunitySelect/CopyCommunitySelect';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { copyIntoCommunity } from '../../redux/reducers';
import { useSnackbar } from 'notistack';
import AmplitudeTools from '../../utils/AmplitudeTools';

type CopyCommunityPopupProps = {
  onClose: () => void;
};

const CopyCommunityPopup = ({ onClose }: CopyCommunityPopupProps) => {
  const dispatch = useDispatch();
  const [targetSibling, setTargetSibling] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleOnChange = (value: string) => {
    setTargetSibling(value);
  };
  const handleStartWithEmptyForm = () => {
    AmplitudeTools.logEvent(AmplitudeTools.EVENTS.NOT_COPYING_INTO_COMMUNITY);
    onClose();
  };
  const handleCopyData = async () => {
    try {
      dispatch(copyIntoCommunity(targetSibling));

      AmplitudeTools.logEvent(AmplitudeTools.EVENTS.COPIED_INTO_COMMUNITY);

      enqueueSnackbar(`Successfully copied attributes to community`, {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      enqueueSnackbar(`Failed to copy attributes to community: ${err}`, {
        variant: 'error',
      });
    }
  };

  return (
    <div className={`${styles.communityPopups} ${styles.copyCommunityPopup}`}>
      <div className={styles.popupHeader}>
        <h3>Looks like you are starting from scratch</h3>
        <p className={styles.headerDescription}>
          It looks like you are just starting to edit this community. Would you like to
          start by copying the data from one of the other communities at this property?
        </p>
      </div>
      <div className={styles.popupContent}>
        <CopyCommunitySelect onSelect={handleOnChange} />
      </div>

      <div className={`flex-kebab-row ${styles.bottomButtons}`}>
        <Button.BLACK_OUTLINED onClick={handleStartWithEmptyForm}>
          Start with empty form
        </Button.BLACK_OUTLINED>
        <Button.DARK onClick={handleCopyData} disabled={!targetSibling}>
          Copy data
        </Button.DARK>
      </div>
    </div>
  );
};

export default CopyCommunityPopup;
