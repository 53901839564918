import _ from 'lodash';
import { AccountType } from '../constants/AccountType';

const accountTypeAPI: any = {
  PROSPECTIVE_RESIDENT: 'prospective_resident_account',
  ADULT_CHILD: 'adult_child_account',
  COMMUNITY_MANAGER: 'community_manager_account',
  CORPORATE_EXECUTIVE: 'corporate_executive_account',
  BOTH_EXECUTIVE_AND_MANAGER: 'community_manager_and_corporate_executive_account',
};

const fromApi = (accountType: string): string | null => {
  const typeKey = _.findKey(accountTypeAPI, (v) => v === accountType);
  return typeKey || null;
};

const toApi = (accountType: AccountType): string => {
  return accountTypeAPI[accountType] as string;
};

const AccountTypeAdapter = {
  toApi,
  fromApi,
};

export default AccountTypeAdapter;
