import api from './api';
import { toURLSearchParams } from './transform';

const PATH = '/v1/admin/organization_services';

export const createOrganizationService = async (formData) => {
  try {
    const { data } = await api.post(`${PATH}`, formData);
    return data;
  } catch (err) {
    throw err;
  }
};

export const updateOrganizationService = async (organizationService, formData) => {
  try {
    if (!organizationService.id) return;

    const { data } = await api.put(`${PATH}/${organizationService.id}`, formData);
    return data;
  } catch (err) {
    throw err;
  }
};

export const queryOrganizationServices = async (form = {}, page = 1) => {
  try {
    const { data } = await api.get(`${PATH}`, {
      params: toURLSearchParams({ ...form, page }),
    });
    return data;
  } catch (err) {
    throw err;
  }
};

export const deleteOrganizationService = async (organizationServiceId) => {
  try {
    if (!organizationServiceId) {
      throw Error('Organization Service ID is undefined');
    }
    const { data } = await api.delete(`${PATH}/${organizationServiceId}`);
    return data;
  } catch (err) {
    throw err;
  }
};
