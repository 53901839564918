import { useEffect, useMemo, useRef, useState } from 'react';

import { clickOutsideHandler } from '../../hooks/useClickOutside';
import DropdownHeader from './DropdownHeader';
import DropdownMenu from './DropdownMenu';
import styles from './Dropdown.module.scss';

export interface DropdownOption {
  label: string;
  value: number | string;
}
export interface DropdownProps {
  title: string;
  options: DropdownOption[];
  onSelect: (value: number | string) => void;
  initialValue?: number | string;
  className?: string;
}

const Dropdown = ({
  title,
  options,
  onSelect,
  initialValue,
  className,
}: DropdownProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [currentSelected, setCurrentSelected] = useState<DropdownOption | undefined>(
    options.find((option) => option.value === initialValue)
  );
  const listOptions = useMemo(() => options, [options]);
  const menuRef = useRef(null);

  const headerTitle = !currentSelected ? title : currentSelected.label;

  useEffect(() => {
    clickOutsideHandler(menuRef, () => setIsMenuOpen(false));
  }, [menuRef]);

  const toggleList = () => {
    setIsMenuOpen((isOpen: boolean) => !isOpen);
  };

  const handleSelectItem = (item: DropdownOption) => {
    setCurrentSelected(item);
    setIsMenuOpen(false);
    if (onSelect) {
      onSelect(item.value);
    }
  };

  return (
    <div className={`${styles.dropdown} ${className || ''}`}>
      <DropdownHeader
        title={headerTitle}
        isSelected={Boolean(currentSelected)}
        isListOpen={isMenuOpen}
        onClick={toggleList}
      />
      {isMenuOpen ? (
        <DropdownMenu
          ref={menuRef}
          options={listOptions}
          selectedItem={currentSelected}
          onSelect={handleSelectItem}
        />
      ) : null}
    </div>
  );
};

export default Dropdown;
