import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-light-svg-icons';
import styles from './PropertyList.module.scss';
import Popup from 'reactjs-popup';
import { Tooltip } from '@mui/material';
import { Constants } from 'web-core';
import { CARE_TYPE_DESCRIPTIONS } from '../../constants/CareType';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

const PropertyDropdownMenu = ({
  row,
  role,
  onAddCommunity,
  handleManageCareTypeRatings,
  handleReviewInterestTags,
}) => {
  const property = row.original;
  const menuRef = useRef(null);

  return (
    <Popup
      ref={menuRef}
      className='dropdown-menu-popup'
      trigger={
        <p className={styles.dropdownMenuIcon}>
          <FontAwesomeIcon icon={faEllipsis} size={'2x'} width={20} />
        </p>
      }
      position={'bottom right'}
      closeOnEscape={true}
      lockScroll={false}
      arrow={false}
      overlayStyle={{
        backgroundColor: 'transparent',
      }}
    >
      <div className='menu'>
        <Tooltip
          title={
            property.communities?.length > 3
              ? 'This property already contains communities for every care type so more communities may not be added.'
              : ''
          }
          placement={'top'}
        >
          <div
            className={
              property.communities?.length > 3 ? 'menu-item__disabled' : 'menu-item'
            }
            onClick={
              property.communities?.length > 3
                ? () => null
                : () => onAddCommunity(property)
            }
          >
            {'+ Care Level'}
          </div>
        </Tooltip>
        {role && role === Constants.AccountRoles.Admin
          ? [
              <hr key={'separator-1'} />,
              [
                property.communities.map((community) => (
                  <div
                    className={'menu-item'}
                    key={community.name}
                    onClick={() => handleManageCareTypeRatings(community)}
                  >
                    {`Manage ${CARE_TYPE_DESCRIPTIONS[community.care_type]} Rating`}
                  </div>
                )),
              ],
              <div
                className='menu-item'
                key={'manage-interest-tags'}
                onClick={() => handleReviewInterestTags(row.original)}
              >
                Manage Interest Tags
              </div>,
            ]
          : null}
      </div>
    </Popup>
  );
};

export default PropertyDropdownMenu;
