import useSWRInfinite from 'swr/infinite';
import api from '../services/api';

// A function to get the SWR key of each page,
// its return value will be accepted by `fetcher`.
// If `null` is returned, the request of that page won't start.
const getKey = (pageIndex = 0, previousPageData) => {
  if (previousPageData && previousPageData?.data?.meta?.last_page) return null; // reached the end
  return `/v1/admin/organizations?page=${pageIndex + 1}`; // SWR key
};

export const useOrganizationsInfinite = (shouldFetch) => {
  //shouldFetch must be passed as bool when using hook, allow for conditional use of hook
  const {
    data: responses,
    error,
    size,
    setSize,
  } = useSWRInfinite(getKey, shouldFetch ? api : null, {
    errorRetryCount: 3,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
  const lastResponseData = responses ? responses[responses.length - 1]?.data : undefined;

  const loadMore = () => {
    if (lastResponseData?.meta?.last_page === false) {
      setSize(size + 1);
    }
  };

  const restartPages = () => {
    setSize(1);
  };

  const organizations = responses
    ? [].concat(...responses.map((res) => res?.data?.results))
    : null;

  return {
    loading: !responses && !error,
    organizations,
    meta: responses && lastResponseData ? lastResponseData.meta : null,
    error,
    loadMore,
    restartPages,
  };
};
