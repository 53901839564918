import styles from './Dropdown.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

export interface DropdownHeaderProps {
  title: string;
  isListOpen: boolean;
  isSelected: boolean;
  onClick: () => void;
}
const DropdownHeader = ({
  title,
  isListOpen,
  isSelected,
  onClick,
}: DropdownHeaderProps) => {
  const arrow = isListOpen ? (
    <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>
  ) : (
    <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
  );

  return (
    <div className={styles.dropdownHeaderContainer} onClick={onClick}>
      <div className={styles.dropdownHeader}>
        <span
          className={`${styles.headerTitle} ${
            isSelected ? '' : styles.headerTitlePlaceholder
          }`}
        >
          {title}
        </span>
        {arrow}
      </div>
    </div>
  );
};

export default DropdownHeader;
