import React, { useMemo, useState } from 'react';
import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
} from 'react-table';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import styles from './TourRequestsTable.module.scss';
import { QueueActionButtons } from '../QueueActionButtons/QueueActionButtons';
import BookTour from '../BookTour/BookTour';
import { Constants, StringUtils, Utilities } from 'web-core';
import moment from 'moment-timezone';

export const TourRequestsTable = ({ tours = [] }) => {
  const [openBookTour, setOpenBookTour] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);

  const handleOpenBookTour = (tour) => {
    setSelectedTour(tour);
    setOpenBookTour(true);
  };

  const handleCloseBookTour = () => {
    setOpenBookTour(false);
    setSelectedTour(null);
  };

  const data = useMemo(() => {
    return tours.map((tour, index) => ({
      id: tour.id,
      created_at: Utilities.formattedLocalDateTime(tour.created_at),
      requester_name: `${tour.account.first_name} ${tour.account.last_name}`,
      requester_email: tour.account.email,
      requester_phone: tour.account.phone,
      community_name: tour.community ? tour.community.name : 'Unknown',
      community_city: tour.community ? tour.community.city : '-',
      community_state: tour.community ? tour.community.state : '-',
      possible_dates: tour.possible_dates
        .map((date) => moment(date).format('MMM Do'))
        .join(' | '),
      tour,
      index,
    }));
  }, [tours]);

  const columns = useMemo(
    () => [
      { Header: 'Created At', accessor: 'created_at' },
      { Header: 'Requester', accessor: 'requester_name' },
      { Header: 'Email', accessor: 'requester_email' },
      { Header: 'Phone', accessor: 'requester_phone' },
      {
        Header: 'Community',
        accessor: 'community_name',
        Cell: ({ row }) => (
          <div className={styles.communityCell}>
            <span
              className={styles.communityLink}
              onClick={() => handleClickCommunityProfile(row.original.tour.community)}
            >
              {row.original.community_name}
            </span>
            <span>{`${row.original.community_city}, ${row.original.community_state}`}</span>
          </div>
        ),
      },
      {
        Header: 'Unit Type',
        accessor: 'unit_type',
        width: 300,
        Cell: ({ row }) => {
          const tour = row.original.tour;
          const {
            [Constants.DynamicSchemaFields.ModelHasStudioLayout]: hasStudioLayout,
            [Constants.DynamicSchemaFields.ModelUnitType]: unitType,
            [Constants.DynamicSchemaFields.ModelBedrooms]: bedrooms,
            [Constants.DynamicSchemaFields.ModelHasCommonBathroom]:
              hasCommonBathroomWithShowers,
            [Constants.DynamicSchemaFields.ModelBathrooms]: bathrooms,
          } = tour.unit_type || {};

          let bed = null;
          let bath = null;

          if (tour.unit_type) {
            bed =
              (((!bedrooms || bedrooms.length === 0) && hasStudioLayout) || bedrooms) &&
              (!bedrooms || bedrooms.length === 0) &&
              hasStudioLayout
                ? ' Studio'
                : ` ${bedrooms.length} Bed`;

            bath =
              (((!bathrooms || bathrooms.length === 0) && hasCommonBathroomWithShowers) ||
                bathrooms) &&
              ((!bathrooms || bathrooms.length === 0) && hasStudioLayout
                ? ' Common Bathroom With Showers'
                : ` ${bathrooms.length} Bath`);
          }

          return tour.unit_type ? (
            <div className={styles.unitTypeCell}>
              <span>{`${
                tour.unit_type[Constants.DynamicSchemaFields.ModelName]
              } - ${unitType} | ${bed} | ${bath}`}</span>
            </div>
          ) : (
            <span>-</span>
          );
        },
      },
      { Header: 'Tour Dates', accessor: 'possible_dates' },
      {
        Header: 'Actions',
        accessor: 'actions',
        width: 80,
        Cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = useState(null);
          const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
          const handleMenuClose = () => setAnchorEl(null);

          const tour = row.original.tour;
          const actionButtons = [];

          if (tour.account.phone) {
            actionButtons.push({
              label: 'Call',
              action: () => handlePhone(tour.account.phone),
            });
          }

          if (tour.account.email) {
            actionButtons.push({
              label: 'Email',
              action: () => handleEmail(tour.account.email),
            });
          }

          actionButtons.push({
            label: 'View Search',
            action: () => handleViewSearch(tour),
          });

          actionButtons.push({
            label: 'Book a Tour',
            action: () => handleOpenBookTour(tour),
          });

          return (
            <div className={styles.actionButtonContainer}>
              {/* 3-Dots Icon for Actions */}
              <IconButton
                onClick={handleMenuOpen}
                className={styles.actionIconButton}
                aria-controls='actions-menu'
                aria-haspopup='true'
              >
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </IconButton>

              {/* Dropdown Menu for Actions */}
              <Menu
                id='actions-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                className={styles.menu}
              >
                {actionButtons.map((action, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      action.action();
                      handleMenuClose();
                    }}
                    className={styles.menuItem}
                  >
                    {action.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          );
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    { columns, data, initialState: { pageSize: 10 } },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const handlePhone = (phone) => {
    window.location.href = `tel:${phone}`;
  };

  const handleEmail = (email) => {
    window.location.href = `mailto:${email}?subject=Tour Request`;
  };

  const handleViewSearch = (tour) => {
    window.open(
      `${process.env.REACT_APP_CONSUMER}/my-search/${tour.consumer_journey_id}`,
      '_blank'
    );
  };

  const handleClickCommunityProfile = (community) => {
    if (community) {
      window.open(
        `${process.env.REACT_APP_CONSUMER}/community/${community.slug}`,
        '_blank'
      );
    }
  };

  return (
    <div className={styles.tableContainer}>
      {tours.length === 0 ? (
        <p>No tour requests available.</p>
      ) : (
        <table {...getTableProps()} className={styles.infoTable}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  key={row.original.id}
                  {...row.getRowProps()}
                  className={hoveredRow === row.original.index ? styles.hoveredRow : ''}
                  onMouseEnter={() => setHoveredRow(row.original.index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  {row.cells.map((cell) => (
                    <td key={cell.column.id} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {selectedTour && (
        <BookTour
          open={openBookTour}
          setOpenBookTour={handleCloseBookTour}
          community={selectedTour.community}
          request={selectedTour}
        />
      )}
    </div>
  );
};
