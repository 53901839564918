import { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  createOrganization,
  deleteOrganization,
  updateOrganization,
} from '../../services/organizations';
import Button from '../Button/Button';
import { KWImage } from '../KWImage/KWImage';
import styles from './OrganizationForm.module.scss';
import { APITools } from 'web-core';

const INITIAL_ORGANIZATION = {
  name: '',
};

export const OrganizationForm = ({ organization, onClose }) => {
  const [form, setForm] = useState(organization || INITIAL_ORGANIZATION);
  const isEditOrganization = Boolean(organization);
  const { enqueueSnackbar } = useSnackbar();

  const handleNameChange = ({ target: { name, value } }) => {
    setForm((currentForm) => ({
      ...currentForm,
      [name]: value,
    }));
  };

  const handlePhotoSelect = (event, key) => {
    const fileSizeLimit = 20 * 1024 * 1024;
    if (event.target.files?.length) {
      const file = event.target.files[0];
      if (file.size < fileSizeLimit) {
        setForm((currentForm) => ({
          ...currentForm,
          [key]: file,
        }));
      } else {
        enqueueSnackbar('Please upload a file smaller than 20 MB', {
          variant: 'default',
        });
      }
    }
  };

  const handleSaveChanges = async () => {
    let formData = new FormData();
    formData.append('name', form.name);
    if (form.logo) {
      formData.append('logo', form.logo);
    }
    if (form.start_page_brand_image) {
      formData.append('start_page_brand_image', form.start_page_brand_image);
    }
    if (form.primary_brand_color) {
      formData.append('primary_brand_color', form.primary_brand_color);
    }
    if (form.secondary_brand_color) {
      formData.append('secondary_brand_color', form.secondary_brand_color);
    }
    try {
      const data = isEditOrganization
        ? await updateOrganization(organization, formData)
        : await createOrganization(formData);
      enqueueSnackbar(
        `Organization ${isEditOrganization ? 'saved' : 'created'} successfully`,
        {
          variant: 'success',
        }
      );
      onClose(true);
    } catch (err) {
      let errorsToDisplay = APITools.errorMessageFromServerResponse(err);
      enqueueSnackbar(errorsToDisplay, { variant: 'error' });
    }
  };

  const handleDeleteOrganization = async () => {
    if (form.id && window.confirm(`Really want to delete "${form.name}"?`)) {
      await deleteOrganization(form.id);
      onClose(true);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSaveChanges();
  };

  const handlePickedColor = (event, id) => {
    let color = event.target.value;
    setForm((currentForm) => ({
      ...currentForm,
      [id]: color,
    }));
  };

  return (
    <>
      <form className={styles.form}>
        <label>Name</label>
        <input name='name' type='text' value={form.name} onChange={handleNameChange} />
        <label>Logo</label>
        <div className={styles.photoInputContainer}>
          {isEditOrganization && (
            <KWImage
              src={organization.file_url}
              alt={`${organization.name} Logo`}
              className={styles.logoImage}
            />
          )}
          <label htmlFor='logo'>{form.logo ? form.logo.name : 'Upload new Logo'}</label>
          <input
            type='file'
            id='logo'
            name='logo'
            accept='image/*'
            onChange={(e) => handlePhotoSelect(e, e.target.id)}
          />
        </div>
        <label>Brand Image</label>
        <div className={styles.photoInputContainer}>
          {isEditOrganization && (
            <KWImage
              src={organization.brand_image_file_url}
              alt={`${organization.name} start page brand image`}
            />
          )}
          <label htmlFor='start_page_brand_image'>
            {form?.start_page_brand_image
              ? form.start_page_brand_image.name
              : 'Upload new image'}
          </label>
          <input
            type='file'
            id='start_page_brand_image'
            name='start_page_brand_image'
            accept='image/*'
            onChange={(e) => handlePhotoSelect(e, e.target.id)}
          />
        </div>
        <label>Primary Brand Color</label>
        <div className={styles.colorPickerContainer}>
          <input
            type='color'
            id='primary_brand_color'
            name='primary_brand_color'
            value={form.primary_brand_color || '#000000'}
            onChange={(event) => handlePickedColor(event, event.target.id)}
            className={styles.overlayColorPicker}
          />
          <label htmlFor={'primary_brand_color'}>{'Please pick a color'}</label>
        </div>

        <label>Secondary Brand Color</label>
        <div className={styles.colorPickerContainer}>
          <input
            type='color'
            id='secondary_brand_color'
            name='secondary_brand_color'
            value={form.secondary_brand_color || '#e4cdb5'} // default secondary brand color to Kithward base color
            onChange={(event) => handlePickedColor(event, event.target.id)}
            className={styles.overlayColorPicker}
          />
          <label htmlFor={'secondary_brand_color'}>{'Please pick a color'}</label>
        </div>
      </form>
      <div className={`flex-kebab-row ${styles.bottomButtons}`}>
        <Button.BLACK onClick={handleFormSubmit}>
          {isEditOrganization ? 'Save Changes' : 'Create Organization'}
        </Button.BLACK>

        {isEditOrganization ? (
          <Button.TEXT onClick={handleDeleteOrganization} className={styles.deleteButton}>
            Delete Organization
          </Button.TEXT>
        ) : null}
      </div>
    </>
  );
};
