import { InformationRequestsList } from '../../components/InformationRequests/InformationRequestsList';
import HeaderTitle from '../../components/HeaderTitle/HeaderTitle';

export const ConsumerInformationRequestsPage = () => {
  return (
    <>
      <HeaderTitle title={'Information Requests'} />
      <div className='page-layout'>
        <div className='header bordered'>
          <div className='header-title'>
            <h1>Information Requests</h1>
          </div>
        </div>
        <InformationRequestsList />
      </div>
    </>
  );
};
