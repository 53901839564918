import styles from './ErrorPages.module.scss';

export default function NotFound() {
  return (
    <div className={styles.errorHeader}>
      <div className={styles.errorAlert}>
        <div>
          <h1>404</h1>
          <h2>Page Not Found</h2>
        </div>
      </div>
      <div className={styles.errorText}>
        <div>
          <h2>
            We&apos;re sorry, we can&apos;t find the page you&apos;re looking for. If you
            believe you may be seeing this message in error,{' '}
            <a
              href={`mailto:hello@kithward.com?subject=Page Not Found Inquiry&body=Current URL: ${window.location}`}
              rel='noreferrer'
            >
              you can let us know here.
            </a>
          </h2>
        </div>
      </div>
    </div>
  );
}
