import { Option } from '../types/Option.type';

export enum AccountType {
  PROSPECTIVE_RESIDENT = 'PROSPECTIVE_RESIDENT',
  ADULT_CHILD = 'ADULT_CHILD',
  COMMUNITY_MANAGER = 'COMMUNITY_MANAGER',
  CORPORATE_EXECUTIVE = 'CORPORATE_EXECUTIVE',
  BOTH_EXECUTIVE_AND_MANAGER = 'BOTH_EXECUTIVE_AND_MANAGER',
}

export enum AccountTypeLabels {
  PROSPECTIVE_RESIDENT = 'Prospective Resident',
  prospective_resident_account = 'Prospective Resident',
  ADULT_CHILD = 'Adult Child',
  adult_child_account = 'Adult Child',
  COMMUNITY_MANAGER = 'Community Manager',
  community_manager_account = 'Community Manager',
  CORPORATE_EXECUTIVE = 'Corporate Executive',
  corporate_executive_account = 'Corporate Executive',
  BOTH_EXECUTIVE_AND_MANAGER = 'Corporate Executive + Community Manager',
  community_manager_and_corporate_executive_account = 'Corporate Executive + Community Manager',
  consumer_account = 'Consumer Account',
}

export const accountTypeOptions: Option[] = Object.entries(AccountType).map(([value]) => {
  return {
    label: AccountTypeLabels[value as keyof typeof AccountTypeLabels],
    value,
  };
});
