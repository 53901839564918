import { useState } from 'react';
import { DateSelect } from '../../../DynamicForm/Widgets/DateSelect/DateSelect';
import DynamicForm from '../../../DynamicForm/DynamicForm';
import { useDispatch, useSelector } from 'react-redux';
import { formDataChange, formFieldChange } from '../../../../redux/reducers';
import { Constants } from 'web-core';
import moment from 'moment-timezone';

const PromotionsTab = () => {
  const { uiSchema, jsonSchema } = useSelector((state) => state.communityForm.schemas);
  const formData = useSelector((state) => state.communityForm.formData);
  const dispatch = useDispatch();
  const handleFormDataChange = ({ formData }) => {
    dispatch(formDataChange(formData));
  };
  const [endDate, setEndDate] = useState(
    formData[Constants.DynamicSchemaFields.PromotionEndDate] || null
  );

  const handleSelectDate = (event) => {
    setEndDate(event);

    dispatch(formFieldChange(Constants.DynamicSchemaFields.PromotionEndDate, event));
  };

  return (
    <>
      <DynamicForm
        schema={jsonSchema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={handleFormDataChange}
        onSubmit={() => console.log('submitted')}
        onError={() => console.log('errors')}
        formContext={{ currentTab: 'Promotions' }}
        widgets={{
          dateSelect: () => <DateSelect endDate={endDate} onChange={handleSelectDate} />,
        }}
      />
    </>
  );
};

export default PromotionsTab;
