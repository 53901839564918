import asFormData from 'json-form-data';

export const toFormData = (form) => {
  var options = {
    initialFormData: new FormData(),
    showLeafArrayIndexes: true,
    includeNullValues: false,
    mapping: function (value) {
      if (typeof value === 'boolean') {
        return +value ? true : false;
      }
      return value;
    },
  };
  return asFormData(form, options);
};

/**
 * Receives an JSON and returns a URLSearchParms
 * removing empty string values
 * @param form
 * @returns URLSearchParms object
 */
export const toURLSearchParams = (form) => {
  if (!form || typeof form !== 'object') {
    return form;
  } else {
    const searchParams = new URLSearchParams();
    for (let key in form) {
      if (form[key] !== '') {
        searchParams.append(key, form[key]);
      }
    }
    return searchParams;
  }
};

/**
 * Receives any type and goes deeply in the object, replacing Object URL (Blob URL) to Blob type
 * @param data: any
 * @returns any
 */
export const parseObjectUrlsToBlob = async (data) => {
  if (Array.isArray(data)) {
    return await Promise.all(data.map(async (elem) => await parseObjectUrlsToBlob(elem)));
  } else if (
    typeof data === 'object' &&
    data?.constructor === Object &&
    Object.entries(data).length > 0
  ) {
    const dataObj = await Object.entries(data).reduce(async (acc, [key, value]) => {
      const accResolved = await acc;
      const parsedValue = await parseObjectUrlsToBlob(value);
      return {
        ...accResolved,
        [key]: parsedValue,
      };
    }, Promise.resolve({}));
    return dataObj;
  } else if (typeof data === 'string') {
    if (isBlob(data)) {
      return await (await fetch(data)).blob();
    } else {
      return await data;
    }
  } else {
    return await data;
  }
};

/**
 * Receives a string and returns if that is a Object URL (or Blob URL)
 * @param url: string
 * @returns boolean
 */
const isBlob = (url) => {
  try {
    return new URL(url).protocol === 'blob:';
  } catch (err) {
    return false;
  }
};
