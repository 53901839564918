import Loader from '../Loader/Loader';
import { TourRequestsTable } from './TourRequestsTable';
import styles from './TourRequests.module.scss';
import { useTourRequests } from '../../hooks/useTourRequests';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Pagination } from '../Pagination/Pagination';
import { useQueryParams } from '../../hooks/useQueryParams';

export const TourRequestsList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const currentPage = useQueryParams().get('page') || '1';
  const { data, loading, meta } = useTourRequests(currentPage);

  useEffect(() => {
    if (!loading && !data) {
      enqueueSnackbar(
        `Sorry, an internal server error occurred. Please try again later.`,
        {
          variant: 'error',
        }
      );
    }
  }, [loading]);

  return (
    <div className={styles.list}>
      <Loader isLoading={loading}>{data && <TourRequestsTable tours={data} />}</Loader>
      {!loading && <Pagination meta={meta} columns={[]} data={data} />}
    </div>
  );
};
