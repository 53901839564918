import { useEffect, useState } from 'react';
import { ModerationQueueItemStatus } from '../../constants/ModerationQueueItemStatus';
import { diff } from 'deep-diff';
import { ModerationQueueActionButtons } from '../ModerationQueueActionButtons/ModerationQueueActionButtons';
import styles from './CommunityEditsItem.module.scss';
import Button from '../../components/Button/Button';
import Difference from './Difference';
import Icons from '../../constants/Icons';
import { format } from 'date-fns';
import {
  checkDiffCondition,
  fieldNameComponent,
  generateDiffGrid,
  hideAdminNotesOnCommunityEdits,
  isValidFieldValue,
} from '../../utils/communityEditsUtils';
import { KWImage } from '../KWImage/KWImage';
import { FeatureGating } from '../FeatureGating/FeatureGating';
import AdminNotes from '../CommunityForm/AdminNotes/AdminNotes';
import { TabKeys } from '../../constants/TabKeys';
import { useDispatch, useSelector } from 'react-redux';
import { shouldHideAdminNote } from '../../utils/communityFormUtils';
import { loadAdminNotesForCommunity } from '../../redux/reducers/adminNotes';
import { getAdminNotesByCommunityId } from '../../redux/selectors/adminNotes';

const DiffObjectSection = ({ fields, diffsInRow, row, approvedCommunityId }) => {
  let sectionDiff = fields.map((field) => {
    let fieldDiffs = diffsInRow.filter(
      (diff) => diff.key === field || diff.key === field.fieldKey
    );

    fieldDiffs = fieldDiffs.filter((fieldDiff) => checkDiffCondition(fieldDiff));

    return fieldDiffs.map((fieldDiff, index) => [
      <DiffRowTitle
        key={`admin-notes-${index}`}
        section={fieldDiff.key}
        row={fieldDiff.row}
        hideAdminNotes={false}
        approvedCommunityId={approvedCommunityId}
        arrayItemId={null}
      />,
      <div key={`previousValue-${index}`}>
        <p className={styles.arrayTitle}>
          {fieldDiff.fieldName === row.title ? '' : fieldDiff.fieldName}
        </p>
        <span className={!isValidFieldValue(fieldDiff.draftValue) ? styles.removed : ''}>
          {fieldNameComponent(fieldDiff, row)}
          <Difference diff={fieldDiff.publishedValue} />
        </span>
      </div>,
      <div key={`newValue-${index}`}>
        <p className={styles.arrayTitle}>
          {fieldDiff.fieldName === row.title ? '' : fieldDiff.fieldName}
        </p>
        <span
          className={!isValidFieldValue(fieldDiff.publishedValue) ? styles.added : ''}
        >
          {fieldNameComponent(fieldDiff, row)}
          <Difference diff={fieldDiff.draftValue} />
        </span>
      </div>,
    ]);
  });

  sectionDiff = sectionDiff.filter((diff) => diff.length > 0);

  if (sectionDiff.length > 0) {
    return sectionDiff;
  } else {
    // if there are no actual changes, then make an empty grid so the UI looks good.
    // e.g. when there is an admin note but no changes made to a section.
    const adminNotesDiff = diffsInRow[diffsInRow.length - 1];
    return (
      <>
        <DiffRowTitle
          section={adminNotesDiff.key}
          row={adminNotesDiff.row}
          hideAdminNotes={false}
          approvedCommunityId={approvedCommunityId}
          arrayItemId={null}
        />
        <div>
          <div className={styles.emptyValue} />
        </div>
        <div>
          <div className={styles.emptyValue} />
        </div>
      </>
    );
  }
};

const DiffRowTitle = ({
  section,
  row,
  hideAdminNotes,
  approvedCommunityId,
  arrayItemId,
}) => {
  return (
    <div className={styles.rowField}>
      <b>{row}</b>
      {!hideAdminNotes && (
        <FeatureGating>
          <AdminNotes
            title={row}
            section={section}
            approvedCommunityId={approvedCommunityId}
            arrayItemId={arrayItemId}
          />
        </FeatureGating>
      )}
    </div>
  );
};

// NOTE: now array types differences(edit) break down into each field in the array, hence there may be
//       both admin note only differences and queue changes differences in diffsInSection
const DiffArraySection = ({ diffsInSection, row, approvedCommunityId }) => {
  // console.log(diffsInSection);
  if (diffsInSection.length > 0) {
    return diffsInSection.map((fieldDiff, index) => [
      <DiffRowTitle
        key={`admin-notes-${index}`}
        section={fieldDiff.arrayKey || fieldDiff.key}
        row={fieldDiff.row}
        hideAdminNotes={hideAdminNotesOnCommunityEdits(fieldDiff.id)}
        approvedCommunityId={approvedCommunityId}
        arrayItemId={fieldDiff.id}
      />,
      checkDiffCondition(fieldDiff) ? (
        <div key={`previousValue-${index}`}>
          <p className={styles.arrayTitle}>{fieldDiff.fieldName}</p>
          <span
            className={!isValidFieldValue(fieldDiff.draftValue) ? styles.removed : ''}
          >
            {fieldNameComponent(fieldDiff, row)}
            <Difference diff={fieldDiff.publishedValue} />
          </span>
        </div>
      ) : (
        <div key={`emptyPreviousValue-${index}`}>
          <div className={styles.emptyValue} />
        </div>
      ),
      checkDiffCondition(fieldDiff) ? (
        <div key={`newValue-${index}`}>
          <p className={styles.arrayTitle}>{fieldDiff.fieldName}</p>
          <span
            className={!isValidFieldValue(fieldDiff.publishedValue) ? styles.added : ''}
          >
            {fieldNameComponent(fieldDiff, row)}
            <Difference diff={fieldDiff.draftValue} />
          </span>
        </div>
      ) : (
        <div key={`emptyNewValue-${index}`}>
          <div className={styles.emptyValue} />
        </div>
      ),
    ]);
  }
};

export const CommunityEditsItem = ({
  editItem,
  onApproveClick,
  onRejectClick,
  adminView,
  uiSchema,
  approvedCommunityId,
}) => {
  const [showExpandedDiffs, setShowExpandedDiffs] = useState(false);
  const adminNotes = useSelector(getAdminNotesByCommunityId(approvedCommunityId));
  const { id, previousData, newData, status } = editItem;
  const imageDifferences = [];
  const modelImageDifferences = [];
  const filteredImageDiffs = [];
  const filteredModelImageDifferences = [];
  const dispatch = useDispatch();

  useEffect(() => {
    // load the admin notes
    dispatch(loadAdminNotesForCommunity(approvedCommunityId, false));
  }, []);

  // now passing the whole previousData and newData object since we need property name and address
  // which are outside of json_data
  // TODO: should generate diffs in a more intelligent way, e.g. when iterating through the schema we call
  //       diff with corresponding entity of json_data(instead of calling diff with whole chunk of json_data)
  const differences = generateDiffGrid(adminNotes, previousData, newData);
  // console.log(differences);

  const renderedDiffs = differences ? differences : [];

  const visibleTabs = new Set();
  const allTabs = new Set();
  TabKeys.forEach((tab) => {
    const hasDiffsInTab = renderedDiffs.some((diff) => diff.tab === tab);
    if (hasDiffsInTab) {
      // by default only show the first tab of diffs, unless they have clicked 'show more'
      // to expand and view the diffs on all tabs
      if (showExpandedDiffs || visibleTabs.size === 0) {
        visibleTabs.add(tab);
      }
      allTabs.add(tab);
    }
  });
  // can expand / contract when there is more than one tab with diffs in it
  const canShowMoreOrLessDiffs = allTabs.size > 1;

  const sections = new Set();
  renderedDiffs.forEach((diff) => sections.add(diff.section));

  const newImages = newData.images.filter((e) => e.approved_version_id === null);
  const deletedImages = previousData.images.filter(({ id: id1 }) =>
    newData.images.every(({ approved_version_id: id2 }) => id2 !== id1)
  );
  const newModelImages = newData.model_type_images.filter(
    (e) => e.approved_version_id === null
  );
  const deletedModelImages = previousData.model_type_images.filter(({ id: id1 }) =>
    newData.model_type_images.every(({ approved_version_id: id2 }) => id2 !== id1)
  );

  newData.images.forEach((image) => {
    imageDifferences.push({
      image,
      prevImage: previousData.images.find((e) => e.id === image.approved_version_id),
      diff: diff(
        image,
        previousData.images.find((e) => e.id === image.approved_version_id)
      ),
    });
  });

  newImages.forEach((image) => {
    imageDifferences.push({
      image,
      prevImage: previousData.images.find((e) => e.id === image.approved_version_id),
      diff: ['New Image'],
    });
  });

  deletedImages.forEach((image) => {
    imageDifferences.push({
      image,
      prevImage: previousData.images.find((e) => e.id === image.approved_version_id),
      diff: ['Deleted Image'],
    });
  });

  newData.model_type_images.forEach((image) => {
    modelImageDifferences.push({
      image,
      prevImage: previousData.model_type_images.find(
        (e) => e.id === image.approved_version_id
      ),
      diff: diff(
        image,
        previousData.model_type_images.find((e) => e.id === image.approved_version_id)
      ),
    });
  });

  newModelImages.forEach((image) => {
    modelImageDifferences.push({
      image,
      prevImage: previousData.model_type_images.find(
        (e) => e.id === image.approved_version_id
      ),
      diff: ['New Image'],
    });
  });

  deletedModelImages.forEach((image) => {
    modelImageDifferences.push({
      image,
      prevImage: previousData.model_type_images.find(
        (e) => e.id === image.approved_version_id
      ),
      diff: ['Deleted Image'],
    });
  });

  if (imageDifferences.length > 0) {
    imageDifferences.forEach((image) => {
      const imageObject = {
        image: image,
        values: [],
      };

      imageObject.values.push({
        valueName: 'Profile Image',
      });

      image.diff.forEach((e) => {
        if (e.path) {
          if (
            e.path[0] === 'sort_order' ||
            e.path[0] === 'caption' ||
            e.path[0] === 'image_types'
          ) {
            imageObject.values.push({
              valueName:
                e.path[0] === 'sort_order'
                  ? 'Sort Order'
                  : e.path[0] === 'caption'
                  ? 'Caption'
                  : e.path[0] === 'image_types'
                  ? 'Image Tag'
                  : e.path[0],
              // NOTE: If we are just editing(changing) an image type tag, the new value(lhs/rhs) WILL NOT be wrapped
              //       in an 'item' object(lhs/rhs will be on the root level of 'e' object)
              prevValue:
                e.path[0] === 'image_types' && e.kind !== 'E' ? e.item.rhs : e.rhs,
              newValue:
                e.path[0] === 'image_types' && e.kind !== 'E' ? e.item.lhs : e.lhs,
            });
          }
        } else if (e === 'Deleted Image' || e === 'New Image') {
          imageObject.values.push({
            valueName: e,
          });
        }
      });
      if (imageObject.values.length > 1) {
        filteredImageDiffs.push(imageObject);
      }
    });
  }

  if (modelImageDifferences.length > 0) {
    modelImageDifferences.forEach((image) => {
      const imageObject = {
        image: image,
        values: [],
      };

      imageObject.values.push({
        valueName: 'Unit Type Image',
      });

      image.diff.forEach((e) => {
        if (e.path) {
          if (
            e.path[0] === 'sort_order' ||
            e.path[0] === 'caption' ||
            e.path[0] === 'image_types'
          ) {
            imageObject.values.push({
              valueName:
                e.path[0] === 'sort_order'
                  ? 'Sort Order'
                  : e.path[0] === 'caption'
                  ? 'Caption'
                  : e.path[0] === 'image_types'
                  ? 'Image Tag'
                  : e.path[0],
              prevValue: e.rhs,
              newValue: e.path[0] === 'image_types' ? e.item.lhs : e.lhs,
            });
          }
        } else if (e === 'Deleted Image' || e === 'New Image') {
          imageObject.values.push({
            valueName: e,
          });
        }
      });
      if (imageObject.values.length > 1) {
        filteredModelImageDifferences.push(imageObject);
      }
    });
  }

  function isArraySection(section) {
    return (
      section === 'Model Information' ||
      section === 'Building Information' ||
      section === 'Unit Information'
    );
  }

  return adminView || (differences && differences.length > 0) ? (
    <>
      <div className={styles.communityEditItem}>
        <div className={styles.itemInfoContainer}>
          {adminView && (
            <>
              <KWImage
                src={newData.cached_image_url}
                width={100}
                height={100}
                alt={`${id}`}
              />
            </>
          )}
          <div className={styles.itemInfo}>
            {adminView && (
              <>
                <h4>{previousData.display_name}</h4>
                <p>
                  Submitted by {editItem.account.first_name} {editItem.account.last_name}{' '}
                  on {format(new Date(editItem.updated_at), 'MMM. d, yyyy')} at{' '}
                  {new Date(editItem.updated_at).toLocaleTimeString('en-US')}
                </p>
                <p>
                  Last edited by {editItem.account.first_name}{' '}
                  {editItem.account.last_name} on{' '}
                  {format(new Date(editItem.newData.updated_at), 'MMM. d, yyyy')} at{' '}
                  {new Date(editItem.newData.updated_at).toLocaleTimeString('en-US')}
                </p>
                <div className={styles.containerButtons}>
                  <a
                    href={`${process.env.PUBLIC_URL}/communities/${previousData.id}/edit`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Button.LIGHT style={{ display: 'inline', padding: '0px 15px' }}>
                      {Icons.Edit}
                    </Button.LIGHT>
                  </a>
                  <a
                    href={`${process.env.REACT_APP_CONSUMER}/community/${previousData.slug}?draft=true`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Button.DARK className='ml-50' style={{ display: 'inline' }}>
                      Preview Changes
                    </Button.DARK>
                  </a>
                  {previousData.status !== 'unpublished' && (
                    <a
                      href={`${process.env.REACT_APP_CONSUMER}/community/${previousData.id}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Button.LIGHT className='ml-50' style={{ display: 'inline' }}>
                        Live Version
                      </Button.LIGHT>
                    </a>
                  )}
                </div>
              </>
            )}
            {/* NOTE: this should only appear on admin view so that we can see something was submitted but has no changes */}
            {renderedDiffs && renderedDiffs.length === 0 && (
              <>
                <div className={styles.queueChangesContainer}>
                  <div className={styles.queueChangesGrid}>
                    <div
                      className={styles.sectionTitle}
                      style={adminView ? { top: '70px' } : { top: '110px' }}
                    >
                      <span>Field Name</span>
                      <span>Previous Value</span>
                      <span>New Value </span>
                    </div>
                  </div>
                </div>
                <div>No profile changes detected</div>
              </>
            )}
            {renderedDiffs && renderedDiffs.length > 0 && (
              <div className={styles.queueChangesContainer}>
                {Array.from(visibleTabs).map((tab, index) => {
                  // get differences in current tab
                  let diffsInTab = renderedDiffs.filter((diff) => diff.tab === tab);
                  let sectionSet = new Set();
                  diffsInTab.map((diff) => sectionSet.add(diff.section));
                  let sectionSchema = uiSchema.tabs?.[tab];
                  let hideAdminNotes = shouldHideAdminNote(tab);
                  return (
                    <div key={index} className={styles.queueChangesGrid}>
                      <h3>{tab === 'Model-Type' ? 'Unit Types' : tab}</h3>
                      {
                        // order section to match each tab's order
                        sectionSchema &&
                          Object.keys(sectionSchema).map((section, index) => {
                            let diffsInSection = diffsInTab.filter(
                              (diff) => diff.section === section
                            );
                            let rowSet = new Set();
                            diffsInSection.map((diff) => rowSet.add(diff.row));
                            let rowSchema = sectionSchema[section]?.rows;
                            return (
                              sectionSet.has(section) && (
                                <div key={index} className={styles.sectionGrid}>
                                  <div
                                    className={styles.sectionTitle}
                                    style={adminView ? { top: '70px' } : { top: '110px' }}
                                  >
                                    <span key={index}>{section}</span>
                                    <span>Previous Value</span>
                                    <span>New Value</span>
                                  </div>
                                  <div className={styles.sectionBody}>
                                    <div className={styles.valueField}>
                                      {isArraySection(section) ? (
                                        <DiffArraySection
                                          key={`array-type-diff-${index}`}
                                          diffsInSection={diffsInSection}
                                          row={''}
                                          approvedCommunityId={approvedCommunityId}
                                        />
                                      ) : (
                                        rowSchema &&
                                        rowSchema.map((row, index) => {
                                          let diffsInRow = diffsInSection.filter(
                                            (diff) => diff.row === row.title
                                          );
                                          return (
                                            rowSet.has(row.title) && (
                                              <DiffObjectSection
                                                key={`object-type-diff-${index}`}
                                                fields={row.fields}
                                                diffsInRow={diffsInRow}
                                                row={row}
                                                approvedCommunityId={approvedCommunityId}
                                              />
                                            )
                                          );
                                        })
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            );
                          })
                      }
                    </div>
                  );
                })}
                {canShowMoreOrLessDiffs && (
                  <div className={styles.showMore}>
                    <Button.LIGHT
                      icon
                      onClick={() => setShowExpandedDiffs(!showExpandedDiffs)}
                      className={styles.loadMoreButton}
                    >
                      Show {showExpandedDiffs ? 'less' : 'more'}{' '}
                      {showExpandedDiffs ? Icons.ArrowUp : Icons.ArrowDown}
                    </Button.LIGHT>
                  </div>
                )}
              </div>
            )}
            {(filteredImageDiffs && filteredImageDiffs.length > 0) ||
            (filteredModelImageDifferences &&
              filteredModelImageDifferences.length > 0) ? (
              <div className={styles.imageDifferenceGrid}>
                {filteredImageDiffs && filteredImageDiffs.length > 0 && (
                  <div className={styles.dividedSection}>
                    <div className={styles.header}>Community Image Changes</div>
                    <div className={styles.imageGrid}>
                      {filteredImageDiffs.map((image, index) => (
                        <a
                          href={`${process.env.REACT_APP_API}${image.image.image.file_url}`}
                          target='_blank'
                          rel='noreferrer'
                          key={index}
                        >
                          <div className={styles.detailCard}>
                            {image.image.image.file_url && (
                              <KWImage
                                src={image.image.image.file_url}
                                width={160}
                                height={100}
                                alt={'Modified content'}
                              />
                            )}
                            {image.values &&
                              image.values.map((value, index) => (
                                <span
                                  className={styles.detailValueName}
                                  key={`${value.valueName}-${index}`}
                                >
                                  {value.prevValue && value.newValue
                                    ? `${value.valueName}: ${value.prevValue} => ${value.newValue}`
                                    : !value.prevValue && value.newValue
                                    ? `${value.valueName} Added: ${value.newValue}`
                                    : value.prevValue && !value.newValue
                                    ? `${value.valueName} Removed: ${value.prevValue}`
                                    : !value.prevValue && !value.newValue
                                    ? `${value.valueName}`
                                    : null}{' '}
                                </span>
                              ))}
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                )}
                {filteredModelImageDifferences &&
                  filteredModelImageDifferences.length > 0 && (
                    <div className={styles.dividedSection}>
                      <div className={styles.header}>Unit Type Image Changes</div>
                      <div className={styles.imageGrid}>
                        {filteredModelImageDifferences.map((image, index) => (
                          <a
                            href={`${process.env.REACT_APP_API}${image.image.image.file_url}`}
                            target='_blank'
                            rel='noreferrer'
                            key={index}
                          >
                            <div className={styles.detailCard}>
                              {image.image.image.file_url && (
                                <KWImage
                                  src={image.image.image.file_url}
                                  width={160}
                                  height={100}
                                  alt={'Modified content'}
                                />
                              )}
                              {image.values &&
                                image.values.map((value, index) => (
                                  <span
                                    className={styles.detailValueName}
                                    key={`${value.valueName}-${index}`}
                                  >
                                    {value.prevValue && value.newValue
                                      ? `${value.valueName}: ${value.prevValue} => ${value.newValue}`
                                      : !value.prevValue && value.newValue
                                      ? `${value.valueName} Added: ${value.newValue}`
                                      : value.prevValue && !value.newValue
                                      ? `${value.valueName} Removed: ${value.prevValue}`
                                      : !value.prevValue && !value.newValue
                                      ? `${value.valueName}`
                                      : null}{' '}
                                  </span>
                                ))}
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
              </div>
            ) : null}
          </div>
          <div>
            {adminView && (
              <div className={styles.stickyActionButtons}>
                <ModerationQueueActionButtons
                  onAccept={onApproveClick}
                  onReject={onRejectClick}
                  status={status === '?' ? ModerationQueueItemStatus.PENDING : status}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : !adminView ? (
    <div className={styles.noChangesDiv}>
      <h1 className={styles.noChangesText}>
        Community profile information is up to date.
      </h1>
      <h2 className={styles.noChangesSubText}>
        Information for this community is published on Kithward.com.
      </h2>
      <a
        href={`${process.env.REACT_APP_CONSUMER}/community/${previousData.id}`}
        target='_blank'
        rel='noreferrer'
      >
        <Button.LIGHT className='ml-auto mr-auto'>View Live Profile</Button.LIGHT>
      </a>
    </div>
  ) : null;
};
