import produce from 'immer';
import { useEffect, useReducer } from 'react';
import SignUpFormAdapter from '../adapters/SignupFormAdapter';
import { approveAccountRequest } from '../services/accountRequests';
import api from '../services/api';

const INITIAL_USER = {
  firstName: '',
  lastName: '',
  companyName: '',
  jobTitle: '',
  email: '',
  phone: '',
  accountType: '',
  organizationId: '',
};

const initialState = {
  userAccount: INITIAL_USER,
  error: null,
  loading: false,
};

const userAccountReducer = produce((draft, action) => {
  switch (action.type) {
    case 'RESET': {
      return initialState;
    }

    // GET
    case 'GET_PENDING': {
      return { ...initialState, loading: true };
    }
    case 'GET_SUCCEEDED': {
      const account = SignUpFormAdapter.fromApi(action.payload);
      const organizationId = account.organization ? account.organization.id : null;
      return { ...initialState, userAccount: { ...account, organizationId } };
    }
    case 'GET_FAILED': {
      return { ...initialState, error: action.payload };
    }
    // Save Form
    case 'SAVE_PENDING': {
      draft.loading = true;
      break;
    }
    case 'SAVE_SUCCEEDED': {
      return { ...initialState, userAccount: action.payload };
    }
    case 'SAVE_FAILED': {
      return { ...initialState, error: action.payload };
    }
    // Modify User
    case 'MODIFY_USER': {
      return { ...initialState, userAccount: action.payload };
    }
    default:
      break;
  }
}, initialState);

export const useModerationUserAccount = (userId) => {
  const [{ loading, userAccount, error }, dispatch] = useReducer(
    userAccountReducer,
    initialState
  );

  useEffect(() => {
    const getUserAccount = async () => {
      try {
        dispatch({ type: 'GET_PENDING' });
        const { data } = await api(`/v1/admin/accounts/${userId}`);
        dispatch({ type: 'GET_SUCCEEDED', payload: data });
      } catch (err) {
        dispatch({ type: 'GET_FAILED', payload: err });
      }
    };

    if (userId) {
      getUserAccount();
    }
  }, [userId]);

  const setUserAccount = (user) => {
    dispatch({ type: 'MODIFY_USER', payload: user });
  };

  const approveAccount = async (account) => {
    try {
      const data = await approveAccountRequest(account);
      return data;
    } catch (err) {}
  };

  return { userAccount, error, loading, setUserAccount, approveAccount };
};
