import useSWR from 'swr';
import api from '../services/api';

export const useTourRequests = (page = 1) => {
  const { data: responses, isValidating } = useSWR(
    page ? `/v1/admin/tour_requests/?filter=pending&page=${page}` : null,
    api,
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );
  const data = responses ? responses.data.results : null;

  return {
    loading: isValidating,
    data,
    meta: responses && responses.data.meta ? responses.data.meta : null,
  };
};
