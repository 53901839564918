import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SignUpFormAdapter from '../../../../adapters/SignupFormAdapter';
import { selectFormData } from '../../../../redux/selectors/communityForm';
import { setFormNavigationFooterVisibility } from '../../../../redux/reducers';
import Loader from '../../../Loader/Loader';
import styles from './UsersTab.module.scss';
import UserList from '../../../UserList/UserList';
import { UserForm } from '../../../UserForm/UserForm';
import { useCommunityAccounts } from '../../../../hooks/useCommunityAccounts';
import Button from '../../../Button/Button';

export const UsersTab = () => {
  const dispatch = useDispatch();
  const { id } = useSelector(selectFormData);
  const [showUserForm, setShowUserForm] = useState(false);
  const [userToEdit, setUserToEdit] = useState(undefined);
  const { data, loading, error, mutate } = useCommunityAccounts(id);

  const handleShowUserForm = (user, toEdit) => {
    setUserToEdit(user ? SignUpFormAdapter.fromApi(user) : null);
    setShowUserForm(toEdit);
  };

  const handleUserFormClose = async () => {
    mutate();
    setUserToEdit(undefined);
    setShowUserForm(false);
  };

  useEffect(() => {
    dispatch(setFormNavigationFooterVisibility(false));
    return () => dispatch(setFormNavigationFooterVisibility(true));
  }, [dispatch]);

  return (
    <div className={styles.formSection}>
      <div className={styles.sectionHeader}>
        <span className={styles.sectionTitle}>Authorized Users</span>
        <Button.LIGHT
          className={styles.sectionTitleButton}
          onClick={() => handleShowUserForm(null, 'editUser')}
        >
          + New User
        </Button.LIGHT>
      </div>
      <Loader isLoading={loading}>
        <UserList onItemClick={handleShowUserForm} providedUsers={data} />
      </Loader>
      {showUserForm && showUserForm === 'editUser' ? (
        <UserForm
          isLoading={loading}
          editUser={true}
          editCommunities={false}
          user={userToEdit}
          onClose={handleUserFormClose}
          fromCommunity={id}
        />
      ) : showUserForm && showUserForm === 'editUserCommunities' ? (
        <UserForm
          isLoading={loading}
          editUser={false}
          editCommunities={true}
          user={userToEdit}
          onClose={handleUserFormClose}
          fromCommunity={id}
        />
      ) : null}
    </div>
  );
};
