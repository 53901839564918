import styles from './UnitImportTable.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import {
  faCheckCircle,
  faPlusCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
export const StatusCell = ({ status }) => {
  const toolTipText =
    status === 'update'
      ? 'We found a unit matching this data, matched unit will be updated with the values here.'
      : status === 'new'
      ? 'This unit will be created as a new unit at the indicated community.'
      : status === 'error'
      ? 'There was an error processing this unit data. Please review the fields indicated with red text and the message to the right of this icon.'
      : null;
  return status !== 'skip' ? (
    <Tooltip title={toolTipText} arrow>
      <div className={`${styles.stateIcon} pl-50`}>
        <FontAwesomeIcon
          icon={
            status === 'update'
              ? faCheckCircle
              : status === 'new'
              ? faPlusCircle
              : status === 'error'
              ? faTimesCircle
              : null
          }
          className={status === 'error' ? styles.red : styles.green}
        />
      </div>
    </Tooltip>
  ) : (
    <></>
  );
};
