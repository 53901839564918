import produce from 'immer';

export const ErrorsActions = {
  RESET_ERROR: 'errors/RESET_ERROR',
  ERROR_CAUGHT: 'errors/CAUGHT_ERROR',
};

export const resetError = () => {
  return async (dispatch) => {
    dispatch({ type: ErrorsActions.RESET_ERROR });
  };
};

export function catchError(error) {
  return (dispatch) => {
    dispatch({ type: ErrorsActions.ERROR_CAUGHT, payload: error });
  };
}

const INITIAL_STATE = {
  error: null,
};

const errorsReducer = produce((draft, action) => {
  switch (action.type) {
    case ErrorsActions.ERROR_CAUGHT:
      draft.error = action.payload;
      break;

    case ErrorsActions.RESET_ERROR:
      return INITIAL_STATE;

    default:
      break;
  }
}, INITIAL_STATE);

export default errorsReducer;
