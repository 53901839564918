import {
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountType, accountTypeOptions } from '../../constants/AccountType';
import { useAccounts } from '../../hooks/useAccounts';
import { useModerationUserAccount } from '../../hooks/useModerationUserAccount';
import { useOrganizationsInfinite } from '../../hooks/useOrganizationsInfinite';
import { useSnackbar } from 'notistack';
import { createOrganization } from '../../services/organizations';
import { getData } from '../../redux/reducers';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import Loader from '../Loader/Loader';
import styles from './ModerationNewUserForm.module.scss';

const OrganizationOrigin = {
  EXISTING: 'EXISTING',
  NEW: 'NEW',
};

const accountTypesToShow = [
  AccountType.COMMUNITY_MANAGER,
  AccountType.CORPORATE_EXECUTIVE,
  AccountType.BOTH_EXECUTIVE_AND_MANAGER,
];
const filteredAccountTypeOptions = accountTypeOptions.filter((option) =>
  accountTypesToShow.includes(option.value)
);

export const ModerationNewUserForm = ({ id, onSuccess, showHeader }) => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state?.auth?.user?.role);
  const {
    userAccount,
    setUserAccount,
    approveAccount,
    loading: loadingUser,
  } = useModerationUserAccount(id);
  const { createAccount } = useAccounts();
  const {
    organizations,
    loading: organizationLoading,
    loadMore,
  } = useOrganizationsInfinite(true);
  const [origin, setOrigin] = useState(OrganizationOrigin.EXISTING);
  const [newOrganizationName, setNewOrganizationName] = useState('');
  const [savingAccount, setSavingAccount] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = ({ target: { name, value } }) => {
    setUserAccount({
      ...userAccount,
      [name]: value,
    });
  };

  const handleSaveChanges = async (event) => {
    event.preventDefault();
    try {
      setSavingAccount(true);
      let accountForm = { ...userAccount };
      if (origin === OrganizationOrigin.NEW) {
        let formData = new FormData();
        formData.append('name', newOrganizationName);
        const newOrg = await createOrganization(formData);
        accountForm = {
          ...accountForm,
          organizationId: newOrg.id,
          organization: newOrg,
        };
      }

      const data = id
        ? await approveAccount(accountForm)
        : await createAccount(accountForm);
      if (data) {
        enqueueSnackbar(id ? `Account approved.` : `Account created.`, {
          variant: 'success',
        });
        onSuccess();
      }
    } catch (err) {
    } finally {
      if (userRole && userRole === 'admin') {
        dispatch(getData());
      }
      setSavingAccount(false);
    }
  };

  const handleOriginRadioChange = (event) => {
    const selectedOrigin = event.target.value;
    if (selectedOrigin !== origin) {
      setUserAccount({
        ...userAccount,
        organizationId: '',
      });
      setOrigin(selectedOrigin);
    }
  };

  const handleAccountTypeSelect = (newAccountType) => {
    setUserAccount({
      ...userAccount,
      accountType: newAccountType,
    });
  };

  const handleOrganizationSelectScroll = (event) => {
    const listEl = event.currentTarget;
    const scrolledToBottom =
      listEl.scrollTop + listEl.clientHeight >= listEl.scrollHeight;
    if (scrolledToBottom) {
      loadMore();
    }
  };

  if (id && !userAccount) return <Loader isLoading />;

  const getHeaderTitle = () => {
    if (id) {
      const hasName = Boolean(userAccount.firstName || userAccount.lastName);
      const name = `${userAccount.firstName || ''} ${userAccount.lastName || ''}${
        hasName ? "'s" : ''
      }`;
      return `Approve ${name} Account`;
    } else {
      return 'Create new Account';
    }
  };

  return (
    <Loader isLoading={loadingUser}>
      <div className={styles.moderationNewUser}>
        {showHeader ? (
          <div className={styles.header}>
            <h2>{getHeaderTitle()}</h2>
          </div>
        ) : null}
        <form className={styles.form} onSubmit={handleSaveChanges}>
          {/* Organization Select */}
          {/* New organization name */}
          <div className={`${styles.organizationOriginWrapper} ${styles.fieldGroup}`}>
            <label>Organization</label>
            <FormControl component='fieldset'>
              <RadioGroup
                aria-label='care-type'
                name='radio-buttons-group'
                className={styles.originRadioGroup}
                onChange={handleOriginRadioChange}
                value={origin}
                color='black'
              >
                <FormControlLabel
                  value={OrganizationOrigin.EXISTING}
                  control={<Radio />}
                  label='Select existing'
                />
                <FormControlLabel
                  value={OrganizationOrigin.NEW}
                  control={<Radio />}
                  label='Create new'
                />
              </RadioGroup>
            </FormControl>

            {origin === OrganizationOrigin.EXISTING ? (
              <FormControl
                sx={{
                  width: '100%',
                }}
                fullWidth
              >
                <InputLabel id='organization-origin-checkbox-label'>
                  Select Organization
                </InputLabel>
                <Select
                  required
                  labelId='organization-origin-checkbox-label'
                  name='organizationId'
                  disabled={organizationLoading}
                  value={userAccount?.organizationId || ''}
                  onChange={handleChange}
                  input={<OutlinedInput label='Organization Origin' />}
                  MenuProps={{
                    PaperProps: {
                      onScroll: handleOrganizationSelectScroll,
                      sx: {
                        maxHeight: '300px',
                      },
                    },
                  }}
                >
                  {organizations?.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <input
                required
                name='organizationName'
                type='text'
                placeholder='New Organization Name'
                value={newOrganizationName}
                onChange={({ target: { value } }) => setNewOrganizationName(value)}
              />
            )}
          </div>

          {/* Access */}
          <div className={styles.fieldGroup}>
            <label>Account Type</label>
            <Dropdown
              title='Account type'
              options={filteredAccountTypeOptions}
              onSelect={handleAccountTypeSelect}
              initialValue={userAccount?.accountType}
            />
          </div>

          {/* Name */}
          <div className={styles.rowGroup}>
            <div className={styles.fieldGroup}>
              <label>First Name</label>
              <input
                required
                name='firstName'
                type='text'
                value={userAccount.firstName}
                onChange={handleChange}
              />
            </div>

            <div className={styles.fieldGroup}>
              <label>Last Name</label>
              <input
                required
                name='lastName'
                type='text'
                value={userAccount.lastName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className={styles.fieldGroup}>
            <label>Company Name</label>
            <input
              required
              name='companyName'
              type='text'
              value={userAccount.companyName}
              onChange={handleChange}
            />
          </div>

          <div className={styles.fieldGroup}>
            <label>Job Title</label>
            <input
              required
              name='jobTitle'
              type='text'
              value={userAccount.jobTitle}
              onChange={handleChange}
            />
          </div>

          <div className={styles.fieldGroup}>
            <label>Email</label>
            <input
              required
              name='email'
              type='email'
              value={userAccount.email}
              onChange={handleChange}
            />
          </div>

          <div className={styles.fieldGroup}>
            <label>Phone</label>
            <input
              required
              name='phone'
              type='tel'
              value={userAccount.phone}
              onChange={handleChange}
            />
          </div>

          <Button.DARK loading={savingAccount}>
            {id ? 'Approve User' : 'Create User'}
          </Button.DARK>
        </form>
      </div>
    </Loader>
  );
};
