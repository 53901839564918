import Loader from '../Loader/Loader';
import { InformationRequestsTable } from './InformationRequestsTable';
import styles from './InformationRequests.module.scss';
import { useInformationRequests } from '../../hooks/useInformationRequests';
import { Pagination } from '../Pagination/Pagination';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

export const InformationRequestsList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const urlPage = useQueryParams().get('page') || '1';
  const { loading, data: requests, meta } = useInformationRequests(urlPage);

  useEffect(() => {
    if (!loading && !requests) {
      enqueueSnackbar(
        `Sorry, an internal server error occurred. Please try again later.`,
        { variant: 'error' }
      );
    }
  }, [loading]);

  return (
    <div className={styles.list}>
      <Loader isLoading={loading}>
        {requests && <InformationRequestsTable requests={requests} />}
      </Loader>
      {!loading && <Pagination meta={meta} columns={[]} data={requests} />}
    </div>
  );
};
