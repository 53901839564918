import styles from './ErrorPages.module.scss';

export default function ErrorPage() {
  return (
    <div className={styles.errorHeader}>
      <div className={styles.errorAlert}>
        <div>
          <h1>An error occurred</h1>
        </div>
      </div>
      <div className={styles.errorText}>
        <div>
          <h2>
            We&apos;re sorry, an internal server error has occurred while trying to reach
            this page. If the issue persists,{' '}
            <a
              href={`mailto:hello@kithward.com?subject=Server Error Report&body=Current URL: ${window.location}`}
              rel='noreferrer'
            >
              you can let us know here.
            </a>
          </h2>
        </div>
      </div>
    </div>
  );
}
