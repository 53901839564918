import { Grid } from '@mui/material';
import ReactPlayer from 'react-player';
import styles from './ModulePage.module.scss';

const ModulePage = ({ videoUrl, description, title, subTitle }) => {
  return (
    <div className={styles.containerModule}>
      <Grid container rowSpacing={1}>
        <Grid item xs={4}>
          <p className={styles.subTitle}>{subTitle}</p>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>{description}</p>
        </Grid>
        <Grid item xs={8}>
          <ReactPlayer url={videoUrl} controls={true} width='100%' height='100%' />
        </Grid>
      </Grid>
    </div>
  );
};

export default ModulePage;
