import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { rejectAccountRequest } from '../../services/accountRequests';
import { getData } from '../../redux/reducers';
import Button from '../Button/Button';
import styles from './RejectAccountRequestPopup.module.scss';

export const RejectAccountRequestPopup = ({ accountId, onClose }) => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state?.auth?.user?.role);
  const [rejectReason, setRejectReason] = useState('');
  const [isRejecting, setIsRejecting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleReasonChange = (event) => {
    setRejectReason(event.target.value);
  };

  const handleRejectClick = async () => {
    setIsRejecting(true);
    try {
      const data = await rejectAccountRequest(accountId, rejectReason);
      enqueueSnackbar('Rejected the account', {
        variant: 'success',
      });
      onClose(true);
    } catch (err) {
    } finally {
      if (userRole && userRole === 'admin') {
        dispatch(getData());
      }
      setIsRejecting(false);
    }
  };

  return (
    <div className={styles.rejectPopup}>
      <h4>Please explain why you're rejecting this request</h4>
      <textarea
        value={rejectReason}
        onChange={handleReasonChange}
        placeholder='Type the reason...'
      />
      <Button.DARK
        onClick={handleRejectClick}
        loading={isRejecting}
        className={styles.rejectButton}
      >
        Reject Account
      </Button.DARK>
    </div>
  );
};
