import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getData, getSchemas } from '../../redux/reducers';
import Icons from '../../constants/Icons';
import { useCommunityEditsInfinite } from '../../hooks/useCommunityEditsInfinite';
import { useSnackbar } from 'notistack';
import { approveCommunityEdit, rejectCommunityEdit } from '../../services/communityEdits';
import Button from '../Button/Button';
import { EmptyBox } from '../EmptyBox/EmptyBox';
import Loader from '../Loader/Loader';
import { CommunityEditsItem } from '../CommunityEditsItem/CommunityEditsItem';
import styles from './CommunityEditsList.module.scss';
import Popup from 'reactjs-popup';
import ReviewInterestTags from './ReviewInterestTags/ReviewInterestTags';
import ApproveCommunityEditRequest from './CommunityEditRequestPopup/ApproveCommunityEditRequest/ApproveCommunityEditRequest';
import RejectCommunityEditRequest from './CommunityEditRequestPopup/RejectCommunityEditRequest/RejectCommunityEditRequest';

const PopupType = {
  NONE: 'NONE',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  INTEREST_TAGS: 'INTEREST_TAGS',
};

export const CommunityEditsList = () => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state?.auth?.user?.role);
  const {
    data: communityEdits,
    meta,
    loading,
    restartPages,
    loadMore,
  } = useCommunityEditsInfinite();
  const [popupType, setPopupType] = useState(PopupType.NONE);
  const [selectedEditRequest, setSelectedEditRequest] = useState(null);
  const [isConfirming, setIsConfirming] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [isSkippingRejectionEmail, setIsSkippingRejectionEmail] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { uiSchema } = useSelector((state) => state.communityForm.schemas);

  const handleReasonChange = (event) => {
    setRejectReason(event.target.value);
  };

  const handleSkipChange = () => {
    setIsSkippingRejectionEmail(!isSkippingRejectionEmail);
  };

  const handleApproveConfirm = async (params) => {
    setIsConfirming(true);
    try {
      await approveCommunityEdit(selectedEditRequest.id, params);
      enqueueSnackbar('Community Edits Published!', {
        variant: 'success',
      });
      // handleClosePopup(true);
      setPopupType(PopupType.INTEREST_TAGS);
    } catch (err) {
      handleClosePopup();
    } finally {
      if (userRole && userRole === 'admin') {
        dispatch(getData());
      }
      setIsConfirming(false);
    }
  };

  const handleRejectConfirm = async () => {
    setIsConfirming(true);
    try {
      await rejectCommunityEdit(
        selectedEditRequest.id,
        rejectReason,
        isSkippingRejectionEmail
      );
      enqueueSnackbar('Community Edits Rejected', {
        variant: 'success',
      });
      handleClosePopup(true);
    } catch (err) {
      handleClosePopup();
    } finally {
      if (userRole && userRole === 'admin') {
        dispatch(getData());
      }
      setIsConfirming(false);
    }
  };

  const handleItemApproveClick = (edit) => () => {
    setPopupType(PopupType.APPROVE);
    setSelectedEditRequest(edit);
  };

  const handleItemRejectClick = (edit) => () => {
    setRejectReason('');
    setIsSkippingRejectionEmail(false);
    setPopupType(PopupType.REJECT);
    setSelectedEditRequest(edit);
  };

  const handleClosePopup = (refreshList) => {
    setPopupType(PopupType.NONE);
    setSelectedEditRequest(null);

    if (refreshList) {
      restartPages();
    }
  };

  const handleLoadMore = () => {
    loadMore();
  };

  const handleDoneUpdatingInterestTags = async () => {
    handleClosePopup(true);

    if (userRole && userRole === 'admin') {
      dispatch(getData());
    }
    setIsConfirming(false);
  };

  // if uiSchema in redux store is empty, we load it from the server
  useEffect(() => {
    if (!uiSchema || Object.entries(uiSchema).length === 0) {
      dispatch(getSchemas()); //load schemas from server to redux store
    }
  }, [dispatch, uiSchema]);

  return (
    <>
      <div className={styles.communityEditsList}>
        <Loader isLoading={loading}>
          <div>
            {communityEdits ? (
              communityEdits.map((edit) => {
                let approvedCommunityId = edit.previousData?.id;
                return (
                  <CommunityEditsItem
                    key={String(edit.id)}
                    editItem={edit}
                    onApproveClick={handleItemApproveClick(edit)}
                    onRejectClick={handleItemRejectClick(edit)}
                    adminView={true}
                    uiSchema={uiSchema}
                    approvedCommunityId={approvedCommunityId}
                  />
                );
              })
            ) : (
              <EmptyBox
                message={`Currently there isn't any Community Edit on the queue.`}
              />
            )}
          </div>
        </Loader>
        {meta?.last_page === false ? (
          <Button.LIGHT icon onClick={handleLoadMore} className={styles.loadMoreButton}>
            Load more {Icons.ArrowDown}
          </Button.LIGHT>
        ) : null}

        <Popup
          open={selectedEditRequest !== null && popupType === PopupType.APPROVE}
          modal
          nested
          closeOnDocumentClick={false}
          closeOnEscape={false}
          lockScroll={true}
        >
          <ApproveCommunityEditRequest
            title={`Community Edits: ${selectedEditRequest?.previousData.name}`}
            onConfirm={handleApproveConfirm}
            requestId={selectedEditRequest?.id}
            isLoading={isConfirming}
            onClose={() => handleClosePopup()}
          />
        </Popup>
        <Popup
          open={selectedEditRequest !== null && popupType === PopupType.REJECT}
          modal
          nested
          closeOnDocumentClick={false}
          closeOnEscape={false}
          lockScroll={true}
        >
          <RejectCommunityEditRequest
            title={`Community Edits: ${selectedEditRequest?.previousData.name}`}
            reason={rejectReason}
            onChangeReason={handleReasonChange}
            silentRejection={isSkippingRejectionEmail}
            onSkipChange={handleSkipChange}
            isLoading={isConfirming}
            onConfirm={handleRejectConfirm}
            onClose={() => handleClosePopup()}
          />
        </Popup>
        <Popup
          open={selectedEditRequest !== null && popupType === PopupType.INTEREST_TAGS}
          modal
          nested
          closeOnDocumentClick={false}
          closeOnEscape={false}
          lockScroll={true}
          onClose={handleClosePopup}
        >
          <ReviewInterestTags
            slug={selectedEditRequest?.previousData.slug}
            communityName={selectedEditRequest?.previousData.name}
            onFinish={handleDoneUpdatingInterestTags}
            propertyId={selectedEditRequest?.previousData.property_id}
          />
        </Popup>
      </div>
    </>
  );
};
