import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Content } from '../components/Content/Content';
import { Footer } from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import { SideMenu } from '../components/SideMenu/SideMenu';
import CommunitiesDashboard from '../pages/communities/CommunitiesDashboard';
import CommunityEdit from '../pages/communities/CommunityEdit';
import PropertiesDashboard from '../pages/properties/PropertiesDashboard';
import { PropertiesSearchAndClaimPage } from '../pages/properties/PropertiesSearchAndClaim/PropertiesSearchAndClaim';
import UnitImportPage from '../pages/import/UnitImportPage/UnitImportPage';
import UserListPage from '../pages/users/UsersListPage';
import { selectIsSuperAdmin, selectIsExecutive } from '../redux/selectors/auth';
import { AssignPropertyPage } from '../pages/moderation/AssignProperty/AssignPropertyPage';
import { OrganizationListPage } from '../pages/organizations/OrganizationListPage';
import { AccountRequestsPage } from '../pages/moderationQueues/AccountRequestsPage/AccountRequestsPage';
import { ModerationNewUserPage } from '../pages/moderation/ModerationNewUserPage/ModerationNewUserPage';
import { CommunityClaimsPage } from '../pages/moderationQueues/CommunityClaimsPage/CommunityClaimsPage';
import { CommunityEditsPage } from '../pages/moderationQueues/CommunityEditsPage/CommunityEditsPage';
import { ConsumerTourRequestPage } from '../pages/consumer-admin/ConsumerTourRequestPage';
import { ConsumerInformationRequestsPage } from '../pages/consumer-admin/ConsumerInformationRequestsPage';
import { StatsPage } from '../pages/stats/StatsPage';
import NotFound from '../pages/error/NotFound';
import Forbidden from '../pages/error/Forbidden';
import ErrorPage from '../pages/error/Error';
import { ConsumerBookedToursPage } from '../pages/consumer-admin/ConsumerBookedToursPage';
import { ServicesPage } from '../pages/services/ServicesPage';
import EditPropertyAssessmentLink from '../pages/properties/PropertyAssessmentLinks/EditPropertyAssessmentLink';
import PropertyAssessmentLinksDashboard from '../pages/properties/PropertyAssessmentLinks/PropertyAssessmentLinksDashboard';
import SubscriptionDashboard from '../pages/premium/SubscriptionDashboard';
import PropertyAssessmentResponsesDashboard from '../pages/properties/PropertyAssessmentResponsesPage/PropertyAssessmentResponsesDashboard';
import PropertyPage from '../pages/properties/PropertyDashboard/PropertyPage';
import AssessmentReportPage from '../pages/properties/AssessmentReportPage/AssessmentReportPage';
import SurveyList from '../components/SurveyList/SurveyList';
import MySearchList from '../components/MySearch/MySearchList';

const AppRoutes = () => {
  const isSuperAdmin = useSelector(selectIsSuperAdmin);
  const isExecutive = useSelector(selectIsExecutive);
  const location = useLocation();
  const showHeader = !(
    location.pathname.includes('/communities/') ||
    (location.pathname.includes('/assessment-links/') &&
      location.pathname.includes('/edit'))
  );
  // isEditorPage - pages that are full screen pages where we are editing data (e.g. community or assessment link
  const isEditorPage =
    location.pathname.includes('/communities/') ||
    (location.pathname.includes('/assessment-links/') &&
      location.pathname.includes('/edit'));
  const showSideMenu = !isEditorPage;

  const superAdminRoutes = useMemo(() => {
    return [
      // {/* Organizations */}
      <Route exact path='/organizations' key='OrganizationListPage'>
        <OrganizationListPage />
      </Route>,
      // {/* Users */}
      <Route exact path='/users' key='UserListPage'>
        <UserListPage userGroup={'all'} title={'Users'} />
      </Route>,
      // {/* Moderation */}
      <Route exact path='/moderation/account-requests' key='AccountRequestsPage'>
        <AccountRequestsPage />
      </Route>,
      <Route exact path='/moderation/account-requests/:id' key='ModerationNewUserPage'>
        <ModerationNewUserPage />
      </Route>,
      <Route exact path='/moderation/assign-property' key='AssignCommunityPage'>
        <AssignPropertyPage />
      </Route>,
      <Route exact path='/moderation/community-claims' key='CommunityClaimsPage'>
        <CommunityClaimsPage />
      </Route>,
      <Route exact path='/moderation/community-edits' key='CommunityEditsPage'>
        <CommunityEditsPage />
      </Route>,
      <Route exact path='/consumer-admin/tour-requests' key='ConsumerTourRequestPage'>
        <ConsumerTourRequestPage />
      </Route>,
      <Route exact path='/consumer-admin/booked-tours' key='ConsumerBookedToursPage'>
        <ConsumerBookedToursPage />
      </Route>,
      <Route
        exact
        path='/consumer-admin/information-requests'
        key='ConsumerInformationRequests'
      >
        <ConsumerInformationRequestsPage />
      </Route>,
      <Route exact path='/consumers' key='UsersConsumers'>
        <UserListPage userGroup={'user'} title={'Consumers'} />
      </Route>,
      <Route exact path='/operators' key='UsersOperators'>
        <UserListPage userGroup={'operator'} title={'Operators'} />
      </Route>,
      <Route exact path='/stats' key='Stats'>
        <StatsPage />
      </Route>,
      <Route exact path='/surveys/:accountId' key='Surveys'>
        <SurveyList />
      </Route>,
      <Route exact path='/my-search/:accountId' key='MySearch'>
        <MySearchList />
      </Route>,
    ];
  }, []);

  return (
    <div>
      {showHeader ? <Header /> : null}
      <Content isEditorPage={isEditorPage}>
        {showSideMenu ? <SideMenu /> : null}
        <ScrollToTop>
          <Switch>
            <Route exact path='/communities'>
              <CommunitiesDashboard />
            </Route>
            <Route exact path='/communities/:id/edit'>
              <CommunityEdit />
            </Route>
            <Route exact path='/properties'>
              <PropertiesDashboard />
            </Route>
            <Route exact path='/properties/:property_slug/assessment-links'>
              <PropertyAssessmentLinksDashboard />
            </Route>
            <Route exact path='/properties/:property_slug'>
              <PropertyPage />
            </Route>
            <Route exact path='/properties/:property_slug/assessment-links/:uuid/edit'>
              <EditPropertyAssessmentLink />
            </Route>
            <Route exact path='/properties/:propertyId/assessment-responses'>
              <PropertyAssessmentResponsesDashboard />
            </Route>
            <Route exact path='/properties/:property_slug/assessment-report'>
              <AssessmentReportPage />
            </Route>
            <Route exact path='/properties/search'>
              <PropertiesSearchAndClaimPage />
            </Route>
            <Route exact path='/premium/:checkout?'>
              <SubscriptionDashboard />
            </Route>
            <Route exact path='/users'>
              <UserListPage userGroup={'all'} title={'Users'} />
            </Route>
            <Route exact path='/import'>
              <UnitImportPage />
            </Route>
            <Route exact path='/services' key='ServicesPage'>
              <ServicesPage />
            </Route>
            <Route exact path='/forbidden'>
              <Forbidden />
            </Route>
            <Route exact path='/notfound'>
              <NotFound />
            </Route>
            <Route exact path='/error'>
              <ErrorPage />
            </Route>
            {isSuperAdmin ? superAdminRoutes : null}
            {isSuperAdmin || isExecutive ? (
              <Route path='/' render={() => <Redirect to='/properties' />} />
            ) : (
              <Route path='/' render={() => <Redirect to='/communities' />} />
            )}
          </Switch>
        </ScrollToTop>
      </Content>
      <Footer />
    </div>
  );
};

export default AppRoutes;
