import { useState } from 'react';
import { PropertyDetailForm } from '../NewPropertyPopup/PropertyDetailForm';
import api from '../../services/api';
import { useSnackbar } from 'notistack';

export const EditPropertyPopup = ({ property, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [propertyForm, setPropertyForm] = useState({
    name: property.name,
    country: property.country,
    street: property.street,
    street_more: property.street_more,
    city: property.city,
    state: property.state,
    postal: property.postal,
    website_url: property.website_url,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPropertyForm((currentForm) => {
      return {
        ...currentForm,
        [name]: value,
      };
    });
  };

  const handleSaveProperty = async (propertyForm) => {
    try {
      const { response } = await api.put(
        `v1/admin/properties/${property.id}`,
        propertyForm
      );
      enqueueSnackbar(`Property saved successfully.`, {
        variant: 'success',
      });
      onClose();
    } catch (err) {
      // Errors already handled in the API snackbar handler
    }
  };

  return (
    <>
      <PropertyDetailForm
        propertyForm={propertyForm}
        setPropertyForm={setPropertyForm}
        onClose={onClose}
        handleChange={handleChange}
        onSaveClick={handleSaveProperty}
        isOpen={true}
        isEditing
      />
    </>
  );
};
