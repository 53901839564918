import { useDispatch, useSelector } from 'react-redux';
import { changeEditingModel } from '../../../redux/reducers';
import Loader from '../../Loader/Loader';
import styles from './TabContent.module.scss';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { Constants, StringUtils } from 'web-core';
import { FeatureGating } from '../../FeatureGating/FeatureGating';
import { Tooltip } from '@mui/material';

const TabContent = ({
  children,
  title,
  rightTopContent,
  onTitleSelect,
  selectedTitle,
  percentCompleteForm,
}) => {
  const isLoading = useSelector((state) => state.communityForm.loading);
  const account = useSelector((state) => state.auth.user);
  const isLoadingSchemas = useSelector((state) => state.communityForm.schemas.loading);
  const dispatch = useDispatch();
  const [percentComplete, setPercentComplete] = useState(percentCompleteForm);

  useEffect(() => {
    if (percentCompleteForm) {
      if (percentCompleteForm.draft) {
        setPercentComplete(percentCompleteForm.draft);
      } else if (percentCompleteForm.approved) {
        // when there is no draft (e.g. on first load after draft approval)
        setPercentComplete(percentCompleteForm.approved);
      }
    }
  }, [percentCompleteForm]);

  const backToSummary = () => {
    dispatch(changeEditingModel(null));
    onTitleSelect(null);
  };

  let tabDescription;
  let newItemTabDescription;
  if (title === 'Profile') {
    tabDescription = 'General information about this community.';
  } else if (title === 'Photos') {
    tabDescription =
      'Upload photos to showcase your community. The first photo in the list will be the main community profile picture shown when browsing the site.';
  } else if (title === 'Location') {
    tabDescription =
      'Where is this community, what are the nearby public transit options, and are there any particular local points of interest or events?';
  } else if (title === 'Facilities') {
    tabDescription =
      'What facilities are available at this community? Select the fitness, dining, social and spiritual facilities that make your community unique.';
  } else if (title === 'Services') {
    tabDescription =
      'What services are available at this community? Select the fitness, dining, social and spiritual services that make your community unique.';
  } else if (title === 'Buildings') {
    tabDescription =
      'Add the specific buildings at this community so that prospective residents can see which amenities are closest to units they are interested in.';
    // NOTE: the Building Form currently doesn't set the selectedTitle so this doesn't appear - probably should change that?
    // newItemTabDescription = "Add all the details about this Building, so that prospective residents can see which amenities are closest to units in it."
  } else if (title === 'Unit Types') {
    tabDescription =
      'Add all the available unit types / floor plans at this community for prospective residents to find the ones that interest them.';
    newItemTabDescription =
      "Add all the details about this Unit Type, so we can market it most effectively. Be sure to enter in this Unit Type's Property Management System ID in the PMS Unit Type field so you can import all your Unit data via CSV / XSLX upload.";
  } else if (title === 'Units') {
    tabDescription =
      'Add all the specific units both occupied and available in this community and update them as occupancy changes.';
    // NOTE: the Unit Form currently doesn't set the selectedTitle so this doesn't appear - probably should change that?
    // newItemTabDescription = "Add all the details for this Unit, paying attention to any differences it has from its Unit Type."
  } else if (title === 'Fees') {
    tabDescription = 'What are the fees for applying to and moving into this community?';
  } else if (title === 'Promotions') {
    tabDescription =
      'Do you have any promotions for moving into this community? A banner will be shown to all people viewing this community profile until the promotion ends.';
  } else {
    tabDescription = null;
  }

  if (newItemTabDescription === null) {
    newItemTabDescription = tabDescription;
  }

  const percentCompleteTodos = percentComplete?.sections?.[title]?.next_steps
    ? percentComplete?.sections[title]?.next_steps.map((elem, index) => (
        <p key={index} style={{ marginBottom: '8px' }}>{`${elem.label}${
          StringUtils.isBlank(elem.label) ? '' : ' - '
        }${elem.message}`}</p>
      ))
    : [];

  return (
    <Loader isLoading={isLoading || isLoadingSchemas}>
      <div className={styles.tabContent}>
        <div className={styles.header}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div>
                {selectedTitle ? (
                  <h4
                    className={`clickable ${styles.subTitle}`}
                    onClick={() => backToSummary()}
                  >
                    {' '}
                    &lt; Back to {title}
                  </h4>
                ) : null}
                {selectedTitle ? <h3>{selectedTitle}</h3> : <h3>Community {title}</h3>}
                {selectedTitle
                  ? newItemTabDescription && (
                      <p className={`p1 ${styles.tabDescription}`}>
                        {newItemTabDescription}
                      </p>
                    )
                  : tabDescription && (
                      <p className={`p1 ${styles.tabDescription}`}>{tabDescription}</p>
                    )}
              </div>
            </Grid>
            {/* only show percent complete stuff on certain tabs */}
            {percentComplete && percentComplete.sections[title] && (
              <FeatureGating>
                <Grid item xs={6}>
                  <div className={styles.columnRightSection}>
                    <div className={styles.descriptionSection}>
                      {percentComplete.sections[title].sections_completed ===
                      percentComplete.sections[title].sections_to_complete ? (
                        <p className={styles.subtitleSection}>Section Completed!</p>
                      ) : (
                        <p className={styles.subtitleSection}>Next to complete</p>
                      )}
                      {/* Show the first error to all users */}
                      {percentCompleteTodos.length > 0 &&
                        percentCompleteTodos.slice(0, 1)}
                      {/* Allow admins to view the full list of todos if there is more than just one */}
                      {percentCompleteTodos.length > 1 &&
                        account &&
                        account.role === Constants.AccountRoles.Admin && (
                          <Tooltip title={percentCompleteTodos} arrow>
                            <div style={{ width: 'fit-content' }}>
                              <h3 className={'section-tag'} style={{ marginBottom: '0' }}>
                                View All
                              </h3>
                            </div>
                          </Tooltip>
                        )}
                    </div>
                    <div className={styles.sectionsComplete}>
                      <span className={styles.statsValue}>
                        {percentComplete.sections[title].sections_completed} /{' '}
                        {percentComplete.sections[title].sections_to_complete}
                      </span>
                      <span className={styles.statsTitle}>Completed</span>
                    </div>
                  </div>
                </Grid>
              </FeatureGating>
            )}
          </Grid>
        </div>
        {children}
      </div>
    </Loader>
  );
};

export default TabContent;
